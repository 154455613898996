const backend_base_url = () => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
        return "http://localhost:8080";
    } else if (process.env.REACT_APP_NODE_ENV === 'production') {
        return "https://camans.twc2.org.sg";
    }
}

const frontend_base_url = () => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
        return "http://localhost:3000";
    } else if (process.env.REACT_APP_NODE_ENV === 'production') {
        return "https://camans.twc2.org.sg";
    }
}

const socket_base_url = () => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
        return "http://localhost:4000";
    } else if (process.env.REACT_APP_NODE_ENV === 'production') {
        return "https://camans.twc2.org.sg";
    }
}

export {backend_base_url, frontend_base_url, socket_base_url};
