import { useContext, useState } from 'react';
import { Link, useNavigate} from "react-router-dom"
import { AuthContext } from "../../AuthProvider"
import AlertNotification from '../../components/alertNotification'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { checkCookieToken, detectBrowser, getIP } from '../../scripts/helpers';
import { backend_base_url } from '../../utils/helper';


export default function Login() {
  let navigate = useNavigate();
  const { onLogin, assignToken, setUsernameSession, setUserRole } = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [outcome, setOutcome] = useState({});

  const handleChange = (event) => {
    const target = event.target;
    if (target.name === 'user_email_address') {
      setUserEmailAddress(event.target.value);
    } else {
      setUserPassword(event.target.value);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (checkCookieToken(document.cookie)) {
      window.location.replace('/');
    } else {
      axios.post(`${backend_base_url()}/api/auth/login`, {
        userEmailAddress: userEmailAddress,
        userPassword: userPassword,
        actionDescription: `With ${detectBrowser(navigator)}.`
      }, {
        withCredentials: true,
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
      })
      .then(result => {
        assignToken(result.data.jwtAccessToken);
        setUsernameSession(result.data.username);
        setUserRole(result.data.role);
  
        setCookie('username', result.data.username, {
          // maxAge: 10,
          path: '/'
        });
        setCookie('userId', result.data.userId, {
          // maxAge: 10,
          path: '/'
        });
        setCookie('role', result.data.role, {
          // maxAge: 10,
          path: '/'
        });
        setCookie('token', result.data.jwtAccessToken, {
          // maxAge: 10,
          path: '/'
        });
  
        window.location.replace('/');
      })
      .catch(error => {
        setOutcome({
          message: error.response.data.message,
          type: 'Danger'
        });
  
        document.getElementById(error.response.data.input).className = 'focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
      });
    }
  };

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById('user_password');

    if (hidePassword) {
      document.getElementById('user_password').type = 'text';
      setHidePassword(false);
    } else {
      document.getElementById('user_password').type = 'password';
      setHidePassword(true);
    }
  };
  
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-56 w-auto"
            src="/logo_cms.png"
            alt="TWC2"
          />
          <h2 className="mt-6 text-center text-3xl font-medium text-gray-900">Login to Camans</h2>
        </div>

        <AlertNotification outcome={outcome} setOutcome={setOutcome} />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleFormSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="user_email_address"
                    name="user_email_address"
                    type="email"
                    autoComplete="user_email_address"
                    required
                    value={userEmailAddress}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="relative mt-1">
                  <input
                    id="user_password"
                    name="user_password"
                    type="password"
                    autoComplete="user_password"
                    required
                    value={userPassword}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                  {hidePassword ? (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility}>
                      <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  ) : (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility}>
                      <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">

                <div className="text-sm">
                  <Link to="/forgotPassword" className="font-medium text-sky-600 hover:text-sky-500">
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}