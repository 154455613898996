import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid'
import { getObject, displayValueByColumnName } from '../../../scripts/helpers'

// components
import Header from '../../../components/header'
import Loading from '../../../components/loading'
import MiniNavigationStub from '../../../components/miniNavigationStub'
import { format } from 'date-fns'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProblemAbridged(props) {
  const {rightStubObj} = props;

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});

  const navigate = useNavigate();
  const params = useParams();

  const {workerId, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction} = params;
  const tableSlug = 'workers';


  useEffect(() => {
    if (Object.keys(rightStubObj).length > 0 && rightStubObj.page === 'List') {
      rightStubObj.tableColumns.forEach((tableColumnResult) => {
        tableColumnResult.forEach(tableColumn => {
          Object.keys(tableColumn).forEach((key, index) => {
            if (rightStubObj['referenceData'][key]) {
              if (rightStubObj['referenceData'][key]['reference'] !== null && rightStubObj['referenceData'][key]['reference_synced'] === false) {
                getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['view']).then(referenced => {
                  const referencedTableSlug = referenced.data.table_slug;
                  const referencedColumnName = referenced.data.column_name;
    
                  getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['store']).then(storedAs => {
                    const storedAsColumnName = storedAs.data.column_name;
    
                    displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn[key]).then((displayValue) => {
                      const rightStubObjToUpdate = {...rightStubObj};
                      const tableColumnsToUpdate = [...rightStubObj.tableColumns];
                      tableColumn[key] = displayValue.data[Object.keys(displayValue.data)[0]];
                      rightStubObjToUpdate['referenceData'][key]['reference_synced'] = true;
    
                      rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                      rightStubObj.setRightStubObj(rightStubObjToUpdate);
                    })
                  });
                });
              }
            }
          });
        })
      });
    }
  }, [rightStubObj]);

  const navigateToObject = (event, objectId) => {
    if (event.target.checked === undefined) {
      navigate(`/${tableSlug}/${workerId}/jobs/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/problems/${objectId}`)
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Go up to worker') {
      navigate(`/workers/${workerId}`);
    } else if (buttonClicked === 'Go up to job') {
      navigate(`/workers/${workerId}/jobs/${workerSubsidiaryIdOrWorkerSubsidiaryAction}`);
    } else if (buttonClicked === 'Add problem') {
      navigate(`/workers/${workerId}/jobs/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/problems/add`);
    }
  };

  const navigateToForwardPage = () => {
    if (page < Math.ceil(rightStubObj.noOfRecords / 10)) {
      setPage(page + 1);
      setSort({});
    }
  };

  const navigateToBackwardPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setSort({});
    }
  };

  if (Object.keys(rightStubObj).length > 0) {
    return (
      <>
        <Header />
        <form onSubmit={(event) => handleFormSubmit(event)}>
          <nav className="bg-gray-100 sticky top-16 z-40">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
              <div className="flex items-center justify-between py-3">
                <div className="flex gap-075">
                  <button
                    className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-blue-500 hover:bg-blue-600')}
                  >
                    Go up to worker
                  </button>
                  <button
                    className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-blue-500 hover:bg-blue-600')}
                  >
                    Go up to job
                  </button>
                  <button
                    className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-blue-500 hover:bg-blue-600')}
                  >
                    Add problem
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className="mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-2">
            {/* Left side of navigation stub */}
            <MiniNavigationStub problemId={jobSubsidiaryIdOrJobSubsidiaryAction} jobId={workerSubsidiaryIdOrWorkerSubsidiaryAction} workerId={workerId} level='jobs' rightStubObj={rightStubObj} />
          </div>
          <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1">
            <div>
              <div className="shadow overflow-hidden">
                <div className="bg-gray-100 px-2 py-1 flex justify-between">
                  <span>
                    <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page && rightStubObj.page.toUpperCase()} {rightStubObj.table && rightStubObj.table.toUpperCase()}</h3>
                    <p className="text-sm font-medium text-gray-500">{rightStubObj['noOfRecords']} records</p>
                  </span>
                  {Math.ceil(rightStubObj['noOfRecords'] / 10) > 1 ? 
                    (
                      <span className="relative z-0 inline-flex shadow-sm">
                        <button
                          type="button"
                          className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                          onClick={navigateToBackwardPage}
                        >
                          <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </button>
                        <button
                          type="button"
                          className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                          disabled
                        >
                          {page} of {Math.ceil(rightStubObj['noOfRecords'] / 10)}
                        </button>
                        <button
                          type="button"
                          className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                          onClick={navigateToForwardPage}
                        >
                          <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </button>
                      </span>
                    ) : (<></>)}
                </div>
                <div className="border-t border-gray-200 p-0">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {rightStubObj.labelColumns !== undefined ? 
                                  (
                                    rightStubObj.labelColumns.map((label, index) => {
                                      return (
                                        <th
                                          scope="col"
                                          className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                          key={index}
                                        >
                                          <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort2(rightStubObj, index, page)}>
                                            {label}
                                            {rightStubObj.sort[index] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                            {rightStubObj.sort[index] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                          </span>
                                        </th>
                                      )
                                    })
                                  ) : (<></>)
                                }
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {rightStubObj['noOfRecords'] > 0 ?
                              (
                                <>
                                  {rightStubObj.tableColumns[page - 1].map((tableColumn, index) => {
                                    return (
                                      <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToObject(event, tableColumn.id)}>
                                        {rightStubObj.dashedLabelColumns.map((key, index2) => {
                                          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
                                          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                                          if (String(rightStubObj.tableColumns[page - 1][index][key]).match(timestampRegex)) {
                                            return (
                                              <td key={index2} className="px-2 py-1 text-sm text-gray-900 align-top">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd HH:mm')}</td>
                                            )
                                          } else if (String(rightStubObj.tableColumns[page - 1][index][key]).match(dateRegex)) {
                                            return (
                                              <td key={index2} className="px-2 py-1 text-sm text-gray-900 align-top">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd')}</td>
                                            )
                                          } else {
                                            return (
                                              <td key={index2} className="px-2 py-1 text-sm text-gray-900 align-top">{rightStubObj.tableColumns[page - 1][index][key]}</td>
                                            )
                                          }
                                        })}
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : '' }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    )

  } else {
    return (
      <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
    )
  }
}