import { useContext, useState } from 'react';
import { Link, useNavigate} from "react-router-dom"
import { AuthContext } from "../../AuthProvider"
import { useCookies } from 'react-cookie'
import axios from 'axios'
import ResetPasswordAlertNotification from '../../components/resetPasswordAlertNotification';
import { backend_base_url } from '../../utils/helper';


export default function ForgotPassword() {
  let navigate = useNavigate();
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [outcome, setOutcome] = useState({});

  const handleChange = (event) => {
    setUserEmailAddress(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    axios.post(`${backend_base_url()}/api/auth/sendPasswordResetEmail`, {
        userEmailAddress: userEmailAddress
      }, {
        withCredentials: true,
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
      })
      .then(result => {
        setOutcome({
          message: "Reset password email sent.",
          type: 'Success'
        });

        
      })
      .catch(error => {
        setOutcome({
          message: error.response.data.message,
          type: 'Danger'
        });

        document.getElementById(error.response.data.input).className = 'focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

        
      });
  };
  
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          
          <img
            className="mx-auto h-56 w-auto"
            src="/logo_cms.png"
            alt="TWC2"
          />
          <h2 className="mt-6 text-center text-3xl font-medium text-gray-900">Forgot password</h2>
        </div>

        <ResetPasswordAlertNotification outcome={outcome} setOutcome={setOutcome} />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleFormSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="user_email_address"
                    name="user_email_address"
                    type="email"
                    autoComplete="user_email_address"
                    required
                    value={userEmailAddress}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300  placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                  <p class="mt-2 text-sm text-gray-500">If you really cannot remember your email address, please contact info@twc2.org.sg</p>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent  text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Send password reset email
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}