export default function EditPrivilegeTable(props) {
  const {tableAndColumnNames, modifyPrivilege} = props;

  if (tableAndColumnNames.length === 0) {
    return (<></>);
  } else {
    return (
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
        <div className="mt-5">
          <div className="sm:flex sm:items-center sm:justify-between">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnNames[0]['table_name']}</h3>
          </div>
          <div className="mt-5 flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div className="py-2 align-top inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-300">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                          <th
                            scope="col"
                            rowSpan="2"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Field name
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Admin
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Manager
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Specialist
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Associate
                          </th>
                          <th
                            scope="col"
                            colSpan="5"
                            className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Recorder
                          </th>
                        </tr>
                        <tr>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            List mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Delete record
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            List mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Delete record
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            List mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Delete record
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            List mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Delete record
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            List mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit mode
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Delete record
                          </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {tableAndColumnNames.map((rowObject, index) => 
                        <tr key={index}>
                          <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{rowObject.column_name}</td>
                          {rowObject['privileges'].map((privilegeObject, index2) => 
                            {if (privilegeObject.mode === "List" || privilegeObject.mode === 'Delete') {
                              if (privilegeObject.privilege === 'Yes') {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top text-center" key={index2} rowSpan={tableAndColumnNames.length}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-green-300 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              } else if (privilegeObject.privilege === 'No') {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top text-center" key={index2} rowSpan={tableAndColumnNames.length}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              } else {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top text-center" key={index2} rowSpan={tableAndColumnNames.length}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-orange-300 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              }
                            } else {
                              if (privilegeObject.privilege === 'Yes') {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" key={index2}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-green-300 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              } else if (privilegeObject.privilege === 'No') {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" key={index2}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              } else {
                                return (
                                  <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" key={index2}>
                                    <button
                                      id={privilegeObject.id}
                                      key={index2}
                                      onClick={(e) => modifyPrivilege(privilegeObject, e)}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium text-gray-700 bg-orange-300 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    >
                                    {privilegeObject.privilege}
                                    </button>
                                  </td>
                                )
                              }
                            }} 
                          )}
                        </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}