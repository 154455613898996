import React, {useState, createContext, useEffect, useRef} from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { io } from 'socket.io-client'
import { countUnreadDirectMessageConversation, deepCopy, findGroupChatNamesWithUserToken } from './scripts/helpers';
import Notification from './components/notification';
import { useLocation } from 'react-router-dom';
import { socket_base_url } from './utils/helper'

const AuthContext = createContext(null);

export default function AuthProvider({ children }) {
  const [value, setValue] = useState({});
  const [notifications, setNotifications] = useState({});
  const [cookies, setCookie] = useCookies(['cookie']);
  const [token, setToken] = useState(null);
  const [sessionUsername, setSessionUsername] = useState(null);
  const [sessionRole, setSessionRole] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  
  const updates = useRef({});
  const show = useRef({});

  const socket = io(`${socket_base_url()}`);

  const assignToken = (token) => {
    setToken(token);
  };

  const setUsernameSession = (username) => {
    setSessionUsername(username);
  };

  const setUserRole = (role) => {
    setSessionRole(role);
  }

  const disappear = (content) => {
    if (content.type === 'dm') {
      delete(updates.current[content.fromId]);
    } else if (content.type === 'gc') {
      delete(updates.current[content.to]);
    }
    setNotifications(updates.current);

    setValue({
      token: cookies.token,
      sessionUsername: cookies.username, 
      sessionRole: cookies.role, 
      assignToken: assignToken,
      setUsernameSession: setUsernameSession,
      setUserRole: setUserRole,
      socket: socket,
      show: show,
      notifications: notifications,
      setNotifications: setNotifications,
      update: update,
      updates: updates,
      disappear: disappear
    });
  };

  const update = (content, show) => {
    if (content.type === 'dm') {
      updates.current[content.fromId]=content;
      show.current[content.fromId]=true;
    } else if (content.type === 'gc') {
      updates.current[content.to]=content;
      show.current[content.to]=true;
    }
    setNotifications(updates.current);
    setValue({
      token: cookies.token,
      sessionUsername: cookies.username, 
      sessionRole: cookies.role, 
      assignToken: assignToken,
      setUsernameSession: setUsernameSession,
      setUserRole: setUserRole,
      socket: socket,
      show: show,
      notifications: notifications,
      setNotifications: setNotifications,
      update: update,
      updates: updates,
      disappear: disappear
    });
  };
  
  useEffect(() => {
    setValue({
      token: cookies.token,
      sessionUsername: cookies.username, 
      sessionRole: cookies.role, 
      assignToken: assignToken,
      setUsernameSession: setUsernameSession,
      setUserRole: setUserRole,
      socket: socket,
      show: show,
      notifications: notifications,
      setNotifications: setNotifications,
      update: update,
      updates: updates,
      disappear: disappear
    });

    socket.on('connect', () => {
      let userToken;
      if (token) {
        userToken = token;
      } else {
        userToken = cookies['token'];
      }

      let username;
      if (sessionUsername) {
        username = sessionUsername;
        socket.emit("join-username", username);
      } else if (cookies['username']) {
        username = cookies['username'];
        socket.emit("join-username", username);
      }

      // count unread messages
      if (cookies['userId']) {
        countUnreadDirectMessageConversation(cookies['userId']).then(result => {
          setValue({
            token: cookies.token,
            unreadCount: result.data.count,
            sessionUsername: cookies.username, 
            sessionRole: cookies.role, 
            assignToken: assignToken,
            setUsernameSession: setUsernameSession,
            setUserRole: setUserRole,
            socket: socket,
            show: show,
            notifications: notifications,
            setNotifications: setNotifications,
            update: update,
            updates: updates,
            disappear: disappear
          });
        }).catch(err => {
          console.info(err)
        })
      }
    });
  }, [token, cookies['token'], sessionUsername, cookies['username']]);

  return (
    <AuthContext.Provider value={Object.keys(value).length > 0 && value}>
      <Notification notifications={notifications} /> 
      {children}
    </AuthContext.Provider>
  );
};

export {
  AuthProvider,
  AuthContext
}