import { Fragment, useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { findGroupChatConversation, getUnreadGroupChatConversation, listenToNotifications } from '../../scripts/helpers'
import { format, isToday, isYesterday } from 'date-fns'
import { AuthContext } from '../../AuthProvider';

export default function GroupChatConversationsShow(props) {
  const [groupChatConversation, setGroupChatConversation] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [unread, setUnread] = useState(false);

  // const context = useContext(AuthContext);
  // const {socket, show, setShow, notifications, setNotifications, update} = context;
  // useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const createMarkup = (message) => {
    return {__html: message};
  };

  const formatDate =(d) => {
    if (isToday(d)) {
      return `Today at ${format(d, 'HH:mm')}`;
    }

    if (isYesterday(d)) {
      return `Yesterday at ${format(d, 'HH:mm')}`;
    }

    return format(d, 'dd/MM/yyyy');
  };

  useEffect(() => {
    findGroupChatConversation(props.groupChatConversation.groupchatconversation_id, props.groupChatConversation.user_id).then(result => {
      const groupChatConversationId = result.data.id;
      getUnreadGroupChatConversation(groupChatConversationId, cookies['userId']).then(result2 => {
        setUnread(result2.data.unread);
      });

      setGroupChatConversation(result.data);
    }).catch(err => {
      console.log(err);
    })
  }, [props])

  if (groupChatConversation !== null) {
    return (
      <a href={`/group-chats/${groupChatConversation.slug}`} className="px-2 flex pt-1 pb-2 hover:bg-gray-50 max-h-[57px] overflow-hidden">
        <div className="flex justify-between w-[100%]">
          <div className="flex space-x-2">
            <div className="flex flex-col">
              <h3 className="font-medium">
                <span className="text-sm text-gray-900">{groupChatConversation.group_chat_name} <span className="font-light text-xs">{groupChatConversation.groupChats[0] !== undefined ? formatDate(new Date(groupChatConversation.groupChats[0].date_record_created)) : ''}</span></span>
              </h3>
              {/* <p className="text-sm font-medium text-gray-900">{groupChatConversation.users[0].user_username}</p> */}
              <div className="text-sm text-gray-700" dangerouslySetInnerHTML={groupChatConversation.groupChats[0] !== undefined ? createMarkup(groupChatConversation.groupChats[0].message) : createMarkup('')}></div>
            </div>
          </div>
          {unread === true && (
            <div>
              <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">
                •
              </span>
            </div>
          )}
        </div>
      </a>
    )
  } else {
    return <></>
  }
}