import { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { populateActions } from '../scripts/helpers'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Action(action, position) {
  if (action.position === position) {
    if (action.position === 'left') {
      if (action.hidden) {
        return null;
      } else {
        if (action.disabled) {
          return (
            <button
              type="button"
              className={`inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-400 bg-gray-300 cursor-not-allowed`}
              disabled
            >
              {action.name}
            </button>
          )
        } else {
          if (action.href === '#') {
            return (
              <button
                type={action.type}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
                onClick={action.click}
              >
                {action.name}
              </button>
            )
          } else {
            return (
              <Link to={action.href}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </Link>
            )
          }
        }
      }
    } else {
      if (action.hidden) {
        return null;
      } else {
        if (action.disabled) {
          return (
            <button
              type="button"
              className={`inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-400 bg-gray-300 cursor-not-allowed`}
              disabled
            >
              {action.name}
            </button>
          )
        } else {
          if (action.href === '#') {
            return (
              <button
                type={action.type}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </button>
            )
          } else {
            return (
              <Link to={action.href}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </Link>
            )
          }
        }
      }
    }
  }
}

function TinyAction(actions, position) {
  if (position === 'left') {
    if (actions.filter((action) => action.position === 'left').length > 0) {
      return (
        <div className="flex items-center justify-between">
          <div className="flex w-full justify-between gap-075">
            {actions.map((action, index) => (
                <div key={index}>{Action(action, "left")}</div>
            ))}
          </div>
        </div>
      )
    }
  } else {
    if (actions.filter((action) => action.position === 'right').length > 0) {
      return (
        <div className="flex items-center justify-between">
          <div className="flex w-full justify-between gap-075">
            {actions.map((action, index) => (
                <div key={index}>{Action(action, "right")}</div>
            ))}
          </div>
        </div>
      )
    }
  }
}

export default function SubHeader2(props) {
  const {subHeaderObj} = props;
  const [actions, setActions] = useState([]);

  useEffect(() => {
    if (Object.keys(subHeaderObj).length > 0) {
      if (subHeaderObj.workerSubsidiarySlugOrWorkerAction === undefined) {
        // worker view
        const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId);
        setActions(actionsToUpdate);
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined
      ) {
        if (subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'edit') {
          // worker edit
          const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId);
          setActions(actionsToUpdate);
        } else if (subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
          const actionsToUpdate = populateActions(subHeaderObj.workerSubsidiarySlugOrWorkerAction, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId);
          setActions(actionsToUpdate);
        } else if (subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'jobs') {
          const actionsToPut = [];
          actionsToPut.push({
            name: 'Go up to worker',
            href: `/workers/${subHeaderObj.workerId}`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: false,
          });

          if (subHeaderObj.privileges.Add.id !== 'No' && subHeaderObj.page === 'List') {
            actionsToPut.push({
              name: 'Add job',
              href: `/workers/${subHeaderObj.workerId}/jobs/add`,
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            });
          }
          setActions(actionsToPut);
        } else {
          if (subHeaderObj.privileges.Add.id !== 'No' && subHeaderObj.page === 'List') {
            setActions([
              {
                name: `Add ${subHeaderObj.subsidiary.toLowerCase()}`,
                href: `/workers/${subHeaderObj.workerId}/${subHeaderObj.workerSubsidiarySlugOrWorkerAction}/add`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            ]);
          } else {
            setActions([]);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined
      ) {
        if (subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || subHeaderObj.workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments') {
          const actionsToUpdate2 = [];
          if (subHeaderObj.privileges.Add.id !== 'No' && subHeaderObj.page === 'Add' && subHeaderObj.page === 'Add') {
            actionsToUpdate2.push(
              {
                name: 'Upload',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            );
          }
          if (subHeaderObj.privileges.Edit.id !== 'No' && subHeaderObj.page === 'View') {
            actionsToUpdate2.push(
              {
                name: 'Edit',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            );
          }
          
          actionsToUpdate2.push({
            name: 'Go back to list',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: false,
          });

          if (subHeaderObj.privileges.Delete.id !== 'No' && subHeaderObj.page !== 'Add') {
            actionsToUpdate2.push(
              {
                name: 'Delete',
                href: '#',
                class: 'bg-red-500 hover:bg-red-600',
                position: 'left',
                hidden: false,
              },
            );
          }

          setActions(actionsToUpdate2);
        } else {
          if (subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction === 'add') {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction);
            setActions(actionsToUpdate);
          } else {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction, subHeaderObj.subsidiary);
            setActions(actionsToUpdate);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction === undefined
      ) {
        // edit job subsidiary record
        if (subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'edit') {
          const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction);
          setActions(actionsToUpdate);
        } else if (subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'facepic-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'pdpa-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
          let addHidden = true;
          let editHidden = true;
          let deleteHidden = true;

          addHidden = subHeaderObj.privileges['Add']['id'] !== 'No' ? false : true;
          editHidden = subHeaderObj.privileges['Edit']['id'] !== 'No' ? false : true;
          deleteHidden = subHeaderObj.privileges['Delete']['id'] === 'Yes' ? false : true;
          const canDownload = (subHeaderObj.privileges['View']['file_path'] === 'Yes' || subHeaderObj.privileges['Edit']['file_path'] !=='No');

          if (subHeaderObj.page === 'List') {
            const buttons = [
              {
                name: 'Add',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: addHidden,
              },
              {
                name: 'Edit',
                href: '#',
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: editHidden,
              }
            ];

            if (canDownload) {
              buttons.push(
                {
                  name: 'Download',
                  href: '#',
                  class: 'bg-blue-500 hover:bg-blue-600',
                  position: 'left',
                  hidden: false,
                }
              )
            }

            buttons.push(
              {
                name: 'Delete',
                href: '#',
                class: 'bg-red-500 hover:bg-red-600',
                position: 'left',
                hidden: deleteHidden,
              }
            )

            setActions(buttons);
          }
        } else {
          if (subHeaderObj.privileges.Add.id !== 'No' && subHeaderObj.page === 'List') {
            setActions([
              {
                name: `Add ${subHeaderObj.subsidiary.toLowerCase()}`,
                href: `/workers/${subHeaderObj.workerId}/jobs/${subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction}/${subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/add`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            ]);
          } else {
            setActions([]);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === undefined
      ) {

        if (subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'facepic-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'pdpa-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments') {
          let addHidden = true;

          addHidden = subHeaderObj.privileges['Add']['id'] !== 'No' ? false : true;
          if (subHeaderObj.page === 'Add') {
            setActions([
              {
                name: 'Upload',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: addHidden,
              },
              {
                name: 'Go back to list',
                href: '#',
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              },
            ]);
          } else if (subHeaderObj.page !== 'No') {
            const actionsToUpdate2 = [];

            if (subHeaderObj.privileges.Edit.id !== 'No' && subHeaderObj.page === 'View') {
              actionsToUpdate2.push({
                name: 'Edit',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              });
            }

            actionsToUpdate2.push({
              name: 'Go back to list',
              href: '#',
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            });

            if (subHeaderObj.privileges.Delete.id !== 'No' && subHeaderObj.page !== 'Add') {
              actionsToUpdate2.push({
                name: 'Delete',
                href: '#',
                class: 'bg-red-500 hover:bg-red-600',
                position: 'left',
                hidden: false,
              });
            }

            setActions(actionsToUpdate2);
          }
        } else {
          if (subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction === 'add') {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction);
            setActions(actionsToUpdate);
          } else {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction, subHeaderObj.subsidiary, subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction, subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction, subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction);
            setActions(actionsToUpdate);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
        subHeaderObj.problemSubsidiaryIdOrProblemSubsidiaryAction === undefined
      ) {
        if (subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'edit') {
          // worker editx
          const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId);
          setActions(actionsToUpdate);
        } else if (subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'facepic-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'pdpa-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
          let addHidden = true;
          let editHidden = true;
          let deleteHidden = true;

          addHidden = subHeaderObj.privileges['Add']['id'] !== 'No' ? false : true;
          editHidden = subHeaderObj.privileges['Edit']['id'] !== 'No' ? false : true;
          deleteHidden = subHeaderObj.privileges['Delete']['id'] === 'Yes' ? false : true;
          const canDownload = (subHeaderObj.privileges['View']['file_path'] === 'Yes' || subHeaderObj.privileges['Edit']['file_path'] !=='No');

          if (subHeaderObj.page === 'List') {
            const buttons = [
              {
                name: 'Add',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: addHidden,
              },
              {
                name: 'Edit',
                href: '#',
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: editHidden,
              }
            ];

            if (canDownload) {
              buttons.push(
                {
                  name: 'Download',
                  href: '#',
                  class: 'bg-blue-500 hover:bg-blue-600',
                  position: 'left',
                  hidden: false,
                }
              )
            }

            buttons.push(
              {
                name: 'Delete',
                href: '#',
                class: 'bg-red-500 hover:bg-red-600',
                position: 'left',
                hidden: deleteHidden,
              }
            )

            setActions(buttons);
          }
        } else {
          if (subHeaderObj.privileges.Add.id !== 'No' && subHeaderObj.page === 'List') {
            setActions([
              {
                name: `Add ${subHeaderObj.subsidiary.toLowerCase()}`,
                href: `/workers/${subHeaderObj.workerId}/jobs/${subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction}/problems/${subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction}/${subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction}/add`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            ]);
          } else {
            setActions([]);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
        subHeaderObj.problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiaryAction === undefined
      ) {
        if (subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'facepic-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'pdpa-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments') {
          let addHidden = true;

          addHidden = subHeaderObj.privileges['Add']['id'] !== 'No' ? false : true;

          if (subHeaderObj.page === 'Add') {
            setActions([
              {
                name: 'Upload',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: addHidden,
              },
              {
                name: 'Go back to list',
                href: '#',
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              },
            ]);
          } else if (subHeaderObj.page !== 'No') {
            const actionsToUpdate2 = [];

            if (subHeaderObj.privileges.Edit.id !== 'No' && subHeaderObj.page === 'View') {
              actionsToUpdate2.push({
                name: 'Edit',
                href: `#`,
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              });
            }

            actionsToUpdate2.push({
              name: 'Go back to list',
              href: '#',
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            });

            if (subHeaderObj.privileges.Delete.id !== 'No' && subHeaderObj.page !== 'Add') {
              actionsToUpdate2.push({
                name: 'Delete',
                href: '#',
                class: 'bg-red-500 hover:bg-red-600',
                position: 'left',
                hidden: false,
              });
            }

            setActions(actionsToUpdate2);
          }
        } else {
          if (subHeaderObj.problemSubsidiaryIdOrProblemSubsidiaryAction === 'add') {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction);
            setActions(actionsToUpdate);
          } else {
            const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId, subHeaderObj.workerSubsidiarySlugOrWorkerAction, subHeaderObj.subsidiary, subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction, subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction, subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction, subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction, subHeaderObj.problemSubsidiaryIdOrProblemSubsidiaryAction);
            setActions(actionsToUpdate);
          }
        }
      } else if (
        subHeaderObj.workerSubsidiarySlugOrWorkerAction !== undefined && 
        subHeaderObj.workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && 
        subHeaderObj.jobSubsidiaryIdOrJobSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined &&
        subHeaderObj.problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined && 
        subHeaderObj.problemSubsidiaryAction !== undefined
      ) {
        const actionsToUpdate = populateActions(subHeaderObj.table, subHeaderObj.privileges, subHeaderObj.page, subHeaderObj.workerId);
        setActions(actionsToUpdate);
      }
    }
  }, [subHeaderObj]);
  
  if (actions !== undefined && actions.filter(action => action.hidden === false).length > 0) {
    return (
      <>
      <nav className="bg-gray-100 sticky top-16 z-40">
        {/* Actions desktop etc. */}
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
          <div className="flex items-center justify-between py-3">
            <div className="flex gap-075">
              {actions.map((action, index) => {
                  if (!action.hidden) {
                    return <div key={index}>{Action(action, "left")}</div>
                  }
                }
              )}
            </div>
            <div className="flex">
              {actions.map((action, index) => {
                  if (!action.hidden) {
                    return <div key={index}>{Action(action, "right")}</div>
                  }
                }
              )}
            </div>
          </div>
        </div>
        {/* Actions tiny screen etc. */}
        {/* <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid gap-y-3	py-3 xxs:hidden">
          <>{TinyAction(actions, "left")}</>
          <>{TinyAction(actions, "right")}</>
        </div> */}
      </nav>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}