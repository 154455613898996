import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, prepareTableColumnsWithPrivileges, deleteObject, getSubsidiaryColumnsToDisplay, prepareSubsidiaryTableColumnsWithPrivileges } from '../../../scripts/helpers'
import { getYear } from 'date-fns'

// components
import Header from '../../../components/header'
import SubHeader from '../../../components/subheader'
import Modal from '../../../components/modal'
import AlertNotification from '../../../components/alertNotification'
import TopAndMiddleNavigation from '../../../components/workers/topAndMiddleNavigation'
import NavigationStub from '../../../components/navigationStub'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkersSubsidiaryView() {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentSingularSubsidiary, setCurrentSingularSubsidiary] = useState('');
  const [subsidiaryColumnsToDisplay, setSubsidiaryColumnsToDisplay] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [subsidiaryTableColumns, setSubsidiaryTableColumns] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  const {workerId, subsidiarySlug, workerSubsidiaryId} = params;
  const tableSlug = 'workers';
  
  const privilegeObject = {
    tableSlug: subsidiarySlug, 
    token: cookies['token']
  };

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['View']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivileges(tableSlug, privilegesToUpdate, 'List', workerId, subsidiarySlug).then(preparedTableColumns => {
        setTableColumns(preparedTableColumns);

        prepareSubsidiaryTableColumnsWithPrivileges(subsidiarySlug, privilegesToUpdate, 'View', workerId, workerSubsidiaryId).then(preparedSubsidiaryTableColumns => {
          setSubsidiaryTableColumns(preparedSubsidiaryTableColumns);
        }); 
      });

    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    getSubsidiaryColumnsToDisplay(subsidiarySlug).then(result => {
      const subsidiaryColumnsToDisplayToUpdate = [];
      result.data.forEach(row => {
        subsidiaryColumnsToDisplayToUpdate.push(row.column_name);
      });

      setSubsidiaryColumnsToDisplay(subsidiaryColumnsToDisplayToUpdate);
      setCurrentSingularSubsidiary(result.data[0]['table_name']);
    });
  }, [subsidiarySlug]);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(subsidiarySlug, privileges, 'View', workerId);
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      navigate(`/${tableSlug}/${workerId}/${subsidiarySlug}/${workerSubsidiaryId}/edit`);
    } else if (buttonClicked === 'Delete') {
      setShowDeleteConfirmation(true);
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject(subsidiarySlug, workerSubsidiaryId).then(result => {
      navigate(`/${tableSlug}/${workerId}/${subsidiarySlug}`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });

      
    });
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single={currentSingularSubsidiary.toLowerCase()} plural={subsidiarySlug.replace(/-/g, ' ').toLowerCase()} />
        <TopAndMiddleNavigation tableColumns={tableColumns} page='workersSubsidiaryView' id={params.workerId} subsidiarySlug={subsidiarySlug} subsidiary={currentSingularSubsidiary} />

        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-20">
          {/* Left side of navigation stub */}
          <NavigationStub tableColumns={tableColumns} />
          {/* Right side of navigation stub */}
          <div>
            <div className="shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">VIEW {currentSingularSubsidiary.toUpperCase()}</h3>
              </div>
              <div className="border-t border-gray-200 p-0">
                <dl className="divide-y divide-gray-200" style={{overflowWrap: 'break-word'}}>
                  {subsidiaryTableColumns.map((subsidiaryTableColumn, index) => {
                    if (subsidiaryTableColumn['label_name'] === 'Attachment path') {
                      return (
                        <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                          <dt className="text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']}</dt>
                          <dd className="text-sm sm:col-span-2">
                            <a href={`${subsidiaryTableColumn['value']}`} download className="text-sky-500 font-medium hover:text-sky-700">{subsidiaryTableColumn['value']}</a>
                          </dd>
                        </div>
                      )
                    } else {
                      return (
                        <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                          <dt className="text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']}</dt>
                          <dd className="text-sm sm:col-span-2">
                            {subsidiaryTableColumn['value']}
                          </dd>
                        </div>
                      )
                    }
                  })}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}