import { useState, useEffect, useRef, Fragment, useContext} from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/solid'
import { getPrivilegeForCurrentPage, getSubsidiaryColumnsToDisplay, populateMonthCalendarDays, populateWeekCalendarDays, prepareListWithPrivileges, listenToNotifications } from '../../../scripts/helpers'

// components
import Header from '../../../components/header'
import MiniNavigationStub from '../../../components/miniNavigationStub'
import { sub, format, add } from 'date-fns'
import { AuthContext } from '../../../AuthProvider'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ClientCalendar() {
  const container = useRef(null)
  const containerNav = useRef(null)
  const containerOffset = useRef(null)

  const [monthDays, setMonthDays] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [level, setLevel] = useState('workers');
  const [dateCounter, setDateCounter] = useState(new Date());

  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [calendarView, setCalendarView] = useState('Month view');
  const [monthView, setMonthView] = useState(format(new Date(), 'MMM yyyy'));
  const [privileges, setPrivileges] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentSingularSubsidiary, setCurrentSingularSubsidiary] = useState('');
  const [subsidiaryColumnsToDisplay, setSubsidiaryColumnsToDisplay] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const navigate = useNavigate();
  const params = useParams();

  const {workerId, workerSubsidiarySlugOrWorkerAction} = params;
  const tableSlug = 'workers';
  
  const privilegeObject = {
    tableSlug: workerSubsidiarySlugOrWorkerAction, 
    token: cookies['token']
  };

  const transformPrivileges = (privilegesResult, action) => {
    const resultPrivileges = privilegesResult;
    const privilegesToUpdate = {};
    resultPrivileges.forEach((privilege) => {
      if (privilegesToUpdate[privilege['mode']] === undefined) {
        privilegesToUpdate[privilege['mode']] = {};
      }

      privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
    });

    // if user is not authorised to view this page, redirect back to home page
    if (privilegesToUpdate[action]['id'] === 'No') {
      navigate('/');
    }

    return privilegesToUpdate;
  };

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const privilegesToUpdate = transformPrivileges(privilegesResult, 'List');
      setPrivileges(privilegesToUpdate);

      prepareListWithPrivileges(workerId, null, null, workerSubsidiarySlugOrWorkerAction, true).then(result => {
        const {data} = result;

        setTableColumns(data.data);
        const currentMinute = new Date().getHours() * 60;
        container.current.scrollTop = ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) * currentMinute) / 1440;
      });
    }).catch(err => {
      navigate('/');
    });


  }, []);

  useEffect(() => {
    if (tableColumns.length > 0) {
      setMonthDays(populateMonthCalendarDays(tableColumns, workerId, dateCounter));
      setWeekDays(populateWeekCalendarDays(tableColumns, workerId, dateCounter));
    }
  }, [tableColumns]);

  useEffect(() => {
    getSubsidiaryColumnsToDisplay(workerSubsidiarySlugOrWorkerAction).then(result => {
      const subsidiaryColumnsToDisplayToUpdate = [];
      result.data.forEach(row => {
        subsidiaryColumnsToDisplayToUpdate.push(row.column_name);
      });

      setSubsidiaryColumnsToDisplay(subsidiaryColumnsToDisplayToUpdate);
      setCurrentSingularSubsidiary(result.data[0]['table_name']);
    });
  }, [workerSubsidiarySlugOrWorkerAction]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      navigate(`/${tableSlug}/${workerId}/edit`);
    } else if (buttonClicked === 'Delete') {
      setShowDeleteConfirmation(true);
    }
  };

  const changeCalendarView = (event) => {
    const view = event.target.innerHTML;
    setCalendarView(view);
  };

  const goPrevious = (event) => {
    if (calendarView === 'Week view') {
      let dateCounterToUpdate = dateCounter;
      dateCounterToUpdate = sub(new Date(dateCounterToUpdate), {weeks: 1});
      setDateCounter(dateCounterToUpdate);
      setMonthView(format(new Date(dateCounterToUpdate), 'MMMM yyyy'));

      setWeekDays(populateWeekCalendarDays(tableColumns, workerId, dateCounterToUpdate));
    } else {
      let dateCounterToUpdate = dateCounter;
      dateCounterToUpdate = sub(new Date(dateCounterToUpdate), {months: 1});
      setDateCounter(dateCounterToUpdate);
      setMonthView(format(new Date(dateCounterToUpdate), 'MMMM yyyy'));

      setMonthDays(populateMonthCalendarDays(tableColumns, workerId, dateCounterToUpdate));
    }
  };

  const goToday = (event) => {
    let dateCounterToUpdate = dateCounter;
    dateCounterToUpdate = new Date();
    setDateCounter(dateCounterToUpdate);
    setMonthView(format(new Date(dateCounterToUpdate), 'MMMM yyyy'));

    setWeekDays(populateWeekCalendarDays(tableColumns, workerId, dateCounterToUpdate));
    setMonthDays(populateMonthCalendarDays(tableColumns, workerId, dateCounterToUpdate));
  };

  const goNext = (event) => {
    if (calendarView === 'Week view') {
      let dateCounterToUpdate = dateCounter;
      dateCounterToUpdate = add(new Date(dateCounterToUpdate), {weeks: 1});
      setDateCounter(dateCounterToUpdate);
      setMonthView(format(new Date(dateCounterToUpdate), 'MMMM yyyy'));

      setWeekDays(populateWeekCalendarDays(tableColumns, workerId, dateCounterToUpdate));
    } else {
      let dateCounterToUpdate = dateCounter;
      dateCounterToUpdate = add(new Date(dateCounterToUpdate), {months: 1});
      setDateCounter(dateCounterToUpdate);
      setMonthView(format(new Date(dateCounterToUpdate), 'MMMM yyyy'));

      setMonthDays(populateMonthCalendarDays(tableColumns, workerId, dateCounterToUpdate));
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <div className="mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-2">
          {/* Left side of navigation stub */}
          <MiniNavigationStub workerId={workerId} level={level} />
        </div>
        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1">
          <div>
            <div className="shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">VIEW {workerSubsidiarySlugOrWorkerAction.replace(/-/g, ' ').toUpperCase()}</h3>
              </div>
              <div className="border-t flex flex-col" style={{height: '75vh'}}>
                <header className="relative z-50 flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
                  <h1 className="text-lg font-semibold text-gray-900">
                    <time dateTime="2022-01">{monthView}</time>
                  </h1>
                  <div className="flex items-center">
                    <div className="flex items-center shadow-sm md:items-stretch">
                      <button
                        type="button"
                        className="flex items-center justify-center border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                        onClick={goPrevious}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                        onClick={goToday}
                      >
                        Today
                      </button>
                      <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                      <button
                        type="button"
                        className="flex items-center justify-center border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                        onClick={goNext}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="hidden md:ml-4 md:flex md:items-center">
                      <Menu as="div" className="relative">
                        <Menu.Button
                          type="button"
                          className="flex items-center border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                        >
                          {calendarView}
                          <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                    onClick={(event) => changeCalendarView(event)}
                                  >
                                    Week view
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm'
                                    )}
                                    onClick={(event) => changeCalendarView(event)}
                                  >
                                    Month view
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <div className="ml-6 h-6 w-px bg-gray-300" />
                    </div>
                    <Menu as="div" className="relative ml-6 md:hidden">
                      <Menu.Button className="-mx-2 flex items-center border border-transparent p-2 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open menu</span>
                        <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Create event
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  Go to today
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                  onClick={(event) => changeCalendarView(event)}
                                >
                                  Week view
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                  onClick={(event) => changeCalendarView(event)}
                                >
                                  Month view
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </header>
                { calendarView === 'Week view' ? (
                  <div ref={container} className="flex flex-auto flex-col overflow-auto bg-white">
                    <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
                      <div
                        ref={containerNav}
                        className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
                      >
                        <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                          {Object.keys(weekDays['datesOfWeek']).map((key) => {
                            return (
                              <button type="button" className="flex flex-col items-center pt-2 pb-3">
                                {key[0]} <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">{weekDays['datesOfWeek'][key]}</span>
                              </button>
                            )
                          })}
                          {/* <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            S <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">03</span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            M <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">04</span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            T <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">05</span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            W{' '}
                            <span className="mt-1 flex h-8 w-8 items-center justify-center bg-sky-600 font-semibold text-white">
                              06
                            </span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            T <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">07</span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            F <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">08</span>
                          </button>
                          <button type="button" className="flex flex-col items-center pt-2 pb-3">
                            S <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">09</span>
                          </button> */}
                        </div>

                        <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                          <div className="col-end-1 w-14" />
                          {Object.keys(weekDays['datesOfWeek']).map((key) => {
                            return (
                              <div className="flex items-center justify-center py-3">
                                <span>
                                  {key.slice(0,3)} <span className="items-center justify-center font-semibold text-gray-900">{weekDays['datesOfWeek'][key]}</span>
                                </span>
                              </div>
                            )
                          })}
                          
                          {/* <div className="flex items-center justify-center py-3">
                            <span>
                              Sun <span className="items-center justify-center font-semibold text-gray-900">03</span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span>
                              Mon <span className="items-center justify-center font-semibold text-gray-900">04</span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span>
                              Tue <span className="items-center justify-center font-semibold text-gray-900">05</span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span className="flex items-baseline">
                              Wed{' '}
                              <span className="mxl-1.5 flex h-8 w-8 items-center justify-center bg-sky-600 font-semibold text-white">
                                06
                              </span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span>
                              Thu <span className="items-center justify-center font-semibold text-gray-900">07</span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span>
                              Fri <span className="items-center justify-center font-semibold text-gray-900">08</span>
                            </span>
                          </div>
                          <div className="flex items-center justify-center py-3">
                            <span>
                              Sat <span className="items-center justify-center font-semibold text-gray-900">09</span>
                            </span>
                          </div> */}
                        </div>
                      </div>
                      <div className="flex flex-auto">
                        <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                        <div className="grid flex-auto grid-cols-1 grid-rows-1">
                          {/* Horizontal lines */}
                          <div
                            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                            style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                          >
                            <div ref={containerOffset} className="row-end-1 h-7"></div>
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                12AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                1AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                2AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                3AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                4AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                5AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                6AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                7AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                8AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                9AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                10AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                11AM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                12PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                1PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                2PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                3PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                4PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                5PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                6PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                7PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                8PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                9PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                10PM
                              </div>
                            </div>
                            <div />
                            <div>
                              <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                11PM
                              </div>
                            </div>
                            <div />
                          </div>

                          {/* Vertical lines */}
                          <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                            <div className="col-start-1 row-span-full" />
                            <div className="col-start-2 row-span-full" />
                            <div className="col-start-3 row-span-full" />
                            <div className="col-start-4 row-span-full" />
                            <div className="col-start-5 row-span-full" />
                            <div className="col-start-6 row-span-full" />
                            <div className="col-start-7 row-span-full" />
                            <div className="col-start-8 row-span-full w-8" />
                          </div>

                          {/* Events */}
                          <ol
                            className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                            style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
                          >
                            {weekDays['events'].map((event, index) => {
                              if (weekDays['overlap'][event.colStart][event.id].length > 0) {
                                return (
                                  <li className={`relative mt-px flex col-start-${event.colStart}`} style={{ gridRow: event.gridRow }}>
                                    <Link
                                      to={event.href}
                                      className={`group absolute inset-1 flex flex-col overflow-y-auto bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100 z-10`}
                                      style={{width: `${95/weekDays['overlap'][event.colStart][event.id].length}%`, marginLeft: `${95/weekDays['overlap'][event.colStart][event.id].length*weekDays['overlap'][event.colStart][event.id].indexOf(event.id.toString())}%`}}
                                    >
                                      <p className="order-1 font-semibold text-blue-700">{event.title}</p>
                                      <p className="text-blue-500">
                                        <time dateTime="2022-01-12T06:00">{event.time}</time>
                                      </p>
                                    </Link>
                                  </li>
                                )
                              } else {
                                return (
                                  <li className={`relative mt-px flex col-start-${event.colStart}`} style={{ gridRow: event.gridRow }}>
                                    <Link
                                      to={event.href}
                                      className="group absolute inset-1 flex flex-col overflow-y-auto bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                                    >
                                      <p className="order-1 font-semibold text-blue-700">{event.title}</p>
                                      <p className="text-blue-500">
                                        <time dateTime="2022-01-12T06:00">{event.time}</time>
                                      </p>
                                    </Link>
                                  </li>
                                )
                              }
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                    <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                      <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                      </div>
                      <div className="bg-white py-2">
                        M<span className="sr-only sm:not-sr-only">on</span>
                      </div>
                      <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">ue</span>
                      </div>
                      <div className="bg-white py-2">
                        W<span className="sr-only sm:not-sr-only">ed</span>
                      </div>
                      <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">hu</span>
                      </div>
                      <div className="bg-white py-2">
                        F<span className="sr-only sm:not-sr-only">ri</span>
                      </div>
                      <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">at</span>
                      </div>
                    </div>
                    <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                      <div className={`hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-${Math.round(monthDays.length / 7)} lg:gap-px`}>
                        {monthDays.map((day, index) => (
                          <div
                            key={`${day.date}_${index}`}
                            className={classNames(
                              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                              'relative pt-2'
                            )}
                          >
                            <time
                              dateTime={day.date}
                              className={
                                classNames(day.isToday
                                  ? 'flex h-6 w-6 items-center justify-center bg-sky-600 font-semibold text-white'
                                  : undefined, 'ml-2')
                              }
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            {day.events.length > 0 && (
                              <ol className="mt-2">
                                {day.events.map((event, index) => (
                                  <li key={event.id} style={{background: event.color, marginTop: event.marginTop, height: '24px'}} className={classNames(
                                    event.margin,
                                    index > 0 ? 'mt-2' : ''
                                  )}>
                                    <Link to={event.href} className="group flex">
                                      {event.position === 'start' || event.position === 'single' ? (
                                        <>
                                        <p className="flex-auto truncate font-medium text-white">
                                          {event.title}
                                        </p>
                                        <time
                                          dateTime={event.datetime}
                                          className="ml-3 hidden flex-none text-white xl:block"
                                        >
                                          {event.time}
                                        </time>
                                        </>
                                      ) : (
                                        <>
                                        <p className="flex-auto truncate font-medium" style={{color: event.color}}>
                                          {event.title}
                                        </p>
                                        <time
                                          dateTime={event.datetime}
                                          className="ml-3 hidden flex-none text-white xl:block"
                                          style={{color: event.color}}
                                        >
                                          {event.time}
                                        </time>
                                        </>
                                      )}
                                    </Link>
                                  </li>
                                ))}
                              </ol>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className={`isolate grid w-full grid-cols-7 grid-rows-${Math.round(monthDays.length / 7)} gap-px lg:hidden`}>
                        {monthDays.map((day, index) => (
                          <button
                            key={`${day.date}_${index}`}
                            type="button"
                            className={classNames(
                              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                              (day.isSelected || day.isToday) && 'font-semibold',
                              day.isSelected && 'text-white',
                              !day.isSelected && day.isToday && 'text-sky-600',
                              !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                              !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                              'flex h-14 flex-col hover:bg-gray-100 focus:z-10'
                            )}
                          >
                            <time
                              dateTime={day.date}
                              className={classNames(
                                day.isSelected && 'flex h-6 w-6 items-center justify-center',
                                day.isSelected && day.isToday && 'bg-sky-600',
                                day.isSelected && !day.isToday && 'bg-gray-900',
                                'ml-auto'
                              )}
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            <p className="sr-only">{day.events.length} events</p>
                            {day.events.length > 0 && (
                              <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                {day.events.map((event) => (
                                  <div key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 bg-gray-400" />
                                ))}
                              </div>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                ) }
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}