import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {getNumberOfTables} from '../scripts/helpers'
import { useCookies } from 'react-cookie'

export default function TopNavigationButton (props) {
  // const {name, table, workerId, link, jobSubsidiarySlug} = props;
  const {title, table, workerId, jobId, problemId, link, jobSubsidiarySlug, page, close} = props.data;
  const [numberOfTables, setNumberOfTables] = useState('');
  const [color, setColor] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);

  useEffect(() => {
    if (table !== undefined && workerId !== undefined && jobId !== undefined && problemId !== undefined) {
      getNumberOfTables(table, workerId, jobId, problemId, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('turqoise');
        if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin' || cookies['role'] === 'Manager') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    } else if (table !== undefined && workerId !== undefined && jobId !== undefined) {
      getNumberOfTables(table, workerId, jobId, null, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('teal2');
        if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin' || cookies['role'] === 'Manager') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    } else if (table !== undefined && workerId !== undefined) {
      getNumberOfTables(table, workerId, null, null, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('sky');
        if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin' || cookies['role'] === 'Manager') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    }
  }, [table, workerId, jobId, problemId]);

  if (page === 'View' || page === 'List' || page === undefined) {
    return (
      <li className="col-span-1 flex shadow-sm hover:bg-sky-700">
        {title.indexOf('attachment') !== -1 ? 
          (
            <Link onClick={close} to={link} className="w-full h-full" target='_blank'>
              <div className={`flex-1 flex justify-between border border-sky-800 bg-sky-800 hover:bg-sky-900 h-full min-h-[50px]`}>
                <div className="flex-grow px-2 py-1 text-sm break-normal">
                  <span className='text-white font-medium'>
                    {title}
                  </span>
                </div>
                <div className={`px-2 py-1 text-sm text-white font-medium`}>
                  {title.indexOf('Go up to') === -1 ? numberOfTables : ''}
                  {/* {numberOfTables} */}
                </div>
              </div>
            </Link>
          ) : (
            <Link onClick={close} to={link} className="w-full h-full">
              <div className={`flex-1 flex justify-between border border-sky-800 bg-sky-800 hover:bg-sky-900 h-full min-h-[50px]`}>
                <div className="flex-grow px-2 py-1 text-sm break-normal">
                  <span className='text-white font-medium'>
                    {title}
                  </span>
                </div>
                <div className={`px-2 py-1 text-sm text-white font-medium`}>
                  {title.indexOf('Go up to') === -1 ? numberOfTables : ''}
                  {/* {numberOfTables} */}
                </div>
              </div>
            </Link>
          ) }
      </li>
    )
  } else if (page === 'Edit' || page === 'Add') {
    return (
      <li className="col-span-1 flex shadow-sm">
        <div className={`flex-1 flex justify-between border border-gray-100 bg-gray-100 h-full min-h-[50px]`}>
          <div className="flex-grow px-2 py-1 text-sm break-normal">
            <span className='text-gray-400 font-medium'>
              {title}
            </span>
          </div>
          <div className={`px-2 py-1 text-sm text-gray-400 font-medium`}>
            {title.indexOf('Go up to') === -1 ? numberOfTables : ''}
          </div>
        </div>
      </li>
    )
  }
}