import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

export default function AlertNotification(props) {
  if (props.outcome.type === 'Success') {
    return (
      <nav className="bg-green-600 sticky z-40" style={{top: '7.9rem'}}>
        {/* Actions desktop etc. */}
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
          <p className="font-medium text-white text-center truncate py-2">
            <span>{props.outcome.message}</span>
          </p>
        </div>
      </nav>
    )
  } else if (props.outcome.type === 'Danger') {
    return (
      // <nav className="bg-red-600 sticky z-40" style={{top: '7.9rem'}}>
      //   {/* Actions desktop etc. */}
      //   <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
      //     <p className="font-medium text-white text-center truncate py-2">
      //       <span>{props.outcome.message}</span>
      //     </p>
      //   </div>
      // </nav>
      <div className="max-w-7xl mx-auto px-2 mt-3 sm:px-6 lg:px-8 block sticky z-40" style={{top: '7.9rem'}}>
        <div className="bg-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-white">{props.outcome.message}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                  onClick={() => props.setOutcome({})}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}