/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition, Menu, Listbox } from '@headlessui/react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/solid'
import GroupChatUsernameComboBox from './groupChatUsernameComboBox'
import AlertNotification from './alertNotification'
import { addAuditLog, createGroupChatConversation } from '../scripts/helpers'
import { useCookies } from 'react-cookie'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CreateGroupChatModal(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [values, setValues] = useState(['Admin', 'Member']);
  const [groupChatName, setGroupChatName] = useState('');
  const [groupChatDescription, setGroupChatDescription] = useState('');
  const [taggedUsernames, setTaggedUsernames] = useState([{username: props.currentUsername, role: 'Admin'}]);
  const { toggleGroupChat } = props;

  useEffect(() => {

    if (toggleGroupChat !== 0) setOpen(true);
    
  }, [toggleGroupChat]);

  const cancelButtonRef = useRef(null);

  const closeModal = () => {
    setTaggedUsernames([{username: props.currentUsername, role: 'Admin'}]);
    setGroupChatName('');
    setOpen(false);
  }

  const confirmModal = () => {
    setOpen(false);
  }

  const toggleTaggedProblem = (event, taggedProblem) => {
    
  }

  const handleInputChange = (event) => {
    
  }

  const handleComboBoxChange = (event) => {
    if (taggedUsernames.indexOf(event) === -1) {
      const taggedUsernamesToUpdate = [...taggedUsernames];
      taggedUsernamesToUpdate.push({username: event, role: 'Member'});
      setTaggedUsernames(taggedUsernamesToUpdate);
    }
  }

  const removeUsername = (removedUsername) => {
    const taggedUsernamesToUpdate = [...taggedUsernames];
    setTaggedUsernames(taggedUsernamesToUpdate.filter(user => user.username !== removedUsername));
  }

  const createGroupChat = () => {
    document.getElementById('group_chat_name').className='shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';

    if (checkGroupNameIsFilled()) {
      createGroupChatConversation(groupChatName, groupChatDescription, taggedUsernames).then(resultingGroupChat => {
        addAuditLog({
          table_name: 'Group Chat',
          action_type: 'Create',
          record_id: resultingGroupChat.data.id,
          action_description: `Created group chat with id: ${resultingGroupChat.data.id}.`,
          user_id: cookies['userId']
        });

        // redirect to newly created group chat conversation
        navigate(`/group-chats/${resultingGroupChat.data.slug}`);
        closeModal();
      }).catch(error => {
        if (error.response.data.input === 'group_chat_name') {
          document.getElementById('group_chat_name').className='shadow-sm focus:ring-red-500 focus:border-red-500 bg-red-50 block w-full sm:text-sm border-red-300';
          setError('Group name must be unique.');

          setTimeout(() => {
            setError(null);
          }, 3000); 
        }
      });
    } else {
      document.getElementById('group_chat_name').className='shadow-sm focus:ring-red-500 focus:border-red-500 bg-red-50 block w-full sm:text-sm border-red-300';
      setError('Compulsory fields have not been filled.');

      setTimeout(() => {
        setError(null);
      }, 3000); 
    }
  };

  const handleChange = (event, user, taggedUsernamesIndex) => {
    const taggedUsernamesToUpdate = [...taggedUsernames];
    taggedUsernamesToUpdate[taggedUsernamesIndex].role = event;
    setTaggedUsernames(taggedUsernamesToUpdate);
  };

  const checkGroupNameIsFilled = () => {
    return (groupChatName !== '');
  };

  // shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-top sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white text-left shadow-xl transform transition-all sm:my-8 sm:align-top sm:w-full" style={{maxWidth: '50rem'}}>
              <div className="bg-white px-4 pt-5 pb-5 sm:p-6 sm:pb-6">
                {error !== null ?
                  <nav className="bg-red-600 mb-4">
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
                      <p className="font-medium text-white text-center truncate py-2">
                        <span>{error}</span>
                      </p>
                    </div>
                  </nav>
                : ''}
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <div className="shadow">
                    <div className="bg-gray-100 px-2 py-1">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">CREATE GROUP CHAT</h3>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group name *</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            <input
                              type='text'
                              name='group_chat_name'
                              id='group_chat_name'
                              value={groupChatName}
                              onChange={(event) => setGroupChatName(event.target.value)}
                              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                            />
                          </dd>
                          <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group description</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            <textarea
                                rows={5}
                                name='group_chat_description'
                                id='group_chat_description'
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                onChange={(event) => setGroupChatDescription(event.target.value)}
                              />
                          </dd>
                          <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Add a group member</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2 z-50">
                            <GroupChatUsernameComboBox handleComboBoxChange={handleComboBoxChange} />
                          </dd>
                          {taggedUsernames.length > 0 ? 
                            <Fragment>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group members</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <ul role="list" className="mt-1 border border-gray-300 divide-y divide-gray-300">
                                  {taggedUsernames.map((user, index) => {
                                    return (
                                      <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                        {user.username === props.currentUsername ? 
                                          <div className="w-full flex justify-between items-center">
                                            <p className="ml-0 mb-0 font-bold">{user.username}</p>
                                            <p className="ml-0 mb-0">{user.role}</p>
                                          </div>
                                          :
                                          <div className="w-full flex justify-between items-center">
                                            <p className="ml-0 mb-0 font-bold">{user.username}</p>
                                            
                                            <Listbox value={user.role} onChange={((event) => handleChange(event, user, index))}>
                                              {({ open }) => (
                                                <>
                                                  <div className="relative">
                                                    <Listbox.Button className={`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm`}>
                                                      <span className="block truncate">{user.role}</span>
                                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                      </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                      show={open}
                                                      as={Fragment}
                                                      leave="transition ease-in duration-100"
                                                      leaveFrom="opacity-100"
                                                      leaveTo="opacity-0"
                                                    >
                                                      <Listbox.Options className="z-50 absolute mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                        {values.map((value, index) => (
                                                          <Listbox.Option
                                                            key={index}
                                                            className={({ active }) =>
                                                              classNames(
                                                                active ? 'text-white bg-sky-600' : 'text-gray-900',
                                                                'cursor-default select-none relative py-0 pl-3 pr-9'
                                                              )
                                                            }
                                                            value={value}
                                                          >
                                                            {({ selected, active }) => (
                                                              <>
                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                  {value}
                                                                </span>

                                                                {selected ? (
                                                                  <span
                                                                    className={classNames(
                                                                      active ? 'text-white' : 'text-sky-600',
                                                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                  >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                  </span>
                                                                ) : null}
                                                              </>
                                                            )}
                                                          </Listbox.Option>
                                                        ))}
                                                      </Listbox.Options>
                                                    </Transition>
                                                  </div>
                                                </>
                                              )}
                                            </Listbox>
                                          </div>
                                        }
                                        {user.username !== props.currentUsername ? 
                                          <div className="ml-4 flex-shrink-0">
                                            <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={() => removeUsername(user.username)} />
                                          </div>
                                          : ''
                                        }
                                      </li>
                                    )
                                  })}
                                </ul>
                              </dd>
                            </Fragment>
                          : ''}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 pt-4 pb-4 sm:px-6 sm:pb-4">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                    onClick={createGroupChat}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
