import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivileges, getAllTableAndColumns, addObject, deleteObject, getObject, displayValueByColumnName, listenToNotifications, addAuditLog } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import Modal from '../../components/modal'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'

export default function AnnouncementsView() {
  const tableSlug = 'announcements';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [object, setObject] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);
  
  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // check whether current logged in announcement can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if announcement is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['View']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivileges(tableSlug, privilegesToUpdate, 'View', params.id).then(preparedTableColumns => {
        
        preparedTableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined && tableColumn.reference_synced === false && tableColumn.value !== null) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const storedAsColumnName = storedAs.data.column_name;

                displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn.value).then((displayValue) => {
                  preparedTableColumns[tableColumn['sequence'] - 1].value = displayValue.data[Object.keys(displayValue.data)[0]];

                  preparedTableColumns[tableColumn['sequence'] - 1]['reference_synced'] = true;

                  setTableColumns(preparedTableColumns);
                })
              });
            });
          }
        });

        // setTableColumns(preparedTableColumns);
      });

    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'View');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      navigate(`/${tableSlug}/${params.id}/edit`);
    } else if (buttonClicked === 'Go back to list') {
      navigate(`/${tableSlug}`);
    } else if (buttonClicked === 'Delete') {
      setShowDeleteConfirmation(true);
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject(tableSlug, params.id).then(result => {
      addAuditLog({
        table_name: 'Announcements',
        action_type: 'Delete',
        action_description: `Deleted announcement with id: ${params.id}.`,
        user_id: cookies['userId']
      });
      
      navigate(`/${tableSlug}`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });

      
    });
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single='announcement' plural='announcements' />
        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">VIEW ANNOUNCEMENT BY ADMINISTRATOR</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {tableColumns.map((tableColumn, index) => {
                  if (tableColumn['privilege'] === 'Yes') {
                    if (tableColumn['value'] === null) {
                      return (
                        <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                          <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            
                          </dd>
                        </div>
                      )
                    } else {
                      if (typeof(tableColumn['value']) === 'object') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              {tableColumn['value'].map((value, index2) => {
                                return (
                                  <button
                                    key={index2}
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 mr-2 border border-transparent text-sm font-medium shadow-sm text-white bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    disabled
                                  >
                                    <strong className="mr-2">{value['table_name']}</strong> {value['label_name']}
                                  </button>
                                )
                              })}
                            </dd>
                          </div>
                        )
                      } else {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                              {tableColumn['value']}
                            </dd>
                          </div>
                        )
                      }
                    }
                  }
                })}
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}