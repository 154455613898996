import { Fragment, useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Transition, Listbox } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { isAfter, getMonth, getYear, format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'

import Header from '../components/header';
import SubHeader from '../components/subheader';
import AlertNotification from '../components/alertNotification'
import SelectOption from '../components/selectOption';
import { searchWorkers, getDropdownValues, getRole, getAnnouncementsToday, deepCopy, listenToNotifications, checkDeactivated } from '../scripts/helpers';
import axios from 'axios';
import { AuthContext } from '../AuthProvider'
import Loading from '../components/loading'
import { backend_base_url } from '../utils/helper'

const genders = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
];

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Index() {
  let navigate = useNavigate();
  
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [searching, setSearching] = useState(null);
  const [offset, setOffset] = useState(0);
  const [actions, setActions] = useState([
    { name: 'Search', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
    { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
    { name: 'Create', href: '#', class: 'bg-gray-500 hover:bg-gray-600', position: 'left', hidden: false, disabled: true },
  ]);
  const [columns, setColumns] = useState([]);
  const [role, setRole] = useState('');
  const [searchColumns, setSearchColumns] = useState([]);
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [problemDropdownValues, setProblemDropdownValues] = useState([]);
  const [aggravatingIssueDropdownValues, setAggravatingIssueDropdownValues] = useState([]);
  const [nationalityDropdownValues, setNationalityDropdownValues] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [displayGroups, setDisplayGroups] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => {
    if (socket) {
      listenToNotifications(socket, notifications, setNotifications, show, update)
    }
  }, [socket]);

  useEffect(() => {
    if (cookies['announcementsSynced'] === undefined) {
      getAnnouncementsToday(new Date()).then(announcementResults => {
        setAnnouncements(announcementResults.data);
      });
    }
    getDropdownValues('Problem', 'chief_problem').then(problemDropdownResults => {
      setProblemDropdownValues(problemDropdownResults.data);
      getDropdownValues('Aggravating Issue', 'aggra_issue').then(aggravatingIssueDropdownResults => {
        setAggravatingIssueDropdownValues(aggravatingIssueDropdownResults.data);
        getDropdownValues('Worker', 'nationality').then(nationalityDropdownResults => {
          setNationalityDropdownValues(nationalityDropdownResults.data);
        });
      });
    });
  }, []);

  const resetSearchFields = () => {
    getRole(cookies['token']).then(roleResult => {
      const role = roleResult.data.role.role;
      if (role === 'Recorder') {
        setColumns({
          twid: {
            column_name: 'twid',
            label_name: 'Twid',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          name_of_worker: {
            column_name: 'name_of_worker',
            label_name: 'Name of worker',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          employer_name: {
            column_name: 'employer_name',
            label_name: 'Employer',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          chief_problem: {
            column_name: 'chief_problem',
            label_name: 'Problem',
            value: '',
            dropdowns: problemDropdownValues
          },
          sg_phone_number: {
            column_name: 'sg_phone_number',
            label_name: 'Sg phone',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          date_of_birth: {
            column_name: 'date_of_birth',
            label_name: 'Date of birth',
            value: '',
            input_type: 'date',
            dropdowns: []
          },
        });
        setRole('recorder');
      } else {
        setColumns({
          twid: {
            column_name: 'twid',
            label_name: 'Twid',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          name_of_worker: {
            column_name: 'name_of_worker',
            label_name: 'Name of worker',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          employer_name: {
            column_name: 'employer_name',
            label_name: 'Employer',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          chief_problem: {
            column_name: 'chief_problem',
            label_name: 'Problem',
            value: '',
            dropdowns: problemDropdownValues
          },
          clan_name: {
            column_name: 'clan_name',
            label_name: 'Clan name',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          aggra_issue: {
            column_name: 'aggra_issue',
            label_name: 'Aggravating issue',
            value: '',
            dropdowns: aggravatingIssueDropdownValues
          },
          sg_phone_number: {
            column_name: 'sg_phone_number',
            label_name: 'Sg phone',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          digital_address: {
            column_name: 'digital_address',
            label_name: 'Digital address',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          home_country_phone_number: {
            column_name: 'home_country_phone_number',
            label_name: 'Home phone',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          ticket_number: {
            column_name: 'ticket_number',
            label_name: 'Ticket number',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          nationality: {
            column_name: 'nationality',
            label_name: 'Nationality',
            value: '',
            dropdowns: nationalityDropdownValues
          },
          date_of_birth: {
            column_name: 'date_of_birth',
            label_name: 'Date of birth',
            value: '',
            input_type: 'date',
            dropdowns: []
          },
          lead_case_worker: {
            column_name: 'lead_case_worker',
            label_name: 'Lead case worker',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          auxiliary_case_worker: {
            column_name: 'auxiliary_case_worker',
            label_name: 'Aux case worker',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          interpreter_name: {
            column_name: 'interpreter_name',
            label_name: 'Interpreter attached',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          problem_registration_start: {
            column_name: 'problem_registration_start',
            label_name: 'Prob registration start',
            value: '',
            input_type: 'date',
            dropdowns: []
          },
          problem_registration_end: {
            column_name: 'problem_registration_end',
            label_name: 'Prob registration end',
            value: '',
            input_type: 'date',
            dropdowns: []
          },
        });
        setRole('nonRecorder');
      }
    });

    setSearchResults([]);
    setShowSearchResults(false);
    setSearching(null);
    setSearchResultsCount(0);
    setActions([
      { name: 'Search', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
      { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
      { name: 'Create', href: '#', class: 'bg-gray-500 hover:bg-gray-600', position: 'left', hidden: false, disabled: true },
    ]);
    setPage(1);
    setTotalPages(0);
    setSort({});
  };

  const acknowledgeAnnouncements = () => {
    setCookie('announcementsSynced', true, { path: '/' });
    window.location.reload();
  }

  useEffect(() => {
    if (nationalityDropdownValues.length > 0) {
      resetSearchFields();
    }
  }, [nationalityDropdownValues]);

  const handleChange = (column, event) => {
    const columnsToUpdate = {...columns};
    columnsToUpdate[column]['value'] = event.target.value;
    setColumns(columnsToUpdate);
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    const columnsToUpdate = {...columns};
    
    if (event.dropdown_item === '-') {
      columnsToUpdate[column]['value'] = '';
    } else {
      columnsToUpdate[column]['value'] = event.dropdown_item;
    }

    setColumns(columnsToUpdate);
  };

  const handleCalendarChange = (column, event) => {
    const columnsToUpdate = {...columns};
    if (event === null) {
      event = '';
    }
    columnsToUpdate[column]['value'] = event;
    setColumns(columnsToUpdate);
  };

  const handleFormSubmit = (event, offset = 0) => {
    let buttonClicked;
    if (event) {
      event.preventDefault();
      buttonClicked = event.nativeEvent.submitter.innerHTML;
    } else {
      buttonClicked = 'Search';
    }

    setOutcome({});

    if (buttonClicked === 'Search') {
      let proceedWithSearch = true;
      setTotalPages(0);

      if (role === 'nonRecorder' && columns['problem_registration_start'].value !== '' && columns['problem_registration_end'].value !== '') {
        if (isAfter(new Date(columns['problem_registration_start'].value), new Date(columns['problem_registration_end'].value))) {
          setOutcome({
            message: 'Problem registration start date cannot be after problem registration end date.',
            type: 'Danger'
          });

          proceedWithSearch = false;
        }
      }

      if (proceedWithSearch) {
        setSearching(true);
        const displayGroupsToUpdate = [false, false, false];
        const group1 = ['twid', 'name_of_worker', 'sg_phone_number', 'digital_address', 'home_country_phone_number', 'ticket_number', 'nationality', 'date_of_birth'];
        const group2 = ['employer_name'];
        const group3 = ['chief_problem', 'aggra_issue', 'clan_name', 'lead_case_worker', 'auxiliary_case_worker', 'interpreter_name', 'problem_registration_start', 'problem_registration_end'];
  
        Object.keys(columns).map((key, index) => {
          // group 1 - twid, name of worker, sg phone, digital address, home phone, ticket number, nationality, date of birth
          // if at least 1 from group 1 is filled up - display group x
          if (!displayGroupsToUpdate[0] && (group1.indexOf(key) !== -1)) {
            if (columns[key]['value'] !== '') {
              if (key === 'nationality') {
                if (columns[key]['value'] !== '-') {
                  displayGroupsToUpdate[0] = true;
                }
              } else {
                displayGroupsToUpdate[0] = true;
              }
            }
          }
          
          // group 2 - employer name
          // if at least 1 from group 2 is filled up - display group x and y 
          if (!displayGroupsToUpdate[1] && (group2.indexOf(key) !== -1)) {
            if (columns[key]['value'] !== '') {
              displayGroupsToUpdate[0] = true;
              displayGroupsToUpdate[1] = true;
            }
          }
  
          // group 3 - problem, aggravating issue, clan name, lead case worker, aux case worker, interpreter, prob registration start, prob registration end
          // if at least group 3 is filled up 0 display group x, y and z
          if (!displayGroupsToUpdate[2] && (group3.indexOf(key) !== -1)) {
            if (columns[key]['value'] !== '') {
              if (key === 'chief_problem' || key === 'aggra_issue') {
                if (columns[key]['value'] !== '-') {
                  displayGroupsToUpdate[0] = true;
                  displayGroupsToUpdate[1] = true;
                  displayGroupsToUpdate[2] = true;
                }
              } else {
                displayGroupsToUpdate[0] = true;
                displayGroupsToUpdate[1] = true;
                displayGroupsToUpdate[2] = true;
              }
            }
          }
        });
  
        setDisplayGroups(displayGroupsToUpdate);
  
        searchWorkers(columns, role, offset).then(searchResult => {
          setSearching(false);
          if (searchResult.data.length === 0) {
            setSearchResultsCount(0);
            setPage(1);
            setSearchResults([]);
            setSort({});
          } else {
            const searchResultsToUpdate = [];
            const dividedSearchResultsToUpdate = [];
  
            if (displayGroupsToUpdate[0] === true && displayGroupsToUpdate[1] === true && displayGroupsToUpdate[2] === true) {
              searchResult.data.forEach((searchRow, index) => {
                if (searchRow['job.worker.facepicAttachments.file_path'] !== null) {
                  searchRow['job.worker.path_current_facepic'] = `/facepics/${searchRow['job.worker.facepicAttachments.file_path']}`;
                } else {
                  searchRow['job.worker.path_current_facepic'] = null;
                }

                if (role === 'nonRecorder') {
                  if (searchRow['leadCaseWorkers.lead_case_worker'] !== null) {
                    searchResultsToUpdate.push({
                      path_current_facepic: searchRow['job.worker.path_current_facepic'],
                      worker_id: searchRow['job.worker.id'],
                      job_id: searchRow['job.id'],
                      problem_id: searchRow['id'],
                      // twid: `${searchRow['job.worker.twid.substring(0'], 4)} ${searchRow['job.worker.twid.substring(4'], 6)} ${searchRow['job.worker.twid.substring(6'], 8)}`'],
                      twid: searchRow['job.worker.twid'],
                      name_of_worker: searchRow['job.worker.name_of_worker'],
                      employer: searchRow['job.employer_name'],
                      chief_problem: searchRow['chief_problem'],
                      clan_name: searchRow['problemClan.id'] !== null ? searchRow['problemClan.clan_name'] : '',
                      lead_case_worker: searchRow['leadCaseWorkers.lead_case_worker'],
                      auxiliary_case_worker: null,
                      date_problem_registered: searchRow['date_problem_registered']
                    }); 
                  }
                  if (searchRow['auxiliaryCaseWorkers.auxiliary_case_worker'] !== null) {
                    searchResultsToUpdate.push({
                      path_current_facepic: searchRow['job.worker.path_current_facepic'],
                      worker_id: searchRow['job.worker.id'],
                      job_id: searchRow['job.id'],
                      problem_id: searchRow['id'],
                      // twid: `${searchRow['job.worker.twid.substring(0'], 4)} ${searchRow['job.worker.twid.substring(4'], 6)} ${searchRow['job.worker.twid.substring(6'], 8)}`'],
                      twid: searchRow['job.worker.twid'],
                      name_of_worker: searchRow['job.worker.name_of_worker'],
                      employer: searchRow['job.employer_name'],
                      chief_problem: searchRow['chief_problem'],
                      clan_name: searchRow['problemClan.id'] !== null ? searchRow['problemClan.clan_name'] : '',
                      lead_case_worker: null,
                      auxiliary_case_worker: searchRow['auxiliaryCaseWorkers.auxiliary_case_worker'],
                      date_problem_registered: searchRow['date_problem_registered']
                    }); 
                  } 
                  if (searchRow['leadCaseWorkers.lead_case_worker'] === null && searchRow['auxiliaryCaseWorkers.auxiliary_case_worker'] === null) {
                    searchResultsToUpdate.push({
                      path_current_facepic: searchRow['job.worker.path_current_facepic'],
                      worker_id: searchRow['job.worker.id'],
                      job_id: searchRow['job.id'],
                      problem_id: searchRow['id'],
                      // twid: `${searchRow['job.worker.twid.substring(0'], 4)} ${searchRow['job.worker.twid.substring(4'], 6)} ${searchRow['job.worker.twid.substring(6'], 8)}`'],
                      twid: searchRow['job.worker.twid'],
                      name_of_worker: searchRow['job.worker.name_of_worker'],
                      employer: searchRow['job.employer_name'],
                      chief_problem: searchRow['chief_problem'],
                      clan_name: searchRow['problemClan.id'] !== null ? searchRow['problemClan.clan_name'] : '',
                      lead_case_worker: null,
                      auxiliary_case_worker: null,
                      date_problem_registered: searchRow['date_problem_registered']
                    }); 
                  }
                } else {
                  searchResultsToUpdate.push({
                    worker_id: searchRow['job.worker.id'],
                    job_id: searchRow['job.id'],
                    problem_id: searchRow['id'],
                    path_current_facepic: searchRow['job.worker.path_current_facepic'],
                    // twid: `${searchRow['job.worker.twid.substring(0, 4)} ${searchRow['job.worker.twid.substring(4, 6)} ${searchRow['job.worker.twid.substring(6, 8)}`,
                    twid: searchRow['job.worker.twid'],
                    name_of_worker: searchRow['job.worker.name_of_worker'],
                    employer: searchRow['job.employer_name'],
                    chief_problem: searchRow['chief_problem']
                  });
                }
              });
            } else if (displayGroupsToUpdate[0] === true && displayGroupsToUpdate[1] === true) {
              searchResult.data.forEach((searchRow) => {
                if (searchRow['job.worker.facepicAttachments.file_path'] !== null) {
                  searchRow['job.worker.path_current_facepic'] = `/facepics/${searchRow['job.worker.facepicAttachments.file_path']}`;
                } else {
                  searchRow['job.worker.path_current_facepic'] = null;
                }

                searchResultsToUpdate.push({
                  path_current_facepic: searchRow['job.worker.path_current_facepic'],
                  worker_id: searchRow['job.worker.id'],
                  job_id: searchRow['job.id'],
                  problem_id: searchRow['id'],
                  // twid: `${searchRow['job.worker.twid.substring(0'], 4)} ${searchRow['job.worker.twid.substring(4'], 6)} ${searchRow['job.worker.twid.substring(6'], 8)}`'],
                  twid: searchRow['job.worker.twid'],
                  name_of_worker: searchRow['job.worker.name_of_worker'],
                  employer: searchRow['job.employer_name'],
                  chief_problem: null,
                  clan_name: null,
                  lead_case_worker: null,
                  auxiliary_case_worker: null,
                  date_problem_registered: null
                });
              });
            } else if (displayGroupsToUpdate[0] === true) {
              searchResult.data.forEach((searchRow) => {
                if (searchRow['job.worker.facepicAttachments.file_path'] !== null) {
                  searchRow['job.worker.path_current_facepic'] = `/facepics/${searchRow['job.worker.facepicAttachments.file_path']}`;
                } else {
                  searchRow['job.worker.path_current_facepic'] = null;
                }

                searchResultsToUpdate.push({
                  path_current_facepic: searchRow['job.worker.path_current_facepic'],
                  worker_id: searchRow['job.worker.id'],
                  job_id: searchRow['job.id'],
                  problem_id: searchRow['id'],
                  // twid: `${searchRow['job.worker.twid.substring(0'], 4)} ${searchRow['job.worker.twid.substring(4'], 6)} ${searchRow['job.worker.twid.substring(6'], 8)}`'],
                  twid: searchRow['job.worker.twid'],
                  name_of_worker: searchRow['job.worker.name_of_worker'],
                  employer: null,
                  chief_problem: null,
                  clan_name: null,
                  lead_case_worker: null,
                  auxiliary_case_worker: null,
                  date_problem_registered: null
                });
              });
            }

            dividedSearchResultsToUpdate[0] = searchResultsToUpdate;
            
            setSearchResultsCount(searchResult.data[0].full_count);
            setSearchResults(dividedSearchResultsToUpdate);
            setTotalPages(Math.ceil(searchResult.data[0].full_count / 20));
          }
          
          setActions([
            { name: 'Search', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
            { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
            { name: 'Create', href: '#', class: 'bg-gray-500 hover:bg-gray-600', position: 'left', hidden: false, disabled: false },
            // { name: 'Go to global search', href: '#', class: 'bg-gray-500 hover:bg-gray-600', position: 'right', hidden: false, disabled: false },
          ]);

          setShowSearchResults(true);
        }).catch(err => {
          setSearching(false);
          setShowSearchResults(false);
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });
        });
      }
    } else if (buttonClicked === 'Reset') {
      resetSearchFields();
      
    } else if (buttonClicked === 'Create') {
      navigate('/compose-twid');
    }
  };

  const toggleSort = (column, page) => {
    let sortToUpdate = {...sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    let searchResultsToUpdate = [...searchResults];
    let currentSearchResultsToUpdate = [...searchResults[0]];

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if (sortToUpdate[column] === false) {
      // searchResultsToUpdate.sort((a,b) => (a[column] > b[column] ? 1 : -1));
      currentSearchResultsToUpdate = _.orderBy(currentSearchResultsToUpdate, [column], ['asc']);
    } else {
      // searchResultsToUpdate.sort((a,b) => (a[column] < b[column] ? 1 : -1));
      currentSearchResultsToUpdate = _.orderBy(currentSearchResultsToUpdate, [column], ['desc']);
    }

    sortToUpdate[column] = !sortToUpdate[column];
    setSort(sortToUpdate);
    searchResultsToUpdate[0] = currentSearchResultsToUpdate;
    setSearchResults(searchResultsToUpdate);
  }

  const navigateToForwardPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      handleFormSubmit(null, page + 1);
    }
  };

  const navigateToBackwardPage = () => {
    if (page > 1) {
      setPage(page - 1);
      handleFormSubmit(null, page - 1);
    }
  };

  const displaySearchResult = (searchRow, index) => {
    if (role === 'nonRecorder') {
      if (displayGroups[0] && displayGroups[1] && displayGroups[2]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.employer}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}/problems/${searchRow.problem_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.chief_problem}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.clan_name}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.lead_case_worker}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.auxiliary_case_worker}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{format(new Date(searchRow.date_problem_registered), 'yyyy-MMM-dd')}</td>
          </tr>
        )
      } else if (displayGroups[0] && displayGroups[1]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.employer}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
          </tr>
        )
      } else if (displayGroups[0]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
          </tr>
        )
      }
    } else {
      if (displayGroups[0] && displayGroups[1] && displayGroups[2]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.employer}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}/problems/${searchRow.problem_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.chief_problem}</Link></td>
          </tr>
        )
      } else if (displayGroups[0] && displayGroups[1]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}/jobs/${searchRow.job_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.employer}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
          </tr>
        )
      } else if (displayGroups[0]) {
        return (
          <tr key={index}>
            <td className="w-[110px] px-2 py-1 text-sm text-gray-900 flex justify-center">{searchRow.path_current_facepic !== null ? <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${searchRow.path_current_facepic.substring(searchRow.path_current_facepic.lastIndexOf('/') + 1)}`} className="w-[75px] h-[100px]" /> : <img src='/user.png' className="w-10" />}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.twid.indexOf('GEN') === -1 ? `${searchRow.twid.substring(0, 4)} ${searchRow.twid.substring(4, 6)} ${searchRow.twid.substring(6, 8)}` : searchRow.twid}</td>
            <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchRow.worker_id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchRow.name_of_worker}</Link></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
            <td className="px-2 py-1 text-sm text-gray-900"></td>
          </tr>
        )
      }
    }
  };

  if (announcements.length > 0 && (cookies['announcementsSynced'] === undefined || cookies['announcementsSynced'] === false)) {
    return (
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
        <div className="mt-5 shadow overflow-hidden">
          <div className="bg-gray-100 px-2 py-1">
            <h3 className="text-lg leading-6 font-bold text-gray-900">ANNOUNCEMENTS BY ADMINISTRATOR</h3>
          </div>
          <div className="border-t border-gray-200 p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              {announcements.map((announcement, index) => {
                return (
                  <div className="flex items-center py-1 grid gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                    <dd className="text-sm mt-0 sm:col-span-2">
                      {announcement.announcement_message}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>
        </div>
        <div className="mt-5">
          <button className="inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-blue-500 hover:bg-blue-600" onClick={() => acknowledgeAnnouncements()}>Acknowledge</button>
        </div>
      </div>
    )
  }

  if (problemDropdownValues.length !== 0 && aggravatingIssueDropdownValues.length !== 0 && nationalityDropdownValues.length !== 0) {
    return (
      <>
        <Header />
        <form onSubmit={handleFormSubmit} className="pb-20">
          <SubHeader actions={actions} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />

          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
              {Object.keys(columns).map((key, index) => (
                <div key={index}>
                  <label htmlFor={columns[key]['column_name']} className="block text-sm font-medium text-gray-700">
                    {columns[key]['label_name']}
                  </label>
                  <div className="mt-1">
                    {columns[key]['dropdowns'].length > 0 ? (
                      <SelectOption 
                        column={columns[key]['column_name']} 
                        values={columns[key]['dropdowns']} 
                        handleDropdownChange={handleDropdownChange} 
                        selectedValue={columns[key]['value']}/>
                    ) : columns[key]['input_type'] === 'text' ? (
                      <input
                        type={columns[key]['input_type']}
                        name={columns[key]['column_name']}
                        id={columns[key]['column_name']}
                        value={columns[key]['value']}
                        onChange={(event) => handleChange(columns[key]['column_name'], event)}
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full text-sm border-gray-300"
                      />
                    ) : (
                      <DatePicker 
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                            <select
                              className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full text-sm border-gray-300"
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full text-sm border-gray-300"
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                          </div>
                        )}
                        selected={columns[key]['value']} 
                        onChange={(event) => handleCalendarChange(key, event)} 
                        dateFormat='yyyy-MMM-dd' 
                        autoComplete='off'
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full text-sm border-gray-300" />
                    ) }
                  </div>
                </div>
              ))}
            </ul>

            <div className="mt-5 mb-20 shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <span>
              <h3 className="text-lg leading-6 font-bold text-gray-900">SEARCH RESULTS</h3>
              {/* {showSearchResults ? (
                <p className="text-sm font-medium text-gray-500">{searchResultsCount} results</p>
              ) : (<></>)} */}
              </span>
              {totalPages > 1 && !searching ? 
                (
                  <span className="relative z-0 inline-flex shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToBackwardPage}
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                      disabled
                    >
                      {page} of {totalPages}
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToForwardPage}
                    >
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                  </span>
                ) : (<></>)}
            </div>
            <div className="border-t border-gray-200 p-0">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase "
                            >
                              Facepic
                            </th>
                            <th 
                              scope="col" 
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('twid', page)}>
                                Twid 
                                {sort['twid'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['twid'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('name_of_worker', page)}>
                                Name of worker
                                {sort['name_of_worker'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['name_of_worker'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('employer', page)}>
                                Employer
                                {sort['employer'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['employer'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('chief_problem', page)}>
                                Problem
                                {sort['chief_problem'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['chief_problem'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            {role === 'nonRecorder' ? (
                              <>
                                <th
                                  scope="col"
                                  className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                                >
                                  <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('clan_name', page)}>
                                    Clan name
                                    {sort['clan_name'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                    {sort['clan_name'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                                >
                                  <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('lead_case_worker', page)}>
                                    Lead case worker
                                    {sort['lead_case_worker'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                    {sort['lead_case_worker'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                                >
                                  <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('auxiliary_case_worker', page)}>
                                    Aux case worker
                                    {sort['auxiliary_case_worker'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                    {sort['auxiliary_case_worker'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                  </span>
                                </th>
                                <th
                                  scope="col"
                                  className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                                >
                                  <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('date_problem_registered', page)}>
                                    Problem reg date
                                    {sort['date_problem_registered'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                    {sort['date_problem_registered'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                  </span>
                                </th>
                              </>
                            ) : (<></>)}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {searchResults.length > 0 && !searching ? (
                            searchResults[0].map((searchRow, index2) => {
                              return (
                                displaySearchResult(searchRow, index2)
                              )
                            })
                          ) : (<></>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </form>
        {searching && <Loading />}
        {searchResults.length === 0 && searching === false && (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            No results found
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        <Header />
        <SubHeader actions={actions} />
        <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
      </>
    )
  }
}
