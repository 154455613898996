import React, {useState, createContext, useEffect, Fragment} from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function RedirectIfLoggedIn({ children }) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);

  useEffect(() => {
    if (cookies.token) {
      navigate('/');
    }
  }, []);

  return children;
};