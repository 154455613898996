import React, {useContext, useEffect, Fragment, useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { useIdleTimer } from 'react-idle-timer'
import {actionIsRecent, addAuditLog, checkCookieToken, detectBrowser, getSessionTimeout, refreshCookies, updateAction} from './scripts/helpers';

import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

import { AuthProvider } from './AuthProvider';
import Protected from './Protected';
import RedirectIfLoggedIn from './RedirectIfLoggedIn';

import Login from './routes/auth/login';
import ForgotPassword from './routes/auth/forgotPassword';
import ResetPassword from './routes/auth/resetPassword';

import ComposeTwid from './routes/composeTwid';

import Index from './routes/index';
import UsersAdd from './routes/users/add';
import UsersEdit from './routes/users/edit';
import UsersIndex from './routes/users/index';
import UsersView from './routes/users/view';

import DropdownsAdd from './routes/dropdowns/add';
import DropdownsEdit from './routes/dropdowns/edit';
import DropdownsIndex from './routes/dropdowns/index';
import DropdownsView from './routes/dropdowns/view';

import ReferencesEdit from './routes/references/edit';
import ReferencesIndex from './routes/references/index';
import ReferencesView from './routes/references/view';

import PrivilegesIndex from './routes/privileges/index';
import PrivilegesEdit from './routes/privileges/edit';

import PasswordsEdit from './routes/passwords/edit';

import WorkersView2 from './routes/workers/view2';

import WorkersSubsidiaryAdd from './routes/workers/subsidiaries/add';
import WorkersSubsidiaryEdit from './routes/workers/subsidiaries/edit';
import WorkersSubsidiaryIndex from './routes/workers/subsidiaries/index';
import WorkersSubsidiaryView from './routes/workers/subsidiaries/view';
import ClientCalendar from './routes/workers/subsidiaries/clientCalendar';
import Abridged from './routes/workers/subsidiaries/abridged';
import ProblemAbridged from './routes/workers/subsidiaries/problemAbridged';

import ColumnVisibilityIndex from './routes/columnVisibilities/index';
import ColumnVisibilityEdit from './routes/columnVisibilities/edit';

import ProblemClansIndex from './routes/problemClans/index';
import ProblemClansAdd from './routes/problemClans/add';
import ProblemClansView from './routes/problemClans/view';
import ProblemClansEdit from './routes/problemClans/edit';

import DefaultValuesIndex from './routes/defaultValues/index';
import DefaultValuesEdit from './routes/defaultValues/edit';

import ProfilesIndex from './routes/profiles/index';
import ProfilesEdit from './routes/profiles/edit';

import './App.css';
import DirectMessagesIndex from './routes/directMessages/index';
import DirectMessagesShow from './routes/directMessages/show';

import GroupChatsIndex from './routes/groupChats/index';
import GroupChatsShow from './routes/groupChats/show';

import TemplatesIndex from './routes/templates';
import TemplatesAdd from './routes/templates/add';
import TemplatesView from './routes/templates/view';
import TemplatesEdit from './routes/templates/edit';

import FacepicAttachmentsIndex from './routes/facepicAttachments';
import FacepicAttachmentsAdd from './routes/facepicAttachments/add';
import FacepicAttachmentsView from './routes/facepicAttachments/view';
import FacepicAttachmentsEdit from './routes/facepicAttachments/edit';
import FacepicAttachmentsAssign from './routes/facepicAttachments/assign';

import PdpaAttachmentsIndex from './routes/pdpaAttachments';
import PdpaAttachmentsAdd from './routes/pdpaAttachments/add';
import PdpaAttachmentsView from './routes/pdpaAttachments/view';
import PdpaAttachmentsEdit from './routes/pdpaAttachments/edit';
import PdpaAttachmentsAssign from './routes/pdpaAttachments/assign';
import UsersSearch from './routes/users/search';
import MyHistoryIndex from './routes/myHistory';
import AuditLogIndex from './routes/auditLog';
import AnnouncementsIndex from './routes/announcements';
import AnnouncementsAdd from './routes/announcements/add';
import AnnouncementsView from './routes/announcements/view';
import AnnouncementsEdit from './routes/announcements/edit';
import ExportIndex from './routes/export';
import ImportIndex from './routes/import';
import ImportImport from './routes/import/import';
import ImportList from './routes/import/list';
import SettingsEdit from './routes/settings/edit';
import SettingsView from './routes/settings/view';
import PresetReportsIndex from './routes/presetReports';
import PresetReportsAdd from './routes/presetReports/add';
import PresetReportsView from './routes/presetReports/view';
import PresetReportsEdit from './routes/presetReports/edit';
import PresetSkinsIndex from './routes/presetSkins';
import PresetSkinsAdd from './routes/presetSkins/add';
import PresetSkinsView from './routes/presetSkins/view';
import PresetSkinsEdit from './routes/presetSkins/edit';
import ReportsIndex from './routes/reports';
import ReportsAdd from './routes/reports/add';
import ReportsView from './routes/reports/view';
import ReportsEdit from './routes/reports/edit';
import ImportView from './routes/import/view';
import AllGroupChatsIndex from './routes/groupChats/all';

function App() {
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [sessionExpireDuration, setSessionExpireDuration] = useState();
  const [sessionPromptDuration, setSessionPromptDuration] = useState();
  const [promptModalDuration, setPromptModalDuration] = useState();

  useEffect(() => {
    if (cookies.token) {
      getSessionTimeout().then(result => {
        if (result.data.session_expire_duration !== null && cookies['role'] !== 'Admin') {
          setSessionExpireDuration(result.data.session_expire_duration * 60);
        }
        if (result.data.session_prompt_duration !== null && cookies['role'] !== 'Admin') {
          setPromptModalDuration(result.data.session_prompt_duration);
          setSessionPromptDuration(result.data.session_prompt_duration * 60);
        }

        if (checkCookieToken(document.cookie)) {
          actionIsRecent(cookies['token']).then((data) => {
            const recent = data.data.recent;

            if (!recent) {
              removeCookie('token');
              removeCookie('username');
              removeCookie('role');
              removeCookie('userId');
              removeCookie('announcementsSynced');
              window.location.replace("/login");
            }
          });
        }
      });
    }
  }, []);

  const onPrompt = () => {
    // Fire a Modal Prompt
    const whiteListedPaths = ['login', '/login', 'resetPassword', 'forgotPassword'];
    if (whiteListedPaths.indexOf(window.location.pathname) === -1 && cookies['role'] !== 'Admin') {
      setOpen(true);
      setPrompt(true);
    } else {
      reset();
    }
  }

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    if (prompt) {
      if (checkCookieToken(document.cookie)) {
        if (cookies['role'] !== 'Admin') {
          // check whether action is recent
          actionIsRecent(cookies['token']).then((data) => {
            const recent = data.data.recent;
            if (!recent) {
              const userId = cookies['userId'];
              setPrompt(false);
  
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Session timeout',
                action_description: `From ${detectBrowser(navigator)}.`,
                user_id: userId
              }).then(result => {
                removeCookie('token');
                removeCookie('username');
                removeCookie('role');
                removeCookie('userId');
                removeCookie('announcementsSynced');
                window.location.replace("/login");
              });
            }
          }).catch(() => {
            window.location.replace("/login");
          })
        } else {
          window.location.replace("/login");
        }
      }
    }
  }

  const onActive = (event) => {
  }

  const onAction = (event) => {
    if (checkCookieToken(document.cookie)) {
      updateAction(cookies['token']).then(() => {

      }).catch(() => {
        window.location.replace("/login");
      });
    }
  }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * sessionExpireDuration,
    promptTimeout: 1000 * sessionPromptDuration,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 30000,
    // eventsThrottle: 1000,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  return (
    <>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-500">
                    <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Session Timeout
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You will be logged out if inactive for the next {promptModalDuration} minutes.
                      </p>
                    </div>
                    <button className="inline-flex items-center grow-2 mt-5 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-red-500 hover:bg-red-600" onClick={() => {
                      setOpen(false);
                      setPrompt(false);
                      reset();
                    }}>Close</button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/search-users' element={<Protected><UsersSearch /></Protected>} />

          <Route path='/profile' element={<Protected><ProfilesIndex /></Protected>} />
          <Route path='/profile/edit' element={<Protected><ProfilesEdit /></Protected>} />
          <Route path='/change-password' element={<Protected><PasswordsEdit /></Protected>} />

          <Route path='/login' element={<RedirectIfLoggedIn><Login /></RedirectIfLoggedIn>} />
          <Route path='/forgotPassword' element={<RedirectIfLoggedIn><ForgotPassword /></RedirectIfLoggedIn>} />
          <Route path='/resetPassword' element={<RedirectIfLoggedIn><ResetPassword /></RedirectIfLoggedIn>} />

          <Route path='/compose-twid' element={<Protected><ComposeTwid /></Protected>} />
          <Route path='/my-history' element={<Protected><MyHistoryIndex /></Protected>} />
          <Route path='/audit-log' element={<Protected><AuditLogIndex /></Protected>} />
          
          <Route path='/preset-reports' element={<Protected><PresetReportsIndex /></Protected>} />
          <Route path='/preset-skins/:id/generate-preset-report' element={<Protected><PresetReportsAdd /></Protected>} />
          <Route path='/preset-reports/:id' element={<Protected><PresetReportsView /></Protected>} />
          <Route path='/preset-reports/:id/edit' element={<Protected><PresetReportsEdit /></Protected>} />

          <Route path='/preset-skins' element={<Protected><PresetSkinsIndex /></Protected>} />
          <Route path='/preset-skins/add' element={<Protected><PresetSkinsAdd /></Protected>} />
          <Route path='/preset-skins/:id' element={<Protected><PresetSkinsView /></Protected>} />
          <Route path='/preset-skins/:id/edit' element={<Protected><PresetSkinsEdit /></Protected>} />

          <Route path='/reports' element={<Protected><ReportsIndex /></Protected>} />
          <Route path='/reports/add' element={<Protected><ReportsAdd /></Protected>} />
          <Route path='/reports/:id' element={<Protected><ReportsView /></Protected>} />
          <Route path='/reports/:id/edit' element={<Protected><ReportsEdit /></Protected>} />

          <Route path='/settings/edit' element={<Protected><SettingsEdit /></Protected>} />
          <Route path='/settings' element={<Protected><SettingsView /></Protected>} />
          
          <Route path='/direct-messages' element={<Protected><DirectMessagesIndex /></Protected>} />
          <Route path='/direct-messages/:username' element={<Protected><DirectMessagesIndex /></Protected>} />

          <Route path='/group-chats' element={<Protected><GroupChatsIndex /></Protected>} />
          <Route path='/all-group-chats' element={<Protected><AllGroupChatsIndex /></Protected>} />
          <Route path='/group-chats/:groupChatSlug' element={<Protected><GroupChatsIndex /></Protected>} />

          <Route path='/problem-clans' element={<Protected><ProblemClansIndex /></Protected>} />
          <Route path='/problem-clans/add' element={<Protected><ProblemClansAdd/></Protected>} />
          <Route path='/problem-clans/:id' element={<Protected><ProblemClansView/></Protected>} />
          <Route path='/problem-clans/:id/edit' element={<Protected><ProblemClansEdit/></Protected>} />

          <Route path='/column-visibilities' element={<Protected><ColumnVisibilityIndex /></Protected>} />
          <Route path='/column-visibilities/:tableSlug/edit' element={<Protected><ColumnVisibilityEdit /></Protected>} />

          <Route path='/' element={<Protected><Index /></Protected>} />
          <Route path='/users/add' element={<Protected><UsersAdd /></Protected>} />
          <Route path='/users/:id/edit' element={<Protected><UsersEdit /></Protected>} />
          <Route path='/users' element={<Protected><UsersIndex /></Protected>} />
          <Route path='/users/:id' element={<Protected><UsersView /></Protected>} />

          <Route path='/announcements' element={<Protected><AnnouncementsIndex /></Protected>} />
          <Route path='/announcements/add' element={<Protected><AnnouncementsAdd /></Protected>} />
          <Route path='/announcements/:id' element={<Protected><AnnouncementsView /></Protected>} />
          <Route path='/announcements/:id/edit' element={<Protected><AnnouncementsEdit /></Protected>} />

          <Route path='/templates' element={<Protected><TemplatesIndex /></Protected>} />
          <Route path='/templates/add' element={<Protected><TemplatesAdd /></Protected>} />
          <Route path='/templates/:id' element={<Protected><TemplatesView /></Protected>} />
          <Route path='/templates/:id/edit' element={<Protected><TemplatesEdit /></Protected>} />

          {/* <Route path='/facepic-attachments' element={<Protected><FacepicAttachmentsIndex /></Protected>} />
          <Route path='/facepic-attachments/add' element={<Protected><FacepicAttachmentsAdd /></Protected>} />
          <Route path='/facepic-attachments/:id' element={<Protected><FacepicAttachmentsView /></Protected>} />
          <Route path='/facepic-attachments/:id/edit' element={<Protected><FacepicAttachmentsEdit /></Protected>} />
          <Route path='/facepic-attachments/:id/assign' element={<Protected><FacepicAttachmentsAssign /></Protected>} /> */}

          <Route path='/pdpa-attachments' element={<Protected><PdpaAttachmentsIndex /></Protected>} />
          <Route path='/pdpa-attachments/add' element={<Protected><PdpaAttachmentsAdd /></Protected>} />
          <Route path='/pdpa-attachments/:id' element={<Protected><PdpaAttachmentsView /></Protected>} />
          <Route path='/pdpa-attachments/:id/edit' element={<Protected><PdpaAttachmentsEdit /></Protected>} />
          <Route path='/pdpa-attachments/:id/assign' element={<Protected><PdpaAttachmentsAssign /></Protected>} />

          <Route path='/dropdowns' element={<Protected><DropdownsIndex /></Protected>} />
          <Route path='/dropdowns/add' element={<Protected><DropdownsAdd /></Protected>} />
          <Route path='/dropdowns/:id/edit' element={<Protected><DropdownsEdit /></Protected>} />
          <Route path='/dropdowns/:id' element={<Protected><DropdownsView /></Protected>} />

          <Route path='/references' element={<Protected><ReferencesIndex /></Protected>} />
          <Route path='/references/:id/edit' element={<Protected><ReferencesEdit /></Protected>} />
          <Route path='/references/:id' element={<Protected><ReferencesView /></Protected>} />

          <Route path='/export' element={<Protected><ExportIndex /></Protected>} />
          <Route path='/imports' element={<Protected><ImportIndex /></Protected>} />
          <Route path='/imports/:tableSlug' element={<Protected><ImportList /></Protected>} />
          <Route path='/imports/:tableSlug/import' element={<Protected><ImportImport /></Protected>} />
          <Route path='/imports/:tableSlug/:id' element={<Protected><ImportView /></Protected>} />

          <Route path='/privileges' element={<Protected><PrivilegesIndex /></Protected>} />
          <Route path='/privileges/:tableSlug/edit' element={<Protected><PrivilegesEdit /></Protected>} />

          <Route path='/default-values' element={<Protected><DefaultValuesIndex /></Protected>} />
          <Route path='/default-values/:defaultTableSlug/edit' element={<Protected><DefaultValuesEdit /></Protected>} />
          
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/calendar-view' element={<Protected><ClientCalendar /></Protected>} />
          <Route path='/workers/:workerId' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction/:jobSubsidiaryIdOrJobSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction/:jobSubsidiaryIdOrJobSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction/:jobSubsidiaryIdOrJobSubsidiaryAction/:problemSubsidiarySlugOrJobSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction/:jobSubsidiaryIdOrJobSubsidiaryAction/:problemSubsidiarySlugOrJobSubsidiaryAction/:problemSubsidiaryIdOrProblemSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
          <Route path='/workers/:workerId/:workerSubsidiarySlugOrWorkerAction/:workerSubsidiaryIdOrWorkerSubsidiaryAction/:jobSubsidiarySlugOrWorkerSubsidiaryAction/:jobSubsidiaryIdOrJobSubsidiaryAction/:problemSubsidiarySlugOrJobSubsidiaryAction/:problemSubsidiaryIdOrProblemSubsidiaryAction/:problemSubsidiaryAction' element={<Protected><WorkersView2 /></Protected>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
    </>
  );
}

export default App;
