import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivileges, getAllTableAndColumns, addObject, deleteObject, getObject, displayValueByColumnName, getUserFromToken, getProfile, getLabelNames, listenToNotifications } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import Modal from '../../components/modal'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'

export default function ProfilesIndex() {
  const tableSlug = 'users';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([
              {
                name: 'Edit',
                href: '#',
                class: 'bg-blue-500 hover:bg-blue-600',
                position: 'left',
                hidden: false,
              }
            ]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [object, setObject] = useState([]);
  
  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const params = useParams();

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    getProfile(cookies['token']).then((result) => {
      getLabelNames('users').then((result2) => {
        const columnToLabelObject = {};

        result2.data.forEach((row) => {
          columnToLabelObject[row['column_name']] = row['label_name'];
        }); 

        const labelObject = {};

        Object.keys(result.data).map((key, index) => {
          labelObject[columnToLabelObject[key]] = result.data[key];
        });

        setTableColumns(labelObject);
      })
    });

  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      navigate(`/profile/edit`);
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject(tableSlug, params.id).then(result => {
      navigate(`/${tableSlug}`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });

      
    });
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single='user' plural='users' />
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5 shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">MY PROFILE</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {Object.keys(tableColumns).map((key, index) => {
                  return (
                    <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                      <dt className="text-sm font-medium text-gray-500">{key}</dt>
                      <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                        {tableColumns[key]}
                      </dd>
                    </div>
                  )
                })}
                {/* {tableColumns.map((tableColumn, index) => {
                  if (tableColumn['privilege'] === 'Yes') {
                    if (tableColumn['value'] === null) {
                      return (
                        <div className="flex items-center py-4 grid grid-cols-2 gap-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={tableColumn['id']}>
                          <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            
                          </dd>
                        </div>
                      )
                    } else {
                      if (typeof(tableColumn['value']) === 'object') {
                        return (
                          <div className="flex items-center py-4 grid grid-cols-2 gap-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={tableColumn['id']}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              {tableColumn['value'].map((value, index2) => {
                                return (
                                  <button
                                    key={index2}
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 mr-2 border border-transparent text-sm font-medium shadow-sm text-white bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                                    disabled
                                  >
                                    <strong className="mr-2">{value['table_name']}</strong> {value['label_name']}
                                  </button>
                                )
                              })}
                            </dd>
                          </div>
                        )
                      } else {
                        return (
                          <div className="flex items-center py-4 grid grid-cols-2 gap-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={tableColumn['id']}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <Badge message={String(tableColumn['value'])} />
                            </dd>
                          </div>
                        )
                      }
                    }
                  }
                })} */}
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}