import { Fragment, useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { findDirectMessageConversation, getObject, getUnreadDirectMessageConversation, listenToNotifications } from '../../scripts/helpers'
import { format, isToday, isYesterday } from 'date-fns'
import { AuthContext } from '../../AuthProvider';

export default function DirectMessageConversationsShow(props) {
  const [directMessageConversation, setDirectMessageConversation] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [unread, setUnread] = useState(false);

  const createMarkup = (message) => {
    return {__html: message};
  };

  const formatDate =(d) => {
    if (isToday(d)) {
      return `Today at ${format(d, 'HH:mm')}`;
    }

    if (isYesterday(d)) {
      return `Yesterday at ${format(d, 'HH:mm')}`;
    }

    return format(d, 'dd/MM/yyyy');
  };

  useEffect(() => {
    findDirectMessageConversation(props.directMessageConversation.directMessageConversation_id, props.directMessageConversation.user_id).then(result => {
      const directMessageConversationId = result.data.id;
      getUnreadDirectMessageConversation(directMessageConversationId, cookies['userId']).then(result2 => {
        setUnread(result2.data.unread);
      });

      setDirectMessageConversation(result.data);
    });
  }, [props])

  if (directMessageConversation !== null) {
    return (
      <a href={`/direct-messages/${directMessageConversation.users[0].user_username}`} className="px-2 flex pt-1 pb-2 hover:bg-gray-50 max-h-[57px] overflow-hidden">
        <div className="flex justify-between w-[100%]">
          <div className="flex space-x-2">
            <div className="flex flex-col">
              <h3 className="font-medium">
                <span className="text-sm text-gray-900">{directMessageConversation.users[0].user_username} {directMessageConversation.users[0].user_status !== 'Active' ? <sup className='bg-red-500 text-white p-1 font-light'>{directMessageConversation.users[0].user_status}</sup> : ''} <span className="font-light text-xs">{formatDate(new Date(directMessageConversation.directMessages[0].date_record_created))}</span></span>
              </h3>
              {/* <p className="text-sm font-medium text-gray-900">{directMessageConversation.users[0].user_username}</p> */}
              <div className="text-sm text-gray-700" dangerouslySetInnerHTML={createMarkup(directMessageConversation.directMessages[0].message)}></div>
              {directMessageConversation.directMessages[0].directMessageAttachments !== undefined ? 
                directMessageConversation.directMessages[0].directMessageAttachments.map(directMessageAttachment => {
                  return (
                      <button
                        type="button"
                        className="mt-2 self-start inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-sky-700 bg-sky-100 cursor:pointer"
                        key={directMessageAttachment.id}
                      >
                        {directMessageAttachment.filename}
                      </button>
                  )
                })
              : ''}
            </div>
          </div>
          {unread === true && (
            <div>
              <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">
                •
              </span>
            </div>
          )}
        </div>
      </a>
    )
  } else {
    return <></>
  }
}