import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { AuthContext } from "../../AuthProvider"
import ResetPasswordAlertNotification from '../../components/resetPasswordAlertNotification'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { backend_base_url } from '../../utils/helper';


export default function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEmailAddress, setUserEmailAddress] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [userRetypePassword, setUserRetypePassword] = useState('');
  const [hideRetypePassword, setHideRetypePassword] = useState(true);
  const [outcome, setOutcome] = useState({});

  useEffect(() => {
    axios.get(`${backend_base_url()}/api/auth/validateResetToken?id=${searchParams.get('id')}`, { withCredentials: true })
    .then((result) => {
      setUserEmailAddress(result.data.user_email_address);
    })
    .catch(err => {
      if (err) {
        navigate('/forgotPassword');
      }
    });
  }, []);

  const handleChange = (event) => {
    setUserPassword(event.target.value);
  };

  const handleRetypeChange = (event) => {
    setUserRetypePassword(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    document.getElementById('user_password').className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
    document.getElementById('user_retype_password').className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';

    const passwordPattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*/);
    if (!userPassword.match(passwordPattern)) {
      setOutcome({
        message: "New password does not adhere to pattern required. Password must be at least 8 - 24 characters long, contain at least one uppercase letter, one lowercase letter, one numeral and one special character e.g. @#$%^&+*!=.",
        type: 'Danger'
      });

      document.getElementById("user_password").className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

      return
    } else if (!userRetypePassword.match(passwordPattern)) {
      setOutcome({
        message: "Retyped password does not adhere to pattern required. Password must be at least 8 - 24 characters long, contain at least one uppercase letter, one lowercase letter, one numeral and one special character e.g. @#$%^&+*!=.",
        type: 'Danger'
      });

      document.getElementById("user_retype_password").className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

      return
    } else if (userPassword !== userRetypePassword) {
      setOutcome({
        message: "New passwords provided do not match.",
        type: 'Danger'
      });

      document.getElementById("user_password").className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
      document.getElementById("user_retype_password").className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

      return
    }

    axios.post(`${backend_base_url()}/api/auth/setNewPassword`, {
      userEmailAddress: userEmailAddress,
      userPassword: userPassword,
      token: searchParams.get('id')
    }, {
      withCredentials: true,
      headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
    })
    .then(result => {
      setOutcome({
        message: "Reset password email sent. Redirecting...",
        type: 'Success'
      });

      setTimeout(() => {
        setOutcome({});
        navigate('/login');
      }, 3000);
    })
    .catch(error => {
      setOutcome({
        message: error.response.data.message,
        type: 'Danger'
      });

      document.getElementById(error.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
    });
  };

  const togglePasswordVisibility = () => {
    if (hidePassword) {
      document.getElementById('user_password').type = 'text';
      setHidePassword(false);
    } else {
      document.getElementById('user_password').type = 'password';
      setHidePassword(true);
    }
  };

  const toggleRetypePasswordVisibility = () => {
    if (hideRetypePassword) {
      document.getElementById('user_retype_password').type = 'text';
      setHideRetypePassword(false);
    } else {
      document.getElementById('user_retype_password').type = 'password';
      setHideRetypePassword(true);
    }
  };
  
  
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-56 w-auto"
            src="/logo_cms.png"
            alt="TWC2"
          />
          <h2 className="mt-6 text-center text-3xl font-medium text-gray-900">Reset password</h2>
        </div>

        {/* <AlertNotification outcome={outcome} setOutcome={setOutcome} /> */}
        <ResetPasswordAlertNotification outcome={outcome} setOutcome={setOutcome} />

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:-lg sm:px-10" id="resetPasswordDiv">
            <form className="space-y-6" onSubmit={handleFormSubmit}>
              <div>
                <label htmlFor="user_password" className="block text-sm font-medium text-gray-700">
                  New Password *
                </label>
                <div className="relative mt-1">
                  <input
                    id="user_password"
                    name="user_password"
                    type="password"
                    autoComplete="user_password"
                    required
                    value={userPassword}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                  {hidePassword ? (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility}>
                      <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  ) : (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility}>
                      <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
                <p class="mt-2 text-sm text-gray-500">Password must be at least 8 - 24 characters long, contain at least one uppercase letter, one lowercase letter, one numeral and one special character e.g. @#$%^&+*!=.</p>
              </div>
              <div>
                <label htmlFor="user_retype_password" className="block text-sm font-medium text-gray-700">
                  Re-type New Password *
                </label>
                <div className="relative mt-1">
                  <input
                    id="user_retype_password"
                    name="user_retype_password"
                    type="password"
                    autoComplete="user_retype_password"
                    required
                    value={userRetypePassword}
                    onChange={handleRetypeChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                  {hideRetypePassword ? (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleRetypePasswordVisibility}>
                      <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  ) : (
                    <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleRetypePasswordVisibility}>
                      <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent  shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Set new password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}