import { useEffect } from 'react'
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Action(action, position) {
  if (action.position === position) {
    if (action.position === 'left') {
      if (action.hidden) {
        return null;
      } else {
        if (action.disabled) {
          return (
            <button
              type="button"
              className={`inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-400 bg-gray-300 cursor-not-allowed`}
              disabled
            >
              {action.name}
            </button>
          )
        } else {
          if (action.href === '#') {
            return (
              <button
                type={action.type}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
                onClick={action.click}
              >
                {action.name}
              </button>
            )
          } else {
            return (
              <Link to={action.href}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </Link>
            )
          }
        }
      }
    } else {
      if (action.hidden) {
        return null;
      } else {
        if (action.disabled) {
          return (
            <button
              type="button"
              className={`inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-400 bg-gray-300 cursor-not-allowed`}
              disabled
            >
              {action.name}
            </button>
          )
        } else {
          if (action.href === '#') {
            return (
              <button
                type={action.type}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </button>
            )
          } else {
            return (
              <Link to={action.href}
                className={classNames('inline-flex items-center grow-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white', action.class)}
              >
                {action.name}
              </Link>
            )
          }
        }
      }
    }
  }
}

function TinyAction(actions, position) {
  if (position === 'left') {
    if (actions.filter((action) => action.position === 'left').length > 0) {
      return (
        <div className="flex items-center justify-between">
          <div className="flex w-full justify-between gap-075">
            {actions.map((action, index) => (
                <div key={index}>{Action(action, "left")}</div>
            ))}
          </div>
        </div>
      )
    }
  } else {
    if (actions.filter((action) => action.position === 'right').length > 0) {
      return (
        <div className="flex items-center justify-between">
          <div className="flex w-full justify-between gap-075">
            {actions.map((action, index) => (
                <div key={index}>{Action(action, "right")}</div>
            ))}
          </div>
        </div>
      )
    }
  }
}

export default function SubHeader(props) {
  const {actions} = props;

  if (actions.length > 0) {
    return (
      <>
      <nav className="bg-gray-100 sticky top-16 z-40">
        {/* Actions desktop etc. */}
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 block">
          <div className="flex items-center justify-between py-3">
            <div className="flex gap-075">
              {actions.map((action, index) => (
                  <div key={index}>{Action(action, "left")}</div>
              ))}
            </div>
            <div className="flex">
              {actions.map((action, index) => (
                  <div key={index}>{Action(action, "right")}</div>
              ))}
            </div>
          </div>
        </div>
      </nav>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}