import {useState} from 'react'
import NavigationButton from './navigationButton'
import TopNavigationButton from './topNavigationButton'
import React, { Fragment, useEffect } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { findVisibilityForTables } from '../scripts/helpers'
import Loading from '../components/loading'

function TopAndMiddleNavigation3 (props) {
  const {userId, topAndMiddleNavigationObj} = props;
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [visibilities, setVisibilities] = useState({});

  useEffect(() => {
    if (cookies && cookies['role']) {
      findVisibilityForTables(cookies['role']).then(results => {
        setVisibilities(results.data);
      })
    }
  }, []);

  if (topAndMiddleNavigationObj.level == 'problems') {
    if (topAndMiddleNavigationObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined && (topAndMiddleNavigationObj.page === 'Add' || topAndMiddleNavigationObj.page === 'Edit')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['aggravating-issues'] && <NavigationButton data={{title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lead-case-workers'] && <NavigationButton data={{title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['auxiliary-case-workers'] && <NavigationButton data={{title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['interpreters'] && <NavigationButton data={{title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ordinary-case-discussions'] && <NavigationButton data={{title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['sensitive-discussions'] && <NavigationButton data={{title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['warrants-to-act'] && <NavigationButton data={{title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lawyers'] && <NavigationButton data={{title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['legal-or-medical-funding'] && <NavigationButton data={{title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['manpower-officers'] && <NavigationButton data={{title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-histories'] && <NavigationButton data={{title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-claim-statuses'] && <NavigationButton data={{title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hearings-progress'] && <NavigationButton data={{title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['injury-histories'] && <NavigationButton data={{title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['illness-histories'] && <NavigationButton data={{title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hospitals'] && <NavigationButton data={{title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-claims-lodged'] && <NavigationButton data={{title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-statuses'] && <NavigationButton data={{title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['insurer-details'] && <NavigationButton data={{title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['mc-statuses'] && <NavigationButton data={{title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-problems'] && <NavigationButton data={{title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['police-reports'] && <NavigationButton data={{title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-complaints-lodged'] && <NavigationButton data={{title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['criminal-case-milestones'] && <NavigationButton data={{title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          
          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined && (topAndMiddleNavigationObj.page === 'List' || topAndMiddleNavigationObj.page === 'View')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
              
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
{/* 
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />} */}
              
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['aggravating-issues'] && <NavigationButton data={{title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lead-case-workers'] && <NavigationButton data={{title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['auxiliary-case-workers'] && <NavigationButton data={{title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['interpreters'] && <NavigationButton data={{title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ordinary-case-discussions'] && <NavigationButton data={{title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['sensitive-discussions'] && <NavigationButton data={{title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['warrants-to-act'] && <NavigationButton data={{title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lawyers'] && <NavigationButton data={{title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['legal-or-medical-funding'] && <NavigationButton data={{title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['manpower-officers'] && <NavigationButton data={{title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-histories'] && <NavigationButton data={{title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-claim-statuses'] && <NavigationButton data={{title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hearings-progress'] && <NavigationButton data={{title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['injury-histories'] && <NavigationButton data={{title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['illness-histories'] && <NavigationButton data={{title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hospitals'] && <NavigationButton data={{title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-claims-lodged'] && <NavigationButton data={{title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-statuses'] && <NavigationButton data={{title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['insurer-details'] && <NavigationButton data={{title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['mc-statuses'] && <NavigationButton data={{title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-problems'] && <NavigationButton data={{title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['police-reports'] && <NavigationButton data={{title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-complaints-lodged'] && <NavigationButton data={{title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['criminal-case-milestones'] && <NavigationButton data={{title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && (topAndMiddleNavigationObj.page === 'Add' || topAndMiddleNavigationObj.page === 'Edit')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['aggravating-issues'] && <NavigationButton data={{title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lead-case-workers'] && <NavigationButton data={{title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['auxiliary-case-workers'] && <NavigationButton data={{title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['interpreters'] && <NavigationButton data={{title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ordinary-case-discussions'] && <NavigationButton data={{title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['sensitive-discussions'] && <NavigationButton data={{title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['warrants-to-act'] && <NavigationButton data={{title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lawyers'] && <NavigationButton data={{title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['legal-or-medical-funding'] && <NavigationButton data={{title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['manpower-officers'] && <NavigationButton data={{title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-histories'] && <NavigationButton data={{title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-claim-statuses'] && <NavigationButton data={{title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hearings-progress'] && <NavigationButton data={{title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['injury-histories'] && <NavigationButton data={{title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['illness-histories'] && <NavigationButton data={{title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hospitals'] && <NavigationButton data={{title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-claims-lodged'] && <NavigationButton data={{title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-statuses'] && <NavigationButton data={{title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['insurer-details'] && <NavigationButton data={{title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['mc-statuses'] && <NavigationButton data={{title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-problems'] && <NavigationButton data={{title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['police-reports'] && <NavigationButton data={{title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-complaints-lodged'] && <NavigationButton data={{title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['criminal-case-milestones'] && <NavigationButton data={{title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined) {
      // view problem
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/'}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['aggravating-issues'] && <NavigationButton data={{title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lead-case-workers'] && <NavigationButton data={{title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['auxiliary-case-workers'] && <NavigationButton data={{title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['interpreters'] && <NavigationButton data={{title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ordinary-case-discussions'] && <NavigationButton data={{title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['sensitive-discussions'] && <NavigationButton data={{title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['warrants-to-act'] && <NavigationButton data={{title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lawyers'] && <NavigationButton data={{title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['legal-or-medical-funding'] && <NavigationButton data={{title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['manpower-officers'] && <NavigationButton data={{title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-histories'] && <NavigationButton data={{title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-claim-statuses'] && <NavigationButton data={{title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hearings-progress'] && <NavigationButton data={{title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['injury-histories'] && <NavigationButton data={{title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['illness-histories'] && <NavigationButton data={{title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hospitals'] && <NavigationButton data={{title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-claims-lodged'] && <NavigationButton data={{title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-statuses'] && <NavigationButton data={{title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['insurer-details'] && <NavigationButton data={{title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['mc-statuses'] && <NavigationButton data={{title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-problems'] && <NavigationButton data={{title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['police-reports'] && <NavigationButton data={{title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-complaints-lodged'] && <NavigationButton data={{title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['criminal-case-milestones'] && <NavigationButton data={{title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/'}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['aggravating-issues'] && <NavigationButton data={{title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lead-case-workers'] && <NavigationButton data={{title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['auxiliary-case-workers'] && <NavigationButton data={{title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['interpreters'] && <NavigationButton data={{title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ordinary-case-discussions'] && <NavigationButton data={{title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['sensitive-discussions'] && <NavigationButton data={{title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['warrants-to-act'] && <NavigationButton data={{title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['lawyers'] && <NavigationButton data={{title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['legal-or-medical-funding'] && <NavigationButton data={{title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['manpower-officers'] && <NavigationButton data={{title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-histories'] && <NavigationButton data={{title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['salary-claim-statuses'] && <NavigationButton data={{title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hearings-progress'] && <NavigationButton data={{title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['injury-histories'] && <NavigationButton data={{title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['illness-histories'] && <NavigationButton data={{title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['hospitals'] && <NavigationButton data={{title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-claims-lodged'] && <NavigationButton data={{title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['wica-statuses'] && <NavigationButton data={{title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['insurer-details'] && <NavigationButton data={{title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['mc-statuses'] && <NavigationButton data={{title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-problems'] && <NavigationButton data={{title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['police-reports'] && <NavigationButton data={{title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['other-complaints-lodged'] && <NavigationButton data={{title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['criminal-case-milestones'] && <NavigationButton data={{title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}` }} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['aggravating-issues'] && <NavigationButton data={{close: close, title: 'Aggravating issue', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/aggravating-issues`, table: 'aggravatingIssues', tableSlug: 'aggravating-issues', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'aggravating-issues', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lead-case-workers'] && <NavigationButton data={{close: close, title: 'Lead case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lead-case-workers`, table: 'leadCaseWorkers', tableSlug: 'lead-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lead-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['auxiliary-case-workers'] && <NavigationButton data={{close: close, title: 'Auxiliary case worker', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/auxiliary-case-workers`, table: 'auxiliaryCaseWorkers', tableSlug: 'auxiliary-case-workers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'auxiliary-case-workers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['interpreters'] && <NavigationButton data={{close: close, title: 'Interpreter', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/interpreters`, table: 'interpreters', tableSlug: 'interpreters', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'interpreters', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ordinary-case-discussions'] && <NavigationButton data={{close: close, title: 'Ordinary case discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/ordinary-case-discussions`, table: 'caseDiscussions', tableSlug: 'ordinary-case-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'ordinary-case-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['sensitive-discussions'] && <NavigationButton data={{close: close, title: 'Sensitive discussion', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/sensitive-discussions`, table: 'sensitiveDiscussion', tableSlug: 'sensitive-discussions', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'sensitive-discussions', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['warrants-to-act'] && <NavigationButton data={{close: close, title: 'POA/Warrant to act', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/warrants-to-act`, table: 'warrantToActs', tableSlug: 'warrants-to-act', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'warrants-to-act', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['lawyers'] && <NavigationButton data={{close: close, title: 'Lawyer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/lawyers`, table: 'lawyers', tableSlug: 'lawyers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'lawyers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['legal-or-medical-funding'] && <NavigationButton data={{close: close, title: 'Legal or medical funding', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/legal-or-medical-funding`, table: 'legalMedicalFundings', tableSlug: 'legal-or-medical-funding', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'legal-or-medical-funding', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['manpower-officers'] && <NavigationButton data={{close: close, title: 'Manpower officer', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/manpower-officers`, table: 'manpowerOfficers', tableSlug: 'manpower-officers', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'manpower-officers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-histories'] && <NavigationButton data={{close: close, title: 'Salary history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-histories`, table: 'salaryHistories', tableSlug: 'salary-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['salary-claim-statuses'] && <NavigationButton data={{close: close, title: 'Salary claim status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/salary-claim-statuses`, table: 'salaryClaimStatuses', tableSlug: 'salary-claim-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'salary-claim-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hearings-progress'] && <NavigationButton data={{close: close, title: 'Hearings progress', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hearings-progress`, table: 'hearingsProgress', tableSlug: 'hearings-progress', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hearingsProgress', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['injury-histories'] && <NavigationButton data={{close: close, title: 'Injury history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/injury-histories`, table: 'injuries', tableSlug: 'injuries', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'injury-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['illness-histories'] && <NavigationButton data={{close: close, title: 'Illness history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/illness-histories`, table: 'illnesses', tableSlug: 'illnesses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'illness-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['hospitals'] && <NavigationButton data={{close: close, title: 'Hospital', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/hospitals`, table: 'hospitals', tableSlug: 'hospitals', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'hospitals', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-claims-lodged'] && <NavigationButton data={{close: close, title: 'Wica claim lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-claims-lodged`, table: 'wicaClaims', tableSlug: 'wica-claims-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-claims-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['wica-statuses'] && <NavigationButton data={{close: close, title: 'Wica status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/wica-statuses`, table: 'wicaStatuses', tableSlug: 'wica-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'wica-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['insurer-details'] && <NavigationButton data={{close: close, title: 'Insurer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/insurer-details`, table: 'insurers', tableSlug: 'insurer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'insurer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['mc-statuses'] && <NavigationButton data={{close: close, title: 'MC status', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/mc-statuses`, table: 'mcStatuses', tableSlug: 'mc-statuses', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'mc-statuses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-problems'] && <NavigationButton data={{close: close, title: 'Other problem', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-problems`, table: 'otherProblems', tableSlug: 'other-problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-problems', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['police-reports'] && <NavigationButton data={{close: close, title: 'Police report', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/police-reports`, table: 'policeReports', tableSlug: 'police-reports', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'police-reports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['other-complaints-lodged'] && <NavigationButton data={{close: close, title: 'Other complaint lodged', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/other-complaints-lodged`, table: 'otherComplaints', tableSlug: 'other-complaints-lodged', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'other-complaints-lodged', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['criminal-case-milestones'] && <NavigationButton data={{close: close, title: 'Criminal case milestone', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems/${topAndMiddleNavigationObj.problemId}/criminal-case-milestones`, table: 'criminalCaseMilestones', tableSlug: 'criminal-case-milestones', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, problemId: topAndMiddleNavigationObj.problemId, problemSubsidiarySlug:'criminal-case-milestones', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  } else if (topAndMiddleNavigationObj.level == 'jobs') {
    if (topAndMiddleNavigationObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && (topAndMiddleNavigationObj.page === 'Add' || topAndMiddleNavigationObj.page === 'Edit')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
              {/* <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['current-passes'] && <NavigationButton data={{title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['benefits'] && <NavigationButton data={{title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ipa-details'] && <NavigationButton data={{title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employment-contracts'] && <NavigationButton data={{title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['intermediary-agents'] && <NavigationButton data={{title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employer-details'] && <NavigationButton data={{title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['workplaces'] && <NavigationButton data={{title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['work-histories'] && <NavigationButton data={{title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['accommodation-during-work'] && <NavigationButton data={{title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['transfer-and-repatriation'] && <NavigationButton data={{title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && (topAndMiddleNavigationObj.page === 'List' || topAndMiddleNavigationObj.page === 'View')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
              {/* <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['current-passes'] && <NavigationButton data={{title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['benefits'] && <NavigationButton data={{title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ipa-details'] && <NavigationButton data={{title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employment-contracts'] && <NavigationButton data={{title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['intermediary-agents'] && <NavigationButton data={{title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employer-details'] && <NavigationButton data={{title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['workplaces'] && <NavigationButton data={{title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['work-histories'] && <NavigationButton data={{title: 'Work history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['accommodation-during-work'] && <NavigationButton data={{title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['transfer-and-repatriation'] && <NavigationButton data={{title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}`, page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.workerSubsidiarySlugOrWorkerAction !== undefined && (topAndMiddleNavigationObj.page === 'Add' || topAndMiddleNavigationObj.page === 'Edit')) {
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
              {/* <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['current-passes'] && <NavigationButton data={{title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['benefits'] && <NavigationButton data={{title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ipa-details'] && <NavigationButton data={{title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employment-contracts'] && <NavigationButton data={{title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['intermediary-agents'] && <NavigationButton data={{title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employer-details'] && <NavigationButton data={{title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['workplaces'] && <NavigationButton data={{title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['work-histories'] && <NavigationButton data={{title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['accommodation-during-work'] && <NavigationButton data={{title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['transfer-and-repatriation'] && <NavigationButton data={{title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close }) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.workerSubsidiarySlugOrWorkerAction !== undefined) {
      // view job
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/'}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
              <TopNavigationButton data={{title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
              {/* <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['current-passes'] && <NavigationButton data={{title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['benefits'] && <NavigationButton data={{title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ipa-details'] && <NavigationButton data={{title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employment-contracts'] && <NavigationButton data={{title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['intermediary-agents'] && <NavigationButton data={{title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['employer-details'] && <NavigationButton data={{title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['workplaces'] && <NavigationButton data={{title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['work-histories'] && <NavigationButton data={{title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['accommodation-during-work'] && <NavigationButton data={{title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['transfer-and-repatriation'] && <NavigationButton data={{title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          <TopNavigationButton data={{close: close, title: 'Problem', table: 'problems', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/problems`, jobSubsidiarySlug: 'problems', page: topAndMiddleNavigationObj.page}} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['current-passes'] && <NavigationButton data={{close: close, title: 'Current Pass', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/current-passes`, table: 'currentPasses', tableSlug: 'current-passes', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'current-passes', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['benefits'] && <NavigationButton data={{close: close, title: 'Benefit', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/benefits`, table: 'benefits', tableSlug: 'benefits', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'benefits', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ipa-details'] && <NavigationButton data={{close: close, title: 'IPA Details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/ipa-details`, table: 'ipaDetails', tableSlug: 'ipa-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'ipa-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['verbal-assurances-pre-job'] && <NavigationButton data={{close: close, title: 'Verbal assurances pre-job', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/verbal-assurances-pre-job`, table: 'verbalAssurances', tableSlug: 'verbal-assurances-pre-job', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'verbal-assurances-pre-job', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employment-contracts'] && <NavigationButton data={{close: close, title: 'Employment contract', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employment-contracts`, table: 'employmentContracts', tableSlug: 'employment-contracts', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employment-contracts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['intermediary-agents'] && <NavigationButton data={{close: close, title: 'Intermediary agent', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/intermediary-agents`, table: 'agents', tableSlug: 'intermediary-agents', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'intermediary-agents', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['employer-details'] && <NavigationButton data={{close: close, title: 'Employer details', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/employer-details`, table: 'employers', tableSlug: 'employer-details', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'employer-details', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['workplaces'] && <NavigationButton data={{close: close, title: 'Workplace', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/workplaces`, table: 'workplaces', tableSlug: 'workplaces', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'workplaces', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['work-histories'] && <NavigationButton data={{close: close, title: 'Work history', table: 'workHistories', tableSlug: 'work-histories', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/work-histories`, jobSubsidiarySlug:'work-histories', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['accommodation-during-work'] && <NavigationButton data={{close: close, title: 'Accommodation during work', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/accommodation-during-work`, table: 'accommodation', tableSlug: 'accommodation-during-work', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'accommodation-during-work', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['transfer-and-repatriation'] && <NavigationButton data={{close: close, title: 'Transfer and repatriation', link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs/${topAndMiddleNavigationObj.jobId}/transfer-and-repatriation`, table: 'transferRepats', tableSlug: 'transfer-and-repatriation', workerId: topAndMiddleNavigationObj.workerId, jobId: topAndMiddleNavigationObj.jobId, jobSubsidiarySlug:'transfer-and-repatriation', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      // job level. mode: view
      if (topAndMiddleNavigationObj.page === 'Edit') {
        return (
          <Fragment>
            {/* desktop */}
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
              </ul>
            </div>
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['passports'] && <NavigationButton data={{title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['languages'] && <NavigationButton data={{title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
              </ul>
            </div>

            {/* tablet */}
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            {/* mobile */}
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </Fragment>
        )
      } else if (topAndMiddleNavigationObj.page === 'View') {
        return (
          <Fragment>
            {/* desktop */}
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                <TopNavigationButton data={{title: 'New search', link: '/' }} />
                <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
              </ul>
            </div>
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards'}} />}
                {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames'}} />}
                {visibilities['passports'] && <NavigationButton data={{title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports'}} />}
                {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers'}} />}
                {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts'}} />}
                {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers'}} />}
                {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers'}} />}
                {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore'}} />}
                {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses'}} />}
                {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins'}} />}
                {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore'}} />}
                {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore'}} />}
                {visibilities['languages'] && <NavigationButton data={{title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages'}} />}
                {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts'}} />}
                {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments'}} />}
              </ul>
            </div>

            {/* tablet */}
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/' }} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards'}} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames'}} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports'}} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers'}} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts'}} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers'}} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers'}} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore'}} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses'}} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins'}} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore'}} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore'}} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages'}} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts'}} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            {/* mobile */}
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/' }} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards'}} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames'}} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports'}} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers'}} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts'}} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers'}} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers'}} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore'}} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses'}} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins'}} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore'}} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore'}} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages'}} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts'}} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }
    }
  } else if (topAndMiddleNavigationObj.level == 'workers') {
    if (topAndMiddleNavigationObj.workerSubsidiarySlugOrWorkerAction !== undefined && (topAndMiddleNavigationObj.page === 'Add' || topAndMiddleNavigationObj.page === 'Edit')) {
      // worker subsidiary level. mode: add
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['passports'] && <NavigationButton data={{title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['languages'] && <NavigationButton data={{title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}`, page: topAndMiddleNavigationObj.page}} />
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else if (topAndMiddleNavigationObj.workerSubsidiarySlugOrWorkerAction !== undefined) {
      // worker subsisidary level. mode: list
      return (
        <Fragment>
          {/* desktop */}
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              <TopNavigationButton data={{title: 'New search', link: '/'}} />
              <TopNavigationButton data={{title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
              {/* <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
              {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
              {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
              {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
              {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
            </ul>
          </div>
          <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
              {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['passports'] && <NavigationButton data={{title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['languages'] && <NavigationButton data={{title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
              {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
            </ul>
          </div>

          {/* tablet */}
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-4 gap-3">
                          {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                        <span>Navigation buttons</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          <TopNavigationButton data={{close: close, title: 'New search', link: '/'}} />
                          <TopNavigationButton data={{close: close, title: 'Go up to worker', link: `/workers/${topAndMiddleNavigationObj.workerId}` }} />
                          {/* <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs'}} /> */}
                          {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                          {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                          {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                          {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
          <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
            <div className="w-full">
              <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                <Disclosure>
                  {({ open, close}) => (
                    <Fragment>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                        <span>Subsidiary tables</span>
                        <ChevronDownIcon
                          className={`${
                            open ? 'transform rotate-180' : ''
                          } w-5 h-5 text-white`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                        <ul role="list" className="grid grid-cols-2 gap-3">
                          {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                          {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                        </ul>
                      </Disclosure.Panel>
                    </Fragment>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      // worker level. mode: view
      if (topAndMiddleNavigationObj.page === 'Edit') {
        return (
          <Fragment>
            {/* desktop */}
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                <TopNavigationButton data={{title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
              </ul>
            </div>
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['passports'] && <NavigationButton data={{title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['languages'] && <NavigationButton data={{title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
              </ul>
            </div>

            {/* tablet */}
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            {/* mobile */}
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/', page: topAndMiddleNavigationObj.page}} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs', page: topAndMiddleNavigationObj.page}} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </Fragment>
        )
      } else if (topAndMiddleNavigationObj.page === 'View') {
        return (
          <Fragment>
            {/* desktop */}
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                <TopNavigationButton data={{title: 'New search', link: '/' }} />
                <TopNavigationButton data={{title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                {visibilities['ordinary-attachments'] && <TopNavigationButton data={{title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                {visibilities['sensitive-attachments'] && <TopNavigationButton data={{title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                {visibilities['facepic-attachments'] && <TopNavigationButton data={{title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                {visibilities['pdpa-attachments'] && <TopNavigationButton data={{title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
              </ul>
            </div>
            <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
              <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
                {visibilities['twid-cards'] && <NavigationButton data={{title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['nicknames'] && <NavigationButton data={{title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['passports'] && <NavigationButton data={{title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['singapore-phone-numbers'] && <NavigationButton data={{title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['digital-contacts'] && <NavigationButton data={{title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-phone-numbers'] && <NavigationButton data={{title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['ticket-numbers'] && <NavigationButton data={{title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['addresses-in-singapore'] && <NavigationButton data={{title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['home-country-addresses'] && <NavigationButton data={{title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['next-of-kins'] && <NavigationButton data={{title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['family-members-in-singapore'] && <NavigationButton data={{title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['friends-in-singapore'] && <NavigationButton data={{title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['languages'] && <NavigationButton data={{title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['bank-accounts'] && <NavigationButton data={{title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                {visibilities['client-appointments'] && <NavigationButton data={{title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
              </ul>
            </div>

            {/* tablet */}
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/' }} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="hidden mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:block lg:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-4 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            {/* mobile */}
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none" style={{background: '#708090'}}>
                          <span>Navigation buttons</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            <TopNavigationButton data={{close: close, title: 'New search', link: '/' }} />
                            <TopNavigationButton data={{close: close, title: 'Job', table: 'jobs', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/jobs`, jobSubsidiarySlug: 'jobs' }} />
                            {visibilities['ordinary-attachments'] && <TopNavigationButton data={{close: close, title: 'Ordinary attachment', table: 'ordinaryAttachment', tableSlug: 'ordinary-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/ordinary-attachments`, jobSubsidiarySlug: 'ordinary-attachments'}} />}
                            {visibilities['sensitive-attachments'] && <TopNavigationButton data={{close: close, title: 'Sensitive attachment', table: 'sensitiveAttachment', tableSlug: 'sensitive-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/sensitive-attachments`, jobSubsidiarySlug: 'sensitive-attachments'}} />}
                            {visibilities['facepic-attachments'] && <TopNavigationButton data={{close: close, title: 'Facepic attachment', table: 'facepicAttachment', tableSlug: 'facepic-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/facepic-attachments`, jobSubsidiarySlug: 'facepic-attachments'}} />}
                            {visibilities['pdpa-attachments'] && <TopNavigationButton data={{close: close, title: 'PDPA attachment', table: 'pdpaAttachment', tableSlug: 'pdpa-attachments', workerId: topAndMiddleNavigationObj.workerId, link: `/workers/${topAndMiddleNavigationObj.workerId}/pdpa-attachments`, jobSubsidiarySlug: 'pdpa-attachments'}} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
            <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 md:hidden">
              <div className="w-full">
                <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
                  <Disclosure>
                    {({ open, close}) => (
                      <Fragment>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-turqoise-600 hover:bg-turqoise-800" style={{background: '#708090'}}>
                          <span>Subsidiary tables</span>
                          <ChevronDownIcon
                            className={`${
                              open ? 'transform rotate-180' : ''
                            } w-5 h-5 text-white`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                          <ul role="list" className="grid grid-cols-2 gap-3">
                            {visibilities['twid-cards'] && <NavigationButton data={{close: close, title: 'Twid Card', link: `/workers/${topAndMiddleNavigationObj.workerId}/twid-cards`, table: 'twidCards', tableSlug: 'twid-cards', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'twid-cards', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['nicknames'] && <NavigationButton data={{close: close, title: 'Nickname', link: `/workers/${topAndMiddleNavigationObj.workerId}/nicknames`, table: 'nicknames', tableSlug: 'nicknames', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'nicknames', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['passports'] && <NavigationButton data={{close: close, title: 'Passport', link: `/workers/${topAndMiddleNavigationObj.workerId}/passports`, table: 'passports', tableSlug: 'passports', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'passports', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['singapore-phone-numbers'] && <NavigationButton data={{close: close, title: 'Singapore phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/singapore-phone-numbers`, table: 'sgPhoneNumbers', tableSlug: 'singapore-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'singapore-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['digital-contacts'] && <NavigationButton data={{close: close, title: 'Digital contact', link: `/workers/${topAndMiddleNavigationObj.workerId}/digital-contacts`, table: 'digitalContacts', tableSlug: 'digital-contacts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'digital-contacts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-phone-numbers'] && <NavigationButton data={{close: close, title: 'Home country phone number', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-phone-numbers`, table: 'homeCountryPhoneNumbers', tableSlug: 'home-country-phone-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-phone-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['ticket-numbers'] && <NavigationButton data={{close: close, title: 'Ticket number', link: `/workers/${topAndMiddleNavigationObj.workerId}/ticket-numbers`, table: 'ticketNumbers', tableSlug: 'ticket-numbers', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'ticket-numbers', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['addresses-in-singapore'] && <NavigationButton data={{close: close, title: 'Address in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/addresses-in-singapore`, table: 'sgAddresses', tableSlug: 'addresses-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'addresses-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['home-country-addresses'] && <NavigationButton data={{close: close, title: 'Home country address', link: `/workers/${topAndMiddleNavigationObj.workerId}/home-country-addresses`, table: 'homeCountryAddresses', tableSlug: 'home-country-addresses', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'home-country-addresses', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['next-of-kins'] && <NavigationButton data={{close: close, title: 'Next of kin', link: `/workers/${topAndMiddleNavigationObj.workerId}/next-of-kins`, table: 'nextOfKins', tableSlug: 'next-of-kins', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'next-of-kins', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['family-members-in-singapore'] && <NavigationButton data={{close: close, title: 'Family member in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/family-members-in-singapore`, table: 'familyMembers', tableSlug: 'family-members-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'family-members-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['friends-in-singapore'] && <NavigationButton data={{close: close, title: 'Friend in Singapore', link: `/workers/${topAndMiddleNavigationObj.workerId}/friends-in-singapore`, table: 'sgFriends', tableSlug: 'friends-in-singapore', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'friends-in-singapore', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['languages'] && <NavigationButton data={{close: close, title: 'Language', link: `/workers/${topAndMiddleNavigationObj.workerId}/languages`, table: 'languages', tableSlug: 'languages', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'languages', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['bank-accounts'] && <NavigationButton data={{close: close, title: 'Bank account', link: `/workers/${topAndMiddleNavigationObj.workerId}/bank-accounts`, table: 'bankAccounts', tableSlug: 'bank-accounts', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'bank-accounts', page: topAndMiddleNavigationObj.page }} />}
                            {visibilities['client-appointments'] && <NavigationButton data={{close: close, title: 'Client appt', link: `/workers/${topAndMiddleNavigationObj.workerId}/client-appointments`, table: 'clientAppointments', tableSlug: 'client-appointments', workerId: topAndMiddleNavigationObj.workerId, jobSubsidiarySlug:'client-appointments', page: topAndMiddleNavigationObj.page }} />}
                          </ul>
                        </Disclosure.Panel>
                      </Fragment>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>
          </Fragment>
        )
      }
    }
  }
  
  return (
    <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
  )
}

export default React.memo(TopAndMiddleNavigation3);