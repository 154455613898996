import { useState, useEffect, useContext } from "react"
import Header from '../../components/header'
import Loading from '../../components/loading'
import { Link } from "react-router-dom"
import axios from "axios"
import { Disclosure } from '@headlessui/react'
import { AuthContext } from "../../AuthProvider"
import { listenToNotifications } from "../../scripts/helpers"
import { backend_base_url } from "../../utils/helper"


// const navigation = [
//   {
//     name: 'Worker',
//     current: false,
//     children: [
//       { name: 'Bank Account', href: '#BankAccount' },
//       { name: 'Client Calendar', href: '#ClientCalendar' },
//       { name: 'Digital Contact', href: '#DigitalContact' },
//       { name: 'Facepic Attachment', href: '#FacepicAttachment' },
//       { name: 'Family Member', href: '#FamilyMember' },
//       { name: 'Home Country Address', href: '#HomeCountryAddress' },
//       { name: 'Home Country Phone Number', href: '#HomeCountryPhoneNumber' },
//       { name: 'Language', href: '#Language' },
//       { name: 'Next Of Kin', href: '#NextOfKin' },
//       { name: 'Nickname', href: '#Nickname' },
//       { name: 'Passport', href: '#Passport' },
//       { name: 'SG Address', href: '#SingaporeAddress' },
//       { name: 'SG Friend', href: '#SingaporeFriend' },
//       { name: 'SG Phone Number', href: '#SingaporePhoneNumber' },
//       { name: 'Ticket Number', href: '#TicketNumber' },
//       { name: 'Twid Card', href: '#TwidCard' },
//       { name: 'Worker', href: '#Worker' },
//     ],
//   },
//   {
//     name: 'Job',
//     current: false,
//     children: [
//       { name: 'Accommodation', href: '#Accommodation' },
//       { name: 'Agent', href: '#Agent' },
//       { name: 'Benefit', href: '#Benefit' },
//       { name: 'Current Pass', href: '#CurrentPass' },
//       { name: 'Employer', href: '#Employer' },
//       { name: 'Employment Contract', href: '#EmploymentContract' },
//       { name: 'IPA Details', href: '#IPADetails' },
//       { name: 'Transfer Repat', href: '#TransferRepat' },
//       { name: 'Verbal Assurance', href: '#VerbalAssurance' },
//       { name: 'Workplace', href: '#Workplace' },
//       { name: 'Work History', href: '#WorkHistory' },
//     ],
//   },
//   {
//     name: 'Problem',
//     current: false,
//     children: [
//       { name: 'Aggravating Issue', href: '#AggravatingIssue' },
//       { name: 'Auxiliary Case Worker', href: '#AuxiliaryCaseWorker' },
//       { name: 'Case Discussion', href: '#CaseDiscussion' },
//       { name: 'Criminal Case Milestone', href: '#CriminalCaseMilestone' },
//       { name: 'Illness', href: '#Illness' },
//       { name: 'Injury', href: '#Injury' },
//       { name: 'Interpreter', href: '#Interpreter' },
//       { name: 'Insurer', href: '#Insurer' },
//       { name: 'Hearings Progress', href: '#HearingsProgress' },
//       { name: 'Hospital', href: '#Hospital' },
//       { name: 'Lawyer', href: '#Lawyer' },
//       { name: 'Lead Case Worker', href: '#LeadCaseWorker' },
//       { name: 'Legal Medical Funding', href: '#LegalMedicalFunding' },
//       { name: 'Manpower Officer', href: '#ManpowerOfficer' },
//       { name: 'MC Status', href: '#MCStatus' },
//       { name: 'Other Complaint', href: '#OtherComplaint' },
//       { name: 'Other Problems', href: '#OtherProblems' },
//       { name: 'Police Report', href: '#PoliceReport' },
//       { name: 'Salary Claim Status', href: '#SalaryClaimStatus' },
//       { name: 'Salary History', href: '#SalaryHistory' },
//       { name: 'Sensitive Discussion', href: '#SensitiveDiscussion' },
//       { name: 'Warrant To Act', href: '#WarrantToAct' },
//       { name: 'Wica Claim', href: '#WicaClaim' },
//       { name: 'Wica Status', href: '#WicaStatus' },
//     ],
//   },
//   {
//     name: 'Standalone',
//     current: false,
//     children: [
//       { name: 'Overview', href: '#' },
//       { name: 'Members', href: '#' },
//       { name: 'Calendar', href: '#' },
//       { name: 'Settings', href: '#' },
//     ],
//   },
// ];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
};

export default function PrivilegesIndex() {
  const [tableAndColumnNames, setTableAndColumnNames] = useState({});
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/getPrivileges`, {withCredentials: true})
      .then(result => {
        const arrangedPrivilegeTables = {};

        result.data.forEach((row) => {
          if (arrangedPrivilegeTables[row.table_name] === undefined) {
            arrangedPrivilegeTables[row.table_name] = {};
            arrangedPrivilegeTables[row.table_name]['tableName'] = row.table_name;
            arrangedPrivilegeTables[row.table_name]['tableSlug'] = row.table_slug;
            arrangedPrivilegeTables[row.table_name]['columns'] = [];
          }

          // loop through privileges and arrange according to admin to recorder, add to delete
          // const arrangedPrivileges = [];

          // row['privileges'].forEach((privilegeObject) => {
          //   if (privilegeObject.role_id === 1) {
          //     if (privilegeObject.mode === 'List') {
          //       arrangedPrivileges[0] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Add') {
          //       arrangedPrivileges[1] = privilegeObject;
          //     } else if (privilegeObject.mode === 'View') {
          //       arrangedPrivileges[2] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Edit') {
          //       arrangedPrivileges[3] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Delete') {
          //       arrangedPrivileges[4] = privilegeObject;
          //     }
          //   } else if (privilegeObject.role_id === 2) {
          //     if (privilegeObject.mode === 'List') {
          //       arrangedPrivileges[5] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Add') {
          //       arrangedPrivileges[6] = privilegeObject;
          //     } else if (privilegeObject.mode === 'View') {
          //       arrangedPrivileges[7] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Edit') {
          //       arrangedPrivileges[8] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Delete') {
          //       arrangedPrivileges[9] = privilegeObject;
          //     }
          //   } else if (privilegeObject.role_id === 3) {
          //     if (privilegeObject.mode === 'List') {
          //       arrangedPrivileges[10] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Add') {
          //       arrangedPrivileges[11] = privilegeObject;
          //     } else if (privilegeObject.mode === 'View') {
          //       arrangedPrivileges[12] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Edit') {
          //       arrangedPrivileges[13] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Delete') {
          //       arrangedPrivileges[14] = privilegeObject;
          //     }
          //   } else if (privilegeObject.role_id === 4) {
          //     if (privilegeObject.mode === 'List') {
          //       arrangedPrivileges[15] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Add') {
          //       arrangedPrivileges[16] = privilegeObject;
          //     } else if (privilegeObject.mode === 'View') {
          //       arrangedPrivileges[17] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Edit') {
          //       arrangedPrivileges[18] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Delete') {
          //       arrangedPrivileges[19] = privilegeObject;
          //     }
          //   } else if (privilegeObject.role_id === 5) {
          //     if (privilegeObject.mode === 'List') {
          //       arrangedPrivileges[20] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Add') {
          //       arrangedPrivileges[21] = privilegeObject;
          //     } else if (privilegeObject.mode === 'View') {
          //       arrangedPrivileges[22] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Edit') {
          //       arrangedPrivileges[23] = privilegeObject;
          //     } else if (privilegeObject.mode === 'Delete') {
          //       arrangedPrivileges[24] = privilegeObject;
          //     }
          //   }
          // });

          // row['privileges'] = arrangedPrivileges;

          // arrangedPrivilegeTables[row.table_name]['columns'].push(row);
        })

        setTableAndColumnNames(arrangedPrivilegeTables);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  } else {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="mt-5 pb-5 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              PRIVILEGES
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-y-5 gap-x-4">
            <div className="col-span-6">
              {
                Object.keys(tableAndColumnNames).map((tableAndColumnNameKey, index) => 
                    <div className="border-b border-gray-300 max-w-7xl mx-auto lg:block" key={index}>
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <a id={tableAndColumnNames[tableAndColumnNameKey].tableName.replace(/\s+/g, '')}>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnNameKey}</h3>
                        </a>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                          <Link
                            to={tableAndColumnNames[tableAndColumnNameKey].tableSlug + '/edit'}
                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                          >
                            Edit
                          </Link>
                        </div>
                      </div>
                      {/* <div className="mt-5 flex flex-col">
                        <div className="-my-2 overflow-x-auto">
                          <div className="py-2 align-top inline-block min-w-full">
                            <div className="shadow overflow-hidden border-b border-gray-300">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      rowSpan="2"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Field name
                                    </th>
                                    <th
                                      scope="col"
                                      colSpan="5"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Admin
                                    </th>
                                    <th
                                      scope="col"
                                      colSpan="5"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Manager
                                    </th>
                                    <th
                                      scope="col"
                                      colSpan="5"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Specialist
                                    </th>
                                    <th
                                      scope="col"
                                      colSpan="5"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Associate
                                    </th>
                                    <th
                                      scope="col"
                                      colSpan="5"
                                      className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Recorder
                                    </th>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      List mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Add mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Edit mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Delete record
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      List mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Add mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Edit mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Delete record
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      List mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Add mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Edit mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Delete record
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      List mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Add mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Edit mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Delete record
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      List mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Add mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      View mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Edit mode
                                    </th>
                                    <th
                                      scope="col"
                                      className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Delete record
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {tableAndColumnNames[tableAndColumnNameKey]['columns'].map((column, index2) => 
                                    <tr key={index2}>
                                      <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{column['column_name']}</td>
                                      {column['privileges'].map((privilegeObject, index3) => 
                                        {if (privilegeObject.mode === "List" || privilegeObject.mode === 'Delete') {
                                          return (
                                            <td className="border border-gray-300 text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500 align-top" key={index3} rowSpan={tableAndColumnNames[tableAndColumnNameKey]['columns'].length}>{privilegeObject.privilege}</td>
                                          )
                                        } else {
                                          return (
                                            <td className="border border-gray-300 text-center px-6 py-4 whitespace-nowrap text-sm text-gray-500" key={index3}>{privilegeObject.privilege}</td>
                                          )
                                        }} 
                                      )}
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}