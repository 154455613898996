import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { getMonth, getYear, format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'

import Header from '../../components/header';
import SubHeader from '../../components/subheader';
import Loading from '../../components/loading';
import AlertNotification from '../../components/alertNotification'
import SelectOption from '../../components/selectOption';
import { getRole, listenToNotifications, searchUsers } from '../../scripts/helpers';
import { AuthContext } from '../../AuthProvider'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function UsersSearch() {
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [searching, setSearching] = useState(false);
  const [actions, setActions] = useState([
    { name: 'Search', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
    { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
    // { name: 'Go to global search', href: '#', class: 'bg-gray-500 hover:bg-gray-600', position: 'right', hidden: false, disabled: false },
  ]);
  const [columns, setColumns] = useState([]);
  const [role, setRole] = useState('');
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const context = useContext(AuthContext);
  const {socket, show, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    resetSearchFields();
  }, []);

  const resetSearchFields = () => {
    getRole(cookies['token']).then(roleResult => {
      const role = roleResult.data.role.role;
      if (role === 'Recorder') {
        setColumns({
          id: {
            column_name: 'id',
            label_name: 'User key',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_username: {
            column_name: 'user_username',
            label_name: 'Username',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_fullname: {
            column_name: 'user_fullname',
            label_name: 'Full name',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_altname: {
            column_name: 'user_altname',
            label_name: 'Alternative name',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_email_address: {
            column_name: 'user_email_address',
            label_name: 'Email address',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_status: {
            column_name: 'user_status',
            label_name: 'User status',
            value: '',
            dropdowns: [
                {dropdown_item: '-'},
                {dropdown_item: 'Active'},
                {dropdown_item: 'Deactivated'},
                {dropdown_item: 'Closed'},
            ]
          }
        });
        setRole('recorder');
      } else {
        setColumns({
          id: {
            column_name: 'id',
            label_name: 'User key',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_username: {
            column_name: 'user_username',
            label_name: 'Username',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_fullname: {
            column_name: 'user_fullname',
            label_name: 'Full name',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_altname: {
            column_name: 'user_altname',
            label_name: 'Alternative name',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_email_address: {
            column_name: 'user_email_address',
            label_name: 'Email address',
            value: '',
            input_type: 'text',
            dropdowns: []
          },
          user_status: {
            column_name: 'user_status',
            label_name: 'User status',
            value: '',
            dropdowns: [
                {dropdown_item: '-'},
                {dropdown_item: 'Active'},
                {dropdown_item: 'Deactivated'},
                {dropdown_item: 'Closed'},
            ]
          }
        });
        setRole('nonRecorder');
      }
    });

    setSearchResults([]);
    setShowSearchResults(false);
    setSearchResultsCount(0);
    setPage(1);
    setSort({});
  };

  const handleChange = (column, event) => {
    const columnsToUpdate = {...columns};
    columnsToUpdate[column]['value'] = event.target.value;
    setColumns(columnsToUpdate);
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    const columnsToUpdate = {...columns};
    
    if (event.dropdown_item === '-') {
      columnsToUpdate[column]['value'] = '';
    } else {
      columnsToUpdate[column]['value'] = event.dropdown_item;
    }

    setColumns(columnsToUpdate);
  };

  const handleCalendarChange = (column, event) => {
    const columnsToUpdate = {...columns};
    if (event === null) {
      event = '';
    }
    columnsToUpdate[column]['value'] = event;
    setColumns(columnsToUpdate);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Search') {
        setSearching(true);
        searchUsers(columns).then(result => {
            result = result.data;
            const dividedSearchResultsToUpdate = [];
            for (let i = 0; i < result.length; i+=20) {
              dividedSearchResultsToUpdate[i/20] = result.slice(i, i+20);
            }

            setSearching(false);
            setSearchResultsCount(result.length);
            setSearchResults(dividedSearchResultsToUpdate);
            setShowSearchResults(true);
        }).catch(err => {
          setSearching(false);
        })
    } else if (buttonClicked === 'Reset') {
      resetSearchFields();
    } else if (buttonClicked === 'Create') {
      navigate('/compose-twid');
    }
  };

  const toggleSort = (column, page) => {
    let sortToUpdate = {...sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    let searchResultsToUpdate = [...searchResults];
    let currentSearchResultsToUpdate = [...searchResults[page-1]];

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if (sortToUpdate[column] === false) {
      currentSearchResultsToUpdate = _.orderBy(currentSearchResultsToUpdate, [column], ['asc']);
    } else {
      currentSearchResultsToUpdate = _.orderBy(currentSearchResultsToUpdate, [column], ['desc']);
    }

    sortToUpdate[column] = !sortToUpdate[column];
    setSort(sortToUpdate);
    searchResultsToUpdate[page-1] = currentSearchResultsToUpdate;
    setSearchResults(searchResultsToUpdate);
  }

  const navigateToForwardPage = () => {
    if (page < searchResults.length) {
      setPage(page + 1);
    }
  };

  const navigateToBackwardPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const displaySearchResult = (searchRow, index) => {
    return (
        <tr key={index} onClick={() => navigate(`/users/${searchRow.id}`)} className="cursor-pointer hover:bg-gray-50">
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.id}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_username}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_fullname}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_altname}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_email_address}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_role}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.datetime_last_login && format(new Date(searchRow.datetime_last_login), 'yyyy-MMM-dd HH:mm')}</td>
            <td className="px-2 py-1 text-sm text-gray-900">{searchRow.user_status}</td>
        </tr>
    )
  };

  if (columns.length !== 0) {
    return (
      <>
        <Header />
        <form onSubmit={handleFormSubmit}>
          <SubHeader actions={actions} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />

          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 lg:block">
            <ul role="list" className="mt-3 grid gap-3 grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
              {Object.keys(columns).map((key, index) => (
                <div key={index}>
                  <label htmlFor={columns[key]['column_name']} className="block text-sm font-medium text-gray-700">
                    {columns[key]['label_name']}
                  </label>
                  <div className="mt-1">
                    {columns[key]['dropdowns'].length > 0 ? (
                      <SelectOption 
                        column={columns[key]['column_name']} 
                        values={columns[key]['dropdowns']} 
                        handleDropdownChange={handleDropdownChange} 
                        selectedValue={columns[key]['value']}/>
                    ) : columns[key]['input_type'] === 'text' ? (
                      <input
                        type={columns[key]['input_type']}
                        name={columns[key]['column_name']}
                        id={columns[key]['column_name']}
                        value={columns[key]['value']}
                        onChange={(event) => handleChange(columns[key]['column_name'], event)}
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      />
                    ) : (
                      <DatePicker 
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                            <select
                              className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                          </div>
                        )}
                        selected={columns[key]['value']} 
                        onChange={(event) => handleCalendarChange(key, event)} 
                        dateFormat='yyyy-MMM-dd' 
                        autoComplete='off'
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                    ) }
                  </div>
                </div>
              ))}
            </ul>

            <div className="mt-5 mb-20 shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <span>
              <h3 className="text-lg leading-6 font-bold text-gray-900">SEARCH RESULTS</h3>
              {showSearchResults ? (
                <p className="text-sm font-medium text-gray-500">{searchResultsCount} results</p>
              ) : (<></>)}
              </span>
              {searchResults.length > 1 ? 
                (
                  <span className="relative z-0 inline-flex shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToBackwardPage}
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                      disabled
                    >
                      {page} of {searchResults.length}
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToForwardPage}
                    >
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                  </span>
                ) : (<></>)}
            </div>
            <div className="border-t border-gray-200 p-0">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('id', page)}>
                              User key
                              {sort['id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th 
                              scope="col" 
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_username', page)}>
                                Username 
                                {sort['user_username'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_username'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_fullname', page)}>
                                Full name
                                {sort['user_fullname'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_fullname'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_altname', page)}>
                                Alternative name
                                {sort['user_altname'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_altname'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_email_address', page)}>
                                Email address
                                {sort['user_email_address'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_email_address'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_role', page)}>
                                Role
                                {sort['user_role'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_role'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('datetime_last_login', page)}>
                                Last logged in
                                {sort['datetime_last_login'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['datetime_last_login'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                            <th
                              scope="col"
                              className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_status', page)}>
                                User status
                                {sort['user_status'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                {sort['user_status'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {searchResults.length > 0 ? (
                            searchResults[page - 1].map((searchRow, index2) => {
                              return (
                                displaySearchResult(searchRow, index2)
                              )
                            })
                          ) : (<></>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </form>
        {searching && <Loading />}
      </>
    )
  } else {
    return (
      <>
        <Header />
        <SubHeader actions={actions} />
        <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
      </>
    )
  }
}
