import { useState, useEffect } from 'react'
import { Disclosure, Transition, Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, ChevronUpIcon, ChevronDownIcon ,ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";

export default function TopAndMiddleNavigation(props) {
  const [topSmartButtons, setTopSmartButtons] = useState([]);
  const [midSmartButtons, setMidSmartButtons] = useState([]);

  const {page, id, tableColumns, subsidiary, subsidiarySlug} = props;

  useEffect(() => {
    if (page === 'workersEdit' && tableColumns[tableColumns.length - 1] !== undefined) {
      setTopSmartButtons([
        { name: 'New search', disabled: true },
        { name: 'Add job', disabled: true },
        { name: 'Job', disabled: true },
        { name: 'Ordinary attachments', disabled: false },
        { name: 'Sensitive attachments', disabled: false },
        { name: 'Facepic attachments', disabled: false },
        { name: 'PDPA attachments', disabled: false },
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: tableColumns[tableColumns.length - 1]['associations']['twid_cards'].length, disabled: true, href: `/workers/${id}/twid-cards` },
        { name: 'Nickname', number: tableColumns[tableColumns.length - 1]['associations']['nicknames'].length, disabled: true, href: `/workers/${id}/nicknames` },
        { name: 'Passport', number: tableColumns[tableColumns.length - 1]['associations']['passports'].length, disabled: true, href: `/workers/${id}/passports` },
        { name: 'Singapore phone number', number: tableColumns[tableColumns.length - 1]['associations']['singapore_phone_numbers'].length, disabled: true, href: `/workers/${id}/singapore-phone-numbers` },
        { name: 'Digital contact', number: tableColumns[tableColumns.length - 1]['associations']['digital_contacts'].length, disabled: true, href: `/workers/${id}/digital-contacts`},
        { name: 'Home country phone number', number: tableColumns[tableColumns.length - 1]['associations']['home_country_phone_numbers'].length, disabled: true, href: `/workers/${id}/home-country-phone-numbers` },
        { name: 'Ticket number', number: tableColumns[tableColumns.length - 1]['associations']['ticket_numbers'].length, disabled: true, href: `/workers/${id}/ticket-numbers` },
        { name: 'Address in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['addresses_in_singapore'].length, disabled: true, href: `/workers/${id}/addresses-in-singapore` },
        { name: 'Home country address', number: tableColumns[tableColumns.length - 1]['associations']['home_country_addresses'].length, disabled: true, href: `/workers/${id}/home-country-addresses` },
        { name: 'Next of kin', number: tableColumns[tableColumns.length - 1]['associations']['next_of_kins'].length, disabled: true, href: `/workers/${id}/next-of-kins` },
        { name: 'Family in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['families_in_singapore'].length, disabled: true, href: `/workers/${id}/family-members-in-singapore` },
        { name: 'Friend in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['friends_in_singapore'].length, disabled: true, href: `/workers/${id}/friends-in-singapore` },
        { name: 'Language', number: tableColumns[tableColumns.length - 1]['associations']['languages'].length, disabled: true, href: `/workers/${id}/languages` },
        { name: 'Bank account', number: tableColumns[tableColumns.length - 1]['associations']['bank_accounts'].length, disabled: true, href: `/workers/${id}/bank-accounts` },
        { name: 'Client\'s Calendar', number: tableColumns[tableColumns.length - 1]['associations']['client_calendars'].length, disabled: true, href: `/workers/${id}/client-calendars` },
      ]);
    } else if (page === 'workersView' && tableColumns[tableColumns.length - 1] !== undefined) {
      setTopSmartButtons([
        { name: 'New search', disabled: false, href: '/' },
        { name: 'List job', disabled: false, href: `/workers/${id}/jobs` },
        { name: 'Ordinary attachments', disabled: false, href: `/workers/${id}/ordinary-attachments` },
        { name: 'Sensitive attachments', disabled: false, href: `/workers/${id}/sensitive-attachments`},
        { name: 'Facepic attachments', disabled: false, href: `/workers/${id}/facepic-attachments` },
        { name: 'PDPA attachments', disabled: false, href: `/workers/${id}/pdpa-attachments` },
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: tableColumns[tableColumns.length - 1]['associations']['twid_cards'].length, disabled: false, href: `/workers/${id}/twid-cards` },
        { name: 'Nickname', number: tableColumns[tableColumns.length - 1]['associations']['nicknames'].length, disabled: false, href: `/workers/${id}/nicknames` },
        { name: 'Passport', number: tableColumns[tableColumns.length - 1]['associations']['passports'].length, disabled: false, href: `/workers/${id}/passports` },
        { name: 'Singapore phone number', number: tableColumns[tableColumns.length - 1]['associations']['singapore_phone_numbers'].length, disabled: false, href: `/workers/${id}/singapore-phone-numbers` },
        { name: 'Digital contact', number: tableColumns[tableColumns.length - 1]['associations']['digital_contacts'].length, disabled: false, href: `/workers/${id}/digital-contacts`},
        { name: 'Home country phone number', number: tableColumns[tableColumns.length - 1]['associations']['home_country_phone_numbers'].length, disabled: false, href: `/workers/${id}/home-country-phone-numbers` },
        { name: 'Ticket number', number: tableColumns[tableColumns.length - 1]['associations']['ticket_numbers'].length, disabled: false, href: `/workers/${id}/ticket-numbers` },
        { name: 'Address in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['addresses_in_singapore'].length, disabled: false, href: `/workers/${id}/addresses-in-singapore` },
        { name: 'Home country address', number: tableColumns[tableColumns.length - 1]['associations']['home_country_addresses'].length, disabled: false, href: `/workers/${id}/home-country-addresses` },
        { name: 'Next of kin', number: tableColumns[tableColumns.length - 1]['associations']['next_of_kins'].length, disabled: false, href: `/workers/${id}/next-of-kins` },
        { name: 'Family in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['families_in_singapore'].length, disabled: false, href: `/workers/${id}/family-members-in-singapore` },
        { name: 'Friend in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['friends_in_singapore'].length, disabled: false, href: `/workers/${id}/friends-in-singapore` },
        { name: 'Language', number: tableColumns[tableColumns.length - 1]['associations']['languages'].length, disabled: false, href: `/workers/${id}/languages` },
        { name: 'Bank account', number: tableColumns[tableColumns.length - 1]['associations']['bank_accounts'].length, disabled: false, href: `/workers/${id}/bank-accounts` },
        { name: 'Client\'s Calendar', number: tableColumns[tableColumns.length - 1]['associations']['client_calendars'].length, disabled: false, href: `/workers/${id}/client-calendars` },
      ]);
    } else if (page === 'workersSubsidiaryView' && tableColumns[tableColumns.length - 1] !== undefined) {
      setTopSmartButtons([
        { name: 'New search', disabled: false, href: '/' },
        { name: 'Go up to worker', disabled: false, href: `/workers/${id}` },
        { name: `Add ${subsidiary.toLowerCase()}`, disabled: false, href: `/workers/${id}/${subsidiarySlug}/add` },
        { name: 'Ordinary attachments', disabled: false, href: `/workers/${id}/ordinary-attachments` },
        { name: 'Sensitive attachments', disabled: false, href: `/workers/${id}/sensitive-attachments`},
        { name: 'Facepic attachments', disabled: false, href: `/workers/${id}/facepic-attachments` },
        { name: 'PDPA attachments', disabled: false, href: `/workers/${id}/pdpa-attachments` },
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: tableColumns[tableColumns.length - 1]['associations']['twid_cards'].length, disabled: false, href: `/workers/${id}/twid-cards` },
        { name: 'Nickname', number: tableColumns[tableColumns.length - 1]['associations']['nicknames'].length, disabled: false, href: `/workers/${id}/nicknames` },
        { name: 'Passport', number: tableColumns[tableColumns.length - 1]['associations']['passports'].length, disabled: false, href: `/workers/${id}/passports` },
        { name: 'Singapore phone number', number: tableColumns[tableColumns.length - 1]['associations']['singapore_phone_numbers'].length, disabled: false, href: `/workers/${id}/singapore-phone-numbers` },
        { name: 'Digital contact', number: tableColumns[tableColumns.length - 1]['associations']['digital_contacts'].length, disabled: false, href: `/workers/${id}/digital-contacts`},
        { name: 'Home country phone number', number: tableColumns[tableColumns.length - 1]['associations']['home_country_phone_numbers'].length, disabled: false, href: `/workers/${id}/home-country-phone-numbers` },
        { name: 'Ticket number', number: tableColumns[tableColumns.length - 1]['associations']['ticket_numbers'].length, disabled: false, href: `/workers/${id}/ticket-numbers` },
        { name: 'Address in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['addresses_in_singapore'].length, disabled: false, href: `/workers/${id}/addresses-in-singapore` },
        { name: 'Home country address', number: tableColumns[tableColumns.length - 1]['associations']['home_country_addresses'].length, disabled: false, href: `/workers/${id}/home-country-addresses` },
        { name: 'Next of kin', number: tableColumns[tableColumns.length - 1]['associations']['next_of_kins'].length, disabled: false, href: `/workers/${id}/next-of-kins` },
        { name: 'Family in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['families_in_singapore'].length, disabled: false, href: `/workers/${id}/family-members-in-singapore` },
        { name: 'Friend in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['friends_in_singapore'].length, disabled: false, href: `/workers/${id}/friends-in-singapore` },
        { name: 'Language', number: tableColumns[tableColumns.length - 1]['associations']['languages'].length, disabled: false, href: `/workers/${id}/languages` },
        { name: 'Bank account', number: tableColumns[tableColumns.length - 1]['associations']['bank_accounts'].length, disabled: false, href: `/workers/${id}/bank-accounts` },
        { name: 'Client\'s Calendar', number: tableColumns[tableColumns.length - 1]['associations']['client_calendars'].length, disabled: false, href: `/workers/${id}/client-calendars` },
      ]);
    } else if (page === 'workersSubsidiaryList' && tableColumns[tableColumns.length - 1] !== undefined) {
      setTopSmartButtons([
        { name: 'New search', disabled: false, href: '/' },
        { name: 'Go up to worker', disabled: false, href: `/workers/${id}` },
        { name: `Add ${subsidiary.toLowerCase()}`, disabled: false, href: `/workers/${id}/${subsidiarySlug}/add` },
        { name: 'Ordinary attachments', disabled: false, href: `/workers/${id}/ordinary-attachments` },
        { name: 'Sensitive attachments', disabled: false, href: `/workers/${id}/sensitive-attachments`},
        { name: 'Facepic attachments', disabled: false, href: `/workers/${id}/facepic-attachments` },
        { name: 'PDPA attachments', disabled: false, href: `/workers/${id}/pdpa-attachments` },
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: tableColumns[tableColumns.length - 1]['associations']['twid_cards'].length, disabled: false, href: `/workers/${id}/twid-cards` },
        { name: 'Nickname', number: tableColumns[tableColumns.length - 1]['associations']['nicknames'].length, disabled: false, href: `/workers/${id}/nicknames` },
        { name: 'Passport', number: tableColumns[tableColumns.length - 1]['associations']['passports'].length, disabled: false, href: `/workers/${id}/passports` },
        { name: 'Singapore phone number', number: tableColumns[tableColumns.length - 1]['associations']['singapore_phone_numbers'].length, disabled: false, href: `/workers/${id}/singapore-phone-numbers` },
        { name: 'Digital contact', number: tableColumns[tableColumns.length - 1]['associations']['digital_contacts'].length, disabled: false, href: `/workers/${id}/digital-contacts`},
        { name: 'Home country phone number', number: tableColumns[tableColumns.length - 1]['associations']['home_country_phone_numbers'].length, disabled: false, href: `/workers/${id}/home-country-phone-numbers` },
        { name: 'Ticket number', number: tableColumns[tableColumns.length - 1]['associations']['ticket_numbers'].length, disabled: false, href: `/workers/${id}/ticket-numbers` },
        { name: 'Address in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['addresses_in_singapore'].length, disabled: false, href: `/workers/${id}/addresses-in-singapore` },
        { name: 'Home country address', number: tableColumns[tableColumns.length - 1]['associations']['home_country_addresses'].length, disabled: false, href: `/workers/${id}/home-country-addresses` },
        { name: 'Next of kin', number: tableColumns[tableColumns.length - 1]['associations']['next_of_kins'].length, disabled: false, href: `/workers/${id}/next-of-kins` },
        { name: 'Family in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['families_in_singapore'].length, disabled: false, href: `/workers/${id}/family-members-in-singapore` },
        { name: 'Friend in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['friends_in_singapore'].length, disabled: false, href: `/workers/${id}/friends-in-singapore` },
        { name: 'Language', number: tableColumns[tableColumns.length - 1]['associations']['languages'].length, disabled: false, href: `/workers/${id}/languages` },
        { name: 'Bank account', number: tableColumns[tableColumns.length - 1]['associations']['bank_accounts'].length, disabled: false, href: `/workers/${id}/bank-accounts` },
        { name: 'Client\'s Calendar', number: tableColumns[tableColumns.length - 1]['associations']['client_calendars'].length, disabled: false, href: `/workers/${id}/client-calendars` },
      ]);
    } else if (page === 'workersSubsidiaryAdd' && tableColumns[tableColumns.length - 1] !== undefined) {
      setTopSmartButtons([
        { name: 'New search', disabled: true, href: '/' },
        { name: 'Go up to worker', disabled: true, href: `/workers/${id}` },
        { name: `List ${subsidiary.toLowerCase()}`, disabled: true, href: `/workers/${id}/${subsidiarySlug}` },
        { name: 'Ordinary attachments', disabled: false, href: `/workers/${id}/ordinary-attachments` },
        { name: 'Sensitive attachments', disabled: false, href: `/workers/${id}/sensitive-attachments`},
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: tableColumns[tableColumns.length - 1]['associations']['twid_cards'].length, disabled: true, href: `/workers/${id}/twid-cards` },
        { name: 'Nickname', number: tableColumns[tableColumns.length - 1]['associations']['nicknames'].length, disabled: true, href: `/workers/${id}/nicknames` },
        { name: 'Passport', number: tableColumns[tableColumns.length - 1]['associations']['passports'].length, disabled: true, href: `/workers/${id}/passports` },
        { name: 'Singapore phone number', number: tableColumns[tableColumns.length - 1]['associations']['singapore_phone_numbers'].length, disabled: true, href: `/workers/${id}/singapore-phone-numbers` },
        { name: 'Digital contact', number: tableColumns[tableColumns.length - 1]['associations']['digital_contacts'].length, disabled: true, href: `/workers/${id}/digital-contacts`},
        { name: 'Home country phone number', number: tableColumns[tableColumns.length - 1]['associations']['home_country_phone_numbers'].length, disabled: true, href: `/workers/${id}/home-country-phone-numbers` },
        { name: 'Ticket number', number: tableColumns[tableColumns.length - 1]['associations']['ticket_numbers'].length, disabled: true, href: `/workers/${id}/ticket-numbers` },
        { name: 'Address in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['addresses_in_singapore'].length, disabled: true, href: `/workers/${id}/addresses-in-singapore` },
        { name: 'Home country address', number: tableColumns[tableColumns.length - 1]['associations']['home_country_addresses'].length, disabled: true, href: `/workers/${id}/home-country-addresses` },
        { name: 'Next of kin', number: tableColumns[tableColumns.length - 1]['associations']['next_of_kins'].length, disabled: true, href: `/workers/${id}/next-of-kins` },
        { name: 'Family in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['families_in_singapore'].length, disabled: true, href: `/workers/${id}/family-members-in-singapore` },
        { name: 'Friend in Singapore', number: tableColumns[tableColumns.length - 1]['associations']['friends_in_singapore'].length, disabled: true, href: `/workers/${id}/friends-in-singapore` },
        { name: 'Language', number: tableColumns[tableColumns.length - 1]['associations']['languages'].length, disabled: true, href: `/workers/${id}/languages` },
        { name: 'Bank account', number: tableColumns[tableColumns.length - 1]['associations']['bank_accounts'].length, disabled: true, href: `/workers/${id}/bank-accounts` },
        { name: 'Client\'s Calendar', number: tableColumns[tableColumns.length - 1]['associations']['client_calendars'].length, disabled: true, href: `/workers/${id}/client-calendars` },
      ]);
    } else if (page === 'composeTwid') {
      setTopSmartButtons([
        { name: 'New search', disabled: true },
        { name: 'Job', disabled: true },
        { name: 'Ordinary attachment', number: 0, disabled: true },
        { name: 'Sensitive attachment', number: 0, disabled: true },
        { name: 'Facepic attachment', number: 0, disabled: true },
        { name: 'PDPA attachment', number: 0, disabled: true },
      ]);

      setMidSmartButtons([
        { name: 'Twid card', number: 0, disabled: true },
        { name: 'Nickname', number: 0, disabled: true },
        { name: 'Passport', number: 0, disabled: true },
        { name: 'Singapore phone number', number: 0, disabled: true },
        { name: 'Digital contact', number: 0, disabled: true },
        { name: 'Home country phone number', number: 0, disabled: true },
        { name: 'Ticket number', number: 0, disabled: true },
        { name: 'Address in Singapore', number: 0, disabled: true },
        { name: 'Home country address', number: 0, disabled: true },
        { name: 'Next of kin', number: 0, disabled: true },
        { name: 'Family in Singapore', number: 0, disabled: true },
        { name: 'Friend in Singapore', number: 0, disabled: true },
        { name: 'Language', number: 0, disabled: true },
        { name: 'Bank account', number: 0, disabled: true },
        { name: 'Client\'s Calendar', number: 0, disabled: true },
      ]);
    }
  }, [tableColumns, subsidiary, page]);

  return (
    <>
      {/* Top Smart Buttons - Desktop */}
        <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
            {topSmartButtons.map((topSmartButton) => (
              <li key={topSmartButton.name} className="col-span-1 flex shadow-sm">
                {topSmartButton.href !== undefined && topSmartButton.disabled === false ? (
                  <Link to={topSmartButton.href} className="w-full h-full">
                    <div className={`flex-1 flex justify-between border ${topSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-800 bg-sky-800 hover:bg-sky-900'} h-full`}>
                      <div className="flex-grow px-2 py-1 text-sm break-normal">
                        <span className={`${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                          {topSmartButton.name}
                        </span>
                      </div>
                      <div className={`px-2 py-1 text-sm ${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                        {topSmartButton.number}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className={`flex-1 flex justify-between border ${topSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-800 bg-sky-800 hover:bg-sky-900'} h-full`}>
                    <div className="flex-grow px-2 py-1 text-sm break-normal">
                      <span className={`${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                        {topSmartButton.name}
                      </span>
                    </div>
                    <div className={`px-2 py-1 text-sm ${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                      {topSmartButton.number}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        
        {/* Top Smart Buttons - Mobile */}
        <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 lg:hidden">
          <div className="w-full">
            <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-800 hover:bg-sky-900 focus:outline-none">
                      <span>Swipe down for navigation buttons</span>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-white`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                      <ul role="list" className="grid grid-cols-1 gap-3">
                        {topSmartButtons.map((topSmartButton) => (
                          <li key={topSmartButton.name} className="col-span-1 flex shadow-sm">
                            {topSmartButton.href !== undefined && topSmartButton.disabled === false ? (
                              <Link to={topSmartButton.href} className="w-full h-full">
                                <div className={`flex-1 flex justify-between border ${topSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-800 bg-sky-800 hover:bg-sky-900'} h-full`}>
                                  <div className="flex-1 px-2 py-1 text-sm break-normal">
                                    <span className={`${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                      {topSmartButton.name}
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <div className={`flex-1 flex justify-between border ${topSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-800 bg-sky-800 hover:bg-sky-900'} h-full`}>
                                <div className="flex-1 px-2 py-1 text-sm break-normal">
                                  <span className={`${topSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                    {topSmartButton.name}
                                  </span>
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>

        {/* Mid Smart Buttons - Desktop*/}               
        <div className="hidden max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <ul role="list" className="mt-3 grid grid-cols-1 gap-3 sm:gap-3 sm:grid-cols-8 lg:grid-cols-8">
            {midSmartButtons.map((midSmartButton) => (
              <li key={midSmartButton.name} className="col-span-1 flex shadow-sm">
                {midSmartButton.href !== undefined && midSmartButton.disabled === false ? (
                  <Link to={midSmartButton.href} className="w-full h-full">
                    <div className={`flex-1 flex justify-between border ${midSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-600 bg-sky-600 hover:bg-sky-700'} h-full`}>
                      <div className="flex-grow px-2 py-1 text-sm break-normal">
                        <span className={`${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                          {midSmartButton.name}
                        </span>
                      </div>
                      <div className={`px-2 py-1 text-sm ${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                        {midSmartButton.number}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className={`flex-1 flex justify-between border ${midSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-600 bg-sky-600 hover:bg-sky-700'} h-full`}>
                    <div className="flex-grow px-2 py-1 text-sm break-normal">
                      <span className={`${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                        {midSmartButton.name}
                      </span>
                    </div>
                    <div className={`px-2 py-1 text-sm ${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                      {midSmartButton.number}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Mid Smart Buttons - Mobile */}
        <div className="block mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 lg:hidden">
          <div className="w-full">
            <div className="w-full max-w-7xl mx-auto bg-white shadow overflow-hidden">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-bold text-white text-left bg-sky-600 hover:bg-sky-800">
                      <span>Swipe down for buttons to subsidiary tables</span>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-white`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="p-4 text-sm text-gray-500 ">
                      <ul role="list" className="grid grid-cols-1 gap-3">
                        {midSmartButtons.map((midSmartButton) => (
                          <li key={midSmartButton.name} className="col-span-1 flex shadow-sm">
                            {midSmartButton.href !== undefined && midSmartButton.disabled === false ? (
                              <Link to={midSmartButton.href} className="w-full h-full">
                                <div className={`flex-1 flex justify-between border ${midSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-600 bg-sky-600 hover:bg-sky-700'} h-full`}>
                                  <div className="flex-1 px-2 py-1 text-sm break-normal">
                                    <a href={midSmartButton.href} className={`${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                      {midSmartButton.name}
                                    </a>
                                  </div>
                                  <div className={`px-2 py-1 text-sm ${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                    {midSmartButton.number}
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              <div className={`flex-1 flex justify-between border ${midSmartButton.disabled === true ? 'border-gray-100 bg-gray-100' : 'border-sky-600 bg-sky-600 hover:bg-sky-700'} h-full`}>
                                <div className="flex-1 px-2 py-1 text-sm break-normal">
                                  <a href={midSmartButton.href} className={`${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                    {midSmartButton.name}
                                  </a>
                                </div>
                                <div className={`px-2 py-1 text-sm ${midSmartButton.disabled === true ? 'text-gray-400' : 'text-white'} font-medium`}>
                                  {midSmartButton.number}
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
    </>
  )
}