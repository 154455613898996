import { useState, useEffect, Fragment, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { createPath, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivilegeAndInputType, addObject, getObjects, findAllRows, getSchemaByTable, deepCopy, createPresetSkin, listenToNotifications, addAuditLog, checkDeactivated, checkSystemDown, checkCookieToken } from '../../scripts/helpers'
import { format } from 'date-fns'
// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AlertNotification from '../../components/alertNotification'
import ReportSelectOption from '../../components/reportSelectOption'
import { AuthContext } from '../../AuthProvider'
import e from '@toast-ui/react-image-editor'
import { backend_base_url } from '../../utils/helper'
const mappings = {
  'Singapore Phone Number': 'singapore-phone-numbers',
  'Police Report': 'police-reports',
  'Criminal Case Milestone': 'criminal-case-milestones',
  'Language': 'languages',
  'Legal Or Medical Funding': 'legal-or-medical-funding',
  'Employment Contract': 'employment-contracts',
  'Users': 'users',
  'Problem': 'problems',
  'Intermediary Agent': 'intermediary-agents',
  'Salary Claim Status': 'salary-claim-statuses',
  'Import Attachment': 'imports',
  'Friend in Singapore': 'friends-in-singapore',
  'Transfer and Repatriation': 'transfer-and-repatriation',
  'Job': 'jobs',
  'Worker': 'workers',
  'PDPA Attachment': 'pdpa-attachments',
  'Nickname': 'nicknames',
  'Home Country Phone Number': 'home-country-phone-numbers',
  'Lawyer': 'lawyers',
  'Templates': 'templates',
  'References': 'references',
  'Verbal Assurances Pre-job': 'verbal-assurances-pre-job',
  'POA/Warrant to act': 'warrants-to-act',
  'Home Country Address': 'home-country-addresses',
  'Twid Card': 'twid-cards',
  'Interpreter': 'interpreters',
  'Lead Case Worker': 'lead-case-workers',
  'Dropdowns': 'dropdowns',
  'Aggravating Issue': 'aggravating-issues',
  'Benefit': 'benefits',
  'Bank Account': 'bank-accounts',
  'IPA Details': 'ipa-details',
  'Wica Claim Lodged': 'wica-claims-lodged',
  'Ordinary Attachment': 'ordinary-attachments',
  'Family Member in Singapore': 'family-members-in-singapore',
  'Passport': 'passports',
  'Other Complaint Lodged': 'other-complaints-lodged',
  'MC Status': 'mc-statuses',
  'Hospital': 'hospitals',
  'Problem Clans': 'problem-clans',
  'Salary History': 'salary-histories',
  'Sensitive Attachment': 'sensitive-attachments',
  'Insurer Details': 'insurer-details',
  'Next Of Kin': 'next-of-kins',
  'Current Pass': 'current-passes',
  'Address in Singapore': 'addresses-in-singapore',
  'Ticket Number': 'ticket-numbers',
  'Sensitive Discussion': 'sensitive-discussions',
  'Injury History': 'injury-histories',
  'Workplace': 'workplaces',
  'Digital Contact': 'digital-contacts',
  'Accommodation During Work': 'accommodation-during-work',
  'Hearings Progress': 'hearings-progress',
  'Facepic Attachment': 'facepic-attachments',
  'Illness History': 'illness-histories',
  'Manpower Officer': 'manpower-officers',
  'Auxiliary Case Worker': 'auxiliary-case-workers',
  'Client Appointment': 'client-appointments',
  'Employer Details': 'employer-details',
  'Other Problem': 'other-problems',
  'Wica Status': 'wica-statuses',
  'Work History': 'work-histories',
  'Announcements': 'announcements',
  'Ordinary Case Discussion': 'ordinary-case-discussions',
  'Settings': 'settings',
  'Reports': 'reports',
  'Group Chat Conversations': 'group-chat-conversations',
  'Group Chat Conversation Users': 'group-chat-conversation-users',
};
export const reverseMappings = {
  'singapore-phone-numbers': 'Singapore Phone Number',
  'police-reports': 'Police Report',
  'criminal-case-milestones': 'Criminal Case Milestone',
  'languages': 'Language',
  'legal-or-medical-funding': 'Legal Or Medical Funding',
  'employment-contracts': 'Employment Contract',
  'users': 'Users',
  'problems': 'Problem',
  'intermediary-agents': 'Intermediary Agent',
  'salary-claim-statuses': 'Salary Claim Status',
  'imports': 'Import Attachment',
  'friends-in-singapore': 'Friend in Singapore',
  'transfer-and-repatriation': 'Transfer and Repatriation',
  'jobs': 'Job',
  'workers': 'Worker',
  'pdpa-attachments': 'PDPA Attachment',
  'nicknames': 'Nickname',
  'home-country-phone-numbers': 'Home Country Phone Number',
  'lawyers': 'Lawyer',
  'templates': 'Templates',
  'references': 'References',
  'verbal-assurances-pre-job': 'Verbal Assurances Pre-job',
  'warrants-to-act': 'POA/Warrant to act',
  'home-country-addresses': 'Home Country Address',
  'twid-cards': 'Twid Card',
  'interpreters': 'Interpreter',
  'lead-case-workers': 'Lead Case Worker',
  'dropdowns': 'Dropdowns',
  'aggravating-issues': 'Aggravating Issue',
  'benefits': 'Benefit',
  'bank-accounts': 'Bank Account',
  'ipa-details': 'IPA Details',
  'wica-claims-lodged': 'Wica Claim Lodged',
  'ordinary-attachments': 'Ordinary Attachment',
  'family-members-in-singapore': 'Family Member in Singapore',
  'passports': 'Passport',
  'other-complaints-lodged': 'Other Complaint Lodged',
  'mc-statuses': 'MC Status',
  'hospitals': 'Hospital',
  'problem-clans': 'Problem Clans',
  'salary-histories': 'Salary History',
  'sensitive-attachments': 'Sensitive Attachment',
  'insurer-details': 'Insurer Details',
  'next-of-kins': 'Next Of Kin',
  'current-passes': 'Current Pass',
  'addresses-in-singapore': 'Address in Singapore',
  'ticket-numbers': 'Ticket Number',
  'sensitive-discussions': 'Sensitive Discussion',
  'injury-histories': 'Injury History',
  'workplaces': 'Workplace',
  'digital-contacts': 'Digital Contact',
  'accommodation-during-work': 'Accommodation During Work',
  'hearings-progress': 'Hearings Progress',
  'facepic-attachments': 'Facepic Attachment',
  'illness-histories': 'Illness History',
  'manpower-officers': 'Manpower Officer',
  'auxiliary-case-workers': 'Auxiliary Case Worker',
  'client-appointments': 'Client Appointment',
  'employer-details': 'Employer Details',
  'other-problems': 'Other Problem',
  'wica-statuses': 'Wica Status',
  'work-histories': 'Work History',
  'announcements': 'Announcements',
  'ordinary-case-discussions': 'Ordinary Case Discussion',
  'settings': 'Settings',
  'reports': 'Reports',
  'group-chat-conversations': 'Group Chat Conversations',
  'group-chat-conversation-users': 'Group Chat Conversation Users',
};
export default function PresetSkinsAdd() {
  const tableSlug = 'preset-skins';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [tables, setTables] = useState({});
  const [columnToDropdowns, setColumnToDropdowns] = useState({});
  const [tablesClicked, setTablesClicked] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [name, setName] = useState('');
  const [selectedPrincipalTable, setSelectedPrincipalTable] = useState('');
  const [columns, setColumns] = useState([]);
  const [principalColumns, setPrincipalColumns] = useState([]);
  const [supportingColumns, setSupportingColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [actions, setActions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [messageInEmail, setMessageInEmail] = useState('');
  const [sendEmailOnSave, setSendEmailOnSave] = useState(false);
  const [payload, setPayload] = useState({supportingTables: []});
  const [showPrincipalSection, setShowPrincipalSection] = useState(false);
  const [showSupportSection, setShowSupportSection] = useState([false]);
  const privilegeObject = {
    tableSlug: 'preset-skins', 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);
  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }
        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });
      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Add']['id'] === 'No') {
        navigate('/');
      }
      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivilegeAndInputType(tableSlug, privilegesToUpdate, 'Add').then(preparedTableColumns => {
        setTableColumns(preparedTableColumns);
      });
    }).catch(err => {
      navigate('/');
    });
    findAllRows().then(result => {
        const tables = {};
        const columnToDropdowns = {};
        result.data.forEach(row => {
          if (row.dropdowns.length > 0) {
            columnToDropdowns[row.column_name] = ['-'];
            row.dropdowns.forEach(dropdown => {
              columnToDropdowns[row.column_name].push(dropdown.dropdown_item);
            });
          }
          
          if (tables[row.table_name] === undefined) tables[row.table_name] = [];
          tables[row.table_name].push(row.column_name);
        });
        setColumnToDropdowns(columnToDropdowns);
        setTables(tables);
    })
  }, []);
  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'Add');
      setActions(actionsToUpdate);
    }
  }, [privileges]);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        const buttonClicked = event.nativeEvent.submitter.innerHTML;
        if (buttonClicked === 'Save') {
          // check that principal tabl eis filled up and at least 1 column is selected for each table
          if (payload.principalTable === undefined) {
            setOutcome({
              message: 'Please select a principal table.',
              type: 'Danger'
            });
            return;
          } else if (payload.principalTable !== undefined) {
            if (Object.keys(payload.principalTable.columns).length === 0) {
              setOutcome({
                message: 'Please select at least 1 column for the principal table.',
                type: 'Danger'
              });
              return;
            }
          }
          for (let i = 0; i < payload.supportingTables.length; i += 1) {
            if (Object.keys(payload.supportingTables[i].columns).length === 0) {
              setOutcome({
                message: 'Please select at least 1 column for each supporting table.',
                type: 'Danger'
              });
              return;
            }
          }

          const result = checkCompulsoryFields(tableColumns);
          if (result.allCompulsoryFieldsHaveBeenFilled) {
            let presetSkinName = '';
            for (let i = 0; i < tableColumns.length; i += 1) {
              if (tableColumns[i]['column_name'] === 'name') {
                presetSkinName = tableColumns[i]['value'];
              };
              if (tableColumns[i]['column_name'] === 'description') {
                createPresetSkin(presetSkinName, tableColumns[i]['value'], payload, cookies['token']).then(result => {
                  addAuditLog({
                    table_name: 'Preset skins',
                    action_type: 'Create',
                    record_id: result.data.id,
                    action_description: `Created preset skin with id: ${result.data.id}.`,
                    user_id: cookies['userId']
                  });
                  
                  navigate(`/preset-skins/${result.data.id}`);
                });
                break;
              }
            }
          } else {
            const emptyFields = result.emptyFields;
      
            emptyFields.forEach((emptyField) => {
              if (document.getElementById(emptyField)) {
                document.getElementById(emptyField).className = '-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
              } else {
                if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                  document.getElementsByClassName(`bg-white relative w-full border border-gray-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                }
              }
            });
      
            setOutcome({
              message: 'Compulsory fields have not been filled.',
              type: 'Danger'
            });
          }
        } else if (buttonClicked === 'Abandon') {
          navigate('/preset-skins');
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - reportSkins/add.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - reportSkins/add.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };
  const handleChange = (column, event) => {
    const tableColumnsToUpdate = deepCopy(tableColumns);
    tableColumnsToUpdate[column['sequence'] - 1].value = event.target.value;
    setTableColumns(tableColumnsToUpdate);
  };
  const handleDropdownChange = (event, type, table, column, index, dataType, index2) => {
    const payloadToUpdate = deepCopy(payload);
    if (type === 'principal') {
      if (event === '-') {
        payloadToUpdate.principalTable.columns[column][index][dataType] = '';
      } else {
        payloadToUpdate.principalTable.columns[column][index][dataType] = event;
      }
    } else {
      if (event === '-') {
        payloadToUpdate.supportingTables[index].columns[column][index2][dataType] = '';
      } else {
        payloadToUpdate.supportingTables[index].columns[column][index2][dataType] = event;
      }
    }
    setPayload(payloadToUpdate);
    // if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
    //   document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 -sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${column}`;
    // }
    // const tableColumnsToUpdate = [...tableColumns];
    // if (event.dropdown_item === '-') {
    //   tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
    // } else {
    //   tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
    // }
    // setTableColumns(tableColumnsToUpdate);
  };
  const deleteButton = (index, index2, dropdown, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    const selectedDropdownToUpdate = [...selectedDropdown];
    tableColumnsToUpdate[index]['value'].splice(index2, 1);
    selectedDropdownToUpdate.splice(index2, 1);
    setTableColumns(tableColumnsToUpdate);
    setSelectedDropdown(selectedDropdownToUpdate);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const selectPrincipalTable = (tableName) => {
    if (selectedPrincipalTable === tableName) {
      setSelectedPrincipalTable('');
      setPrincipalColumns([]);
      setTablesClicked(tablesClicked.filter(table => table !== tableName));
      const payloadToUpdate = deepCopy(payload);
      delete(payloadToUpdate.principalTable);
      setPayload(payloadToUpdate);
    } else {
      setSelectedPrincipalTable(tableName);
      setPrincipalColumns(tables[tableName]);
      setTablesClicked(tablesClicked.filter(table => table !== reverseMappings[payload.principalTable.table]).concat(tableName));
      const payloadToUpdate = deepCopy(payload);
      delete(payloadToUpdate.principalTable);
      payloadToUpdate.principalTable = {'table': mappings[tableName], 'columns': {}};
      setPayload(payloadToUpdate);
    }
  };
  const selectPrincipalField = (tableNameColumnName) => {
    const [tableName, columnName] = tableNameColumnName.split('__');
    getSchemaByTable(mappings[tableName]).then(schemasResult => {
      const dataType = schemasResult.data.filter(row => row.column_name === columnName)[0]['data_type'];
      const payloadToUpdate = deepCopy(payload);
      if (payloadToUpdate['principalTable']['columns'][columnName] !== undefined) {
        delete(payloadToUpdate['principalTable']['columns'][columnName]);
      } else {
        payloadToUpdate['principalTable']['columns'][columnName] = [];
        payloadToUpdate['principalTable']['columns'][columnName][0] = {};
        if (dataType === 'timestamp with time zone' || dataType === 'date') {
          payloadToUpdate['principalTable']['columns'][columnName][0][dataType] = {from: '', to: ''};
        } else if (dataType === 'character varying' && Object.keys(columnToDropdowns).indexOf(columnName) === -1) {
          payloadToUpdate['principalTable']['columns'][columnName][0][dataType] = {beginWith: '', contain: '', equal: '', endWith: ''};
        } else if (dataType === 'double precision' || dataType === 'integer') {
          payloadToUpdate['principalTable']['columns'][columnName][0][dataType] = {gte: '', gt: '', equal: '', lt: '', lte: ''};
        } else {
          payloadToUpdate['principalTable']['columns'][columnName][0][dataType] = '';
        }
      }
      setPayload(payloadToUpdate);
    });
  };
  const addPrincipalFilter = (tableNameColumnName) => {
    const [tableName, columnName] = tableNameColumnName.split('__');
    getSchemaByTable(mappings[tableName]).then(schemasResult => {
      const dataType = schemasResult.data.filter(row => row.column_name === columnName)[0]['data_type'];
      const payloadToUpdate = deepCopy(payload);
      payloadToUpdate['principalTable']['columns'][columnName].push({});
      if (dataType === 'timestamp with time zone' || dataType === 'date') {
        payloadToUpdate['principalTable']['columns'][columnName][payloadToUpdate['principalTable']['columns'][columnName].length - 1][dataType] = {from: '', to: ''};
      } else if (dataType === 'character varying' && Object.keys(columnToDropdowns).indexOf(columnName) === -1) {
        payloadToUpdate['principalTable']['columns'][columnName][payloadToUpdate['principalTable']['columns'][columnName].length - 1][dataType] = {beginWith: '', contain: '', eq: '', endWith: ''};
      } else if (dataType === 'double precision' || dataType === 'integer') {
        payloadToUpdate['principalTable']['columns'][columnName][payloadToUpdate['principalTable']['columns'][columnName].length - 1][dataType] = {gte: '', gt: '', equal: '', lt: '', lte: ''};
      } else {
        payloadToUpdate['principalTable']['columns'][columnName][payloadToUpdate['principalTable']['columns'][columnName].length - 1][dataType] = '';
      }
      setPayload(payloadToUpdate);
    });
  };
  const handlePrincipalInput = (principalTable, columnName, index, dataType, event, property) => {
    const payloadToUpdate = deepCopy(payload);
    if (property === undefined) {
      payloadToUpdate['principalTable']['columns'][columnName][index][dataType] = event.target.value;
    } else {
      payloadToUpdate['principalTable']['columns'][columnName][index][dataType][property] = event.target.value;
    }
    setPayload(payloadToUpdate);
  };
  const removePrincipalFilter = (principalTable, columnName, index) => {
    const payloadToUpdate = deepCopy(payload);
    payloadToUpdate['principalTable']['columns'][columnName].splice(index, 1);
    setPayload(payloadToUpdate);
  };
  const addSupportingSection = () => {
    const payloadToUpdate = deepCopy(payload);
    payloadToUpdate.supportingTables.push({});
    setPayload(payloadToUpdate);
  };
  const toggleShowSupportingSection = (index) => {
    const showSupportSectionToUpdate = deepCopy(showSupportSection);
    showSupportSectionToUpdate[index] = !showSupportSectionToUpdate[index];
    setShowSupportSection(showSupportSectionToUpdate);
  };
  const selectSupportingTable = (index, tableName) => {
    const payloadToUpdate = deepCopy(payload);
    const supportingColumnsToUpdate = deepCopy(supportingColumns);
    if (payloadToUpdate.supportingTables[index]['table'] === mappings[tableName]) {
      payloadToUpdate.supportingTables[index] = {};
      supportingColumnsToUpdate.splice(index, 1);
      setTablesClicked(tablesClicked.filter(table => table !== tableName));
    } else {
      supportingColumnsToUpdate[index] = tables[tableName];
      payloadToUpdate.supportingTables[index]['table'] = mappings[tableName];
      payloadToUpdate.supportingTables[index]['columns'] = {};
      setTablesClicked(tablesClicked.filter(table => table !== reverseMappings[payload.supportingTables[index].table]).concat(tableName));
    }
    setPayload(payloadToUpdate);
    setSupportingColumns(supportingColumnsToUpdate);
  };
  const selectSupportingField = (index, tableNameColumnName) => {
    const [tableName, columnName] = tableNameColumnName.split('__');
    getSchemaByTable(tableName).then(schemasResult => {
      const dataType = schemasResult.data.filter(row => row.column_name === columnName)[0]['data_type'];
      const payloadToUpdate = deepCopy(payload);
      if (payloadToUpdate.supportingTables[index]['columns'][columnName] !== undefined) {
        delete(payloadToUpdate.supportingTables[index]['columns'][columnName]);
      } else {
        payloadToUpdate.supportingTables[index]['columns'][columnName] = [];
        payloadToUpdate.supportingTables[index]['columns'][columnName][0] = {};
        if (dataType === 'timestamp with time zone' || dataType === 'date') {
          payloadToUpdate.supportingTables[index]['columns'][columnName][0][dataType] = ['', ''];
        } else if (dataType === 'character varying' && Object.keys(columnToDropdowns).indexOf(columnName) === -1) {
          payloadToUpdate.supportingTables[index]['columns'][columnName][0][dataType] = {beginWith: '', contain: '', eq: '', endWith: ''};
        } else if (dataType === 'double precision' || dataType === 'integer') {
          payloadToUpdate.supportingTables[index]['columns'][columnName][0][dataType] = {gte: '', gt: '', equal: '', lt: '', lte: ''};
        } else {
          payloadToUpdate.supportingTables[index]['columns'][columnName][0][dataType] = '';
        }
      }
      setPayload(payloadToUpdate);
    });
  };
  const addSupportingFilter = (index, tableNameColumnName) => {
    const [tableName, columnName] = tableNameColumnName.split('__');
    getSchemaByTable(tableName).then(schemasResult => {
      const dataType = schemasResult.data.filter(row => row.column_name === columnName)[0]['data_type'];
      const payloadToUpdate = deepCopy(payload);
      payloadToUpdate['supportingTables'][index]['columns'][columnName].push({});
      if (dataType === 'timestamp with time zone' || dataType === 'date') {
        payloadToUpdate['supportingTables'][index]['columns'][columnName][payloadToUpdate['supportingTables'][index]['columns'][columnName].length - 1][dataType] = ['', ''];
      } else if (dataType === 'character varying' && Object.keys(columnToDropdowns).indexOf(columnName) === -1) {
        payloadToUpdate['supportingTables'][index]['columns'][columnName][payloadToUpdate['supportingTables'][index]['columns'][columnName].length - 1][dataType] = {beginWith: '', contain: '', eq: '', endWith: ''};
      } else if (dataType === 'double precision' || dataType === 'integer') {
        payloadToUpdate['supportingTables'][index]['columns'][columnName][payloadToUpdate['supportingTables'][index]['columns'][columnName].length - 1][dataType] = {gte: '', gt: '', equal: '', lt: '', lte: ''};
      } else {
        payloadToUpdate['supportingTables'][index]['columns'][columnName][payloadToUpdate['supportingTables'][index]['columns'][columnName].length - 1][dataType] = '';
      }
      setPayload(payloadToUpdate);
    });
  };
  const handleSupportingInput = (index, supportingTable, columnName, index2, dataType, event, property) => {
    const payloadToUpdate = deepCopy(payload);
    if (property !== undefined) {
      payloadToUpdate['supportingTables'][index]['columns'][columnName][index2][dataType][property] = event.target.value;
    } else {
      payloadToUpdate['supportingTables'][index]['columns'][columnName][index2][dataType] = event.target.value;
    }
    setPayload(payloadToUpdate);
  };
  const removeSupportingFilter = (index, principalTable, columnName, index2) => {
    const payloadToUpdate = deepCopy(payload);
    payloadToUpdate['supportingTables'][index]['columns'][columnName].splice(index2, 1);
    setPayload(payloadToUpdate);
  };
  const removeSupportingSection = (index) => {
    const payloadToUpdate = deepCopy(payload);
    const supportingColumnsToUpdate = deepCopy(supportingColumns);
    payloadToUpdate['supportingTables'].splice(index, 1);
    supportingColumnsToUpdate.splice(index, 1);
    setPayload(payloadToUpdate);
    setSupportingColumns(supportingColumnsToUpdate);
  };
  
  return (
    <>
      <Header />
        <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        {/* <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={objectsSelected.map((object) => object['dropdown_item'])} single='dropdown item' plural='dropdown items' /> */}
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5">
            <div className="bg-gray-100 px-2 py-1 border border-gray-200 flex justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">ADD PRESET SKIN</h3>
              {/* <button className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium  text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" onClick={handleFormSubmit}>Submit</button> */}
            </div>
          </div>

          <div className="border border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                {tableColumns.map((tableColumn, index) => {
                    if (tableColumn['hidden'] === undefined) {
                    if (tableColumn['privilege'] === 'Yes') {
                      if (tableColumn['textarea']) {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <textarea
                                rows={5}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className=" focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                onChange={(event) => handleChange(tableColumn, event)}
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'checkbox') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type="checkbox"
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                onChange={(event) => handleChange(tableColumn, event)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300 float-left"
                                style={{marginTop: '0.08rem'}}
                              />
                              {tableColumn['detail'] !== undefined ? 
                                (
                                  <p className='ml-6'>{tableColumn['detail']}</p>
                                ) : (<></>)}
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'float') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              {tableColumn['float_step'] === '.01' ? 
                                (
                                  <input
                                    type='number'
                                    step={tableColumn['float_step']}
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    value={tableColumn['value']}
                                    onChange={(event) => handleChange(tableColumn, event)}
                                    className=" focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  />
                                ) : (
                                  <input
                                    type='number'
                                    min='0'
                                    step={tableColumn['float_step']}
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    value={tableColumn['value']}
                                    onChange={(event) => handleChange(tableColumn, event)}
                                    className=" focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  />
                                )
                              }
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type={tableColumn['input_type']}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                value={tableColumn['value']}
                                onChange={(event) => handleChange(tableColumn, event)}
                                className=" focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      }
                    } else if (tableColumn['privilege'] === 'View') {
                      if (tableColumn['label_name'] === 'Created by' || tableColumn['label_name'] === 'Submitted by') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type='text'
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={cookies['username']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type='text'
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={tableColumn['value']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      }
                    }
                    }
                  })}
              </div>
            </dl>
          </div>
        </div>
        <div className="max-w-[2500px] mx-auto">
          <div className="mt-5">
            <div className="bg-gray-200 px-2 py-1 border border-gray-200 flex justify-between">
              <span className="w-[66px]"></span>
              <h4 className="text-sm font-bold leading-6 text-gray-900 text-center">PRINCIPAL SECTION {payload.principalTable !== undefined ? `- ${payload.principalTable.table?.toUpperCase().replace(/-/g, ' ')}` : ''}</h4>
              <button type="button" className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium  text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500" onClick={() => setShowPrincipalSection(!showPrincipalSection)}>{showPrincipalSection ? 'Hide' : 'Show'}</button>
            </div>
            {showPrincipalSection ?
            <div className="px-4 py-5 sm:p-0">
                <div className="grid grid-cols-8">
                    <div className="col-span-2">
                        <div className="">
                            <div className="bg-gray-100 px-2 py-1 border-l border-r border-gray-200">
                              <h4 className="text-sm font-bold leading-6 text-gray-900">PRINCIPAL TABLE</h4>
                            </div>
                            <div className="border border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                              <ul role="list" className="divide-y divide-gray-200">
                                  {Object.keys(tables).map((tableName, index) => {
                                    if (tablesClicked.indexOf(tableName) === -1 || tableName === reverseMappings[payload.principalTable?.table]) {
                                      return (
                                          <li key={index} className={classNames(tableName === selectedPrincipalTable ? 'bg-gray-100' : '', 'flex py-1 pl-3 hover:cursor-pointer hover:bg-gray-100')} onClick={() => selectPrincipalTable(tableName)}>
                                              <p className="text-sm">{tableName}</p>
                                          </li>
                                      )
                                    }
                                  })}
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="">
                            <div className="bg-gray-100 px-2 py-1 border-r border-gray-200">
                              <h4 className="text-sm font-bold leading-6 text-gray-900">PRINCIPAL FIELD(S)</h4>
                            </div>
                            <div className="border-r border-b border-t border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                              <ul role="list" className="divide-y divide-gray-200">
                                  {principalColumns.map((columnName, index) => {
                                      return (
                                        <li key={index} className={classNames(payload['principalTable']['columns'][columnName] ? 'bg-gray-100' : '', 'flex py-1 pl-3 hover:cursor-pointer hover:bg-gray-100')} onClick={() => selectPrincipalField(`${selectedPrincipalTable}__${columnName}`)}>
                                            <p className="text-sm">{columnName}</p>
                                        </li>
                                      )
                                  })}
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="">
                            <div className="bg-gray-100 px-2 py-1 border-r border-gray-200">
                              <h4 className="text-sm font-bold leading-6 text-gray-900">SELECTED FILTER</h4>
                            </div>
                            <div className="border-r border-b border-t border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                              <ul role="list" className="divide-y divide-gray-200">
                                {payload['principalTable'] && Object.keys(payload['principalTable']['columns']).map((key, keyIndex) => {
                                  return (
                                    <li key={keyIndex} className='flex py-2 pl-3 pr-3 flex flex-col'>
                                      <div className='flex justify-between items-center'>
                                        <p className="text-sm">{key}</p>
                                        <button
                                          type="button"
                                          className="inline-flex items-center border border-transparent bg-blue-600 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          onClick={() => addPrincipalFilter(`${selectedPrincipalTable}__${key}`)}
                                        >
                                          +
                                        </button>
                                      </div>
                                      {payload['principalTable']['columns'][key].map((row, index) => {
                                        if (Object.keys(row)[0] === 'character varying') {
                                          return (
                                            <div className="pb-2 pt-2" key={index}>
                                              {index !== 0 ?
                                                Object.keys(columnToDropdowns).indexOf(key) !== -1 ? <div className='flex justify-between items-center'>
                                                    <input type="text" name={index} id={index} className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" disabled />
                                                    <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                  </div> : <div className='flex justify-between items-center'>
                                                    <div className="mt-1 flex w-full">
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        Begin with
                                                      </span>
                                                      <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['beginWith']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'beginWith')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        Contain
                                                      </span>
                                                      <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['contain']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'contain')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        Equal
                                                      </span>
                                                      <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'equal')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        End with
                                                      </span>
                                                      <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['endWith']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'endWIth')} disabled />
                                                      <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                    </div>
                                                </div>
                                                :
                                                Object.keys(columnToDropdowns).indexOf(key) !== -1 ? 
                                                <input type="text" name={index} id={index} className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" disabled />
                                                :
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    Begin with
                                                  </span>
                                                  <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['beginWith']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'beginWith')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    Contain
                                                  </span>
                                                  <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['contain']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'contain')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    Equal
                                                  </span>
                                                  <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'equal')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    End with
                                                  </span>
                                                  <input type="text" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['endWith']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'character varying', event, 'endWith')} disabled />
                                                </div>
                                              }
                                            </div>
                                          );
                                        } else if (Object.keys(row)[0] === 'double precision') {
                                          return (
                                            <div className="pb-2 pt-2" key={index}>
                                              {index !== 0 ? 
                                              <div className='flex justify-between items-center'>
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`>=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'gte')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`>`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'gt')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'equal')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`<`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'lt')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`<=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'lte')} disabled />
                                                  <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                </div>  
                                              </div> :
                                              <div className="mt-1 flex w-full">
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`>=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'gte')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`>`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'gt')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'equal')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`<`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'lt')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`<=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'double precision', event, 'lte')} disabled />
                                              </div>
                                              }
                                            </div>
                                          );
                                        } else if (Object.keys(row)[0] === 'integer') {
                                          return (
                                            <div className="pb-2 pt-2" key={index}>
                                              {index !== 0 ? 
                                              <div className='flex justify-between items-center'>
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`>=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'gte')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`>`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'gt')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'equal')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`<`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'lt')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    {`<=`}
                                                  </span>
                                                  <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'lte')} disabled />
                                                  <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                </div>
                                              </div> :
                                              <div className="mt-1 flex w-full">
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`>=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'gte')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`>`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'gt')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['equal']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'equal')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`<`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lt']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'lt')} disabled />
                                                <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                  {`<=`}
                                                </span>
                                                <input type="number" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lte']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'integer', event, 'lte')} disabled />
                                              </div>
                                              }
                                            </div>
                                          );
                                        } else if (Object.keys(row)[0] === 'date') {
                                          return (
                                            <div className="pb-2 pt-2" key={index}>
                                              {index !== 0 ? 
                                              <div className='flex justify-between items-center'>
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    From
                                                  </span>
                                                  <input type="date" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['from']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'date', event, 'from')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    To
                                                  </span>
                                                  <input type="date" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['to']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'date', event, 'to')} disabled />
                                                  <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                </div>
                                              </div> :
                                              <div className='flex justify-between items-center'>
                                                {/* <p className="text-sm mr-3">From</p> */}
                                                <div className="mt-1 flex  w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    From
                                                  </span>
                                                  <input type="date" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['from']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'date', event, 'from')} disabled />
                                                  
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    To
                                                  </span>
                                                  <input type="date" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['to']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'date', event, 'to')} disabled />
                                                </div>
                                              </div>
                                              }
                                            </div>
                                          );
                                        } else if (Object.keys(row)[0] === 'timestamp with time zone') {
                                          return (
                                            <div className="pb-2 pt-2" key={index}>
                                              {index !== 0 ? 
                                              <div className='flex justify-between items-center'>
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    From
                                                  </span>
                                                  <input type="datetime-local" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['from']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'timestamp with time zone', event, 'from')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    To
                                                  </span>
                                                  <input type="datetime-local" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['to']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'timestamp with time zone', event, 'to')} disabled />
                                                  <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removePrincipalFilter(selectedPrincipalTable, key, index)}>-</button>
                                                </div>
                                              </div> :
                                              <div className='flex justify-between items-center'>
                                                <div className="mt-1 flex w-full">
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    From
                                                  </span>
                                                  <input type="datetime-local" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['from']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'timestamp with time zone', event, 'from')} disabled />
                                                  <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                    To
                                                  </span>
                                                  <input type="datetime-local" name={index} id={index} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['to']} onChange={(event) => handlePrincipalInput(selectedPrincipalTable, key, index, 'timestamp with time zone', event, 'to')} disabled />
                                                </div>
                                              </div>
                                              }
                                            </div>
                                          );
                                        }
                                      })}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
          </div>
          {payload.supportingTables && payload.supportingTables.map((supportingTable, index) => {
            return (
              <div className="mt-5" key={index}>
                <div className="bg-gray-200 px-2 py-1 border border-gray-200 flex justify-between">
                  <button type="button" className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium  text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => removeSupportingSection(index)}>Remove</button>
                  <h4 className="text-sm font-bold leading-6 text-gray-900 text-center">SUPPORTING SECTION {payload.supportingTables[index].table !== undefined ? `- ${payload.supportingTables[index].table?.toUpperCase().replace(/-/g, ' ')}` : ''}</h4>
                  <button type="button" className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium  text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500" onClick={() => toggleShowSupportingSection(index)}>{showSupportSection[index] ? 'Hide' : 'Show'}</button>
                </div>
                {showSupportSection[index] ?
                  <div className="px-4 py-5 sm:p-0">
                      <div className="grid grid-cols-8">
                          <div className="col-span-2">
                              <div className="">
                                  <div className="bg-gray-100 px-2 py-1 border-l border-r border-gray-200">
                                    <h4 className="text-sm font-bold leading-6 text-gray-900">SUPPORTING TABLE</h4>
                                  </div>
                                  <div className="border border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {Object.keys(tables).map((tableName, index2) => {
                                          if (tablesClicked.indexOf(tableName) === -1 || tableName === reverseMappings[payload.supportingTables[index]?.table]) {
                                            return (
                                                <li key={index2} className={classNames(mappings[tableName] === payload['supportingTables'][index]['table'] ? 'bg-gray-100' : '', 'flex py-1 pl-3 hover:cursor-pointer hover:bg-gray-100')} onClick={() => selectSupportingTable(index, tableName)}>
                                                    <p className="text-sm">{tableName}</p>
                                                </li>
                                            )
                                          }
                                        })}
                                    </ul>
                                  </div>
                              </div>
                          </div>
                          <div className="col-span-2">
                              <div className="">
                                  <div className="bg-gray-100 px-2 py-1 border-r border-gray-200">
                                    <h4 className="text-sm font-bold leading-6 text-gray-900">SUPPORTING FIELD(S)</h4>
                                  </div>
                                  <div className="border-r border-b border-t border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {supportingColumns[index] && supportingColumns[index].map((columnName, index3) => {
                                            return (
                                              <li key={index3} className={classNames(payload['supportingTables'][index]['columns'][columnName] ? 'bg-gray-100' : '', 'flex py-1 pl-3 hover:cursor-pointer hover:bg-gray-100')} onClick={() => selectSupportingField(index, `${payload['supportingTables'][index]['table']}__${columnName}`)}>
                                                  <p className="text-sm">{columnName}</p>
                                              </li>
                                            )
                                        })}
                                    </ul>
                                  </div>
                              </div>
                          </div>
                          <div className="col-span-4">
                              <div className="">
                                  <div className="bg-gray-100 px-2 py-1 border-r border-gray-200">
                                    <h4 className="text-sm font-bold leading-6 text-gray-900">SELECTED FILTER</h4>
                                  </div>
                                  <div className="border-r border-b border-t border-gray-200 px-4 py-5 sm:p-0 min-h-[77vh] max-h-[77vh] overflow-scroll">
                                    <ul role="list" className="divide-y divide-gray-200">
                                      {payload['supportingTables'][index] && payload['supportingTables'][index]['columns'] && Object.keys(payload['supportingTables'][index]['columns']).map((key, index4) => {
                                        return (
                                          <li key={index4} className='flex py-2 pl-3 pr-3 flex flex-col'>
                                            <div className='flex justify-between items-center'>
                                              <p className="text-sm">{key}</p>
                                              <button
                                                type="button"
                                                className="inline-flex items-center border border-transparent bg-blue-600 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={() => addSupportingFilter(index, `${payload['supportingTables'][index]['table']}__${key}`)}
                                              >
                                                +
                                              </button>
                                            </div>
                                            {payload['supportingTables'][index]['columns'][key].map((row, index2) => {
                                              if (Object.keys(row)[0] === 'character varying') {
                                                return (
                                                  <div className="pb-2 pt-2" key={index2}>
                                                    {index2 !== 0 ? 
                                                      Object.keys(columnToDropdowns).indexOf(key) !== -1 ? 
                                                      <div className='flex justify-between items-center'>
                                                        <input type="text" name={index} id={index} className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" disabled />
                                                        <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                      </div>
                                                      :
                                                      <div className='flex justify-between items-center'>
                                                        <div className="mt-1 flex w-full">
                                                          <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                            Begin with
                                                          </span>
                                                          <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['beginWith']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'beginWith')} disabled />
                                                          <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                            Contain
                                                          </span>
                                                          <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['contain']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'contain')} disabled />
                                                          <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                            Equal
                                                          </span>
                                                          <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'equal')} disabled />
                                                          <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                            End with
                                                          </span>
                                                          <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['endWith']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'endWith')} disabled />
                                                          <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                        </div>
                                                      </div> 
                                                      :
                                                      Object.keys(columnToDropdowns).indexOf(key) !== -1 ? 
                                                      <input type="text" name={index} id={index} className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" disabled />
                                                      :
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          Begin with
                                                        </span>
                                                        <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['beginWith']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'beginWith')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          Contain
                                                        </span>
                                                        <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['contain']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'contain')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          Equal
                                                        </span>
                                                        <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'equal')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          End with
                                                        </span>
                                                        <input type="text" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['character varying']['endWith']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'character varying', event, 'endWith')} disabled />
                                                      </div>
                                                    }
                                                  </div>
                                                );
                                              } else if (Object.keys(row)[0] === 'double precision') {
                                                return (
                                                  <div className="pb-2 pt-2" key={index2}>
                                                    {index2 !== 0 ? 
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`>=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'gte')} disabled /> 
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`>`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'gt')} disabled /> 
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'equal')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`<`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'lt')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`<=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'lte')} disabled />   
                                                        <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                      </div>
                                                    </div> :
                                                    <div className="mt-1 flex w-full">
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`>=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'gte')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`>`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['gt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'gt')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'equal')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`<`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'lt')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`<=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['double precision']['lte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'double precision', event, 'lte')} disabled />
                                                    </div>
                                                    }
                                                  </div>
                                                );
                                              } else if (Object.keys(row)[0] === 'integer') {
                                                return (
                                                  <div className="pb-2 pt-2" key={index2}>
                                                    {index2 !== 0 ? 
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`>=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'gte')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`>`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'gt')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'equal')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`<`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'lt')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          {`<=`}
                                                        </span>
                                                        <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'lte')} disabled />
                                                        <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                      </div>
                                                    </div> :
                                                    <div className="mt-1 flex w-full">
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`>=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'gte')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`>`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['gt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'gt')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['equal']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'equal')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`<`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lt']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'lt')} disabled />
                                                      <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                        {`<=`}
                                                      </span>
                                                      <input type="number" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['integer']['lte']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'integer', event, 'lte')} disabled />
                                                    </div>
                                                  }
                                                  </div>
                                                );
                                              } else if (Object.keys(row)[0] === 'date') {
                                                return (
                                                  <div className="pb-2 pt-2" key={index2}>
                                                    {index2 !== 0 ? 
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          From
                                                        </span>
                                                        <input type="date" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['from']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'date', event, 'from')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          To
                                                        </span>
                                                        <input type="date" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['to']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'date', event, 'to')} disabled />
                                                        <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                      </div>
                                                    </div> :
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          From
                                                        </span>
                                                        <input type="date" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['from']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'date', event, 'from')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          To
                                                        </span>
                                                        <input type="date" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['date']['to']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'date', event, 'to')} disabled />
                                                      </div>
                                                    </div>
                                                    }
                                                  </div>
                                                );
                                              } else if (Object.keys(row)[0] === 'timestamp with time zone') {
                                                return (
                                                  <div className="pb-2 pt-2" key={index2}>
                                                    {index2 !== 0 ? 
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          From
                                                        </span>
                                                        <input type="datetime-local" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['from']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'timestamp with time zone', event, 'from')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          To
                                                        </span>
                                                        <input type="datetime-local" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['to']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'timestamp with time zone', event, 'to')} disabled />
                                                        <button type="button" className="inline-flex items-center border border-transparent bg-red-600 ml-3 px-1.5 py-0.5 text-xs font-medium text-white  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2" onClick={() => removeSupportingFilter(index, payload['supportingTables'][index]['table'], key, index2)}>-</button>
                                                      </div>
                                                    </div> :
                                                    <div className='flex justify-between items-center'>
                                                      <div className="mt-1 flex w-full">
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          From
                                                        </span>
                                                        <input type="datetime-local" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['from']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'timestamp with time zone', event, 'from')} disabled />
                                                        <span className="inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                                          To
                                                        </span>
                                                        <input type="datetime-local" name={index2} id={index2} className=" focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-50" value={row['timestamp with time zone']['to']} onChange={(event) => handleSupportingInput(index, payload['supportingTables'][index]['table'], key, index2, 'timestamp with time zone', event, 'to')} disabled />
                                                      </div>
                                                    </div>
                                                    }
                                                  </div>
                                                );
                                              }
                                            })}
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> : null}
              </div>
            )
          })}
          <div className="mt-5 pb-[50px] text-center">
            <button
              type="button"
              className="inline-flex items-center border border-transparent bg-blue-600 px-3 py-2 text-xs font-medium leading-4 text-white  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={addSupportingSection}
            >
              Add supporting section
            </button>
          </div>
        </div>
        </form>
    </>
  )
}