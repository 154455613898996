export default function Badge(props) {
  const message = props.message;

  switch (message) {
    case 'Active':
    case 'Recorder':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-green-100 text-green-800">
          {message}
        </span>
      )
    case 'Associate':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-gray-100 text-gray-800">
          {message}
        </span>
      )
    case 'Admin':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-purple-100 text-purple-800">
          {message}
        </span>
      )
    case 'Manager':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-sky-100 text-sky-800">
          {message}
        </span>
      )
    case 'Specialist':
    case 'Inactive':
    case 'Closed':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-pink-100 text-pink-800">
          {message}
        </span>
      )
    case 'Dormant':
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-orange-100 text-orange-800">
          {message}
        </span>
      )
    case 'false':
    case false:
    case null:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-pink-100 text-pink-800">
          false
        </span>
      )
    case 'true':
    case true:
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold bg-green-100 text-green-800">
          true
        </span>
      )
    case undefined:
      return (
        <></>
      )
    default: 
      return message;
  }
}