import { useEffect, useState } from "react";
import { getValueFromReference } from "../scripts/helpers";

export default function Reference(props) {
  const [referencedValue, setReferencedValue] = useState('');

  useEffect(async () => {
    const {reference, value} = {...props};
    const dbValue = await getValueFromReference(reference, value);
    setReferencedValue(dbValue);
  }, [props])

  return referencedValue;
}