import { useState, useEffect, Fragment, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivileges, getAllTableAndColumns, addObject, deleteObject, getObject, displayValueByColumnName, listenToNotifications } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import Modal from '../../components/modal'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'
import { frontend_base_url } from '../../utils/helper'

export default function PdpaAttachmentsView() {
  const tableSlug = 'pdpa-attachments';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [object, setObject] = useState([]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const params = useParams();

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['View']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivileges(tableSlug, privilegesToUpdate, 'View', params.id).then(preparedTableColumns => {
        
        preparedTableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined && tableColumn.reference_synced === false && tableColumn.value !== null) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const storedAsColumnName = storedAs.data.column_name;

                displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn.value).then((displayValue) => {
                  preparedTableColumns[tableColumn['sequence'] - 1].value = displayValue.data[Object.keys(displayValue.data)[0]];

                  preparedTableColumns[tableColumn['sequence'] - 1]['reference_synced'] = true;

                  setTableColumns(preparedTableColumns);
                })
              });
            });
          }
        });

        setTableColumns(preparedTableColumns);
      });

    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'View');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject(tableSlug, params.id).then(result => {
      navigate(`/${tableSlug}`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });

      
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;
    
    if (buttonClicked === 'Delete') {
      setShowDeleteConfirmation(true);
    } else if (buttonClicked === 'Edit') {
      navigate(`/${tableSlug}/${params.id}/edit`);
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single='pdpa attachment' plural='pdpa attachments' />

        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
          <div>
            <div className="shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1 flex justify-between">
                <h3 className="text-lg leading-6 font-bold text-gray-900">VIEW PDPA ATTACHMENT</h3>
              </div>
              <div className="border-t border-gray-200 p-0">
                <dl className="divide-y divide-gray-200">
                  {tableColumns.map((tableColumn, index) => {
                    if (tableColumn['privilege'] === 'Yes') {
                      if (tableColumn['label_name'] === 'Attachment path') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2">
                              <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/pdpa-attachments/${tableColumns.find(tableColumn => tableColumn.column_name === 'filename').value}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                              {/* <a href='#' className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} onClick={() => downloadTempFile(tableColumn['value'], rightStubObj.tableColumns.filter((row) => row.column_name === 'filename')[0]['value'])}>{tableColumn['value']}</a> */}
                            </dd>
                          </div>
                        )
                      } else if (tableColumn['label_name'] === 'Created by') {
                        return (
                          <Fragment key={index}>
                            <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                              <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                              <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                                {cookies['username']}
                              </dd>
                            </div>
                          </Fragment>
                        )
                      } else if (tableColumn['column_name'] === 'tagged_users') {
                        return (
                          <Fragment key={index}>
                            <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                              <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                              <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                                {tableColumn.value.length > 0 ? (
                                <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                  {tableColumn.value.map((row, index) => {
                                    return (
                                      <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                        <div className="w-full">
                                          <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ul>
                                ) : (<></>)}
                              </dd>
                            </div>
                          </Fragment>
                        )
                      } else {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                              {tableColumn['value']}
                            </dd>
                          </div>
                        )
                      }
                    }
                  })}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}