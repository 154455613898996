import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {getNumberOfTables} from '../scripts/helpers'
import { useCookies } from 'react-cookie'
import { el } from 'date-fns/locale';

export default function NavigationButton (props) {
  const {title, table, workerId, jobId, problemId, page, link, close} = props.data;
  const [numberOfTables, setNumberOfTables] = useState('');
  const [color, setColor] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);

  useEffect(() => {
    if (table !== undefined && workerId !== undefined && jobId !== undefined && problemId !== undefined) {
      getNumberOfTables(table, workerId, jobId, problemId, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('turqoise');
        if (table === 'sensitiveDiscussion') {
          if (cookies['role'] === 'Admin' || cookies['role'] === 'Manager') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin' || cookies['role'] === 'Manager') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    } else if (table !== undefined && workerId !== undefined && jobId !== undefined) {
      getNumberOfTables(table, workerId, jobId, null, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('teal2');
        if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    } else if (table !== undefined && workerId !== undefined) {
      getNumberOfTables(table, workerId, null, null, cookies['userId'], cookies['role']).then(numberOfTablesResult => {
        setColor('sky');
        if (table === 'sensitiveAttachment') {
          if (cookies['role'] === 'Admin') {
            setNumberOfTables(numberOfTablesResult.data);
          } else {
            setNumberOfTables(numberOfTablesResult.data.length);
          }
        } else {
          setNumberOfTables(numberOfTablesResult.data);
        }
      });
    }
  }, [props]);

  if (page === 'View' || page === 'List' || page === undefined) {
    if (table !== undefined && workerId !== undefined && jobId !== undefined && problemId !== undefined) {
      return (
        <li className="col-span-1 flex shadow-sm hover:bg-sky-700">
          <Link onClick={close} to={link} className="w-full h-full">
            <div className={`flex-1 flex justify-between border border-turqoise-600 bg-turqoise-600 hover:bg-turqoise-700 h-full min-h-[50px]`}>
              <div className="flex-grow px-2 py-1 text-sm break-normal">
                <span className='text-white font-medium'>
                  {title}
                </span>
              </div>
              <div className={`px-2 py-1 text-sm text-white font-medium`}>
                {numberOfTables}
              </div>
            </div>
          </Link>
        </li>
      )
    } else if (table !== undefined && workerId !== undefined && jobId !== undefined) {
      return (
        <li className="col-span-1 flex shadow-sm hover:bg-sky-700">
          <Link onClick={close} to={link} className="w-full h-full">
            <div className={`flex-1 flex justify-between border border-teal2-600 bg-teal2-600 hover:bg-teal2-700 h-full min-h-[50px]`}>
              <div className="flex-grow px-2 py-1 text-sm break-normal">
                <span className='text-white font-medium'>
                  {title}
                </span>
              </div>
              <div className={`px-2 py-1 text-sm text-white font-medium`}>
                {numberOfTables}
              </div>
            </div>
          </Link>
        </li>
      )
    } else {
      return (
        <li className="col-span-1 flex shadow-sm hover:bg-sky-700">
          <Link onClick={close} to={link} className="w-full h-full">
            <div className={`flex-1 flex justify-between border border-sky-600 bg-sky-600 hover:bg-sky-700 h-full min-h-[50px]`}>
              <div className="flex-grow px-2 py-1 text-sm break-normal">
                <span className='text-white font-medium'>
                  {title}
                </span>
              </div>
              <div className={`px-2 py-1 text-sm text-white font-medium`}>
                {numberOfTables}
              </div>
            </div>
          </Link>
        </li>
      )
    }
  } else if (page === 'Edit' || page === 'Add') {
    return (
      <li className="col-span-1 flex shadow-sm">
        <div className={`flex-1 flex justify-between border border-gray-100 bg-gray-100 h-full min-h-[50px]`}>
          <div className="flex-grow px-2 py-1 text-sm break-normal">
            <span className='text-gray-400 font-medium'>
              {title}
            </span>
          </div>
          <div className={`px-2 py-1 text-sm text-gray-400 font-medium`}>
            {numberOfTables}
          </div>
        </div>
      </li>
    )
  }
}