import axios from 'axios'
import { format, getMonth, getYear, add, sub, subDays, addDays, lastDayOfMonth, startOfMonth, isAfter, differenceInMilliseconds, differenceInMinutes, differenceInHours, differenceInDays,differenceInWeeks } from 'date-fns'
import { reverseMappings } from '../routes/reports/add';
import { backend_base_url } from '../utils/helper';


// not exported

const getPrivilegeByTableAndRoleId = async (tableSlug, roleId) => {
  return await axios.get(`${backend_base_url()}/api/privileges/getPrivilegeByTableAndRoleId`, {
    params: {
      tableSlug: tableSlug,
      roleId: roleId
    },
    withCredentials: true
  }).then(result => {
    return result;
  }).catch(err => {

  });
};

const getLargestSequence = async (tableSlug, workerId) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/getLargestSequence`, {
    params: {
      tableSlug: tableSlug,
      workerId: workerId
    },
    withCredentials: true
  }).then(result => {
    return result;
  }).catch(err => {
  });
};

const getListPrivileges = async (token) => {
  return await axios.get(`${backend_base_url()}/api/privileges/getListPrivileges`, {
    params: {
      token: token
    },
    withCredentials: true
  }).then(result => {
    return result;
  }).catch(err => {

  });
};

// exported

const tokenIsValid = async (token) => {
  return await axios.post(`${backend_base_url()}/api/auth/validateToken`, {
    token: token
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const assignPdpa = async (tableColumns, objectId) => {
  const pdpaAssignObject = {};

  let userToken;

  tableColumns.forEach((tableColumn, index) => {
    if (tableColumn.column_name === 'tagged_workers') {
      // check if twid or temp twid
      let workerTwid;
      if (tableColumn.value.slice(0,3) === 'GEN') {
        workerTwid = tableColumn.value;
        workerTwid = workerTwid.split(' ');
        workerTwid = workerTwid[0];
      } else {
        workerTwid = tableColumn.value;
        workerTwid = workerTwid.split(' ');
        workerTwid = workerTwid[0]+workerTwid[1]+workerTwid[2];
      }
      pdpaAssignObject.workerTwid = workerTwid;
    } else if (tableColumn.column_name === 'pdpa_status') {
      pdpaAssignObject.pdpa_status = tableColumn.value;
    } else if (tableColumn.column_name === 'file_path') {
      pdpaAssignObject.file_path = tableColumn.value;
    } else if (tableColumn.token) {
      userToken = tableColumn.token;
    }
  });

  return await axios.put(`${backend_base_url()}/api/pdpa-attachments/${objectId}/assignPdpa`, {
    pdpaAssignObject: pdpaAssignObject,
    userToken: userToken,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const addAuditLog = async (auditLog) => {
  return await axios.post(`${backend_base_url()}/api/audit-logs/addAuditLog`, {
    auditLog: auditLog,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const updatePassword = async (token, fields) => {
  return await axios.post(`${backend_base_url()}/api/auth/updatePassword`, {
    token: token,
    fields: fields,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const countUnreadDirectMessageConversation = async (userId) => {
  return await axios.get(`${backend_base_url()}/api/unread-direct-message-conversations/countUnreadDirectMessageConversation/${userId}`, {withCredentials: true});
};

const countUnreadGroupChatConversation = async (userId) => {
  return await axios.get(`${backend_base_url()}/api/unread-group-chat-conversations/countUnreadGroupChatConversation/${userId}`, {withCredentials: true});
};

const zeroUnreadDirectMessageConversation = async (fromId, toId) => {
  return await axios.put(`${backend_base_url()}/api/unread-direct-message-conversations/zeroUnreadDirectMessageConversation`, {
    fromId: fromId,
    toId: toId,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const zeroUnreadDirectMessageConversationWithUsername = async (fromUsername, toId) => {
  return await axios.put(`${backend_base_url()}/api/unread-direct-message-conversations/zeroUnreadDirectMessageConversationWithUsername`, {
    fromUsername: fromUsername,
    toId: toId,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const zeroUnreadGroupChatConversationWithUsername = async (groupChatSlug, toId) => {
  return await axios.put(`${backend_base_url()}/api/unread-group-chat-conversations/zeroUnreadGroupChatConversationWithUsername`, {
    groupChatSlug: groupChatSlug,
    toId: toId,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const increment = async (fromId, toId) => {
  return await axios.put(`${backend_base_url()}/api/unread-direct-message-conversations/increment`, {
    fromId: fromId,
    toId: toId,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getRoleId = async (token) => {
  return await axios.post(`${backend_base_url()}/api/getRoleId`, {
    token: token,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getProfile = async (token) => {
  return await axios.get(`${backend_base_url()}/api/users/getProfile`, {
    params: {
      token: token
    },
    withCredentials: true
  });
};

const updateProfile = async (tableColumns, token) => {
  return await axios.post(`${backend_base_url()}/api/users/updateProfile`, {
      token: token, 
      tableColumns: tableColumns,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getUserFromToken = async (token) => {
  return await axios.get(`${backend_base_url()}/api/getUserFromToken`, {
    params: {
      token: token
    },
    withCredentials: true
  });
};

const getRole = async (token) => {
  return await axios.post(`${backend_base_url()}/api/getRole`, {
    token: token,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getPrivilegeForCurrentPage = (privilegeObject) => {
  return new Promise ((resolve, reject) => {
    const {tableSlug, token} = privilegeObject;

    // get roleId from the token submitted by user's browser
    getRoleId(token).then((result) => {
      const roleId = result.data.roleId;

      // using the table, mode and roleId, check user's privilege
      // for current request
      getPrivilegeByTableAndRoleId(tableSlug, roleId).then((result2) => {
        const privilege = result2.data;
        resolve(privilege);
      });
    }).catch(err => {
      
    });
  });
};

const getGeneratePresetReportPrivilege = (token) => {
  return new Promise ((resolve, reject) => {

    // get roleId from the token submitted by user's browser
    getRoleId(token).then((result) => {
      const roleId = result.data.roleId;

      // using the table, mode and roleId, check user's privilege
      // for current request
      getPrivilegeByTableAndRoleId('preset-reports', roleId).then((result2) => {
        const privilege = result2.data;
        resolve(privilege);
      });
    }).catch(err => {
      
    });
  });
};

const initialiseSubHeaderButtons = async (privilegeObject) => {
  // const {table, mode, token} = privilegeObject;

};

const populateActions = (tableSlug, privilegeObjects, mode, workerId, subsidiarySlug, subsidiary, workerSubsidiaryId, jobSubsidiarySlug, jobSubsidiaryId, problemSubsidiarySlug, problemSubsidiaryId) => {
  let canDownload = false;
  if (privilegeObjects) {
    canDownload = (privilegeObjects['View']['file_path'] === 'Yes' || privilegeObjects['Edit']['file_path'] !=='No');
  }

  if (tableSlug === 'all-group-chats') {
    return [
      {
        name: 'Delete',
        href: '#',
        class: 'bg-red-500 hover:bg-red-600',
        position: 'left',
        hidden: false,
        type: 'submit',
      }
    ]
  }

  if (mode === 'List') {
    if (subsidiarySlug !== undefined && subsidiarySlug !== 'edit') {
      let addHidden = true;
      let editHidden = true;
      let deleteHidden = true;

      addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
      editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
      deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;
      
      const buttons = [
        {
          name: 'Add',
          href: `/${tableSlug}/add`,
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: addHidden,
        },
        {
          name: 'Edit',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: editHidden,
        },
        {
          name: 'Delete',
          href: '#',
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: deleteHidden,
          type: 'submit',
        }
      ];

      // if (canDownload) {
      //   buttons.push(
      //     {
      //       name: 'Delete',
      //       href: '#',
      //       class: 'bg-red-500 hover:bg-red-600',
      //       position: 'left',
      //       hidden: deleteHidden,
      //       type: 'submit',
      //     }
      //   )
      // }

      return buttons;
    } else {
      if (tableSlug === 'dropdowns') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;
        
        return [
          {
            name: 'Add dropdown',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        ];
      } else if (tableSlug === 'problem-clans') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;
        
        return [
          {
            name: 'Add problem clan',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        ];
      } else if (tableSlug === 'users') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        return [
          {
            name: 'Add user',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        ];
      } else if (tableSlug === 'announcements') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        return [
          {
            name: 'Add announcement',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        ];
      }  else if (tableSlug === 'preset-skins') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        const buttons = [
          {
            name: 'Add preset skin',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          }
        ];

        buttons.push(
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        );

        return buttons;
      }  else if (tableSlug === 'preset-reports') {
        let editHidden = true;
        let deleteHidden = true;

        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        const buttons = [
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          }
        ];

        buttons.push(
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        );

        return buttons;
      }  else if (tableSlug === 'reports') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        const buttons = [
          {
            name: 'Add report',
            href: `/${tableSlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          }
        ];

        if (canDownload) {
          buttons.push(
            {
              name: 'Download',
              href: '#',
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            }
          )
        }

        buttons.push(
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        );

        return buttons;
      } else if (tableSlug === 'facepic-attachments' || tableSlug === 'pdpa-attachments' || tableSlug === 'ordinary-attachments' || tableSlug === 'sensitive-attachments' || tableSlug === 'templates') {
        let addHidden = true;
        let editHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        const buttons = [
          {
            name: `Add ${reverseMappings[tableSlug].toLowerCase()}`,
            href: `#`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          }
        ];

        if (canDownload) {
          buttons.push(
            {
              name: 'Download',
              href: '#',
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            }
          )
        }

        buttons.push(
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        );

        return buttons;
      } else if (tableSlug === 'imports') {
        let addHidden = true;
        let deleteHidden = true;

        addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
        deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

        const buttons = [
          {
            name: 'Import',
            href: `#`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          }
        ];

        if (canDownload) {
          buttons.push(
            {
              name: 'Download',
              href: '#',
              class: 'bg-blue-500 hover:bg-blue-600',
              position: 'left',
              hidden: false,
            }
          )
        }

        buttons.push(
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
            type: 'submit',
          }
        )

        return buttons;
      } else {
        return [];
      }
    }
  } else if (mode === 'Add') {
    if (tableSlug === 'templates') {
      let saveHidden = true;
      saveHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
      return [
        {
          name: 'Upload',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: saveHidden,
        },
        {
          name: 'Go back to list',
          href: `#`,
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: false,
        }
      ];
    } else if (tableSlug === 'imports') {
      let saveHidden = true;
      saveHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
      return [
        {
          name: 'Upload',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: saveHidden,
        },
        {
          name: 'Go back to list',
          href: `#`,
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: false,
        }
      ];
    } else {
      let saveHidden = true;
      saveHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
      return [
        {
          name: 'Save',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: saveHidden,
        },
        {
          name: 'Abandon',
          href: `#`,
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: false,
        }
      ];
    }
  } else if (mode === 'View') {
    let editHidden = true;
    let deleteHidden = true;
    let addHidden = true;
    let listHidden = true;

    addHidden = privilegeObjects['Add']['id'] !== 'No' ? false : true;
    editHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
    deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;
    listHidden = privilegeObjects['List']['id'] === 'Yes' ? false : true;

    if (tableSlug === 'templates' || tableSlug === 'announcements' || tableSlug === 'users') {
      return [
        {
          name: 'Edit',
          href: `#`,
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: editHidden,
        },
        {
          name: 'Go back to list',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: false,
        },
        {
          name: 'Delete',
          href: '#',
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: deleteHidden,
        }
      ];
    } else if (tableSlug === 'imports') {
      return [
        {
          name: 'Go back to list',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: false,
        },
        {
          name: 'Delete',
          href: '#',
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: deleteHidden,
        }
      ];
    } else if (tableSlug === 'preset-skins') {
      return [
        {
          name: 'Edit',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: editHidden,
        },
        {
          name: 'Delete',
          href: '#',
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: deleteHidden,
        }
      ];
    } else {
      if (problemSubsidiarySlug !== undefined) {
        return [
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: `Add ${subsidiary.toLowerCase()}`,
            href: `/workers/${workerId}/jobs/${workerSubsidiaryId}/${jobSubsidiarySlug}/${jobSubsidiaryId}/${problemSubsidiarySlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: `List ${problemSubsidiarySlug.replace(/-/g, ' ').toLowerCase()}`,
            href: `/workers/${workerId}/jobs/${workerSubsidiaryId}/${jobSubsidiarySlug}/${jobSubsidiaryId}/${problemSubsidiarySlug}`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: listHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
          }
        ];
      } else if (jobSubsidiarySlug !== undefined) {
        return [
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: `Add ${subsidiary.toLowerCase()}`,
            href: `/workers/${workerId}/jobs/${workerSubsidiaryId}/${jobSubsidiarySlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: `List ${jobSubsidiarySlug.replace(/-/g, ' ').toLowerCase()}`,
            href: `/workers/${workerId}/jobs/${workerSubsidiaryId}/${jobSubsidiarySlug}`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: listHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
          }
        ];
      } else if (jobSubsidiarySlug === undefined && subsidiarySlug !== undefined) {
        return [
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: `Add ${subsidiary.toLowerCase()}`,
            href: `/workers/${workerId}/${subsidiarySlug}/add`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: addHidden,
          },
          {
            name: `List ${subsidiarySlug.replace(/-/g, ' ').toLowerCase()}`,
            href: `/workers/${workerId}/${subsidiarySlug}`,
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: listHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
          }
        ];
      } else {
        return [
          {
            name: 'Edit',
            href: '#',
            class: 'bg-blue-500 hover:bg-blue-600',
            position: 'left',
            hidden: editHidden,
          },
          {
            name: 'Delete',
            href: '#',
            class: 'bg-red-500 hover:bg-red-600',
            position: 'left',
            hidden: deleteHidden,
          }
        ];
      }
    }
  } else if (mode === 'Edit') {
    if (subsidiarySlug !== undefined) {
      let saveHidden = true;
      saveHidden = privilegeObjects['Edit']['id'] !== 'No' ? false : true;
      return [
        {
          name: 'Save',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: saveHidden,
        },
        {
          name: 'Abandon',
          href: `#`,
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: false,
        }
      ];
    } else {
      let saveHidden = true;
      saveHidden = privilegeObjects === null || privilegeObjects === undefined || privilegeObjects['Edit']['id'] !== 'No' ? false : true;
      return [
        {
          name: 'Save',
          href: '#',
          class: 'bg-blue-500 hover:bg-blue-600',
          position: 'left',
          hidden: saveHidden,
        },
        {
          name: 'Abandon',
          href: `#`,
          class: 'bg-red-500 hover:bg-red-600',
          position: 'left',
          hidden: false,
        }
      ];
    }
  }
};

const populateActions2 = (tableSlug, privilegeObjects, mode) => {
  let deleteHidden = true;
  deleteHidden = privilegeObjects['Delete']['id'] === 'Yes' ? false : true;

  const canDownload = (privilegeObjects['View']['file_path'] === 'Yes' || privilegeObjects['Edit']['file_path'] !=='No');

  const buttons = [
    {
      name: 'Assign pdpa attachment',
      href: `#`,
      class: 'bg-blue-500 hover:bg-blue-600',
      position: 'left',
      hidden: false,
    }
  ];


  if (canDownload) {
    buttons.push(
      {
        name: 'Download',
        href: '#',
        class: 'bg-blue-500 hover:bg-blue-600',
        position: 'left',
        hidden: false,
      }
    )
  }

  buttons.push(
    {
      name: 'Delete',
      href: '#',
      class: 'bg-red-500 hover:bg-red-600',
      position: 'left',
      hidden: deleteHidden,
      type: 'submit',
    }
  )

  return buttons;
};

const deleteObjects = async (tableSlug, objectsSelected) => {
  return await axios.delete(`${backend_base_url()}/api/${tableSlug}`, {
    data: {
      objects: objectsSelected
    },
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const deleteImportObjects = async (tableSlug, objectsSelected) => {
  return await axios.delete(`${backend_base_url()}/api/imports`, {
    data: {
      tableSlug: tableSlug,
      objects: objectsSelected
    },
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getSchemaByTable = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/getSchema`, {withCredentials: true}); 
};

const getLabelNames = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getLabelNames`, {
    params: {
      table: tableSlug
    },
    withCredentials: true
  });
};

const getColumnsByTable = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getColumnsByTable`, {
    params: {
      table: tableSlug
    },
    withCredentials: true
  });
};

const getObjects = (tableSlug) => {
  return new Promise ((resolve, reject) => {
    axios.get(`${backend_base_url()}/api/${tableSlug}`, {withCredentials: true}).then(objects => {
      const objectsData = objects.data;

      objectsData.forEach(objectData => {
        const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        
        Object.keys(objectData).forEach((key, index) => {
          if (String(objectData[key]).match(timestampRegex)) {
            objectData[key] = format(new Date(objectData[key]), 'yyyy-MMM-dd HH:mm')
          } else if (String(objectData[key]).match(dateRegex)) {
            objectData[key] = format(new Date(objectData[key]), 'yyyy-MMM-dd')
          }
        });
      });

      resolve(objectsData);
    });
  });
};

const findAllRows = async () => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/findAllRows`, {withCredentials: true});
};

const getObject = async (tableSlug, id) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/${id}`, {withCredentials: true});
};

const getPresetReportSkinCount = async (id) => {
  return await axios.get(`${backend_base_url()}/api/preset-reports/getCount/${id}/`, {withCredentials: true});
};

const getUnreadDirectMessageConversation = async (directMessageConversationId, userId) => {
  return await axios.get(`${backend_base_url()}/api/unread-direct-message-conversations/getUnreadDirectMessageConversation`, {
    params: {
      directMessageConversationId: directMessageConversationId,
      userId: userId
    },
    withCredentials: true
  });
};

const getUnreadGroupChatConversation = async (groupChatConversationId, userId) => {
  return await axios.get(`${backend_base_url()}/api/unread-group-chat-conversations/getUnreadGroupChatConversation`, {
    params: {
      groupChatConversationId: groupChatConversationId,
      userId: userId
    },
    withCredentials: true
  });
};

const getProblemsByWorkerTwid = async (twid) => {
  return await axios.get(`${backend_base_url()}/api/problems/getProblemsByWorkerTwid`, {
    params: {
      twid: twid.replace(/ /g, '')
    },
    withCredentials: true
  });
};

const getJobByJobIdAndWorkerId = async (jobId, workerId) => {
  return await axios.get(`${backend_base_url()}/api/jobs/getJobByJobIdAndWorkerId`, {
    params: {
      jobId: jobId,
      workerId: workerId
    },
    withCredentials: true
  });
};

const getProblemByProblemIdAndJobIdAndWorkerId = async (problemId, jobId, workerId) => {
  return await axios.get(`${backend_base_url()}/api/problems/getProblemByProblemIdAndJobIdAndWorkerId`, {
    params: {
      problemId: problemId,
      jobId: jobId,
      workerId: workerId
    },
    withCredentials: true
  });
};

const getSubsidiaryObject = async (subsidiarySlug, workerId, workerSubsidiaryId) => {
  return await axios.get(`${backend_base_url()}/api/${subsidiarySlug}/getSubisidiaryObject`, {
    params: {
      workerId: workerId,
      workerSubsidiaryId: workerSubsidiaryId
    },
    withCredentials: true
  });
};

const prepareTableColumnsWithPrivilegeAndInputType = (tableSlug, privileges, mode, objectId, workerId, jobId, problemId, userId) => {
  if (mode === 'Add') {
    return new Promise ((resolve, reject) => {
      getSchemaByTable(tableSlug).then(schemasResult => {
        const schemaRows = schemasResult.data;

        getDefaultTableSlugFromTableSlug(tableSlug).then(result => {
          const defaultTableSlug = result['data']['default_table_slug'];

          getObject(defaultTableSlug, 0).then(objectResult => {
            const object = objectResult.data;

            // get columns of table and the sequence
            getColumnsByTable(tableSlug).then(columnsResult => {
              const tableColumns = columnsResult.data;

              let counterToInsertTaggedWorkers = 0;

              let currentUsernameIndex = -1;

              tableColumns.forEach((tableColumn, index3) => {
                tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
                
                if (object[tableColumn['column_name']] !== null || object[tableColumn['Auto-generated']] !== null || object[tableColumn['column_name']] !== undefined) {
                  if (object[tableColumn['column_name']] === null) {
                    tableColumn['value'] = '';
                  } else {
                    if (object[tableColumn['column_name']] === undefined) {
                      tableColumn['value'] = '';
                    } else {
                      tableColumn['value'] = object[tableColumn['column_name']];
                    }
                  }
                } else {
                  tableColumn['value'] = '';
                }

                if (tableSlug === 'pdpa-attachments' || tableSlug === 'facepic-attachments') {
                  tableColumn['sequence'] = tableColumn['sequence'] + 1;
                } else if (tableSlug === 'ordinary-attachments') {
                  tableColumn['sequence'] = tableColumn['sequence'] + 3;
                }
                
                const tableColumnColumnName = tableColumn['column_name'];
                
                // going through schema and assigning input types
                for (let i = 0; i < schemaRows.length; i += 1) {
                  const schemaColumnName = schemaRows[i]['column_name'];
                  if (schemaColumnName === tableColumnColumnName) {
                    switch (schemaRows[i]['data_type']) {
                      case 'boolean':
                        tableColumn['input_type'] = 'checkbox';
                        tableColumn['value'] = false;
                        break;
                      case 'integer':
                        if (tableColumn['value'] === null) {
                          tableColumn['value'] = '';
                        } 

                        if (tableColumn['reference'] !== null) {
                          tableColumn['input_type'] = 'combobox';
                          if (tableColumn['value'] === '' || tableColumn['value'] === null) {
                            tableColumn['reference_synced'] = true;
                          } else {
                            getObject('tableAndColumns', tableColumn['reference']['edit']).then(referenced => {
                              tableColumn['referencedColumnName'] = referenced.data.column_name;
                              tableColumn['referencedTableSlug'] = referenced.data.table_slug;

                              getObject('tableAndColumns', tableColumn['reference']['store']).then(storeAs => {
                                // get all values
                                tableColumn['storeAsColumnName'] = storeAs.data.column_name;
                                tableColumn['storeAsColumnValue'] = tableColumn['value'];
                              });
                            });

                            tableColumn['reference_synced'] = false;
                          }
                        } else {
                          tableColumn['input_type'] = 'number';
                        }
                        break;
                      case 'double precision':
                        tableColumn['input_type'] = 'float';
                        break;
                      case 'character varying':
                        tableColumn['input_type'] = 'text';
                        if (tableColumn['value'] === "Current user's username") {
                          currentUsernameIndex = index3;
                        }
                        break;
                      case 'timestamp with time zone':
                        tableColumn['input_type'] = 'datetime-local';
                        if (tableColumn['value'] !== '' && (getYear(new Date(tableColumn['value'])) === 1000 || getYear(new Date(tableColumn['value'])) === 1001)) {
                          tableColumn['value'] = new Date();
                        } else if (tableColumn['value'] === '') {
                          tableColumn['value'] = '';
                        } else if (tableColumn['value'] !== null) {
                          tableColumn['value'] = new Date(tableColumn['value']);
                        }
                        
                        break;
                      case 'date':
                        tableColumn['input_type'] = 'date';
                        if (tableColumn['value'] === '0001-01-01') {
                          tableColumn['value'] = new Date();
                          tableColumn['value'].setHours(0,0,0,0);
                          // tableColumn['value'] = format(new Date(), 'yyyy-MMM-dd');
                        } else if (tableColumn['value'] === '') {
                          tableColumn['value'] = '';
                        } else if (tableColumn['value'] !== null) {
                          tableColumn['value'] = new Date(tableColumn['value']);
                        }
                         
                        break;
                    }
                    tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                    break;
                  }
                }

                if (tableColumn['privilege'] === 'View') {
                  tableColumn['is_nullable'] = true;
                }
                
                if (tableColumnColumnName.indexOf("password") !== -1) {
                  tableColumn['input_type'] = 'password';
                } else if (tableColumnColumnName === 'id' || tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login' || tableColumnColumnName === 'file_size' || tableColumnColumnName === 'filename' || tableColumnColumnName === 'file_path' || tableColumnColumnName === 'records_inserted' || tableColumnColumnName === 'records_exported' || tableColumnColumnName === 'report_object' || tableColumnColumnName === 'report') {
                  tableColumn['input_type'] = 'text';
                  tableColumn['value'] = 'Auto-generated';
                  tableColumn['is_nullable'] = true;
                } else if (tableColumnColumnName === 'tableAndColumn_id') {
                  tableColumn['value'] = [];
                  tableColumn['is_nullable'] = true;
                } else if (tableColumn['dropdowns'].length > 0) {
                  tableColumn['dropdowns'].unshift({
                    dropdown_item: '-'
                  });
                  if (tableColumn['value'] === null || tableColumn['value'] === undefined) {
                    tableColumn['value'] = '-';
                  }
                } else if (tableColumnColumnName === 'worker_id') {
                  tableColumn['value'] = workerId;
                } else if (tableColumnColumnName === 'created_by') {
                  tableColumn['value'] = userId;
                } else if (tableColumnColumnName === 'attachment_submitted_by') {
                  tableColumn['value'] = userId;
                } else if (tableColumnColumnName === 'job_id') {
                  tableColumn['value'] = jobId;
                } else if (tableColumnColumnName === 'problem_id') {
                  tableColumn['value'] = problemId;
                } else if (tableColumnColumnName === 'problem_clan_checkbox') {
                  counterToInsertTaggedWorkers = tableColumn['sequence'];
                }
              });

              if (tableSlug === 'ordinary-attachments') {
                // find jobs and problems tied to worker id so that
                // values can be populated in dropdowns to tag
                // attachments with
                tableColumns.unshift(
                  {
                    column_name: 'upload',
                    input_type: 'file',
                    is_nullable: false,
                    label_name: 'Upload',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 0,
                    value: ''
                  }
                );

                tableColumns.splice(1, 0, 
                  {
                    column_name: 'employer_name',
                    input_type: 'text',
                    is_nullable: true,
                    label_name: 'Employer',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 2,
                    value: '',
                    reference: null
                  },
                  {
                    column_name: 'chief_problem',
                    input_type: 'text',
                    is_nullable: true,
                    label_name: 'Chief problem',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 3,
                    value: '',
                    reference: null
                  }
                );
              } else if (tableSlug === 'templates' || tableSlug === 'imports') {
                // find jobs and problems tied to worker id so that
                // values can be populated in dropdowns to tag
                // attachments with
                tableColumns.unshift(
                  {
                    column_name: 'upload',
                    input_type: 'file',
                    is_nullable: false,
                    label_name: 'Upload',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 0,
                    value: ''
                  }
                );
              } else if (tableSlug === 'sensitive-attachments') {
                tableColumns.unshift(
                  {
                    column_name: 'upload',
                    input_type: 'file',
                    is_nullable: false,
                    label_name: 'Upload',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 0,
                    value: ''
                  }
                );

                tableColumns.splice(1, 0, 
                  {
                    column_name: 'tag_user',
                    input_type: 'combobox',
                    is_nullable: true,
                    dropdowns: [],
                    label_name: 'Add a tagged user',
                    privilege: 'Yes',
                    reference: {
                      add: 178,
                      view: 178,
                      edit: 178,
                      store: 171
                    },
                    records: [],
                    remarks: "NULL",
                    sequence: 2,
                    table_slug: tableSlug,
                    value: ''
                  },
                  {
                    column_name: 'tagged_users',
                    input_type: 'list',
                    is_nullable: true,
                    dropdowns: [],
                    label_name: 'Tagged users (they can view and edit)',
                    privilege: 'Yes',
                    reference: null,
                    records: [],
                    remarks: "NULL",
                    sequence: 3,
                    table_slug: tableSlug,
                    value: {}
                  },
                  {
                    column_name: 'employer_name',
                    input_type: 'text',
                    is_nullable: true,
                    label_name: 'Employer',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 4,
                    value: '',
                    reference: null
                  },
                  {
                    column_name: 'chief_problem',
                    input_type: 'text',
                    is_nullable: true,
                    label_name: 'Chief problem',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 5,
                    value: '',
                    reference: null
                  }
                );

                for (let i = 5; i < tableColumns.length; i += 1) {
                  tableColumns[i]['sequence'] = i + 1;
                }
              } else if (tableSlug === 'pdpa-attachments' || tableSlug === 'facepic-attachments') {
                // find jobs and problems tied to worker id so that
                // values can be populated in dropdowns to tag
                // attachments with

                tableColumns.unshift(
                  {
                    column_name: 'upload',
                    input_type: 'file',
                    is_nullable: false,
                    label_name: 'Upload',
                    dropdowns: [],
                    privilege: 'Yes',
                    remarks: null,
                    sequence: 0,
                    value: ''
                  }
                );
              }
              
              if (tableSlug === 'ordinary-case-discussions' || tableSlug === 'hearings-progress') {
                const addedOnTaggedSection = [
                  {
                  column_name: 'tag_worker',
                  input_type: 'combobox',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Add a tagged worker',
                  privilege: 'Yes',
                  reference: {
                    add: 5,
                    view: 5,
                    edit: 5,
                    store: 1
                  },
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 1,
                  table_slug: tableSlug,
                  value: ''
                  },
                  {
                  column_name: 'tagged_workers',
                  input_type: 'list',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Tagged workers (copied to their records)',
                  privilege: 'Yes',
                  reference: null,
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 2,
                  table_slug: tableSlug,
                  value: {}
                  }
                ];  

                let updatedSequencedTableColumns = tableColumns.slice(counterToInsertTaggedWorkers);
                updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = counterToInsertTaggedWorkers + 3 + index);

                resolve(tableColumns.slice(0, counterToInsertTaggedWorkers).concat(addedOnTaggedSection, updatedSequencedTableColumns));
              } else if (tableSlug === 'sensitive-discussions') {
                const addedOnTaggedSection = [
                  {
                  column_name: 'tag_worker',
                  input_type: 'combobox',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Add a tagged worker',
                  privilege: 'Yes',
                  reference: {
                    add: 5,
                    view: 5,
                    edit: 5,
                    store: 1
                  },
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 1,
                  table_slug: tableSlug,
                  value: ''
                  },
                  {
                  column_name: 'tagged_workers',
                  input_type: 'list',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Tagged workers (copied to their records)',
                  privilege: 'Yes',
                  reference: null,
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 2,
                  table_slug: tableSlug,
                  value: {}
                  },
                  {
                  column_name: 'tag_user',
                  input_type: 'combobox',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Add a tagged user',
                  privilege: 'Yes',
                  reference: {
                    add: 178,
                    view: 178,
                    edit: 178,
                    store: 171
                  },
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 3,
                  table_slug: tableSlug,
                  value: ''
                  },
                  {
                  column_name: 'tagged_users',
                  input_type: 'list',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Tagged users (they can view and edit)',
                  privilege: 'Yes',
                  reference: null,
                  records: [],
                  remarks: "NULL",
                  sequence: counterToInsertTaggedWorkers + 4,
                  table_slug: tableSlug,
                  value: {}
                  }
                ];  

                let updatedSequencedTableColumns = tableColumns.slice(counterToInsertTaggedWorkers);
                updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = counterToInsertTaggedWorkers + 5 + index);

                resolve(tableColumns.slice(0, counterToInsertTaggedWorkers).concat(addedOnTaggedSection, updatedSequencedTableColumns));
              } else {
                if (currentUsernameIndex !== -1) {
                  getObject('users', userId).then(user => {
                    tableColumns[currentUsernameIndex]['value'] = user.data.user_username;
                    resolve(tableColumns);
                  });
                } else {
                  resolve(tableColumns);
                }
              }
            });
          });
        });
      });
    });
  } else if (mode === 'Edit') {
    return new Promise ((resolve, reject) => {
      getSchemaByTable(tableSlug).then(schemasResult => {
        const schemaRows = schemasResult.data;
        
        getObject(tableSlug, objectId).then(objectResult => {
          const object = objectResult.data;
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

          const dateOrDatetimeColumns = {};
          for (let i = 0; i < schemaRows.length; i += 1) {
            switch (schemaRows[i]['data_type']) {
              case 'timestamp with time zone':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
              case 'date':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
            }
          } 

          Object.keys(object).forEach((key, index) => {
            if (dateOrDatetimeColumns[key] && String(object[key]).match(timestampRegex)) {
              object[key] = new Date(object[key])
            } else if (dateOrDatetimeColumns[key] && String(object[key]).match(dateRegex)) {
              object[key] = new Date(object[key]);
            } else if (key.indexOf("password") !== -1) {
              object[key] = '';
            } 
          });

          // get columns of table and the sequence
          getColumnsByTable(tableSlug).then(columnsResult => {
            const tableColumns = columnsResult.data;
            let problemId = null;
            let jobId = null;

            tableColumns.forEach(tableColumn => {
              tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
              tableColumn['value'] = object[tableColumn['column_name']];
              const tableColumnColumnName = tableColumn['column_name'];

              for (let i = 0; i < schemaRows.length; i += 1) {
                const schemaColumnName = schemaRows[i]['column_name'];
                if (schemaColumnName === tableColumnColumnName) {
                  switch (schemaRows[i]['data_type']) {
                    case 'boolean':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      }
                      tableColumn['input_type'] = 'checkbox';
                      break;
                    case 'integer':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } 

                      if (tableColumn['reference'] !== null) {
                        tableColumn['input_type'] = 'combobox';
                        if (tableColumn['value'] === '' || tableColumn['value'] === null) {
                          tableColumn['reference_synced'] = true;
                        } else {
                          getObject('tableAndColumns', tableColumn['reference']['edit']).then(referenced => {
                            tableColumn['referencedColumnName'] = referenced.data.column_name;
                            tableColumn['referencedTableSlug'] = referenced.data.table_slug;

                            getObject('tableAndColumns', tableColumn['reference']['store']).then(storeAs => {
                              // get all values
                              tableColumn['storeAsColumnName'] = storeAs.data.column_name;
                              tableColumn['storeAsColumnValue'] = tableColumn['value'];
                            });
                          });

                          tableColumn['reference_synced'] = false;
                        }
                      } else {
                        tableColumn['input_type'] = 'number';
                      }
                      break;
                    case 'double precision':
                      tableColumn['input_type'] = 'float';
                      if (object[tableColumn['column_name']] !== "" && object[tableColumn['column_name']] !== null) {
                        tableColumn['value'] = parseFloat(object[tableColumn['column_name']]).toFixed(tableColumn['float_step'].split('.')[1].length);
                      }
                      break;
                    case 'character varying':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      }
                      tableColumn['input_type'] = 'text';

                      break;
                    case 'timestamp with time zone':
                      tableColumn['input_type'] = 'datetime-local';
                      if (tableColumn['value'] === null || tableColumn['value'] === '') {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                    case 'date':
                      tableColumn['input_type'] = 'date';
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                  }
                  tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                  break;
                }
              }

              if (tableColumnColumnName.indexOf("password") !== -1) {
                tableColumn['is_nullable'] = true;
                tableColumn['input_type'] = 'password';
              } else if (tableColumnColumnName === 'id') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
              } else if (tableColumnColumnName === 'tableAndColumn_id') {
                tableColumn['is_nullable'] = true;
                tableColumn['value'] = object['tableAndColumns'];
              } else if (tableColumnColumnName === 'job_id') {
                jobId = tableColumn.value;
              } else if (tableColumnColumnName === 'problem_id') {
                problemId = tableColumn.value;
              } else if (tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
                if (tableColumn['value'] !== '' && tableColumn['value'] !== null) {
                  tableColumn['value'] = format(new Date(tableColumn['value']), 'yyyy-MMM-dd HH:mm');
                }
              } else if (tableColumn.dropdowns.length > 0) {
                tableColumn.dropdowns.unshift({
                  dropdown_item: '-'
                });
                if (tableColumn['value'] === null) {
                  tableColumn['value'] = '-';
                }
              } else if (tableColumnColumnName === 'problem_clan_checkbox') {
              }
            });
            
            if (tableSlug === 'ordinary-attachments') {
              tableColumns.splice(6, 0, 
                {
                  column_name: 'employer_name',
                  input_type: 'text',
                  is_nullable: true,
                  label_name: 'Employer',
                  dropdowns: [],
                  job_id: jobId,
                  privilege: 'Yes',
                  remarks: null,
                  sequence: 7,
                  value: '',
                  reference: null
                },
                {
                  column_name: 'chief_problem',
                  input_type: 'text',
                  is_nullable: true,
                  label_name: 'Chief problem',
                  dropdowns: [],
                  problem_id: problemId,
                  privilege: 'Yes',
                  remarks: null,
                  sequence: 8,
                  value: '',
                  reference: null
                }
              );

              for (let i = 8; i < tableColumns.length; i += 1) {
                tableColumns[i].sequence = i + 1;
              }
            } else if (tableSlug === 'sensitive-attachments') {
              const taggedToUsers = {};

              if (objectResult.data.taggedUsers) {
                objectResult.data.taggedUsers.forEach((row) => {
                  taggedToUsers[row.user_username] = true;
                });
              }

              tableColumns.splice(6, 0, 
                {
                  column_name: 'tag_user',
                  input_type: 'combobox',
                  is_nullable: true,
                  dropdowns: [],
                  label_name: 'Add a tagged user',
                  privilege: 'Yes',
                  reference: {
                    add: 178,
                    view: 178,
                    edit: 178,
                    store: 171
                  },
                  records: [],
                  remarks: "NULL",
                  sequence: 7,
                  table_slug: tableSlug,
                  value: ''
                },
                {
                  column_name: 'tagged_users',
                  input_type: 'list',
                  label_name: 'Tagged users (they can view and edit)',
                  privilege: 'View',
                  sequence: 8,
                  value: taggedToUsers
                },
                {
                  column_name: 'employer_name',
                  input_type: 'text',
                  is_nullable: true,
                  label_name: 'Employer',
                  dropdowns: [],
                  job_id: jobId,
                  privilege: 'Yes',
                  remarks: null,
                  sequence: 9,
                  value: '',
                  reference: null
                },
                {
                  column_name: 'chief_problem',
                  input_type: 'text',
                  is_nullable: true,
                  label_name: 'Chief problem',
                  dropdowns: [],
                  problem_id: problemId,
                  privilege: 'Yes',
                  remarks: null,
                  sequence: 10,
                  value: '',
                  reference: null
                }
              );

              for (let i = 10; i < tableColumns.length; i += 1) {
                tableColumns[i].sequence = i + 1;
              }
              
            } else if (tableSlug === 'sensitive-discussions') {
              const copiedToWorkers = [];

              objectResult.data.copiedTo.forEach((row) => {
                copiedToWorkers.push([`${row.problem.job.worker.twid} ${row.problem.job.worker.name_of_worker}`, row.problem.job.employer_name, row.problem.chief_problem, `/workers/${row.problem.job.worker.id}/jobs/${row.problem.job.id}/problems/${row.problem.id}/${tableSlug}/${row.id}`]);
              });

              const taggedToUsers = {};

              if (objectResult.data.taggedUsers) {
                objectResult.data.taggedUsers.forEach((row) => {
                  taggedToUsers[row.user_username] = true;
                });
              }

              if (copiedToWorkers.length > 0) {
                tableColumns.splice(17, 0, 
                  {
                    column_name: 'tagged_workers',
                    input_type: 'list',
                    label_name: 'Tagged workers (copied to their records)',
                    privilege: 'View',
                    sequence: 18,
                    value: copiedToWorkers
                  },
                  {
                    column_name: 'tag_user',
                    input_type: 'combobox',
                    is_nullable: true,
                    dropdowns: [],
                    label_name: 'Add a tagged user',
                    privilege: 'Yes',
                    reference: {
                      add: 178,
                      view: 178,
                      edit: 178,
                      store: 171
                    },
                    records: [],
                    remarks: "NULL",
                    sequence: 19,
                    table_slug: tableSlug,
                    value: ''
                  },
                  {
                    column_name: 'tagged_users',
                    input_type: 'list',
                    label_name: 'Tagged users (they can view and edit)',
                    privilege: 'View',
                    sequence: 20,
                    value: taggedToUsers
                  }
                );

                for (let i = 20; i < tableColumns.length; i += 1) {
                  tableColumns[i].sequence = i + 1;
                }
              } else {
                tableColumns.splice(17, 0, 
                  {
                    column_name: 'tag_user',
                    input_type: 'combobox',
                    is_nullable: true,
                    dropdowns: [],
                    label_name: 'Add a tagged user',
                    privilege: 'Yes',
                    reference: {
                      add: 178,
                      view: 178,
                      edit: 178,
                      store: 171
                    },
                    records: [],
                    remarks: "NULL",
                    sequence: 18,
                    table_slug: tableSlug,
                    value: ''
                  },
                  {
                    column_name: 'tagged_users',
                    input_type: 'list',
                    label_name: 'Tagged users (they can view and edit)',
                    privilege: 'View',
                    sequence: 19,
                    value: taggedToUsers
                  }
                );

                for (let i = 19; i < tableColumns.length; i += 1) {
                  tableColumns[i].sequence = i + 1;
                }
              }
            } else if (tableSlug === 'ordinary-case-discussions') {
              const copiedToWorkers = [];

              objectResult.data.copiedTo.forEach((row) => {
                copiedToWorkers.push([`${row.problem.job.worker.twid} ${row.problem.job.worker.name_of_worker}`, row.problem.job.employer_name, row.problem.chief_problem, `/workers/${row.problem.job.worker.id}/jobs/${row.problem.job.id}/problems/${row.problem.id}/${tableSlug}/${row.id}`]);
              });

              if (copiedToWorkers.length > 0) {
                tableColumns.splice(17, 0, 
                  {
                    column_name: 'tagged_workers',
                    input_type: 'list',
                    label_name: 'Tagged workers (copied to their records)',
                    privilege: 'View',
                    sequence: 18,
                    value: copiedToWorkers
                  }
                );

                for (let i = 18; i < tableColumns.length; i += 1) {
                  tableColumns[i].sequence = i + 1;
                }
              }
            } else if (tableSlug === 'hearings-progress') {
              const copiedToWorkers = [];

              objectResult.data.copiedTo.forEach((row) => {
                copiedToWorkers.push([`${row.problem.job.worker.twid} ${row.problem.job.worker.name_of_worker}`, row.problem.job.employer_name, row.problem.chief_problem, `/workers/${row.problem.job.worker.id}/jobs/${row.problem.job.id}/problems/${row.problem.id}/${tableSlug}/${row.id}`]);
              });

              if (copiedToWorkers.length > 0) {
                tableColumns.splice(14, 0, 
                  {
                    column_name: 'tagged_workers',
                    input_type: 'list',
                    label_name: 'Tagged workers (copied to their records)',
                    privilege: 'View',
                    sequence: 15,
                    value: copiedToWorkers
                  }
                );

                for (let i = 15; i < tableColumns.length; i += 1) {
                  tableColumns[i].sequence = i + 1;
                }
              }
            }
            
            resolve(tableColumns);
          });
        });
      });
    });
  } else if (mode === 'Assign') {
    return new Promise ((resolve, reject) => {
      getSchemaByTable(tableSlug).then(schemasResult => {
        const schemaRows = schemasResult.data;
        
        getObject(tableSlug, objectId).then(objectResult => {
          const object = objectResult.data;
          
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          Object.keys(object).forEach((key, index) => {
            if (String(object[key]).match(timestampRegex)) {
              object[key] = new Date(object[key])
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = new Date(object[key])
            } else if (key.indexOf("password") !== -1) {
              object[key] = '';
            } 
          });

          // get columns of table and the sequence
          getColumnsByTable(tableSlug).then(columnsResult => {
            const tableColumns = columnsResult.data;
            let workerIndex = -1;

            tableColumns.forEach((tableColumn, index) => {
              tableColumn['privilege'] = privileges['Edit'][tableColumn['column_name']];
              tableColumn['value'] = object[tableColumn['column_name']];
              const tableColumnColumnName = tableColumn['column_name'];

              for (let i = 0; i < schemaRows.length; i += 1) {
                const schemaColumnName = schemaRows[i]['column_name'];
                if (schemaColumnName === tableColumnColumnName) {
                  switch (schemaRows[i]['data_type']) {
                    case 'boolean':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      }
                      tableColumn['input_type'] = 'checkbox';
                      break;
                    case 'integer':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } 

                      if (tableColumn['reference'] !== null) {
                        tableColumn['input_type'] = 'combobox';
                        if (tableColumn['value'] === '' || tableColumn['value'] === null) {
                          tableColumn['reference_synced'] = true;
                        } else {
                          getObject('tableAndColumns', tableColumn['reference']['edit']).then(referenced => {
                            tableColumn['referencedColumnName'] = referenced.data.column_name;
                            tableColumn['referencedTableSlug'] = referenced.data.table_slug;

                            getObject('tableAndColumns', tableColumn['reference']['store']).then(storeAs => {
                              // get all values
                              tableColumn['storeAsColumnName'] = storeAs.data.column_name;
                              tableColumn['storeAsColumnValue'] = tableColumn['value'];
                            });
                          });

                          tableColumn['reference_synced'] = false;
                        }
                      } else {
                        tableColumn['input_type'] = 'number';
                      }
                      break;
                    case 'double precision':
                      tableColumn['input_type'] = 'float';
                      tableColumn['value'] = parseFloat(object[tableColumn['column_name']]).toFixed(tableColumn['float_step'].split('.')[1].length);
                      break;
                    case 'character varying':
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      }
                      tableColumn['input_type'] = 'text';
                      break;
                    case 'timestamp with time zone':
                      tableColumn['input_type'] = 'datetime-local';
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                    case 'date':
                      tableColumn['input_type'] = 'date';
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                  }
                  tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                  break;
                }
              }

              if (tableColumnColumnName.indexOf("password") !== -1) {
                tableColumn['is_nullable'] = true;
                tableColumn['input_type'] = 'password';
              } else if (tableColumnColumnName === 'id') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
              } else if (tableColumnColumnName === 'tableAndColumn_id') {
                tableColumn['is_nullable'] = true;
                tableColumn['value'] = object['tableAndColumns'];
              } else if (tableColumnColumnName === 'job_id') {
                jobId = tableColumn.value;
              } else if (tableColumnColumnName === 'problem_id') {
                problemId = tableColumn.value;
              } else if (tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
                format(new Date(tableColumn['value']), 'yyyy-MMM-dd HH:mm')
                tableColumn['value'] = format(new Date(tableColumn['value']), 'yyyy-MMM-dd HH:mm');
              } else if (tableColumn.dropdowns.length > 0) {
                tableColumn.dropdowns.unshift({
                  dropdown_item: '-'
                });
                if (tableColumn['value'] === null) {
                  tableColumn['value'] = '-';
                }
              } else if (tableColumnColumnName === 'problem_clan_checkbox') {
              } else if (tableColumnColumnName === 'worker_id') {
                workerIndex = 0;
              }
            });

            const addedOnTaggedSection = [
              {
              column_name: 'tag_worker',
              input_type: 'combobox',
              is_nullable: true,
              dropdowns: [],
              label_name: 'Assign to worker',
              privilege: 'Yes',
              reference: {
                add: 5,
                view: 5,
                edit: 5,
                store: 1
              },
              records: [],
              remarks: "NULL",
              sequence: workerIndex + 1,
              table_slug: tableSlug,
              value: ''
              },
              {
              column_name: 'tagged_workers',
              input_type: 'list',
              is_nullable: true,
              dropdowns: [],
              label_name: 'Assigned worker (copied to their records)',
              privilege: 'Yes',
              reference: null,
              records: [],
              remarks: "NULL",
              sequence: workerIndex + 2,
              table_slug: tableSlug,
              value: ''
              }
            ];  

            let updatedSequencedTableColumns = tableColumns.slice(workerIndex);
            updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = workerIndex + 3 + index);

            resolve(tableColumns.slice(0, workerIndex).concat(addedOnTaggedSection, updatedSequencedTableColumns));
          });
        });
      });
    });
  }
};

const prepareDefaultTableColumnsWithPrivilegeAndInputType = (tableSlug, defaultTableSlug, privileges) => {
  const mode = 'Add';

  return new Promise ((resolve, reject) => {
    getSchemaByTable(tableSlug).then(schemasResult => {
      const schemaRows = schemasResult.data;

      getObject(defaultTableSlug, 0).then(objectResult => {
        const object = objectResult.data;
        
        const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        Object.keys(object).forEach((key, index) => {
          if (String(object[key]).match(timestampRegex)) {
            // object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
          } else if (String(object[key]).match(dateRegex)) {
            // object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
          } else if (key.indexOf("password") !== -1) {
            object[key] = '********';
          } else if (key === 'creator') {
            // object['created_by'] = object[key]['user_username'];
          } 
        });

        // get columns of table and the sequence
        getColumnsByTable(tableSlug).then(columnsResult => {
          const tableColumns = columnsResult.data;

          tableColumns.forEach(tableColumn => {
            tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
            tableColumn['value'] = object[tableColumn['column_name']];
            const tableColumnColumnName = tableColumn['column_name'];

            for (let i = 0; i < schemaRows.length; i += 1) {
              const schemaColumnName = schemaRows[i]['column_name'];
              if (schemaColumnName === tableColumnColumnName) {
                switch (schemaRows[i]['data_type']) {
                  case 'boolean':
                    if (tableColumn['value'] === null) {
                      tableColumn['value'] = '';
                    }
                    tableColumn['input_type'] = 'checkbox';
                    break;
                  case 'integer':
                    if (tableColumn['value'] === null) {
                      tableColumn['value'] = '';
                    }
                    tableColumn['input_type'] = 'number';
                    break;
                  case 'double precision':
                    tableColumn['input_type'] = 'float';
                    break;
                  case 'character varying':
                    if (tableColumn['value'] === null) {
                      tableColumn['value'] = '';
                    }
                    tableColumn['input_type'] = 'text';
                    break;
                  case 'timestamp with time zone':
                    tableColumn['input_type'] = 'datetime-local';
                    if (tableColumn['value'] === null) {
                      tableColumn['value'] = '';
                    } else {
                      tableColumn['value'] = new Date(tableColumn['value']);
                    }
                    break;
                  case 'date':
                    tableColumn['input_type'] = 'date';
                    if (tableColumn['value'] === null) {
                      tableColumn['value'] = '';
                    } else {
                      tableColumn['value'] = new Date(tableColumn['value']);
                    }
                    break;
                }
                tableColumn['is_nullable'] = true;

                break;
              }
            }

            if (tableColumnColumnName.indexOf("password") !== -1) {
              tableColumn['input_type'] = 'password';
            } else if (tableColumnColumnName === 'id') {
              tableColumn['input_type'] = 'text';
              tableColumn['is_nullable'] = true;
              tableColumn['value'] = 'Auto-generated';
            } else if (tableColumnColumnName === 'worker_id' || tableColumnColumnName === 'job_id' || tableColumnColumnName === 'path_current_pdpa'|| tableColumnColumnName === 'problem_id' || tableColumnColumnName === 'path_current_pdpa' || tableColumnColumnName === 'path_current_facepic' || tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
              tableColumn['input_type'] = 'text';
              tableColumn['is_nullable'] = true;
              tableColumn['value'] = 'Auto-generated';
            } else if (tableColumnColumnName === 'tableAndColumn_id') {
              tableColumn['is_nullable'] = true;
              tableColumn['value'] = object['tableAndColumns'];
            } else if (tableColumn.dropdowns.length > 0) {
              tableColumn.dropdowns.unshift({
                dropdown_item: '-'
              });
            }
          });

          resolve(tableColumns);
        });
      });
    });
  });
};

const prepareTableColumnsWithPrivilegeAndInputType2 = (tableSlug, privileges, mode, object) => {
  if (mode === 'Add') {
    return new Promise ((resolve, reject) => {
      getSchemaByTable(tableSlug).then(schemasResult => {
        const schemaRows = schemasResult.data;

        // get columns of table and the sequence
        getColumnsByTable(tableSlug).then(columnsResult => {
          const tableColumns = columnsResult.data;

          tableColumns.forEach(tableColumn => {
            tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
            tableColumn['value'] = '';
            
            const tableColumnColumnName = tableColumn['column_name'];
            
            for (let i = 0; i < schemaRows.length; i += 1) {
              const schemaColumnName = schemaRows[i]['column_name'];
              if (schemaColumnName === tableColumnColumnName) {
                switch (schemaRows[i]['data_type']) {
                  case 'boolean':
                    tableColumn['input_type'] = 'checkbox';
                    tableColumn['value'] = false;
                    break;
                  case 'integer':
                    tableColumn['input_type'] = 'number';
                    break;
                  case 'double precision':
                    tableColumn['input_type'] = 'float';
                    break;
                  case 'character varying':
                    tableColumn['input_type'] = 'text';
                    break;
                  case 'timestamp with time zone':
                    tableColumn['input_type'] = 'datetime-local';
                    break;
                  case 'date':
                    tableColumn['input_type'] = 'date';
                    break;
                }
                tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                break;
              }
            }
            
            if (tableColumnColumnName.indexOf("password") !== -1) {
              tableColumn['input_type'] = 'password';
            } else if (tableColumnColumnName === 'id' || tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
              tableColumn['input_type'] = 'text';
              tableColumn['value'] = 'Auto-generated';
              tableColumn['is_nullable'] = true;
            } else if (tableColumnColumnName === 'tableAndColumn_id') {
              tableColumn['value'] = [];
              tableColumn['is_nullable'] = true;
            } else if (tableColumn['dropdowns'].length > 0) {
              tableColumn['dropdowns'].unshift({
                dropdown_item: '-'
              });
            } 
          });

          resolve(tableColumns);
        });
      });
    });
  } else if (mode === 'Edit') {
    if (tableSlug === 'workers') {
      return new Promise ((resolve, reject) => {
        getSchemaByTable(tableSlug).then(schemasResult => {
          const schemaRows = schemasResult.data;
          
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          Object.keys(object).forEach((key, index) => {
            if (String(object[key]).match(timestampRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
            } else if (key.indexOf("password") !== -1) {
              object[key] = '********';
            } else if (key === 'creator') {
              // object['created_by'] = object[key]['user_username'];
            } 
            // else if (key === 'twid') {
            //   object['twid'] = `${object['twid'].substring(0, 4)} ${object['twid'].substring(4, 6)} ${object['twid'].substring(6, 8)}`;
            // }
          });

          // get columns of table and the sequence
          getColumnsByTable(tableSlug).then(columnsResult => {
            const tableColumns = columnsResult.data;

            tableColumns.forEach(tableColumn => {
              tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
              tableColumn['value'] = object[tableColumn['column_name']];
              const tableColumnColumnName = tableColumn['column_name'];

              for (let i = 0; i < schemaRows.length; i += 1) {
                const schemaColumnName = schemaRows[i]['column_name'];
                if (schemaColumnName === tableColumnColumnName) {
                  switch (schemaRows[i]['data_type']) {
                    case 'boolean':
                      tableColumn['input_type'] = 'checkbox';
                      break;
                    case 'integer':
                      tableColumn['input_type'] = 'number';
                      break;
                    case 'double precision':
                      tableColumn['input_type'] = 'float';
                      break;
                    case 'character varying':
                      tableColumn['input_type'] = 'text';
                      break;
                    case 'timestamp with time zone':
                      tableColumn['input_type'] = 'datetime-local';
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                    case 'date':
                      tableColumn['input_type'] = 'date';
                      if (tableColumn['value'] === null) {
                        tableColumn['value'] = '';
                      } else {
                        tableColumn['value'] = new Date(tableColumn['value']);
                      }
                      break;
                  }
                  tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                  break;
                }
              }

              if (tableColumnColumnName.indexOf("password") !== -1) {
                tableColumn['input_type'] = 'password';
              } else if (tableColumnColumnName === 'id') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
              } else if (tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
                tableColumn['value'] = format(new Date(tableColumn['value']), 'yyyy-MMM-dd HH:mm');
              } else if (tableColumnColumnName === 'tableAndColumn_id') {
                tableColumn['is_nullable'] = true;
                tableColumn['value'] = object['tableAndColumns'];
              } else if (tableColumn.dropdowns.length > 0) {
                tableColumn.dropdowns.unshift({
                  dropdown_item: '-'
                });
              }
            });

            tableColumns.push({
              associations: {
                twid_cards: object['twidCards'], 
                singapore_phone_numbers: object['sgPhoneNumbers'],
                digital_contacts: object['digitalContacts'],
                home_country_phone_numbers: object['homeCountryPhoneNumbers'],
                ticket_numbers: object['ticketNumbers'],
                injuries: object['injuries'],
                aggravating_issues: object['aggravatingIssues'],
                lead_case_workers: object['leadCaseWorkers'],
                auxiliary_case_workers: object['auxiliaryCaseWorkers'],
                interpreters: object['interpreters'],
                nicknames: object['nicknames'],
                passports: object['passports'],
                addresses_in_singapore: object['sgAddresses'],
                home_country_addresses: object['homeCountryAddresses'],
                next_of_kins: object['nextOfKins'],
                facepic_attachments: object['facepicAttachments'],
                pdpa_attachments: object['pdpaAttachments'],
                families_in_singapore: object['familyMembers'],
                friends_in_singapore: object['sgFriends'],
                languages: object['languages'],
                bank_accounts: object['bankAccounts'],
                client_appointments: object['clientAppointments'],
                jobs: object['jobs'],
                problems: object['problems']
              }
            })

            resolve(tableColumns);
          });
        });
      });
    } else {
      return new Promise ((resolve, reject) => {
        getSchemaByTable(tableSlug).then(schemasResult => {
          const schemaRows = schemasResult.data;
            
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          Object.keys(object).forEach((key, index) => {
            if (String(object[key]).match(timestampRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
            } else if (key.indexOf("password") !== -1) {
              object[key] = '********';
            } else if (key === 'creator') {
              // object['created_by'] = object[key]['user_username'];
            }
          });

          // get columns of table and the sequence
          getColumnsByTable(tableSlug).then(columnsResult => {
            const tableColumns = columnsResult.data;

            tableColumns.forEach(tableColumn => {
              tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
              tableColumn['value'] = object[tableColumn['column_name']];
              const tableColumnColumnName = tableColumn['column_name'];

              for (let i = 0; i < schemaRows.length; i += 1) {
                const schemaColumnName = schemaRows[i]['column_name'];
                if (schemaColumnName === tableColumnColumnName) {
                  switch (schemaRows[i]['data_type']) {
                    case 'boolean':
                      tableColumn['input_type'] = 'checkbox';
                      break;
                    case 'integer':
                      tableColumn['input_type'] = 'number';
                      break;
                    case 'double precision':
                      tableColumn['input_type'] = 'float';
                      break;
                    case 'character varying':
                      tableColumn['input_type'] = 'text';
                      break;
                    case 'timestamp with time zone':
                      tableColumn['input_type'] = 'datetime-local';
                      break;
                    case 'date':
                      tableColumn['input_type'] = 'date';
                      break;
                  }
                  tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                  break;
                }
              }

              if (tableColumnColumnName.indexOf("password") !== -1) {
                tableColumn['input_type'] = 'password';
              } else if (tableColumnColumnName === 'id') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
              } else if (tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
                tableColumn['input_type'] = 'text';
                tableColumn['is_nullable'] = true;
              } else if (tableColumnColumnName === 'tableAndColumn_id') {
                tableColumn['is_nullable'] = true;
                tableColumn['value'] = object['tableAndColumns'];
              } else if (tableColumn.dropdowns.length > 0) {
                tableColumn.dropdowns.unshift({
                  dropdown_item: '-'
                });
              }
            });

            resolve(tableColumns);
          });
        });
      });
    }
  }
};

const prepareSubsidiaryTableColumnsWithPrivilegeAndInputType = (subsidiarySlug, privileges, mode, workerSubsidiaryId, workerId) => {
  if (mode === 'Add') {
    return new Promise ((resolve, reject) => {
      getSchemaByTable(subsidiarySlug).then(schemasResult => {
        const schemaRows = schemasResult.data;

        // get columns of table and the sequence
        getColumnsByTable(subsidiarySlug).then(columnsResult => {
          const tableColumns = columnsResult.data;

          tableColumns.forEach(tableColumn => {
            tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
            tableColumn['value'] = '';
            
            const tableColumnColumnName = tableColumn['column_name'];
            
            for (let i = 0; i < schemaRows.length; i += 1) {
              const schemaColumnName = schemaRows[i]['column_name'];
              if (schemaColumnName === tableColumnColumnName) {
                switch (schemaRows[i]['data_type']) {
                  case 'boolean':
                    tableColumn['input_type'] = 'checkbox';
                    tableColumn['value'] = false;
                    break;
                  case 'integer':
                    tableColumn['input_type'] = 'number';
                    break;
                  case 'double precision':
                    tableColumn['input_type'] = 'float';
                    break;
                  case 'character varying':
                    tableColumn['input_type'] = 'text';
                    break;
                  case 'timestamp with time zone':
                    tableColumn['input_type'] = 'datetime-local';
                    break;
                  case 'date':
                    tableColumn['input_type'] = 'date';
                    break;
                }
                tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;

                break;
              }
            }
            
            if (tableColumnColumnName.indexOf("password") !== -1) {
              tableColumn['input_type'] = 'password';
            } else if (tableColumnColumnName === 'id' || tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
              tableColumn['input_type'] = 'text';
              tableColumn['value'] = 'Auto-generated';
              tableColumn['is_nullable'] = true;
            } else if (tableColumnColumnName === 'tableAndColumn_id') {
              tableColumn['value'] = [];
              tableColumn['is_nullable'] = true;
            } else if (tableColumn['dropdowns'].length > 0) {
              tableColumn['dropdowns'].unshift({
                dropdown_item: '-'
              });
            } else if (tableColumnColumnName === 'worker_id') {
              tableColumn['value'] = workerId;
            }
          });

          if (subsidiarySlug === 'pdpa-attachments' || subsidiarySlug === 'facepic-attachments') {
            tableColumns.unshift({
              column_name: 'upload',
              input_type: 'file',
              is_nullable: false,
              label_name: 'Upload',
              dropdowns: [],
              privilege: 'Yes',
              remarks: null,
              sequence: 0,
              value: ''
            });
          }

          resolve(tableColumns);
        });
      });
    });
  } else if (mode === 'Edit') {
    return new Promise ((resolve, reject) => {
        getSchemaByTable(subsidiarySlug).then(schemasResult => {
          const schemaRows = schemasResult.data;
          
          getObject(subsidiarySlug, workerSubsidiaryId).then(objectResult => {
            const object = objectResult.data;
            
            const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            Object.keys(object).forEach((key, index) => {
              if (String(object[key]).match(timestampRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
              } else if (String(object[key]).match(dateRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
              } else if (key.indexOf("password") !== -1) {
                object[key] = '********';
              } else if (key === 'creator') {
                // object['created_by'] = object[key]['user_username'];
              }
            });
  
            // get columns of table and the sequence
            getColumnsByTable(subsidiarySlug).then(columnsResult => {
              const tableColumns = columnsResult.data;
  
              tableColumns.forEach(tableColumn => {
                tableColumn['privilege'] = privileges[mode][tableColumn['column_name']];
                tableColumn['value'] = object[tableColumn['column_name']];
                const tableColumnColumnName = tableColumn['column_name'];
  
                for (let i = 0; i < schemaRows.length; i += 1) {
                  const schemaColumnName = schemaRows[i]['column_name'];
                  if (schemaColumnName === tableColumnColumnName) {
                    switch (schemaRows[i]['data_type']) {
                      case 'boolean':
                        tableColumn['input_type'] = 'checkbox';
                        break;
                      case 'integer':
                        tableColumn['input_type'] = 'number';
                        break;
                      case 'double precision':
                        tableColumn['input_type'] = 'float';
                        break;
                      case 'character varying':
                        tableColumn['input_type'] = 'text';
                        break;
                      case 'timestamp with time zone':
                        tableColumn['input_type'] = 'datetime-local';
                        if (tableColumn['value'] === null) {
                          tableColumn['value'] = '';
                        } else {
                          tableColumn['value'] = new Date(tableColumn['value']);
                        }
                        break;
                      case 'date':
                        tableColumn['input_type'] = 'date';
                        if (tableColumn['value'] === null) {
                          tableColumn['value'] = '';
                        } else {
                          tableColumn['value'] = new Date(tableColumn['value']);
                        }
                        break;
                    }
                    tableColumn['is_nullable'] = schemaRows[i]['is_nullable'] === 'NO' ? false : true;
  
                    break;
                  }
                }
  
                if (tableColumnColumnName.indexOf("password") !== -1) {
                  tableColumn['input_type'] = 'password';
                } else if (tableColumnColumnName === 'id') {
                  tableColumn['input_type'] = 'text';
                  tableColumn['is_nullable'] = true;
                } else if (tableColumnColumnName === 'date_record_created' || tableColumnColumnName === 'date_last_updated' || tableColumnColumnName === 'datetime_last_login') {
                  tableColumn['input_type'] = 'text';
                  tableColumn['is_nullable'] = true;
                  tableColumn['value'] = format(new Date(tableColumn['value']), 'yyyy-MMM-dd HH:mm');
                } else if (tableColumnColumnName === 'tableAndColumn_id') {
                  tableColumn['is_nullable'] = true;
                  tableColumn['value'] = object['tableAndColumns'];
                } else if (tableColumn.dropdowns.length > 0) {
                  tableColumn.dropdowns.unshift({
                    dropdown_item: '-'
                  });
                }
              });

              resolve(tableColumns);
            });
          });
        });
      });
  }
};

const prepareTableColumnsWithPrivileges = (tableSlug, privileges, mode, objectId) => {
  if (mode === 'View') {
    if (tableSlug === 'workers') {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
        getSchemaByTable(tableSlug).then(schemasResult => {
          const schemaRows = schemasResult.data;

          const dateOrDatetimeColumns = {};
          for (let i = 0; i < schemaRows.length; i += 1) {
            switch (schemaRows[i]['data_type']) {
              case 'timestamp with time zone':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
              case 'date':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
            }
          } 
          
          getObject(tableSlug, objectId).then(objectResult => {
            const object = objectResult.data;
            
            const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  
            Object.keys(object).forEach((key, index) => {
              if (dateOrDatetimeColumns[key] && String(object[key]).match(timestampRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
              } else if (dateOrDatetimeColumns[key] && String(object[key]).match(dateRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
              } else if (key.indexOf("password") !== -1) {
                object[key] = '********';
              }
            });
  
            getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
              const sequencedTableColumns = sequencedTableColumnsResult.data;
  
              sequencedTableColumns.forEach(sequencedTableColumn => {                
                if (sequencedTableColumn['reference'] !== null) {
                  let reference_synced;
                  if (object[sequencedTableColumn['column_name']] === '' || object[sequencedTableColumn['column_name']] === null) {
                    reference_synced = true;
                  } else {
                    reference_synced = false;
                  }
                  
                  tableColumns.push({
                      reference_synced: reference_synced,
                      sequence: sequencedTableColumn['sequence'],
                      reference: sequencedTableColumn['reference'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: object[sequencedTableColumn['column_name']],
                      privilege: privileges[mode][sequencedTableColumn['column_name']]
                    });
                } else {
                  if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: object['tableAndColumns'],
                      privilege: privileges[mode]['tableAndColumns']
                    });
                  } else if (sequencedTableColumn['float_step'] !== null) {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: parseFloat(object[sequencedTableColumn['column_name']]).toFixed(sequencedTableColumn['float_step'].split('.')[1].length),
                      privilege: privileges[mode][sequencedTableColumn['column_name']]
                    });
                  } else {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: object[sequencedTableColumn['column_name']],
                      privilege: privileges[mode][sequencedTableColumn['column_name']]
                    });
                  }
                }
              });
  
              tableColumns.push({
                associations: {
                  twid_cards: object['twidCards'], 
                  singapore_phone_numbers: object['sgPhoneNumbers'],
                  digital_contacts: object['digitalContacts'],
                  home_country_phone_numbers: object['homeCountryPhoneNumbers'],
                  ticket_numbers: object['ticketNumbers'],
                  injuries: object['injuries'],
                  aggravating_issues: object['aggravatingIssues'],
                  lead_case_workers: object['leadCaseWorkers'],
                  auxiliary_case_workers: object['auxiliaryCaseWorkers'],
                  interpreters: object['interpreters'],
                  nicknames: object['nicknames'],
                  passports: object['passports'],
                  addresses_in_singapore: object['sgAddresses'],
                  home_country_addresses: object['homeCountryAddresses'],
                  next_of_kins: object['nextOfKins'],
                  facepic_attachments: object['facepicAttachments'],
                  pdpa_attachments: object['pdpaAttachments'],
                  families_in_singapore: object['familyMembers'],
                  friends_in_singapore: object['sgFriends'],
                  languages: object['languages'],
                  bank_accounts: object['bankAccounts'],
                  client_appointments: object['clientAppointments'],
                  jobs: object['jobs'],
                  problems: object['problems']
                }
              });
  
              resolve(tableColumns);
            });
          });
        });
      });
    } else {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
        getSchemaByTable(tableSlug).then(schemasResult => {
          const schemaRows = schemasResult.data;

          const dateOrDatetimeColumns = {};
          for (let i = 0; i < schemaRows.length; i += 1) {
            switch (schemaRows[i]['data_type']) {
              case 'timestamp with time zone':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
              case 'date':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
            }
          } 
          
          getObject(tableSlug, objectId).then(objectResult => {
            const object = objectResult.data;
            const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  
            Object.keys(object).forEach((key, index) => {
              if (dateOrDatetimeColumns[key] && String(object[key]).match(timestampRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
              } else if (dateOrDatetimeColumns[key] && String(object[key]).match(dateRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
              } else if (key.indexOf("password") !== -1) {
                object[key] = '********';
              } else if (key === 'creator') {
                // object['created_by'] = object[key]['user_username'];
              } 
            });
  
            let counterToInsertTaggedWorkers = 0;
  
            getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
              const sequencedTableColumns = sequencedTableColumnsResult.data;
  
              sequencedTableColumns.forEach(sequencedTableColumn => {
                if (sequencedTableColumn['reference'] !== null) {
                  tableColumns.push({
                    reference_synced: false,
                    sequence: sequencedTableColumn['sequence'],
                    reference: sequencedTableColumn['reference'],
                    column_name: sequencedTableColumn['column_name'],
                    label_name: sequencedTableColumn['label_name'],
                    value: object[sequencedTableColumn['column_name']],
                    privilege: privileges[mode][sequencedTableColumn['column_name']]
                  });
                } else {
                  if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: object['tableAndColumns'],
                      privilege: privileges[mode]['tableAndColumns']
                    });
                  } else if (sequencedTableColumn['float_step'] !== null) {
                    if (object[sequencedTableColumn['column_name']] !== null) {
                      tableColumns.push({
                        sequence: sequencedTableColumn['sequence'],
                        column_name: sequencedTableColumn['column_name'],
                        label_name: sequencedTableColumn['label_name'],
                        value: parseFloat(object[sequencedTableColumn['column_name']]).toFixed(sequencedTableColumn['float_step'].split('.')[1].length),
                        privilege: privileges[mode][sequencedTableColumn['column_name']]
                      });
                    } else {
                      tableColumns.push({
                        sequence: sequencedTableColumn['sequence'],
                        column_name: sequencedTableColumn['column_name'],
                        label_name: sequencedTableColumn['label_name'],
                        value: '',
                        privilege: privileges[mode][sequencedTableColumn['column_name']]
                      });
                    }
                  } else {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: object[sequencedTableColumn['column_name']],
                      privilege: privileges[mode][sequencedTableColumn['column_name']]
                    });
                  }
                }
  
                if (sequencedTableColumn['column_name'] === 'problem_clan_copied_to') {
                  counterToInsertTaggedWorkers = sequencedTableColumn['sequence'];
                }
              });
  
              if (counterToInsertTaggedWorkers !== 0) {
                const copiedToWorkers = [];
  
                objectResult.data.copiedTo.forEach((row) => {
                  copiedToWorkers.push([`${row.problem.job.worker.twid} ${row.problem.job.worker.name_of_worker}`, row.problem.job.employer_name, row.problem.chief_problem, `/workers/${row.problem.job.worker.id}/jobs/${row.problem.job.id}/problems/${row.problem.id}/${tableSlug}/${row.id}`]);
                });
  
                const addedOnTaggedSection = [];
                
                if (copiedToWorkers.length > 0) {
                  addedOnTaggedSection.push({
                    column_name: 'tagged_workers',
                    input_type: 'list',
                    label_name: 'Tagged workers (copied to their records)',
                    privilege: 'Yes',
                    sequence: counterToInsertTaggedWorkers + 1,
                    value: copiedToWorkers
                  });
                }
  
                const taggedToUsers = [];
  
                if (objectResult.data.taggedUsers) {
                  objectResult.data.taggedUsers.forEach((row) => {
                    taggedToUsers.push([`${row.user_username}`]);
                  });
                }
  
                if (taggedToUsers.length > 0) {
                  addedOnTaggedSection.push({
                    column_name: 'tagged_users',
                    input_type: 'list',
                    label_name: 'Tagged users (they can view and edit)',
                    privilege: 'Yes',
                    sequence: counterToInsertTaggedWorkers + 2,
                    value: taggedToUsers
                  });
                }
  
                if (tableSlug === 'hearings-progress') {
                  if (addedOnTaggedSection.length !== 0) {
                    let updatedSequencedTableColumns = tableColumns.slice(counterToInsertTaggedWorkers);
                    updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = counterToInsertTaggedWorkers + 2 + index);
                    resolve(tableColumns.slice(0, counterToInsertTaggedWorkers).concat(addedOnTaggedSection, updatedSequencedTableColumns));
                  } else {
                    resolve(tableColumns);
                  }
                } else if(tableSlug === 'ordinary-case-discussions') {
                  if (addedOnTaggedSection.length !== 0) {
                    let updatedSequencedTableColumns = tableColumns.slice(counterToInsertTaggedWorkers);
                    updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = counterToInsertTaggedWorkers + 2 + index);
  
                    resolve(tableColumns.slice(0, counterToInsertTaggedWorkers).concat(addedOnTaggedSection, updatedSequencedTableColumns));
                  } else {
                    resolve(tableColumns);
                  }
                } else if(tableSlug === 'sensitive-discussions') {
                  if (addedOnTaggedSection.length !== 0) {
                    let updatedSequencedTableColumns = tableColumns.slice(counterToInsertTaggedWorkers);
                    updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = counterToInsertTaggedWorkers + (addedOnTaggedSection.length + 1) + index);
  
                    resolve(tableColumns.slice(0, counterToInsertTaggedWorkers).concat(addedOnTaggedSection, updatedSequencedTableColumns));
                  } else {
                    resolve(tableColumns);
  
                  }
                }
              } else {
                if (tableSlug === 'sensitive-attachments') {
                  const addedOnTaggedSection = [];
  
                  const taggedToUsers = [];
  
                  if (objectResult.data.taggedUsers) {
                    objectResult.data.taggedUsers.forEach((row) => {
                      taggedToUsers.push([`${row.user_username}`]);
                    });
                  }
  
                  if (taggedToUsers.length > 0) {
                    addedOnTaggedSection.push({
                      column_name: 'tagged_users',
                      input_type: 'list',
                      label_name: 'Tagged users (they can view and edit)',
                      privilege: 'Yes',
                      sequence: 7,
                      value: taggedToUsers
                    });
                  }
                  
                  if (addedOnTaggedSection.length !== 0) {
                    let updatedSequencedTableColumns = tableColumns.slice(6);
                    updatedSequencedTableColumns.forEach((tableColumnRow, index) => updatedSequencedTableColumns[index]['sequence'] = 5 + (addedOnTaggedSection.length + 1) + index);
  
                    resolve(tableColumns.slice(0, 6).concat(addedOnTaggedSection, updatedSequencedTableColumns));
                  } else {
                    resolve(tableColumns);
                  }
                } else {
                  resolve(tableColumns);
                }
              }
            });
          });
        });
      });
    }
  } else if (mode === 'List') {
    if (tableSlug === 'workers') {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
        getSchemaByTable(tableSlug).then(schemasResult => {
          const schemaRows = schemasResult.data;

          const dateOrDatetimeColumns = {};
          for (let i = 0; i < schemaRows.length; i += 1) {
            switch (schemaRows[i]['data_type']) {
              case 'timestamp with time zone':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
              case 'date':
                dateOrDatetimeColumns[schemaRows[i]['column_name']] = true;
                break;
            }
          } 
          
          getObject(tableSlug, objectId).then(objectResult => {
            const object = objectResult.data;
            
            const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  
            Object.keys(object).forEach((key, index) => {
              if (dateOrDatetimeColumns[key] && String(object[key]).match(timestampRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
              } else if (dateOrDatetimeColumns[key] && String(object[key]).match(dateRegex)) {
                object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
              } else if (key.indexOf("password") !== -1) {
                object[key] = '********';
              } else if (key === 'creator') {
                // object['created_by'] = object[key]['user_username'];
              }
            });
  
            getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
              const sequencedTableColumns = sequencedTableColumnsResult.data;
  
              sequencedTableColumns.forEach(sequencedTableColumn => {
                if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
                  tableColumns.push({
                    sequence: sequencedTableColumn['sequence'],
                    column_name: sequencedTableColumn['column_name'],
                    label_name: sequencedTableColumn['label_name'],
                    value: object['tableAndColumns'],
                    privilege: privileges[mode]['tableAndColumns']
                  });
                } else if (sequencedTableColumn['float_step'] !== null) {
                    tableColumns.push({
                      sequence: sequencedTableColumn['sequence'],
                      column_name: sequencedTableColumn['column_name'],
                      label_name: sequencedTableColumn['label_name'],
                      value: parseFloat(object[sequencedTableColumn['column_name']]).toFixed(sequencedTableColumn['float_step'].split('.')[1].length),
                      privilege: privileges[mode][sequencedTableColumn['column_name']]
                    });
                } else {
                  tableColumns.push({
                    sequence: sequencedTableColumn['sequence'],
                    column_name: sequencedTableColumn['column_name'],
                    label_name: sequencedTableColumn['label_name'],
                    value: object[sequencedTableColumn['column_name']],
                    privilege: privileges[mode][sequencedTableColumn['column_name']]
                  });
                }
              });
  
              tableColumns.push({
                associations: {
                  twid_cards: object['twidCards'], 
                  singapore_phone_numbers: object['sgPhoneNumbers'],
                  digital_contacts: object['digitalContacts'],
                  home_country_phone_numbers: object['homeCountryPhoneNumbers'],
                  ticket_numbers: object['ticketNumbers'],
                  injuries: object['injuries'],
                  aggravating_issues: object['aggravatingIssues'],
                  lead_case_workers: object['leadCaseWorkers'],
                  auxiliary_case_workers: object['auxiliaryCaseWorkers'],
                  interpreters: object['interpreters'],
                  nicknames: object['nicknames'],
                  passports: object['passports'],
                  addresses_in_singapore: object['sgAddresses'],
                  home_country_addresses: object['homeCountryAddresses'],
                  next_of_kins: object['nextOfKins'],
                  facepic_attachments: object['facepicAttachments'],
                  pdpa_attachments: object['pdpaAttachments'],
                  families_in_singapore: object['familyMembers'],
                  friends_in_singapore: object['sgFriends'],
                  languages: object['languages'],
                  bank_accounts: object['bankAccounts'],
                  client_appointments: object['clientAppointments'],
                  jobs: object['jobs'],
                  problems: object['problems']
                }
              })
  
              resolve(tableColumns);
            });
          });
        });
      });
    }
  }
};

const prepareTableColumnsWithPrivileges2 = (tableSlug, privileges, mode, object) => {
  if (mode === 'View') {
    if (tableSlug === 'workers') {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
          
        const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        Object.keys(object).forEach((key, index) => {
          if (String(object[key]).match(timestampRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
            } else if (key.indexOf("password") !== -1) {
            object[key] = '********';
          } else if (key === 'creator') {
            // object['created_by'] = object[key]['user_username'];
          }
        });

        getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
          const sequencedTableColumns = sequencedTableColumnsResult.data;

          sequencedTableColumns.forEach(sequencedTableColumn => {
            if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object['tableAndColumns'],
                privilege: privileges[mode]['tableAndColumns']
              });
            } else {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object[sequencedTableColumn['column_name']],
                privilege: privileges[mode][sequencedTableColumn['column_name']]
              });
            }
          });

          tableColumns.push({
            associations: {
              twid_cards: object['twidCards'], 
              singapore_phone_numbers: object['sgPhoneNumbers'],
              digital_contacts: object['digitalContacts'],
              home_country_phone_numbers: object['homeCountryPhoneNumbers'],
              ticket_numbers: object['ticketNumbers'],
              injuries: object['injuries'],
              aggravating_issues: object['aggravatingIssues'],
              lead_case_workers: object['leadCaseWorkers'],
              auxiliary_case_workers: object['auxiliaryCaseWorkers'],
              interpreters: object['interpreters'],
              nicknames: object['nicknames'],
              passports: object['passports'],
              addresses_in_singapore: object['sgAddresses'],
              home_country_addresses: object['homeCountryAddresses'],
              next_of_kins: object['nextOfKins'],
              facepic_attachments: object['facepicAttachments'],
              pdpa_attachments: object['pdpaAttachments'],
              families_in_singapore: object['familyMembers'],
              friends_in_singapore: object['sgFriends'],
              languages: object['languages'],
              bank_accounts: object['bankAccounts'],
              client_appointments: object['clientAppointments'],
              jobs: object['jobs'],
              problems: object['problems']
            }
          })

          resolve(tableColumns);
        });
      });
    } else {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
        const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
        Object.keys(object).forEach((key, index) => {
          if (String(object[key]).match(timestampRegex)) {
            object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
          } else if (key.indexOf("password") !== -1) {
            object[key] = '********';
          } else if (key === 'creator') {
            // object['created_by'] = object[key]['user_username'];
          } 
        });

        getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
          const sequencedTableColumns = sequencedTableColumnsResult.data;

          sequencedTableColumns.forEach(sequencedTableColumn => {
            if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object['tableAndColumns'],
                privilege: privileges[mode]['tableAndColumns']
              });
            } else {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object[sequencedTableColumn['column_name']],
                privilege: privileges[mode][sequencedTableColumn['column_name']]
              });
            }
          });

          resolve(tableColumns);
        });
      });
    }
  } else if (mode === 'List') {
    if (tableSlug === 'workers') {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
    
        const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        Object.keys(object).forEach((key, index) => {
          if (String(object[key]).match(timestampRegex)) {
            object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
          } else if (String(object[key]).match(dateRegex)) {
            object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
          } else if (key.indexOf("password") !== -1) {
            object[key] = '********';
          } else if (key === 'creator') {
            // object['created_by'] = object[key]['user_username'];
          }
        });

        getColumnsByTable(tableSlug).then(sequencedTableColumnsResult => {
          const sequencedTableColumns = sequencedTableColumnsResult.data;

          sequencedTableColumns.forEach(sequencedTableColumn => {
            if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object['tableAndColumns'],
                privilege: privileges[mode]['tableAndColumns']
              });
            } else {
              tableColumns.push({
                sequence: sequencedTableColumn['sequence'],
                column_name: sequencedTableColumn['column_name'],
                label_name: sequencedTableColumn['label_name'],
                value: object[sequencedTableColumn['column_name']],
                privilege: privileges[mode][sequencedTableColumn['column_name']]
              });
            }
          });

          tableColumns.push({
            associations: {
              twid_cards: object['twidCards'], 
              singapore_phone_numbers: object['sgPhoneNumbers'],
              digital_contacts: object['digitalContacts'],
              home_country_phone_numbers: object['homeCountryPhoneNumbers'],
              ticket_numbers: object['ticketNumbers'],
              injuries: object['injuries'],
              aggravating_issues: object['aggravatingIssues'],
              lead_case_workers: object['leadCaseWorkers'],
              auxiliary_case_workers: object['auxiliaryCaseWorkers'],
              interpreters: object['interpreters'],
              nicknames: object['nicknames'],
              passports: object['passports'],
              addresses_in_singapore: object['sgAddresses'],
              home_country_addresses: object['homeCountryAddresses'],
              next_of_kins: object['nextOfKins'],
              facepic_attachments: object['facepicAttachments'],
              pdpa_attachments: object['pdpaAttachments'],
              families_in_singapore: object['familyMembers'],
              friends_in_singapore: object['sgFriends'],
              languages: object['languages'],
              bank_accounts: object['bankAccounts'],
              client_appointments: object['clientAppointments'],
              jobs: object['jobs'],
              problems: object['problems']
            }
          })

          resolve(tableColumns);
        });
      });
    }
  }
};

const prepareSubsidiaryTableColumnsWithPrivileges = (subsidiarySlug, privileges, mode, workerId, workerSubsidiaryId) => {
  if (mode === 'View') {
    return new Promise ((resolve, reject) => {
      const tableColumns = [];

      getSchemaByTable(subsidiarySlug).then(schemasResult => {
        getSubsidiaryObject(subsidiarySlug, workerId, workerSubsidiaryId).then(objectResult => {
          const object = objectResult.data;
          
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          Object.keys(object).forEach((key, index) => {
            if (String(object[key]).match(timestampRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
            } else if (key.indexOf("password") !== -1) {
              object[key] = '********';
            } else if (key === 'creator') {
              // object['created_by'] = object[key]['user_username'];
            } 
          });
  
          getColumnsByTable(subsidiarySlug).then(sequencedTableColumnsResult => {
            const sequencedTableColumns = sequencedTableColumnsResult.data;
  
            sequencedTableColumns.forEach(sequencedTableColumn => {
              if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
                tableColumns.push({
                  sequence: sequencedTableColumn['sequence'],
                  column_name: sequencedTableColumn['column_name'],
                  label_name: sequencedTableColumn['label_name'],
                  value: object['tableAndColumns'],
                  privilege: privileges[mode]['tableAndColumns']
                });
              } else {
                tableColumns.push({
                  sequence: sequencedTableColumn['sequence'],
                  column_name: sequencedTableColumn['column_name'],
                  label_name: sequencedTableColumn['label_name'],
                  value: object[sequencedTableColumn['column_name']],
                  privilege: privileges[mode][sequencedTableColumn['column_name']]
                });
              }
            });
  
            resolve(tableColumns);
          });
        });
      });
    });
  } else if (mode === 'List') {
    if (subsidiarySlug === 'workers') {
      return new Promise ((resolve, reject) => {
        const tableColumns = [];
        getSubsidiaryObject(subsidiarySlug, workerId, workerSubsidiaryId).then(objectResult => {
          const object = objectResult.data;
          
          const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          Object.keys(object).forEach((key, index) => {
            if (String(object[key]).match(timestampRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd HH:mm')
            } else if (String(object[key]).match(dateRegex)) {
              object[key] = format(new Date(object[key]), 'yyyy-MMM-dd')
            } else if (key.indexOf("password") !== -1) {
              object[key] = '********';
            } else if (key === 'creator') {
              // object['created_by'] = object[key]['user_username'];
            }
          });

          getColumnsByTable(subsidiarySlug).then(sequencedTableColumnsResult => {
            const sequencedTableColumns = sequencedTableColumnsResult.data;

            sequencedTableColumns.forEach(sequencedTableColumn => {
              if (sequencedTableColumn['column_name'] === 'tableAndColumn_id') {
                tableColumns.push({
                  sequence: sequencedTableColumn['sequence'],
                  column_name: sequencedTableColumn['column_name'],
                  label_name: sequencedTableColumn['label_name'],
                  value: object['tableAndColumns'],
                  privilege: privileges[mode]['tableAndColumns']
                });
              } else {
                tableColumns.push({
                  sequence: sequencedTableColumn['sequence'],
                  column_name: sequencedTableColumn['column_name'],
                  label_name: sequencedTableColumn['label_name'],
                  value: object[sequencedTableColumn['column_name']],
                  privilege: privileges[mode][sequencedTableColumn['column_name']]
                });
              }
            });

            tableColumns.push({
              associations: {
                twid_cards: object['twidCards'], 
                singapore_phone_numbers: object['sgPhoneNumbers'],
                digital_contacts: object['digitalContacts'],
                home_country_phone_numbers: object['homeCountryPhoneNumbers'],
                ticket_numbers: object['ticketNumbers'],
                injuries: object['injuries'],
                aggravating_issues: object['aggravatingIssues'],
                lead_case_workers: object['leadCaseWorkers'],
                auxiliary_case_workers: object['auxiliaryCaseWorkers'],
                interpreters: object['interpreters'],
                nicknames: object['nicknames'],
                passports: object['passports'],
                addresses_in_singapore: object['sgAddresses'],
                home_country_addresses: object['homeCountryAddresses'],
                next_of_kins: object['nextOfKins'],
                facepic_attachments: object['facepicAttachments'],
                pdpa_attachments: object['pdpaAttachments'],
                families_in_singapore: object['familyMembers'],
                friends_in_singapore: object['sgFriends'],
                languages: object['languages'],
                bank_accounts: object['bankAccounts'],
                client_appointments: object['clientAppointments'],
                jobs: object['jobs'],
                problems: object['problems']
              }
            })

            resolve(tableColumns);
          });
        });
      });
    }
  }
};

const checkCompulsoryFields = (tableColumns) => {
  const emptyFields = [];

  tableColumns.forEach(tableColumn => {
    if (tableColumn['dropdowns'] !== undefined) {
      if (tableColumn['is_nullable'] === false && (tableColumn['value'] === '' || tableColumn['value'] === null)) {
        emptyFields.push(tableColumn['column_name']);
      } else if (tableColumn['dropdowns'].length > 0 && tableColumn['value'] === '-') {
        emptyFields.push(tableColumn['column_name']);
      }
    } 
  });

  const allCompulsoryFieldsHaveBeenFilled = emptyFields.length > 0 ? false : true;

  return {
    allCompulsoryFieldsHaveBeenFilled,
    emptyFields
  };
};

const getAllTableAndColumns = async () => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns`, {withCredentials: true});
};

const addObject = async (tableSlug, tableColumns) => {
  let containsFile = false;

  if (tableSlug === 'ordinary-attachments' || tableSlug === 'sensitive-attachments' || tableSlug === 'templates') {
    tableColumns.map((tableColumn, index) => {
      if (tableColumn.token === undefined) {
        if (tableColumn.reference !== null && tableColumn.column_name !== 'upload') {
          if (tableColumn.value === '') {
            tableColumns[index].value = '';
          } else {
            tableColumns[index].value = tableColumn.storeAsColumnValue;
          }
        } else if (tableColumn.column_name === 'employer_name') {
          tableColumns[index].value = tableColumns[index].job_id;
        } else if (tableColumn.column_name === 'chief_problem') {
          tableColumns[index].value = tableColumns[index].problem_id;
        }  else if (tableColumn.value === undefined) {
          tableColumns[index].value = '';
        }
      }
  
      if (tableColumn.input_type === 'file') containsFile = true;
    });
  } else {
    tableColumns.map((tableColumn, index) => {
      if (tableColumn.token === undefined) {
        if (tableColumn.reference !== null && tableColumn.column_name !== 'upload') {
          if (tableColumn.value === '') {
            tableColumns[index].value = '';
          } else {
            tableColumns[index].value = tableColumn.storeAsColumnValue;
          }
        } else if (tableColumn.value === undefined) {
          tableColumns[index].value = '';
        }
      }

      if (tableColumn.input_type === 'file') containsFile = true;
    });
  }

  if (containsFile) {
    const formInput = new FormData();

    tableColumns.forEach((obj, index) => {
      if (obj['column_name'] === 'tagged_users') {
        const usernames = [];
        Object.keys(obj['value']).forEach(username => {
          usernames.push(username);
        });
        if (usernames.length > 0) {
          formInput.append('tagged_users', usernames);
        } else {
          formInput.append('tagged_users', '');
        }
      } else {
        formInput.append(obj['column_name'], obj['value']);
      }
    });

    formInput.append('token', tableColumns[tableColumns.length - 1]['token']);
    return await axios.post(`${backend_base_url()}/api/${tableSlug}`, formInput, {
      withCredentials: true,
      headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
    });
  } else {
    return await axios.post(`${backend_base_url()}/api/${tableSlug}`, {
      tableColumns: tableColumns,
    }, {
      withCredentials: true,
      headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
    });
  }
};

const addObjectsViaFile = async (tableSlug, tableColumns, tableSlugBelong) => {
  // need to add record in import table
  // need to add records in table_slug table
  const formInput = new FormData();

  tableColumns.forEach((obj, index) => {
    if (obj['column_name'] === 'tagged_users') {
      const usernames = [];
      Object.keys(obj['value']).forEach(username => {
        usernames.push(username);
      });
      if (usernames.length > 0) {
        formInput.append('tagged_users', usernames);
      } else {
        formInput.append('tagged_users', '');
      }
    } else {
      formInput.append(obj['column_name'], obj['value']);
    }
  });

  formInput.append('token', tableColumns[tableColumns.length - 1]['token']);
  formInput.append('tableSlugBelong', tableSlugBelong);
  formInput.append('withCredentials', true);

  return await axios.post(`${backend_base_url()}/api/${tableSlug}`, formInput, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const updateFacepic = async (facepicId, tableColumns) => {
  const formInput = new FormData();
  tableColumns.forEach((obj, index) => {
    formInput.append(obj['column_name'], obj['value']);
  });

  formInput.append('withCredentials', true);

  formInput.append('token', tableColumns[tableColumns.length - 1]['token']);
  return await axios.put(`${backend_base_url()}/api/facepic-attachments/${facepicId}`, formInput, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const editObject = async (tableSlug, tableColumns, objectId) => {
  if (tableSlug === 'sensitive-attachments' || tableSlug === 'sensitive-discussions') {
    tableColumns.map((tableColumn, index) => {
      if (tableColumn.token === undefined) {
        if (tableColumn.reference !== null) {
          if (tableColumn.column_name === 'tagged_users') {
          } else {
            if (tableColumn.value === '') {
              tableColumns[index].value = '';
            } else {
              if (tableColumn['column_name'] !== 'created_by' && tableColumn['column_name'] !== 'attachment_submitted_by') tableColumns[index].value = tableColumn.storeAsColumnValue;
            }
          }
        } 
      }
    });
  } else {
    tableColumns.map((tableColumn, index) => {
      if (tableColumn.token === undefined) {
        if (tableColumn.reference !== null) {
          if (tableColumn.value === '') {
            tableColumns[index].value = '';
          } else {
            tableColumns[index].value = tableColumn.storeAsColumnValue;
          }
        }
      }
    });
  }

  return await axios.put(`${backend_base_url()}/api/${tableSlug}/${objectId}`, {
    tableColumns: tableColumns,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const deleteObject = async (tableSlug, objectId) => {
  return await axios.delete(`${backend_base_url()}/api/${tableSlug}/${objectId}`, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  })
};

const getDropdownValues = async (tableName, columnName) => {
  return await axios.get(`${backend_base_url()}/api/dropdowns/getDropdownValuesByTableNameAndColumnName`, {
    params: {
      tableName: tableName,
      columnName: columnName
    },
    withCredentials: true
  });
};

const searchWorkers = async (columns, role, offset) => {
  return await axios.get(`${backend_base_url()}/api/workers/searchWorkers`, {
    params: {
      columns: columns,
      role: role,
      offset: offset
    },
    withCredentials: true
  });
};

const searchWorkersBeforeCreating = async (columns) => {
  return await axios.get(`${backend_base_url()}/api/workers/searchWorkersBeforeCreating`, {
    params: {
      columns: columns
    },
    withCredentials: true
  });
};

const getSubsidiaryColumnsToDisplay = async (subsidiarySlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getSubsidiaryColumnsToDisplay`, {
    params: {
      subsidiarySlug: subsidiarySlug
    },
    withCredentials: true
  });
};

const generateTwid = (last4CharFin, yearOfBirth) => {
  return new Promise ((resolve, reject) => {
    const columns = {
      last4CharFin,
      yearOfBirth
    };
    axios.get(`${backend_base_url()}/api/workers/searchTwidBeforeCreating`, {
      params: {
        columns: columns
      },
      withCredentials: true
    }).then((searchResults) => {
      const existingTwids = searchResults.data;

      if (existingTwids.length < 9) {
        resolve((`${last4CharFin}${yearOfBirth.slice(-2)}0${existingTwids.length + 1}`).toUpperCase());
      } else {
        resolve((`${last4CharFin}${yearOfBirth.slice(-2)}${existingTwids.length + 1}`).toUpperCase());
      }
    });
  });
};

const generateTempTwid = () => {
  return new Promise ((resolve, reject) => {
    const tempTwid = `GEN${String(Math.random()).slice(-6)}`;
    const columns = {
      tempTwid
    };

    axios.get(`${backend_base_url()}/api/workers/searchTwidBeforeCreating`, {
      params: {
        columns: columns
      },
      withCredentials: true
    }).then((searchResults) => {
      if (searchResults.data.length > 0) {
        generateTempTwid();
      } else {
        resolve(tempTwid);
      }
    });
  });
};

function compare( a, b ) {
  if ( a.numberOfDays > b.numberOfDays ){
    return -1;
  }
  if ( a.numberOfDays < b.numberOfDays ){
    return 1;
  }
  return 0;
}

const populateMonthCalendarDays = (clientAppointments, workerId, date) => {
  const year = getYear(new Date(date));
  const currentMonth = format(new Date(date), 'MM'); // 04
  const month = getMonth(new Date(date)); // january 0 february 1 march 2
  const nextMonth = getMonth(add(new Date(date), {months: 1})); // month after current month
  const lastMonth = getMonth(sub(new Date(date), {months: 1})); // month before current month
  const lastDateOfMonth = parseInt(format(new Date(lastDayOfMonth(new Date(date))), 'dd')); // 29, 30, 31
  const startOfMonth2 = parseInt(format(new Date(startOfMonth(date)), 'e')); // friday 5 saturday 6
  const endOfMonth = parseInt(format(new Date(lastDayOfMonth(new Date(date))), 'e')); // friday 5 saturday 6
  const lastDateOfLastMonth = parseInt(format(lastDayOfMonth(new Date(sub(new Date(date), {months: 1}))), 'dd'));
  
  const dates = [];
  const populatedMonthDays = [];

  for (let i = 0; i < startOfMonth2; i += 1) {
    dates.unshift(`${year}-${(lastMonth).toString().padStart(2, '0')}-${(lastDateOfLastMonth - i).toString().padStart(2, '0')}`);
  }

  for (let i = 0; i < lastDateOfMonth; i += 1) {
    dates.push(`${year}-${(month + 1).toString().padStart(2, '0')}-${(1 + i).toString().padStart(2, '0')}`);
  }

  for (let i = 1; i <= 6 - endOfMonth; i += 1) {
    dates.push(`${year}-${(nextMonth + 1).toString().padStart(2, '0')}-${(i).toString().padStart(2, '0')}`);
  }

  if (clientAppointments !== undefined && clientAppointments.length > 0) {
    const events = {};

    clientAppointments.forEach((clientCalendar, index) => {

      let color;

      if (clientCalendar.calendar_event_status === 'Tentatively scheduled') {
        color = '#218c74';
      } else if (clientCalendar.calendar_event_status === 'Scheduled') {
        color = '#2980b9';
      } else if (clientCalendar.calendar_event_status === 'Postponed') {
        color = '#6F1E51';
      } else if (clientCalendar.calendar_event_status === 'Cancelled') {
        color = '#e84118';
      } else if (clientCalendar.calendar_event_status === 'Completed') {
        color = '#1e272e';
      }

      if (events[format(new Date(clientCalendar.datetime_event_start), 'yyyy-MMM-dd')] === undefined) {
        events[format(new Date(clientCalendar.datetime_event_start), 'yyyy-MMM-dd')] = [];
      }

      const moreThan1Day = (isAfter(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start)) && parseInt(differenceInDays(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start))) > 0);
      const startEndDiffInMilliseconds = differenceInMilliseconds(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start));

      if (moreThan1Day) {
        let startEndDiffInDays = Math.round(differenceInMilliseconds(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start)) / 86400000);

        events[format(new Date(clientCalendar.datetime_event_start), 'yyyy-MMM-dd')].unshift({
          id: clientCalendar.id,
          title: clientCalendar.calendar_event_title,
          time: format(new Date(clientCalendar.datetime_event_start), 'hh:mm A'),
          datetime: clientCalendar.datetime_event_start,
          href: `/workers/${workerId}/client-calendars/${clientCalendar.id}`,
          position: 'start',
          color: color,
          margin: 'ml-2',
          marginTop: '0.5rem',
          numberOfDays: startEndDiffInDays,
          numberOfMilliseconds: startEndDiffInMilliseconds
        });

        for (let i = 1; i <= startEndDiffInDays; i += 1) {
          if (events[format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: 1})), 'yyyy-MMM-dd')] === undefined) {
            events[format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: 1})), 'yyyy-MMM-dd')] = [];
          }

          if (i === startEndDiffInDays) {
            events[format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: 1})), 'yyyy-MMM-dd')].unshift({
              id: clientCalendar.id,
              title: '_',
              time: '_',
              datetime: '_',
              href: `/workers/${workerId}/client-calendars/${clientCalendar.id}`,
              position: 'end',
              color: color,
              margin: 'mr-2',
              marginTop: '0.5rem',
              numberOfDays: startEndDiffInDays,
              numberOfMilliseconds: startEndDiffInMilliseconds
            });
          } else {
            events[format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: 1})), 'yyyy-MMM-dd')].unshift({
              id: clientCalendar.id,
              title: '_',
              time: '_',
              datetime: '_',
              href: `/workers/${workerId}/client-calendars/${clientCalendar.id}`,
              position: 'middle',
              color: color,
              margin: '',
              marginTop: '0.5rem',
              numberOfDays: startEndDiffInDays,
              numberOfMilliseconds: startEndDiffInMilliseconds
            });
          }
        }
      } else {
        events[format(new Date(clientCalendar.datetime_event_start), 'yyyy-MMM-dd')].push({
          id: clientCalendar.id,
          title: clientCalendar.calendar_event_title,
          time: format(new Date(clientCalendar.datetime_event_start), 'hh:mm A'),
          datetime: clientCalendar.datetime_event_start,
          href: `/workers/${workerId}/client-calendars/${clientCalendar.id}`,
          position: 'single',
          color: color,
          margin: 'ml-2 mr-2',
          marginTop: '0.5rem',
          numberOfDays: 1,
          numberOfMilliseconds: startEndDiffInMilliseconds
        });
      }
    });

    const clonedEvents = {}; 
    let eventPositions = {};
    let arrayOfNumberOfDays = [];
    let objectIdToEvent = {};

    Object.assign(clonedEvents, events);

    dates.forEach((date, index) => {
      if(clonedEvents[date] !== undefined) {
        clonedEvents[date] = clonedEvents[date].sort(compare); 

        if (Object.keys(eventPositions).length > 0) {

          clonedEvents[date].forEach((clonedEvent, index2) => {
            if (eventPositions[clonedEvent.id] !== undefined && eventPositions[clonedEvent.id] !== index2) {
              const positionDiff = eventPositions[clonedEvent.id] - index2;
              
              for (let i = 0; i < positionDiff; i += 1) {
                clonedEvents[date].unshift({
                  href: '#'
                });
              }
            } 
          });
        }

        if (Object.keys(objectIdToEvent).length > 0) {
          clonedEvents[date].forEach((clonedEvent, index2) => {
            // loop through objectIdToEvent
            const numberOfDaysArray = [];
            Object.keys(objectIdToEvent).forEach((key, index5) => {
              numberOfDaysArray.push(objectIdToEvent[key]);
            });

            if (objectIdToEvent[clonedEvent.id] === undefined && numberOfDaysArray.indexOf(clonedEvent.numberOfDays) !== -1 && clonedEvent.href !== '#') {
              clonedEvents[date][clonedEvents[date].length] = clonedEvent;
              clonedEvents[date].splice(index2, 1);
            }
          });
        }

        eventPositions = {};
        arrayOfNumberOfDays = [];
        objectIdToEvent = {};

        clonedEvents[date].forEach((clonedEvent, index2) => {
          eventPositions[clonedEvent.id] = index2;
          arrayOfNumberOfDays.push(clonedEvent.numberOfDays);
          objectIdToEvent[clonedEvent.id] = clonedEvent.numberOfDays;
        });
      }

      if (events[date] !== undefined && events[date].length > 0) {
        populatedMonthDays.push({
          date: date, 
          events: events[date],
          isCurrentMonth: date.split('-')[1] === currentMonth ? true : false
        });
      } else {
        populatedMonthDays.push({
          date: date, 
          events: [],
          isCurrentMonth: date.split('-')[1] === currentMonth ? true : false
        });
      }
    });
  } else {
    dates.forEach((date, index) => {
      populatedMonthDays.push({
        date: date, 
        events: [],
        isCurrentMonth: date.split('-')[1] === currentMonth ? true : false
      });
    }); 
  }

  return populatedMonthDays;
};

const populateWeekCalendarDays = (clientAppointments, workerId, date) => { 
  const returnObject = {};
  const dayOfWeek = format(new Date(date), 'e'); // sun 0 wed 3
  const datesOfWeek = {};
  const digitToDay = {
    0: 'Sunday', 
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday', 
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };
  const events = [];
  const fullDatesOfWeek = [];

  for (let i = 0; i < 7; i += 1) {
    if (i === dayOfWeek) {
      // date supplied is sunday
      datesOfWeek[digitToDay[i]] = format(new Date(date), 'dd');
      fullDatesOfWeek.push(format(new Date(date), 'yyyy-MMM-dd'));
    } else {
      if (dayOfWeek - i > 0) {
        const newMomentTime = sub(new Date(date), {days: dayOfWeek - i});
        datesOfWeek[digitToDay[i]] = format(new Date(newMomentTime), 'dd');
        fullDatesOfWeek.push(format(new Date(newMomentTime), 'yyyy-MMM-dd'));
      } else {
        const newMomentTime = add(new Date(date), {days: i - dayOfWeek});
        datesOfWeek[digitToDay[i]] = format(new Date(newMomentTime), 'dd');
        fullDatesOfWeek.push(format(new Date(newMomentTime), 'yyyy-MMM-dd'));
      }
    }
  }

  const colStartToCalendarId = {};
  const arraysOf24Hours = {};
  const overlap = {};

  if (clientAppointments !== undefined && clientAppointments.length > 0) { 
    clientAppointments.forEach((clientCalendar, index) => {
      let startEndDiffInDays = Math.round(differenceInMilliseconds(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start)) / 86400000);
      let minutesDiff = differenceInMinutes(new Date(clientCalendar.datetime_event_end), new Date(clientCalendar.datetime_event_start));

      if (minutesDiff % 5 !== 0) {
        if (minutesDiff % 5 > 3) {
          minutesDiff += (5 - minutesDiff % 5);
        } else if (minutesDiff % 5 < 3) {
          minutesDiff -= minutesDiff;
        }
      }

      for (let i = 0; i <= startEndDiffInDays; i += 1) {
        if (fullDatesOfWeek.indexOf(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: 1})), 'yyyy-MMM-dd')) !== -1) {
          events.push({
            gridRow: (parseInt(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i}), 'k'))) * 12 + 2).toString() + ' / ' + ` span ${minutesDiff / 5}`,
            title: clientCalendar.calendar_event_title,
            time: format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'hh:mm A'),
            colStart: parseInt(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'e')) + 1,
            href: `/workers/${workerId}/client-calendars/${clientCalendar.id}`,
            timeStart: clientCalendar.datetime_event_start,
            timeEnd: clientCalendar.datetime_event_end,
            id: clientCalendar.id
          });

          if (colStartToCalendarId[parseInt(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'e')) + 1] === undefined) {
            colStartToCalendarId[parseInt(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'e')) + 1] = [];
          }

          colStartToCalendarId[parseInt(format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'e')) + 1].push({
            id: clientCalendar.id,
            twoDigitDay: format(new Date(add(new Date(clientCalendar.datetime_event_start), {days: i})), 'dd'),
            timeStart: clientCalendar.datetime_event_start,
            timeEnd: clientCalendar.datetime_event_end,
          });
        }
      }
    });

    Object.keys(colStartToCalendarId).forEach((key, index) => {
      if (arraysOf24Hours[key] === undefined) {
        arraysOf24Hours[key] = {};
      }
      let arraysOf24HoursToPush = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
      colStartToCalendarId[key].forEach((obj, index2) => {
        const timeStart = obj.timeStart;
        const timeEnd = obj.timeEnd;

        let startEndDiffInHours = Math.round(differenceInHours(new Date(timeEnd), new Date(timeStart)));
        for (let i = 0; i <= startEndDiffInHours; i += 1) {

          if (format(new Date(add(new Date(timeStart), {hours: i})), 'dd') === obj.twoDigitDay) {
            arraysOf24HoursToPush[parseInt(format(new Date(add(new Date(timeStart), {hours: i})), 'H'))] = 1;
          }
        }

        if (arraysOf24Hours[key][obj.id] === undefined) {
          arraysOf24Hours[key][obj.id] = {};
        }

        arraysOf24Hours[key][obj.id] = arraysOf24HoursToPush;
        arraysOf24HoursToPush = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
      })
    });

    Object.keys(arraysOf24Hours).forEach((key, index) => {
      if (overlap[key] === undefined) {
        overlap[key] = {};
      }

      Object.keys(arraysOf24Hours[key]).forEach((key2, index2) => {
        if (overlap[key][key2] === undefined) {
          overlap[key][key2] = [];
          overlap[key][key2].push(key2);
        }

        const overlapLength = overlap[key][key2].length;
        
        Object.keys(arraysOf24Hours[key]).forEach((key3, index3) => {
          if (key2 !== key3) {
            for (let i = 0; i < 24; i += 1) {
              if (arraysOf24Hours[key][key2][i] * arraysOf24Hours[key][key3][i] === 1) {
                for (let j = 0; j < overlapLength; j += 1) {
                  if (key3 !== overlap[key][key2][j]) {
                    if (key3 > overlap[key][key2][j]) {
                      overlap[key][key2].splice(j, 0, key3);
                    } else {
                      overlap[key][key2].splice(j, 0, key3);
                    }
                  }
                }
                break;
              }
            }
          }
        });
      });
    }); 
  }

  
  returnObject['datesOfWeek'] = datesOfWeek;
  returnObject['events'] = events;
  returnObject['overlap'] = overlap;

  return returnObject;
};

const populateNavigationStub = async (workerId, jobId, problemId) => {
  return await axios.get(`${backend_base_url()}/api/workers/populateNavigationStub`, {
    params: {
      workerId: workerId
    },
    withCredentials: true
  });
};

const populateTopAndMiddleNavigation = async (objectId, level, role, userId) => {
  if (level === 'workers') {
    return await axios.get(`${backend_base_url()}/api/workers/populateTopAndMiddleNavigation`, {
      params: {
        objectId: objectId,
        level: level
      },
      withCredentials: true
    });
  } else if (level === 'jobs') {
    return await axios.get(`${backend_base_url()}/api/jobs/populateTopAndMiddleNavigation`, {
      params: {
        objectId: objectId,
        level: level
      },
      withCredentials: true
    });
  } else if (level === 'problems') {
    return await axios.get(`${backend_base_url()}/api/problems/populateTopAndMiddleNavigation`, {
      params: {
        objectId: objectId,
        role: role,
        userId: userId,
        level: level
      },
      withCredentials: true
    });
  }
};

const prepareListWithPrivileges = async (workerId, jobId, problemId, subsidiarySlug, calendarView, token) => {
  return new Promise ((resolve, reject) => {
    getSchemaByTable(subsidiarySlug).then(schemasResult => {
      const schemaRows = schemasResult.data;
      const stringColumns = {};
      const dateOrDateTimeColumns = {};

      for (let i = 0; i < schemaRows.length; i += 1) {
        switch (schemaRows[i]['data_type']) {
          case 'character varying':
            stringColumns[schemaRows[i]['column_name']] = true;
            break;
          case 'timestamp with time zone':
            dateOrDateTimeColumns[schemaRows[i]['column_name']] = true;
            break;
          case 'date':
            dateOrDateTimeColumns[schemaRows[i]['column_name']] = true;
            break;
        }
      } 
      
      getSubsidiaryColumnsToDisplay(subsidiarySlug).then(result => {
        const referenceData = {};
        const floatData = {};
        getColumnsByTable(subsidiarySlug).then(result2 => {
          result2.data.forEach(result2Row => {
            if (result2Row['float_step']) floatData[result2Row['column_name']] = result2Row['float_step'];
            referenceData[result2Row['column_name']] = {
              reference: result2Row.reference,
              reference_id: result2Row.reference_id,
              reference_synced: false
            }
          });
  
          const columns = result.data;
  
          columns.forEach(column => {
            column['reference'] = referenceData[column['column_name']]['reference'];
            column['reference_id'] = referenceData[column['column_name']]['reference_id'];
            column['reference_synced'] = referenceData[column['column_name']]['reference_synced'];
          })
  
          const columnNames = columns.map((column) => column.column_name);
          columnNames.unshift('id');
  
          const params = {};
          if (workerId !== null) {
            params['workerId'] = workerId;
          }
          if (jobId !== null) {
            params['jobId'] = jobId;
          }
          if (problemId !== null) {
            params['problemId'] = problemId;
          }
  
          if (subsidiarySlug === 'sensitive-discussions' || subsidiarySlug === 'sensitive-attachments') {
            params['token'] = token;
          }
  
          params['columnNames'] = JSON.stringify(columnNames);
    
          axios.get(`${backend_base_url()}/api/${subsidiarySlug}/prepareList`, {
            params: params,
            withCredentials: true
          }).then(result => {
            result.data.forEach((object, index) => {
              Object.keys(floatData).forEach(key => {
                if (object[key] !== null) result.data[index][key] = parseFloat(object[key]).toFixed(floatData[key].split('.')[1].length);
              });
            });
  
            let diaryColumns = [];
            let diaryData = {};
            const diffWeeks = [];
  
            if (subsidiarySlug === 'client-appointments') {
  
              diaryColumns = [
                {
                  column_name: 'day',
                  label_name: 'Day',
                },
                {
                  column_name: 'date',
                  label_name: 'Date',
                },
                {
                  column_name: 'brief_details',
                  label_name: 'Brief Details',
                }
              ];
  
              const current = new Date();
              const firstDateOfWeek = subDays(current, current.getDay() - 1);
  
              diaryData[0] = {
                0: {
                  day: 'MON',
                  date: firstDateOfWeek,
                  brief_details: []
                },
                1: {
                  day: 'TUE',
                  date: addDays(new Date(firstDateOfWeek), 1),
                  brief_details: []
                },
                2: {
                  day: 'WED',
                  date: addDays(new Date(firstDateOfWeek), 2),
                  brief_details: []
                },
                3: {
                  day: 'THURS',
                  date: addDays(new Date(firstDateOfWeek), 3),
                  brief_details: []
                },
                4: {
                  day: 'FRI',
                  date: addDays(new Date(firstDateOfWeek), 4),
                  brief_details: []
                },
                5: {
                  day: 'SAT',
                  date: addDays(new Date(firstDateOfWeek), 5),
                  brief_details: []
                },
                6: {
                  day: 'SUN',
                  date: addDays(new Date(firstDateOfWeek), 6),
                  brief_details: []
                }
              };
              diffWeeks.push(0);
  
              // go through each date
              // check the delta from current date in weeks
              result.data.forEach((appt) => {
                const currentApptDate = new Date(appt.datetime_event_start);
                let dateIndex;
  
                if (currentApptDate.getDay() - 1 === -1) {
                  dateIndex = 6;
                } else {
                  dateIndex = currentApptDate.getDay() - 1;
                }
  
                const firstDateOfApptDate = subDays(currentApptDate, dateIndex);
                const diffInWeeksToCurrent = differenceInWeeks(new Date(format(new Date(firstDateOfApptDate), 'yyyy-MMM-dd')), new Date(format(new Date(firstDateOfWeek), 'yyyy-MMM-dd')));
  
                if (diaryData[diffInWeeksToCurrent] === undefined) {
                  diaryData[diffInWeeksToCurrent] = {
                    0: {
                      day: 'MON',
                      date: new Date(firstDateOfApptDate),
                      brief_details: []
                    },
                    1: {
                      day: 'TUE',
                      date: addDays(new Date(firstDateOfApptDate), 1),
                      brief_details: []
                    },
                    2: {
                      day: 'WED',
                      date: addDays(new Date(firstDateOfApptDate), 2),
                      brief_details: []
                    },
                    3: {
                      day: 'THURS',
                      date: addDays(new Date(firstDateOfApptDate), 3),
                      brief_details: []
                    },
                    4: {
                      day: 'FRI',
                      date: addDays(new Date(firstDateOfApptDate), 4),
                      brief_details: []
                    },
                    5: {
                      day: 'SAT',
                      date: addDays(new Date(firstDateOfApptDate), 5),
                      brief_details: []
                    },
                    6: {
                      day: 'SUN',
                      date: addDays(new Date(firstDateOfApptDate), 6),
                      brief_details: []
                    }
                  };
                  diffWeeks.push(diffInWeeksToCurrent);
                }
  
                diaryData[diffInWeeksToCurrent][dateIndex].brief_details.push({
                  date: currentApptDate,
                  title: appt.calendar_event_title,
                  location: appt.calendar_event_location,
                  accompanying: appt.accompanying_twc2_person,
                  id: appt.id
                })
              });
            }
  
            diffWeeks.sort((a,b) => a-b);
  
            let currWeekCounter = 0;
  
            for (let i = 0; i < diffWeeks.length; i += 1) {
              if(diffWeeks[i] === 0) {
                currWeekCounter = i;
                break;
              }
            }
            
            resolve({
              dateOrDateTimeColumns: dateOrDateTimeColumns,
              stringColumns: stringColumns,
              columns: columns,
              data: result,
              referenceData: referenceData,
              diaryColumns: diaryColumns,
              diaryData: diaryData,
              diffWeeks: diffWeeks,
              currWeekCounter: currWeekCounter
            });
          });
        });
      });
    });
  });
};

const getSubsidiaryTableFromSubsidiarySlug = async (subsidiarySlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getSubsidiaryTableFromSubsidiarySlug`, {
    params: {
      subsidiarySlug: subsidiarySlug
    },
    withCredentials: true
  });
};

const checkCurrentUserCanViewSensitiveDiscussion = async (sensitiveDiscussionId, problemId, token) => {
  return await axios.get(`${backend_base_url()}/api/sensitive-discussions/checkCurrentUserCanViewSensitiveDiscussion`, {
    params: {
      sensitiveDiscussionId: sensitiveDiscussionId,
      problemId: problemId,
      token: token
    },
    withCredentials: true
  });
};

const checkCurrentUserCanViewSensitiveAttachment = async (sensitiveAttachmentId, token) => {
  return await axios.get(`${backend_base_url()}/api/sensitive-attachments/checkCurrentUserCanViewSensitiveAttachment`, {
    params: {
      sensitiveAttachmentId: sensitiveAttachmentId,
      token: token
    },
    withCredentials: true
  });
};

const getValuesByColumnName = async (tableSlug, columnName, query) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/getValuesByColumnName`, {
    params: {
      columnName: columnName,
      query: query
    },
    withCredentials: true
  });
};

const getUsernamesByQuery = async (query) => {
  return await axios.get(`${backend_base_url()}/api/users/getUsernamesByQuery`, {
    params: {
      query: query
    },
    withCredentials: true
  });
};

const getValueByColumnName = async (tableSlug, columnNameToStoreAs, columnNameToSearch, columnValueToSearch) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/getValueByColumnName`, {
    params: {
      columnNameToStoreAs: columnNameToStoreAs,
      columnNameToSearch: columnNameToSearch,
      columnValueToSearch: columnValueToSearch
    },
    withCredentials: true
  });
};

const displayValueByColumnName = async (tableSlug, columnNameToDisplay, columnNameToSearch, columnValueToSearch) => {
  return await axios.get(`${backend_base_url()}/api/${tableSlug}/displayValueByColumnName`, {
    params: {
      columnNameToDisplay: columnNameToDisplay,
      columnNameToSearch: columnNameToSearch,
      columnValueToSearch: columnValueToSearch
    },
    withCredentials: true
  });
};

const editColumnVisibility = async (tableAndColumnVisibilityId, tableAndColumnVisibility) => {
  return await axios.post(`${backend_base_url()}/api/tableAndColumns/editColumnVisibility`, {
    tableAndColumnVisibilityId: tableAndColumnVisibilityId,
    tableAndColumnVisibility: JSON.stringify(tableAndColumnVisibility)
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const insertCopiedCaseDiscussion = async (copiedFromId, copiedToId) => {
  return await axios.post(`${backend_base_url()}/api/copied-ordinary-case-discussions`, {
    copiedFrom_id: copiedFromId,
    copiedTo_id: copiedToId,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const createGroupChatConversation = async (groupChatName, groupChatDescription, taggedUsernames) => {
  return await axios.post(`${backend_base_url()}/api/group-chat-conversations`, {
    groupChatName: groupChatName,
    groupChatDescription: groupChatDescription,
    taggedUsernames: taggedUsernames,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const updateGroupChatConversation = async (groupChatName, groupChatDescription, taggedUsernames, groupChatSlug) => {
  return await axios.put(`${backend_base_url()}/api/group-chat-conversations/${groupChatSlug}`, {
    groupChatName: groupChatName,
    groupChatDescription: groupChatDescription,
    taggedUsernames: taggedUsernames,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};


const editDefaultValue = async (tableAndColumnDefaultValueId, tableAndColumnDefaultValue) => {
  return await axios.post(`${backend_base_url()}/api/tableAndColumns/editDefaultValue`, {
    tableAndColumnDefaultValueId: tableAndColumnDefaultValueId,
    tableAndColumnDefaultValue: JSON.stringify(tableAndColumnDefaultValue),
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getTableSlugFromDefaultTableSlug = async (defaultTableSlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getTableSlugFromDefaultTableSlug`, {
    params: {
      defaultTableSlug: defaultTableSlug
    },
  }, {
    withCredentials: true
  });
};

const getDefaultTableSlugFromTableSlug = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getDefaultTableSlugFromTableSlug`, {
    params: {
      tableSlug: tableSlug
    },
    withCredentials: true
  });
};

const findOtherRecordIds = async (workerId, workerSubsidiarySlug, workerSubsidiaryId, jobSubsidiarySlug, jobSubsidiaryId, problemSubsidiarySlug, problemSubsidiaryId, token) => {
  if (problemSubsidiarySlug !== undefined) {
    // if problem subsidiary slug exists, find records that matches problem, job and worker id
    return await axios.get(`${backend_base_url()}/api/${problemSubsidiarySlug}/findOtherRecordIds`, {
      params: {
        problemId: jobSubsidiaryId,
        jobId: workerSubsidiaryId,
        workerId: workerId,
        token: token
      },
      withCredentials: true
    });
  } else if (jobSubsidiarySlug !== undefined) {
    // if problem subsidiary slug does not exist but job subsidairy slug exist, find records that matches job and worker id
    return await axios.get(`${backend_base_url()}/api/${jobSubsidiarySlug}/findOtherRecordIds`, {
      params: {
        jobId: workerSubsidiaryId,
        workerId: workerId
      },
      withCredentials: true
    });
  } else {
    // if job subsidiary slug does not exist but worker subsidiary slug exist, find records that matches worker id
    return await axios.get(`${backend_base_url()}/api/${workerSubsidiarySlug}/findOtherRecordIds`, {
      params: {
        workerId: workerId
      },
      withCredentials: true
    });
  }
};

const downloadFiles = async (tableSlug, fileNames, userId) => {
  if (tableSlug === 'facepic-attachments') {
    return await axios.get(`${backend_base_url()}/api/${tableSlug}/downloadZippedFiles`, {
      params: {
        fileNames: fileNames,
        userId: userId
      },
      withCredentials: true
    });
  } else {
    return await axios.get(`${backend_base_url()}/api/${tableSlug}/downloadFiles`, {
      params: {
        fileNames: fileNames,
        userId: userId
      },
      withCredentials: true
    });
  }
};

const pdpaDownloadFiles = async (fileNames, filePaths, userId) => {
  return await axios.get(`${backend_base_url()}/api/pdpa-attachments/downloadFiles`, {
    params: {
      fileNames: fileNames,
      filePaths: filePaths,
      userId: userId
    },
    withCredentials: true
  });
};

const downloadFile = async (fileName) => {
  return await axios.get(`${backend_base_url()}/api/ordinary-attachments/downloadFile`, {
    responseType: 'stream',
    params: {
      fileName: fileName
    },
    withCredentials: true
  });
};

const fileIsAbove25M = (value) => {
  return (value >= 25000000);
}

const downloadPdpaFile = async (fileName) => {
  return await axios.get(`${backend_base_url()}/api/pdpa-attachments/downloadFile`, {
    responseType: 'stream',
    params: {
      fileName: fileName
    },
    withCredentials: true
  });
};

const searchUserByUsername = async (username, currentUsername) => {
  return await axios.get(`${backend_base_url()}/api/users/searchUserByUsername`, {
    params: {
      username: username,
      currentUsername: currentUsername
    },
    withCredentials: true
  });
};

const searchConversedUserByUsername = async (username) => {
  return await axios.get(`${backend_base_url()}/api/users/searchConversedUserByUsername`, {
    params: {
      username: username
    },
    withCredentials: true
  });
};

const checkCookieToken = (cookie) => {
  let cookieToken = '';
  let name = "token=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      cookieToken = c.substring(name.length, c.length);
    }
  }

  if (cookieToken === '') {
    return false;
  }

  return true;
};

const searchGroupChatConversationByGroupChatName = async (groupChatName) => {
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/searchGroupChatConversationByGroupChatName`, {
    params: {
      groupChatName: groupChatName
    },
    withCredentials: true
  });
};

const fourtyTruncate = (str) => {
    if (str.length > 40) return str.slice(0,40) + '...';
    
    return str;
};

const fiftyTruncate = (str) => {
    if (str.length > 50) return str.slice(0,50) + '...';
    
    return str;
};

const getNumberOfTables = async (table, workerId, jobId, problemId, userId, role) => {
  return await axios.get(`${backend_base_url()}/api/helpers/getNumberOfTables`, {
    params: {
      table: table,
      workerId: workerId,
      jobId: jobId,
      problemId,
      userId, 
      role
    },
    withCredentials: true
  });
};

const findVisibilityForTables = async (role) => {
  return await axios.get(`${backend_base_url()}/api/helpers/findVisibilityForTables`, {
    params: {
      role
    },
    withCredentials: true
  });
};

const checkValidityOfWorkerJobProblem = async (tableColumns) => {
  return await axios.post(`${backend_base_url()}/api/helpers/checkValidityOfWorkerJobProblem`, {
    tableColumns: tableColumns,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getJobAndProblemByWorkerId = async (workerId) => {
  return await axios.get(`${backend_base_url()}/api/workers/getJobAndProblemByWorkerId`, {
    params: {
      workerId: workerId
    },
    withCredentials: true
  });
};

const findJobsAndProblemsByWorkerId = async (workerId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/workers/findJobsAndProblemsByWorkerId`, {
    params: {
      workerId: workerId
    },
    withCredentials: true
  });
};

const findAllDirectMessageConversationsByUserToken = async (userToken) => {
  // find all conversations between user and other users and get the latest message
  try {
    const results =  await axios.get(`${backend_base_url()}/api/direct-messages/findAllDirectMessageConversationsByUserToken`, {
      params: {
        userToken: userToken
      },
      withCredentials: true
    });

    return results;
  } catch (e) {
    
  }
};

const findAllDirectMessageConversationsByUsername = async (username) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-messages/findAllDirectMessageConversationsByUsername`, {
    params: {
      username: username
    },
    withCredentials: true
  });
};

const detectBrowser = (navigator) => {
  let userAgent = navigator.userAgent;
  let browserName;
  
  if(userAgent.match(/chrome|chromium|crios/i)){
    browserName = "chrome";
  }else if(userAgent.match(/firefox|fxios/i)){
    browserName = "firefox";
  }  else if(userAgent.match(/safari/i)){
    browserName = "safari";
  }else if(userAgent.match(/opr\//i)){
    browserName = "opera";
  } else if(userAgent.match(/edg/i)){
    browserName = "edge";
  } else{
    browserName="No browser detection";
  }

  return browserName + ' on ' + navigator.platform;
}

const refreshCookies = async (token) => {
  return await axios.post(`${backend_base_url()}/api/auth/refreshCookies`, {
    token: token,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
}

const findAllGroupChatConversationsByUserToken = async (userToken) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/findAllGroupChatConversationsByUserToken`, {
    params: {
      userToken: userToken
    },
    withCredentials: true
  });
};

const findAllDirectMessageAttachmentsByDirectMessageConversation = async (directMessageConversationId) => {
  // find all direct message attachments by direct message conversations
  return await axios.get(`${backend_base_url()}/api/direct-message-attachments/findAllDirectMessageAttachmentsByDirectMessageConversation`, {
    params: {
      directMessageConversationId: directMessageConversationId
    },
    withCredentials: true
  });
};

const findDirectMessageConversation = async (id, userId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-message-conversations/findDirectMessageConversation`, {
    params: {
      id: id,
      userId: userId
    },
    withCredentials: true
  });
};

const getUnreadDirectMessages = async (userId) => {
  return await axios.get(`${backend_base_url()}/api/direct-messages/getUnreadDirectMessages`, {
    params: {
      userId: userId
    },
    withCredentials: true
  });
};

const getUnreadGroupChats = async (userId) => {
  return await axios.get(`${backend_base_url()}/api/direct-messages/getUnreadGroupChats`, {
    params: {
      userId: userId
    },
    withCredentials: true
  });
};

const findGroupChatNamesWithUserToken = async (userToken) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/findGroupChatNamesWithUserToken`, {
    params: {
      userToken: userToken
    },
    withCredentials: true
  });
};

const findGroupChatConversation = async (id, userId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/findGroupChatConversation`, {
    params: {
      id: id,
      userId: userId
    },
    withCredentials: true
  });
};

const findDirectMessageConversationIdBetweenTwoUsers = async (fromUserId, toUsername) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-message-conversations/findDirectMessageConversationIdBetweenTwoUsers`, {
    params: {
      fromUserId: fromUserId,
      toUsername: toUsername
    },
    withCredentials: true
  });
};

const findGroupChatConversationIdBetweenTwoUsers = async (fromUserId, toUsername) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-message-conversations/findGroupChatConversationIdBetweenTwoUsers`, {
    params: {
      fromUserId: fromUserId,
      toUsername: toUsername
    },
    withCredentials: true
  });
};

const findDirectMessageAttachmentsByDirectMessageConversationId = async (directMessageConversationId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-message-attachments/findDirectMessageAttachmentsByDirectMessageConversationId`, {
    params: {
      directMessageConversationId: directMessageConversationId
    },
    withCredentials: true
  });
};

const findGroupChatAttachmentsByGroupChatConversationId = async (directMessageConversationId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-attachments/findGroupChatAttachmentsByGroupChatConversationId`, {
    params: {
      directMessageConversationId: directMessageConversationId
    },
    withCredentials: true
  });
};

const findDirectMessagesByFromIdAndToUsername = async (fromUserId, toUsername, offset) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/direct-messages/findDirectMessagesByFromIdAndToUsername`, {
    params: {
      fromUserId: fromUserId,
      toUsername: toUsername,
      offset: offset
    },
    withCredentials: true
  });
};

const findGroupChatsByFromIdAndToUsername = async (fromUserId, toUsername) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chats/findGroupChatsByFromIdAndToUsername`, {
    params: {
      fromUserId: fromUserId,
      toUsername: toUsername
    },
    withCredentials: true
  });
};

const searchGroupChatAttachmentsByGroupChatSlug = async (groupChatSlug) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-attachments/searchGroupChatAttachmentsByGroupChatSlug`, {
    params: {
      groupChatSlug: groupChatSlug
    },
    withCredentials: true
  });
};

const searchGroupChatConversationByGroupChatSlug = async (groupChatSlug) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/searchGroupChatConversationByGroupChatSlug`, {
    params: {
      groupChatSlug: groupChatSlug
    },
    withCredentials: true
  });
};

const findGroupChatsByGroupChatSlug = async (groupChatSlug, offset) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chats/findGroupChatsByGroupChatSlug`, {
    params: {
      groupChatSlug: groupChatSlug,
      offset: offset
    },
    withCredentials: true
  });
};

const checkWhetherCurrentUserCanViewGroupChat = async (groupChatSlug, userId) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/group-chats/checkWhetherCurrentUserCanViewGroupChat`, {
    params: {
      groupChatSlug: groupChatSlug,
      userId: userId
    },
    withCredentials: true
  });
};

const searchUsers = async (columns) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/users/searchUsers`, {
    params: {
      columns: columns
    },
    withCredentials: true
  });
};

const getMyHistory = async (token, page) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/audit-logs/getMyHistory`, {
    params: {
      token: token,
      page: page
    },
    withCredentials: true
  });
};

const getAuditLog = async (token, page) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/audit-logs/getAuditLog`, {
    params: {
      page: page
    },
    withCredentials: true
  });
};

const getAnnouncementsToday = async (date) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/announcements/getAnnouncementsToday`, {
    params: {
      date: date
    },
    withCredentials: true
  });
};

const exportTable = async (tableSlug) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/exports`, {
    params: {
      tableSlug: tableSlug
    },
    withCredentials: true
  });
};

const createReport = async (reportName, reportDescription, payload, token) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/reports/createReport`, {
    params: {
      payload: payload,
      reportName: reportName,
      reportDescription: reportDescription,
      token: token
    },
    withCredentials: true
  });
};

const createPresetReport = async (presetReportName, presetReportDescription, presetSkinName, payload, token) => {
  // find all conversations between user and other users and get the latest message
  return await axios.get(`${backend_base_url()}/api/preset-reports/createPresetReport`, {
    params: {
      payload: payload,
      presetReportName: presetReportName,
      presetReportDescription: presetReportDescription,
      presetSkinName: presetSkinName,
      token: token
    },
    withCredentials: true
  });
};

const createPresetSkin = async (presetSkinName, presetSkinDescription, payload, token) => {
  return await axios.get(`${backend_base_url()}/api/preset-skins/createPresetSkin`, {
    params: {
      payload: payload,
      presetSkinName: presetSkinName,
      presetSkinDescription: presetSkinDescription,
      token: token
    },
    withCredentials: true
  });
};

const savePresetSkin = async (presetSkinId, presetSkinName, presetSkinDescription, payload) => {
  return await axios.post(`${backend_base_url()}/api/preset-skins/${presetSkinId}`, {
    presetSkinId: presetSkinId,
    presetSkinName: presetSkinName,
    presetSkinDescription: presetSkinDescription,
    payload: payload,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getValueFromReference = async (reference, value) => {
  let referenceFrom = await axios.get(`${backend_base_url()}/api/tableAndColumns/${reference.store}`, {withCredentials: true});
  let referenceTo = await axios.get(`${backend_base_url()}/api/tableAndColumns/${reference.view}`, {withCredentials: true});

  referenceFrom = referenceFrom.data;
  referenceTo = referenceTo.data;

  const referenceTableSlug = referenceFrom.table_slug;

  let derivedValue = await axios.get(`${backend_base_url()}/api/${referenceTableSlug}/${value}`, {withCredentials: true});
  derivedValue = derivedValue.data;
  derivedValue = derivedValue[referenceTo.column_name];

  return derivedValue;
};

const getReferenceValues = async (referenceData) => {
}

const saveReport = async (reportId, reportName, reportDescription, payload) => {
  return await axios.post(`${backend_base_url()}/api/reports/${reportId}`, {
    reportId: reportId,
    reportName: reportName,
    reportDescription: reportDescription,
    payload: payload,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const savePresetReport = async (presetReportId, presetReportName, presetReportDescription, presetSkinName, payload) => {
  return await axios.post(`${backend_base_url()}/api/preset-reports/${presetReportId}`, {
    presetReportId: presetReportId,
    presetReportName: presetReportName,
    presetReportDescription: presetReportDescription,
    presetSkinName: presetSkinName,
    payload: payload,
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
};

const getTableNameFromTableSlug = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/tableAndColumns/getTableNameFromTableSlug`, {
    params: {
      tableSlug: tableSlug
    },
  }, {
    withCredentials: true
  })
};

const getImportAttachments = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/imports/getImportAttachments`, {
    params: {
      tableSlug: tableSlug
    },
    withCredentials: true
  })
};

const getSessionTimeout = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/settings/getSessionTimeout`, {withCredentials: true});
};

const callGoogleAPI = async (tableSlug) => {
  return await axios.get(`${backend_base_url()}/api/pdpa-attachments/callGoogleAPI`, {withCredentials: true});
};

const getPendingPDPAs = async (tableSlug) => {
  const results = await axios.get(`${backend_base_url()}/api/pdpa-attachments/getPendingPDPAs`, {withCredentials: true});

  results.data.forEach((objectData, index) => {
    const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    
    Object.keys(objectData).forEach((key) => {
      if (String(objectData[key]).match(timestampRegex)) {
        results.data[index][key] = format(new Date(objectData[key]), 'yyyy-MMM-dd HH:mm')
      } else if (String(objectData[key]).match(dateRegex)) {
        results.data[index][key] = format(new Date(objectData[key]), 'yyyy-MMM-dd')
      }
    });
  });

  return results;
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

const deepCopy = (objOrArr) => {
  return JSON.parse(JSON.stringify(objOrArr));
};

const makeUnreadDirectMessage = async (fromId, toUsername) => {
  return await axios.post(`${backend_base_url()}/api/unread-direct-message-conversations/makeUnreadDirectMessage`, {
    fromId: fromId,
    toUsername: toUsername
  }, {
    withCredentials: true,
    headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
}; 

const checkDeactivated = async (token) => {
  return await axios.get(`${backend_base_url()}/api/users/checkDeactivated`, {
    params: {
      token: token
    },
    withCredentials: true
  });
};

const checkSystemDown = async () => {
  return await axios.get(`${backend_base_url()}/api/settings/checkSystemDown`, {withCredentials: true});
};

const listenToNotifications = (socket, notifications, setNotifications, show, update) => {
  socket.on('receive-dm', (content) => {
    if (content.type === 'dm') {
      update(content, show);
      
      // set unread to true
      socket.emit('get-unread', {
        fromId: content.fromId,
        toUsername: content.to,
      }, content.to);
      // makeUnreadDirectMessage(content.fromId, content.to);
    } else if (content.type === 'gc') {
      update(content, show);

      socket.emit('get-unread', {
        fromId: content.fromId,
        toGroupChat: content.to,
      }, content.to);
    }
  });
};

const updateAction = async (token) => {
  return await axios.post(`${backend_base_url()}/api/actions/updateAction`, {
    token: token,
  }, {
      withCredentials: true,
      headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
  });
}; 

const actionIsRecent = async (token) => {
  return await axios.get(`${backend_base_url()}/api/actions/actionIsRecent`, {
    params: {
      token: token
    },
    withCredentials: true
  });
}; 

const getAllGroupChats = async (token) => {
  return await axios.get(`${backend_base_url()}/api/group-chat-conversations/getAllGroupChats`, {
    withCredentials: true
  })
}

export {
  getAllGroupChats,
  actionIsRecent,
  updateAction,
  refreshCookies,
  detectBrowser,
  checkSystemDown,
  checkDeactivated,
  makeUnreadDirectMessage,
  listenToNotifications,
  deepCopy,
  classNames,
  assignPdpa,
  callGoogleAPI,
  getPendingPDPAs,
  findVisibilityForTables,
  checkValidityOfWorkerJobProblem,
  getSessionTimeout,
  deleteImportObjects,
  getImportAttachments,
  addObjectsViaFile,
  getTableNameFromTableSlug,
  exportTable,
  createReport,
  createPresetReport,
  createPresetSkin,
  savePresetSkin,
  saveReport,
  savePresetReport,
  getAnnouncementsToday,
  getAuditLog,
  getMyHistory,
  searchUsers,
  addAuditLog,
  checkWhetherCurrentUserCanViewGroupChat,
  findGroupChatsByGroupChatSlug,
  searchGroupChatConversationByGroupChatSlug,
  searchGroupChatAttachmentsByGroupChatSlug,
  createGroupChatConversation, 
  updateGroupChatConversation,
  findDirectMessagesByFromIdAndToUsername, 
  findDirectMessageConversation,
  findDirectMessageAttachmentsByDirectMessageConversationId,
  findDirectMessageConversationIdBetweenTwoUsers,
  findAllDirectMessageConversationsByUserToken,
  findAllDirectMessageConversationsByUsername,
  findAllDirectMessageAttachmentsByDirectMessageConversation,
  findGroupChatConversation,
  findAllGroupChatConversationsByUserToken,
  findGroupChatsByFromIdAndToUsername,
  findGroupChatConversationIdBetweenTwoUsers,
  findGroupChatAttachmentsByGroupChatConversationId,
  fiftyTruncate,
  fourtyTruncate,
  getUserFromToken,
  downloadPdpaFile,
  downloadFile,
  downloadFiles,
  pdpaDownloadFiles,
  getTableSlugFromDefaultTableSlug,
  getDefaultTableSlugFromTableSlug,
  tokenIsValid, 
  getRoleId, 
  getRole,
  getPrivilegeForCurrentPage,
  getGeneratePresetReportPrivilege,
  initialiseSubHeaderButtons,
  populateActions,
  populateActions2,
  deleteObjects,
  findGroupChatNamesWithUserToken,
  getSchemaByTable,
  getColumnsByTable,
  prepareTableColumnsWithPrivilegeAndInputType,
  prepareDefaultTableColumnsWithPrivilegeAndInputType,
  prepareSubsidiaryTableColumnsWithPrivilegeAndInputType,
  prepareTableColumnsWithPrivilegeAndInputType2,
  prepareTableColumnsWithPrivileges,
  prepareTableColumnsWithPrivileges2,
  checkCompulsoryFields,
  getAllTableAndColumns,
  addObject,
  editObject,
  deleteObject,
  getObject,
  getPresetReportSkinCount,
  getJobByJobIdAndWorkerId,
  getProblemByProblemIdAndJobIdAndWorkerId,
  getObjects,
  findAllRows,
  getDropdownValues,
  searchWorkers,
  searchWorkersBeforeCreating,
  generateTwid,
  generateTempTwid,
  getSubsidiaryColumnsToDisplay,
  prepareSubsidiaryTableColumnsWithPrivileges,
  populateMonthCalendarDays,
  populateWeekCalendarDays,
  populateNavigationStub,
  populateTopAndMiddleNavigation,
  prepareListWithPrivileges,
  getSubsidiaryTableFromSubsidiarySlug,
  editColumnVisibility,
  editDefaultValue,
  getValuesByColumnName,
  getUsernamesByQuery,
  getValueByColumnName,
  displayValueByColumnName,
  findOtherRecordIds,
  updateFacepic,
  getLabelNames,
  updatePassword,
  getProfile,
  updateProfile,
  getListPrivileges,
  getLargestSequence,
  getProblemsByWorkerTwid,
  insertCopiedCaseDiscussion,
  checkCurrentUserCanViewSensitiveDiscussion,
  getNumberOfTables,
  getJobAndProblemByWorkerId,
  checkCurrentUserCanViewSensitiveAttachment,
  searchUserByUsername,
  searchConversedUserByUsername,
  searchGroupChatConversationByGroupChatName,
  getUnreadDirectMessages,
  getUnreadGroupChats,
  zeroUnreadDirectMessageConversation,
  zeroUnreadDirectMessageConversationWithUsername,
  zeroUnreadGroupChatConversationWithUsername,
  countUnreadDirectMessageConversation,
  countUnreadGroupChatConversation,
  increment,
  getUnreadDirectMessageConversation,
  getUnreadGroupChatConversation,
  checkCookieToken,
  getValueFromReference,
  getReferenceValues,
  fileIsAbove25M,
  findJobsAndProblemsByWorkerId
}