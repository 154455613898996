import { useState, useEffect, Fragment, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Combobox } from '@headlessui/react'
import { getMonth, getYear, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, XIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivilegeAndInputType, addObject, listenToNotifications, addAuditLog, checkDeactivated, checkSystemDown, checkCookieToken } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AlertNotification from '../../components/alertNotification'
import SelectOption from '../../components/selectOption'
import { AuthContext } from '../../AuthProvider'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function AnnouncementsAdd() {
  const tableSlug = 'announcements';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [messageInEmail, setMessageInEmail] = useState('');
  const [sendEmailOnSave, setSendEmailOnSave] = useState(false);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Add']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivilegeAndInputType(tableSlug, privilegesToUpdate, 'Add').then(preparedTableColumns => {
        setTableColumns(preparedTableColumns);
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'Add');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        const buttonClicked = event.nativeEvent.submitter.innerHTML;

        if (buttonClicked === 'Save') {
          const result = checkCompulsoryFields(tableColumns);
          if (result.allCompulsoryFieldsHaveBeenFilled) {
            const tableColumnsToSubmit = [...tableColumns];

            tableColumnsToSubmit.push({
              token: cookies['token']
            });
            addObject(tableSlug, tableColumnsToSubmit).then(result => {
              addAuditLog({
                table_name: 'Announcements',
                action_type: 'Create',
                record_id: result.data.id,
                action_description: `Created announcement with id: ${result.data.id}.`,
                user_id: cookies['userId']
              });
              
              navigate(`/${tableSlug}/${result.data.id}`);
            }).catch(err => {
              setOutcome({
                message: err.response.data.message,
                type: 'Danger'
              });
      
              document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
      
              
            });
          } else {
            const emptyFields = result.emptyFields;
      
            emptyFields.forEach((emptyField) => {
              if (document.getElementById(emptyField)) {
                document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
              } else {
                if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                  document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
                }
              }
            });
      
            setOutcome({
              message: 'Compulsory fields have not been filled.',
              type: 'Danger'
            });
      
            
          }
        } else if (buttonClicked === 'Abandon') {
          navigate('/announcements');
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - announcements/add.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - announcements/add.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };

  const handleChange = (column, event) => {
    if (column === 'send_email_on_save') {
      setSendEmailOnSave(event.target.checked);
    } else if (column === 'message_in_email') {
      setMessageInEmail(event.target.value);
    } else {
      const tableColumnsToUpdate = [...tableColumns];
      const selectedDropdownToUpdate = [...selectedDropdown];

      if (event.target !== undefined && event.target.type !== 'checkbox') {
        event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      }

      // if checkbox, handle differently
      if (column['input_type'] === 'checkbox') {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.checked;
      } else if (column['column_name'] === 'tableAndColumn_id') {
        const filteredSelectedDropdownToUpdate = selectedDropdownToUpdate.map((selectedDropdownToUpdateRow) => {
          return selectedDropdownToUpdateRow.column_name
        });

        if (filteredSelectedDropdownToUpdate.indexOf(event.column_name) === -1) {
          tableColumnsToUpdate[column['sequence'] - 1]['value'].push(event);
          selectedDropdownToUpdate.push(event);
        }

        document.getElementsByClassName('w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm')[0].value = '';
      } else {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
      }

      setTableColumns(tableColumnsToUpdate);
      setSelectedDropdown(selectedDropdownToUpdate);
    }
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const tableColumnsToUpdate = [...tableColumns];
    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
    } else {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
    }
    setTableColumns(tableColumnsToUpdate);
  };

  const handleCalendarChange = (column, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    tableColumnsToUpdate[column['sequence'] - 1].value = event;

    setTableColumns(tableColumnsToUpdate);
  };

  const deleteButton = (index, index2, dropdown, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    const selectedDropdownToUpdate = [...selectedDropdown];

    tableColumnsToUpdate[index]['value'].splice(index2, 1);
    selectedDropdownToUpdate.splice(index2, 1);

    setTableColumns(tableColumnsToUpdate);
    setSelectedDropdown(selectedDropdownToUpdate);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        {/* <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={objectsSelected.map((object) => object['dropdown_item'])} single='dropdown item' plural='dropdown items' /> */}
        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">ADD ANNOUNCEMENT BY ADMINISTRATOR</h3>
            </div>
            <div className="border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="items-top sm:grid pb-4 sm:py-4 sm:grid-cols-3 sm:gap-4 px-6">
                  {tableColumns.map((tableColumn, index) => {
                    if (tableColumn['privilege'] === 'Yes') {
                      if (tableColumn['input_type'] === 'checkbox') {
                        return (
                          <>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type="checkbox"
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                onChange={(event) => handleChange(tableColumn, event)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              
                            </dd>
                          </>
                        )
                      } else if (tableColumn['dropdowns'].length > 0) {
                        return (
                          <>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            <SelectOption tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={tableColumn['value']}/>
                            {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </>
                        )
                      } else if (tableColumn['input_type'] === 'datetime-local') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <DatePicker 
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={getYear(date)}
                                      onChange={({ target: { value } }) => changeYear(value)}
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={months[getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                  </div>
                                )}
                                showTimeSelect
                                selected={tableColumn['value']} 
                                onChange={(event) => handleCalendarChange(tableColumn, event)} 
                                dateFormat='yyyy-MMM-dd HH:mm' 
                                autoComplete='off'
                                id={tableColumn['column_name']}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['textarea']) {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <textarea
                                rows={5}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                onChange={(event) => handleChange(tableColumn, event)}
                                value={tableColumn['value']}
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else {
                        return (
                          <>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type={tableColumn['input_type']}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                value={tableColumn['value']}
                                onChange={(event) => handleChange(tableColumn, event)}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </>
                        )
                      }
                    } else if (tableColumn['privilege'] === 'View') {
                      if (tableColumn['label_name'] === 'Created by') {
                        return (
                          <>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type='text'
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={cookies['username']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type={tableColumn['input_type']}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={tableColumn['value']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </>
                        )
                      }
                    }
                  })}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}