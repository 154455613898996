import { Fragment, useState, useEffect, Component, useContext } from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { Link, Navigate, useParams, useNavigate } from "react-router-dom"
import { editColumnVisibility, listenToNotifications } from '../../scripts/helpers'
import axios from 'axios'


import Header from '../../components/header';
import SubHeader2 from '../../components/subheader2'
import AlertNotification from '../../components/alertNotification';
import EditPrivilegeTable from '../../components/privileges/editPrivilegeTable';
import { AuthContext } from '../../AuthProvider'
import { backend_base_url } from '../../utils/helper'

export default function ColumnVisibilitiesEdit() {
  const [tableAndColumnVisibilities, setTableAndColumnVisibilities] = useState({});
  const [subHeaderObj, setSubHeaderObj] = useState([]);
  const [modifiedColumnVisibilities, setModifiedColumnVisibilities] = useState({});
  const [outcome, setOutcome] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const {tableSlug} = params;

  useEffect(() => {
    setSubHeaderObj({
      columnVisibility: true,
      table: tableSlug, 
      page: 'Edit'
    });
    
    axios.get(`${backend_base_url()}/api/tableAndColumns/getColumnVisibilities`, {
      params: {
        tableSlug: tableSlug
      },
      withCredentials: true
    })
      .then(result => {
        setTableAndColumnVisibilities(result.data);
      })
  }, []);

  const handleChange = (index, column, event) => {
    const tableAndColumnVisibilitiesToUpdate = [...tableAndColumnVisibilities];

    tableAndColumnVisibilitiesToUpdate[index][column] = parseInt(event.target.value);

    setTableAndColumnVisibilities(tableAndColumnVisibilitiesToUpdate);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;
    
    if (buttonClicked === 'Save') {
      let counter = 0;

      tableAndColumnVisibilities.forEach((tableAndColumnVisibility, index) => {
        editColumnVisibility(tableAndColumnVisibility['id'], tableAndColumnVisibility).then(result => {
          counter++;

          if (counter === tableAndColumnVisibilities.length) {
            navigate('/column-visibilities');
          }
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });

          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

          
        });
      });
    } else if (buttonClicked === 'Abandon') {
      navigate('/column-visibilities');
    }
  };

  if (tableAndColumnVisibilities.length > 0) {
    return (
      <>
        <Header />
        <form onSubmit={handleFormSubmit}>
          <SubHeader2 subHeaderObj={subHeaderObj} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
          <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <div className="mt-5">
              <div className="sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnVisibilities[0]['table_name']}</h3>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-top inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-300">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Field name
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container C
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container D
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container F
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container G
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container H
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container J
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container K
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container M
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container N
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Container P
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Calendar view
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {tableAndColumnVisibilities.map((tableAndColumnVisibility, index) => 
                            <tr key={index}>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableAndColumnVisibility['column_name']}</td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_c']}
                                  onChange={(event) => handleChange(index, 'container_c', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_d']}
                                  onChange={(event) => handleChange(index, 'container_d', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_f']}
                                  onChange={(event) => handleChange(index, 'container_f', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_g']}
                                  onChange={(event) => handleChange(index, 'container_g', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_h']}
                                  onChange={(event) => handleChange(index, 'container_h', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_j']}
                                  onChange={(event) => handleChange(index, 'container_j', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_k']}
                                  onChange={(event) => handleChange(index, 'container_k', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_m']}
                                  onChange={(event) => handleChange(index, 'container_m', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_n']}
                                  onChange={(event) => handleChange(index, 'container_n', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['container_p']}
                                  onChange={(event) => handleChange(index, 'container_p', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='number' onKeyDown={(event) => event.keyCode !== 69}
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['calendar_view']}
                                  onChange={(event) => handleChange(index, 'calendar_view', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}