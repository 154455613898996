import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { CheckIcon, SelectorIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Disclosure, Transition, Listbox } from '@headlessui/react'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivileges, getAllTableAndColumns, addObject, deleteObject, getSubsidiaryColumnsToDisplay, prepareSubsidiaryTableColumnsWithPrivilegeAndInputType } from '../../../scripts/helpers'
import { getMonth, getYear, format } from 'date-fns'
import DatePicker from 'react-datepicker'

// components
import Header from '../../../components/header'
import SubHeader from '../../../components/subheader'
import Badge from '../../../components/badge'
import Modal from '../../../components/modal'
import AlertNotification from '../../../components/alertNotification'
import TopAndMiddleNavigation from '../../../components/workers/topAndMiddleNavigation'
import NavigationStub from '../../../components/navigationStub'
import SelectOption from '../../../components/selectOption'
import { set, sub } from 'date-fns'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkersSubsidiaryAdd() {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentSingularSubsidiary, setCurrentSingularSubsidiary] = useState('');
  const [subsidiaryColumnsToDisplay, setSubsidiaryColumnsToDisplay] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [subsidiaryTableColumns, setSubsidiaryTableColumns] = useState([]);
  const [navigationStub, setNavigationStub] = useState({});
  const [object, setObject] = useState([]);

  const navigate = useNavigate();
  const params = useParams();

  const {workerId, subsidiarySlug} = params;
  const tableSlug = 'workers';
  
  const privilegeObject = {
    tableSlug: subsidiarySlug, 
    token: cookies['token']
  };

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Add']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivileges(tableSlug, privilegesToUpdate, 'List', workerId, subsidiarySlug).then(preparedTableColumns => {
        setTableColumns(preparedTableColumns);

        prepareSubsidiaryTableColumnsWithPrivilegeAndInputType(subsidiarySlug, privilegesToUpdate, 'Add', '', workerId).then(preparedSubsidiaryTableColumns => {
          setSubsidiaryTableColumns(preparedSubsidiaryTableColumns);
        }); 
      });

    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    getSubsidiaryColumnsToDisplay(subsidiarySlug).then(result => {
      const subsidiaryColumnsToDisplayToUpdate = [];
      result.data.forEach(row => {
        subsidiaryColumnsToDisplayToUpdate.push(row.column_name);
      });

      setSubsidiaryColumnsToDisplay(subsidiaryColumnsToDisplayToUpdate);
      setCurrentSingularSubsidiary(result.data[0]['table_name']);
    });
  }, [subsidiarySlug]);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(subsidiarySlug, privileges, 'Add', workerId);
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    const result = checkCompulsoryFields(subsidiaryTableColumns);
    if (result.allCompulsoryFieldsHaveBeenFilled) {
      const subsidiaryTableColumnsToSubmit = [...subsidiaryTableColumns];
      subsidiaryTableColumnsToSubmit.push({
        token: cookies['token']
      });

      addObject(subsidiarySlug, subsidiaryTableColumnsToSubmit).then(result => {
        navigate(`/workers/${workerId}/${subsidiarySlug}/${result.data.id}`);
      }).catch(err => {
        setOutcome({
          message: err.response.data.message,
          type: 'Danger'
        });

        document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

        
      });
    } else {
      const emptyFields = result.emptyFields;

      emptyFields.forEach((emptyField) => {
        if (document.getElementById(emptyField)) {
          document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
        } else {
          if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
            document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
          }
        }
      });

      setOutcome({
        message: 'Compulsory fields have not been filled.',
        type: 'Danger'
      });

       
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject(tableSlug, workerId).then(result => {
      navigate(`/`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });

      
    });
  };

  const handleChange = (column, event) => {
    const subsidiaryTableColumnsToUpdate = [...subsidiaryTableColumns];

    if (event.target !== undefined && event.target.type !== 'checkbox') {
      event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
    }

    let sequence;

    if (subsidiarySlug === 'facepic-attachments' || subsidiarySlug === 'pdpa-attachments') {
      sequence = column['sequence'];
    } else {
      sequence = column['sequence'] - 1;
    }

    // if checkbox, handle differently
    if (column['input_type'] === 'checkbox') {
      subsidiaryTableColumnsToUpdate[sequence]['value'] = event.target.checked;
    } else {
      subsidiaryTableColumnsToUpdate[sequence]['value'] = event.target.value;
    }

    setSubsidiaryTableColumns(subsidiaryTableColumnsToUpdate);
  };

  const handleFileChange = (column, event) => {
    const subsidiaryTableColumnsToUpdate = [...subsidiaryTableColumns];

    const file = event.target.files[0];

    subsidiaryTableColumnsToUpdate[0]['value'] = file;

    setSubsidiaryTableColumns(subsidiaryTableColumnsToUpdate);
  };

  const handleCalendarChange = (column, event) => {
    const subsidiaryTableColumnsToUpdate = [...subsidiaryTableColumns];

    let sequence;

    if (subsidiarySlug === 'facepic-attachments' || subsidiarySlug === 'pdpa-attachments') {
      sequence = column['sequence'];
    } else {
      sequence = column['sequence'] - 1;
    }

    subsidiaryTableColumnsToUpdate[sequence].value = event;
    setSubsidiaryTableColumns(subsidiaryTableColumnsToUpdate);
  };

  const handleDropdownChange = (tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const subsidiaryTableColumnsToUpdate = [...subsidiaryTableColumns];

    let sequence;

    if (subsidiarySlug === 'facepic-attachments' || subsidiarySlug === 'pdpa-attachments') {
      sequence = tableColumn['sequence'];
    } else {
      sequence = tableColumn['sequence'] - 1;
    }

    if (event.dropdown_item === '-') {
      subsidiaryTableColumnsToUpdate[sequence]['value'] = '';
    } else {
      subsidiaryTableColumnsToUpdate[sequence]['value'] = event.dropdown_item;
    }
    setSubsidiaryTableColumns(subsidiaryTableColumnsToUpdate);
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single='worker' plural='workers' />
        <TopAndMiddleNavigation tableColumns={tableColumns} page='workersSubsidiaryAdd' id={params.workerId} subsidiarySlug={subsidiarySlug} subsidiary={currentSingularSubsidiary} />

        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-20">
          {/* Left side of navigation stub */}
          <NavigationStub tableColumns={tableColumns} />
          {/* Right side of navigation stub */}
          <div>
            <div className="shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">ADD {currentSingularSubsidiary.toUpperCase()}</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="items-center sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                    {subsidiaryTableColumns.map((subsidiaryTableColumn, index) => {
                      if (subsidiaryTableColumn['privilege'] === 'Yes') {
                        if (subsidiaryTableColumn['input_type'] === 'checkbox') {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type="checkbox"
                                  name={subsidiaryTableColumn['column_name']}
                                  id={subsidiaryTableColumn['column_name']}
                                  onChange={(event) => handleChange(subsidiaryTableColumn, event)}
                                  className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else if (subsidiaryTableColumn['input_type'] === 'file') {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type="file"
                                  name={subsidiaryTableColumn['column_name']}
                                  id={subsidiaryTableColumn['column_name']}
                                  onChange={(event) => handleFileChange(subsidiaryTableColumn, event)}
                                />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else if (subsidiaryTableColumn['input_type'] === 'datetime-local') {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <DatePicker 
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                    </div>
                                  )}
                                  showTimeSelect
                                  selected={subsidiaryTableColumn['value']} 
                                  onChange={(event) => handleCalendarChange(subsidiaryTableColumn, event)} 
                                  dateFormat='yyyy-MMM-dd HH:mm' 
                                  id={subsidiaryTableColumn['column_name']}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else if (subsidiaryTableColumn['input_type'] === 'date') {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <DatePicker 
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                    </div>
                                  )}
                                  selected={subsidiaryTableColumn['value']} 
                                  onChange={(event) => handleCalendarChange(subsidiaryTableColumn, event)} 
                                  dateFormat='yyyy-MMM-dd' 
                                  id={subsidiaryTableColumn['column_name']}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else if (subsidiaryTableColumn['dropdowns'].length > 0) {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <SelectOption tableColumn={subsidiaryTableColumn} column={subsidiaryTableColumn['column_name']} values={subsidiaryTableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={subsidiaryTableColumn['value']}/>
                              {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']} {subsidiaryTableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type={subsidiaryTableColumn['input_type']}
                                  name={subsidiaryTableColumn['column_name']}
                                  id={subsidiaryTableColumn['column_name']}
                                  value={subsidiaryTableColumn['value']}
                                  onChange={(event) => handleChange(subsidiaryTableColumn, event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        }
                      } else if (subsidiaryTableColumn['privilege'] === 'View') {
                        if (subsidiaryTableColumn['label_name'] === 'Created by') {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type={subsidiaryTableColumn['input_type']}
                                  name={subsidiaryTableColumn['column_name']}
                                  id={subsidiaryTableColumn['column_name']}
                                  className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                  placeholder={cookies['username']}
                                  value={cookies['token']}
                                  disabled
                                />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        } else {
                          return (
                            <>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{subsidiaryTableColumn['label_name']}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type={subsidiaryTableColumn['input_type']}
                                  name={subsidiaryTableColumn['column_name']}
                                  id={subsidiaryTableColumn['column_name']}
                                  className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                  placeholder={subsidiaryTableColumn['value']}
                                  disabled
                                />
                                {subsidiaryTableColumn['remarks'] === 'NULL' || subsidiaryTableColumn['remarks'] === null || subsidiaryTableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{subsidiaryTableColumn['remarks']}</p>}
                              </dd>
                            </>
                          )
                        }
                      }
                    })}
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}