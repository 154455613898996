import { useState, useEffect, useRef, Fragment} from 'react'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/solid'
import { getObject, displayValueByColumnName, findOtherRecordIds } from '../../../scripts/helpers'
import { format } from 'date-fns'
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'

// components
import Header from '../../../components/header'
import AlertNotification from '../../../components/alertNotification'
import MiniNavigationStub from '../../../components/miniNavigationStub'
import SubHeader2 from '../../../components/subheader2'
import SelectOption from '../../../components/selectOption'
import ComboBox from '../../../components/comboBox'
import Loading from '../../../components/loading'
import { frontend_base_url, backend_base_url } from '../../../utils/helper'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Abridged(props) {
  const editorRef = useRef();
  const {rightStubObj, subHeaderObj, outcome, setOutcome} = props;
  const params = useParams();

  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const [level, setLevel] = useState('workers');

  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({});
  const [editorInstance, setEditorInstance] = useState({});
  const [originalPoster, setOriginalPoster] = useState('');
  const [otherRecordIds, setOtherRecordIds] = useState([]);

  const navigate = useNavigate();
  const {workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction} = params;

  const tableSlug = 'workers';

  const navigateToObject = (event, objectId) => {
    if (event.target.checked === undefined) {
      navigate(`/${tableSlug}/${workerId}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${objectId}`)
    }
  };

  const navigateToAttachmentObject = (event, object) => {
    if (event.target.checked === undefined) {
      if (rightStubObj.workerSubsidiarySlugOrWorkerAction !== undefined && rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined && rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === undefined) {
        if (object.worker_id !== null && object.job_id === null && object.problem_id === null) {
          // worker level
          navigate(`/workers/${workerId}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id === null) {
          // job level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id !== null) {
          // problem level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/problems/${object.problem_id}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${object.id}`);
        }
      } else if (rightStubObj.workerSubsidiarySlugOrWorkerAction !== undefined && rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === undefined) {
        if (object.worker_id !== null && object.job_id === null && object.problem_id === null) {
          // worker level
          navigate(`/workers/${workerId}/${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id === null) {
          // job level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id !== null) {
          // problem level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/problems/${object.problem_id}/${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/${object.id}`);
        }
      } else if (rightStubObj.workerSubsidiarySlugOrWorkerAction !== undefined && rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined) {
        if (object.worker_id !== null && object.job_id === null && object.problem_id === null) {
          // worker level
          navigate(`/workers/${workerId}/${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id === null) {
          // job level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction}/${object.id}`);
        } else if (object.worker_id !== null && object.job_id !== null && object.problem_id !== null) {
          // problem level
          navigate(`/workers/${workerId}/jobs/${object.job_id}/problems/${object.problem_id}/${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction}/${object.id}`);
        }
      }
    }
  };

  useEffect(() => {
    if (document.getElementsByClassName('tui-image-editor-header-buttons')[0] !== undefined) {
      setEditorInstance(editorRef.current.getInstance());
      document.getElementsByClassName('tui-image-editor-help-menu top')[0].style.background = 'transparent';
      document.getElementsByClassName('tui-image-editor-header-buttons')[0].remove();
      document.getElementsByClassName('tui-image-editor-header-logo')[0].remove();
      document.getElementsByClassName('tui-image-editor-button preset preset-square')[0].remove();
      document.getElementsByClassName('tie-btn-zoomIn')[0].remove();
      document.getElementsByClassName('tie-btn-zoomOut')[0].remove();
      document.getElementsByClassName('tie-btn-hand')[0].remove();
      document.getElementsByClassName('tui-image-editor-item')[0].remove();
      document.getElementsByClassName('tui-image-editor-item')[4].remove();

      document.getElementsByClassName('tie-crop-preset-button')[0].innerHTML = `<div class='tui-image-editor-button preset preset-none'><div><svg class='svg_ic-submenu'><use xlink:href='#ic-shape-rectangle' class='normal use-default'></use><use xlink:href='#ic-shape-rectangle' class='active use-default'></use></svg></div><label> Custom </label></div><div class='tui-image-editor-button preset preset-3-4 active'><div><svg class='svg_ic-submenu'><use xlink:href='#ic-crop' class='normal use-default'></use><use xlink:href='#ic-crop' class='active use-default'></use></svg></div><label> 3:4 </label></div>`;

      document.getElementsByClassName("tui-image-editor-button")[2].addEventListener("click", () => {
          const rightStubObjToUpdate = {...rightStubObj};

          rightStubObjToUpdate['image_edited'] = true;

          rightStubObj.setRightStubObj(rightStubObjToUpdate);
      });
    }
    
    if (Object.keys(rightStubObj).length > 0) {
      if (
        rightStubObj.page === 'View' || 
        rightStubObj.page === 'Add' || 
        rightStubObj.page === 'Edit'
      ) {
        rightStubObj.tableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined && tableColumn.reference_synced === false) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const storedAsColumnName = storedAs.data.column_name;

                displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn.value).then((displayValue) => {
                  const rightStubObjToUpdate = {...rightStubObj};
                  const tableColumnsToUpdate = [...rightStubObj.tableColumns];

                  tableColumn['reference_synced'] = true;

                  tableColumnsToUpdate[tableColumn['sequence'] - 1].value = displayValue.data[Object.keys(displayValue.data)[0]];

                  rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                  rightStubObj.setRightStubObj(rightStubObjToUpdate);
                })
              });
            });
          }
        });

        if (rightStubObj.page === 'View' && workerSubsidiarySlugOrWorkerAction !== undefined) {
          findOtherRecordIds(workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction).then(result => {
            setOtherRecordIds(result.data.map((row) => row.id));
          });
        } else if (rightStubObj.page === 'Edit' && workerSubsidiarySlugOrWorkerAction === 'facepic-attachments') {
          // document.getElementsByClassName('FIE_topbar-save-button')[0].childNodes[0].innerHTML = 'Fix Changes';
        }
      } else if (
          rightStubObj.page === 'List' && 
          rightStubObj.workerSubsidiarySlugOrWorkerAction !== 'ordinary-attachments' && 
          rightStubObj.workerSubsidiarySlugOrWorkerAction !== 'sensitive-attachments' &&
          rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== 'ordinary-attachments' && 
          rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== 'sensitive-attachments' &&
          rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction !== 'ordinary-attachments' && 
          rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction !== 'sensitive-attachments'
      ) {
        if (rightStubObj.tableColumns[page - 1] !== undefined) {
          rightStubObj.tableColumns[page - 1].forEach((tableColumn) => {
            Object.keys(tableColumn).forEach((key, index) => {
              if (rightStubObj['referenceData'][key]) {
                if (rightStubObj['referenceData'][key]['reference'] !== null && rightStubObj['referenceData'][key]['reference_synced'] === false) {
                  getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['view']).then(referenced => {
                    const referencedTableSlug = referenced.data.table_slug;
                    const referencedColumnName = referenced.data.column_name;
    
                    getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['store']).then(storedAs => {
                      const storedAsColumnName = storedAs.data.column_name;
    
                      displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn[key]).then((displayValue) => {
                        const rightStubObjToUpdate = {...rightStubObj};
                        const tableColumnsToUpdate = [...rightStubObj.tableColumns];
                        tableColumn[key] = displayValue.data[Object.keys(displayValue.data)[0]];
                        rightStubObjToUpdate['referenceData'][key]['reference_synced'] = true;
    
                        rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                        rightStubObj.setRightStubObj(rightStubObjToUpdate);
                      })
                    });
                  });
                }
              }
            });
          });
        }
      } else if (
          rightStubObj.page === 'List' && 
          (rightStubObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || 
          rightStubObj.workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments' || 
          rightStubObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || 
          rightStubObj.workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments')
      ) {
        rightStubObj.tableColumns.forEach((tableColumn) => {
          Object.keys(tableColumn).forEach((key, index) => {
            if (rightStubObj['referenceData'][key] !== undefined) {
              if (rightStubObj['referenceData'][key]['reference'] !== null && rightStubObj['referenceData'][key]['reference_synced'] === false) {
                getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['view']).then(referenced => {
                  const referencedTableSlug = referenced.data.table_slug;
                  const referencedColumnName = referenced.data.column_name;
  
                  getObject('tableAndColumns', rightStubObj['referenceData'][key]['reference']['store']).then(storedAs => {
                    const storedAsColumnName = storedAs.data.column_name;
  
                    displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn[key]).then((displayValue) => {
                      const rightStubObjToUpdate = {...rightStubObj};
                      const tableColumnsToUpdate = [...rightStubObj.tableColumns];
                      tableColumn[key] = displayValue.data[Object.keys(displayValue.data)[0]];
                      rightStubObjToUpdate['referenceData'][key]['reference_synced'] = true;
  
                      rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                      rightStubObj.setRightStubObj(rightStubObjToUpdate);
                    })
                  });
                });
              }
            }
          });
        });
      }
    }
    setIsImgEditorShown(true);

    if (rightStubObj.table === 'Sensitive Attachment') {
      for (let index = 0; index < rightStubObj.tableColumns.length; index++) {
        if (rightStubObj.tableColumns[index]['column_name'] === 'attachment_submitted_by') {
          setOriginalPoster(rightStubObj.tableColumns[index]['value']);
          break;
        }
      }
    }

  }, [rightStubObj]);

  useEffect(() => {
    const privilegeObject = {
      tableSlug: '', 
      token: cookies['token']
    };
  }, []);

  useEffect(() => {
    if (Object.keys(editorInstance).length > 0) {
      setTimeout(() => {
        editorRef.current.getRootElement().getElementsByClassName('tui-image-editor-button preset preset-3-4')[0].addEventListener('click', () => {
          setTimeout(() => {
            editorInstance.setCropzoneRect(3/4); 
          }, 50);
        });
        editorRef.current.getRootElement().getElementsByClassName('tie-btn-crop tui-image-editor-item normal')[0].addEventListener('click', () => {
          document.getElementsByClassName('tui-image-editor-button preset preset-3-4')[0].className += ' active';
          setTimeout(() => {
            editorInstance.setCropzoneRect(3/4); 
          }, 50);
        });
      }, 50);
    }
  }, [editorInstance]);

  const navigateToForwardPage = () => {
    if (page < Math.ceil(rightStubObj.noOfRecords / 10)) {
      setPage(page + 1);
      setSort({});
    }
  };

  const navigateToBackwardPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setSort({});
    }
  };

  const myTheme = {
    // Theme object to extends default dark theme.
    // download: false
  };

  if (Object.keys(rightStubObj).length > 0) {
    return (
      <>
        <Header />
        <form onSubmit={(event) => {
            if (rightStubObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' && rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'edit') {
              const rightStubObjToUpdate = {...rightStubObj};
              rightStubObjToUpdate.updatedFacepic = editorInstance.toDataURL();
              rightStubObj.setRightStubObj(rightStubObjToUpdate);
              rightStubObj.handleFormSubmit(event, rightStubObjToUpdate);
            } else {
              rightStubObj.handleFormSubmit(event, rightStubObj)
            }
          }}>
          <SubHeader2 subHeaderObj={subHeaderObj} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
          
          <div className="mt-5 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
            {/* Left side of navigation stub */}
            <MiniNavigationStub problemId={jobSubsidiaryIdOrJobSubsidiaryAction} jobId={workerSubsidiaryIdOrWorkerSubsidiaryAction} workerId={workerId} level={level} rightStubObj={rightStubObj} />
          </div>
          {rightStubObj.workerSubsidiaryIdOrWorkerSubsidiaryAction === 'add' || rightStubObj.jobSubsidiaryIdOrJobSubsidiaryAction === 'add' || rightStubObj.problemSubsidiaryIdOrProblemSubsidiaryAction === 'add' ? 
            (
              <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
                <div>
                  <div className="shadow overflow-hidden">
                    <div className="bg-gray-100 px-2 py-1">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                    </div>
                    <div className="border-t border-gray-200 p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                          {rightStubObj.tableColumns.map((tableColumn, index) => {
                            if (tableColumn['privilege'] === 'Yes') {
                              if (tableColumn['input_type'] === 'checkbox') {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <input
                                        type="checkbox"
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                        onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                        className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      />
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                    </dd>
                                  </Fragment>
                                )
                              } else if (tableColumn['input_type'] === 'file') {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <input
                                        type="file"
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        onChange={(event) => {
                                          let filename = event.target.value.replace(/.*[\/\\]/, '');
                                          let filenameArray = filename.split('.');
                                          filenameArray.pop();
                                          let attachmentName = filenameArray.join('');
                                          attachmentName = attachmentName.replace(/[^a-z%0-9.\/_]/ig, '-');
                                          rightStubObj.handleChange(rightStubObj, null, null, attachmentName);
                                          rightStubObj.handleFileChange(rightStubObj, tableColumn, event);
                                        }}
                                      />
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                    </dd>
                                  </Fragment>
                                )
                              } else if (tableColumn['input_type'] === 'combobox') {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <ComboBox reference={tableColumn['reference']} mode={rightStubObj.page} rightStubObj={rightStubObj} handleComboBoxChange={rightStubObj.handleComboBoxChange} deleteButton={rightStubObj.deleteButton} tableColumn={tableColumn} />
                                    </dd>
                                  </Fragment>
                                )
                              } else if (tableColumn['dropdowns'].length > 0) {
                                if (tableColumn.column_name === 'employer_name') {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleJobChange} selectedValue={tableColumn['value']}/>
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                } else {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleDropdownChange} selectedValue={tableColumn['value']}/>
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                }
                              } else if (tableColumn['input_type'] === 'list') {
                                if (tableColumn['column_name'] === 'tagged_users') {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        {Object.keys(tableColumn.value).length > 0 ? (
                                          <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                            {Object.keys(tableColumn.value).map((key, index) => {
                                              return (
                                                <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                                  <div className="w-full">
                                                    <p className="ml-0 mb-0 font-bold flex-1">{key}</p>
                                                  </div>
                                                  <div className="ml-4 flex-shrink-0">
                                                    <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={(event) => rightStubObj.handleComboBoxChange(rightStubObj, null, null, tableColumn['column_name'], null, null, key)} />
                                                  </div>
                                                </li>
                                              )
                                            })}
                                          </ul>
                                        ) : (
                                          <Fragment>
                                            <input
                                              type='text'
                                              className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                              disabled
                                            />
                                          </Fragment>
                                        )}
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                }
                              } else {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <input
                                        type={tableColumn['input_type']}
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        value={tableColumn['value']}
                                        onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                        onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      />
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                    </dd>
                                  </Fragment>
                                )
                              }
                            } else if (tableColumn['privilege'] === 'View') {
                              if (tableColumn['label_name'] === 'Created by' || tableColumn['label_name'] === 'Submitted by') {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <input
                                        type='text'
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                        placeholder={cookies['username']}
                                        disabled
                                      />
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                    </dd>
                                  </Fragment>
                                )
                              } else {
                                return (
                                  <Fragment key={index}>
                                    <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                      <input
                                        type={tableColumn['input_type']}
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                        placeholder={tableColumn['value']}
                                        disabled
                                      />
                                      {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                    </dd>
                                  </Fragment>
                                )
                              }
                            }
                          })}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ) : 
            (
              <></>
            )
          }
          {rightStubObj.problemSubsidiaryAction === 'edit' || rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'edit' || rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'edit' ? 
            (
              <div>
                <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1">
                  <div>
                    <div className="shadow overflow-hidden">
                      <div className="bg-gray-100 px-2 py-1">
                        <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                      </div>
                      <div className="border-t border-gray-200 p-0">
                        {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' ? 
                  (
                    <div style={{width: '100%', height: '950px'}}>
                      <ImageEditor
                        ref={editorRef} 
                        includeUI={{
                          loadImage: {
                            path: `${backend_base_url()}/api/attachments/downloadFile?filename=${rightStubObj.tableColumns.filter(row => row.column_name === 'file_path')[0]['value'].substring(rightStubObj.tableColumns.filter(row => row.column_name === 'file_path')[0]['value'].lastIndexOf('/') + 1)}`,
                            name: 'SampleImage',
                          },
                          theme: myTheme,
                          menu: ['crop', 'rotate'],
                          initMenu: '',
                          uiSize: {
                            width: '100%',
                            height: '950px',
                          },
                          menuBarPosition: 'bottom',
                        }}
                        cssMaxHeight={500}
                        cssMaxWidth={700}
                        selectionStyle={{
                          cornerSize: 20,
                          rotatingPointOffset: 70,
                        }}
                        usageStatistics={false}
                      />
                    </div>
                  ) : (<></>)}
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                            {rightStubObj.tableColumns.map((tableColumn, index) => {
                              if (tableColumn['privilege'] === 'Yes') {
                                if (tableColumn['input_type'] === 'checkbox') {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <input
                                          type="checkbox"
                                          name={tableColumn['column_name']}
                                          id={tableColumn['column_name']}
                                          onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                          onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                          className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                        />
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                } else if (tableColumn['dropdowns'] !== undefined && tableColumn['dropdowns'].length > 0) {
                                  if (tableColumn.column_name === 'employer_name') {
                                    return (
                                      <Fragment key={index}>
                                        <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleJobChange} selectedValue={tableColumn['value']}/>
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                        </dd>
                                      </Fragment>
                                    )
                                  } else {
                                    return (
                                      <Fragment key={index}>
                                        <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleDropdownChange} selectedValue={tableColumn['value']}/>
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                        </dd>
                                      </Fragment>
                                    )
                                  }
                                } else if (tableColumn['input_type'] === 'combobox') {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <ComboBox reference={tableColumn['reference']} mode={rightStubObj.page} rightStubObj={rightStubObj} handleComboBoxChange={rightStubObj.handleComboBoxChange} deleteButton={rightStubObj.deleteButton} tableColumn={tableColumn} />
                                      </dd>
                                    </Fragment>
                                  )
                                } else {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <input
                                          type={tableColumn['input_type']}
                                          name={tableColumn['column_name']}
                                          id={tableColumn['column_name']}
                                          value={tableColumn['value']}
                                          onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                          onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                          className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        />
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                }
                              } else if (tableColumn['privilege'] === 'View') {
                                if (tableColumn['column_name'] === 'tagged_users') {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        {Object.keys(tableColumn.value).length > 0 ? (
                                          <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                            {Object.keys(tableColumn.value).map((key, index) => {
                                              return (
                                                <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                                  <div className="w-full">
                                                    <p className="ml-0 mb-0 font-bold flex-1">{key}</p>
                                                  </div>
                                                  <div className="ml-4 flex-shrink-0">
                                                    {key !== originalPoster ? <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={(event) => rightStubObj.handleComboBoxChange(rightStubObj, null, null, tableColumn['column_name'], null, null, key)} /> : '' }
                                                  </div>
                                                </li>
                                              )
                                            })}
                                          </ul>
                                        ) : (
                                          <Fragment>
                                            <input
                                              type='text'
                                              className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                              disabled
                                            />
                                          </Fragment>
                                        )}
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                } else {
                                  return (
                                    <Fragment key={index}>
                                      <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                        <input
                                          type='text'
                                          name={tableColumn['column_name']}
                                          id={tableColumn['column_name']}
                                          className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                          placeholder={tableColumn['value']}
                                          disabled
                                        />
                                        {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                      </dd>
                                    </Fragment>
                                  )
                                }
                              }
                            })}
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : 
            (
              <></>
            )
          }
          {rightStubObj.page === 'View' ? 
            (
              <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
                <div>
                  <div className="shadow overflow-hidden">
                    <div className="bg-gray-100 px-2 py-1 flex justify-between">
                      <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                    </div>
                    <div className="border-t border-gray-200 p-0">
                      <dl className="divide-y divide-gray-200">
                        {rightStubObj.tableColumns.map((tableColumn, index) => {
                          if (tableColumn['privilege'] === 'Yes') {
                            if (tableColumn['label_name'] === 'Attachment path') {
                              return (
                                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                                  <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                  <dd className="text-sm sm:col-span-2">
                                    {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' ? 
                                      (
                                        <>
                                        <img src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`}/>
                                        
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/facepic-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/facepic-attachments/downloadFacepic?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&recordId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                        
                                        </>
                                      ) : (<></>)}
                                    {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/pdpa-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/pdpa-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&recordId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/ordinary-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/ordinary-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&recordId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/ordinary-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/ordinary-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&jobId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&recordId=${rightStubObj['jobSubsidiaryIdOrJobSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/ordinary-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/ordinary-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&jobId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&problemId=${rightStubObj['jobSubsidiaryIdOrJobSubsidiaryAction']}&recordId=${rightStubObj['problemSubsidiaryIdOrProblemSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/sensitive-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/sensitive-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&recordId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/sensitive-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/sensitive-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&jobId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&recordId=${rightStubObj['jobSubsidiaryIdOrJobSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments' ? 
                                    (
                                      <>
                                        <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/sensitive-attachments/${tableColumn['value'].substring(tableColumn['value'].lastIndexOf('/') + 1)}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                        <div className="mt-3 mb-3">
                                        <a href={`${backend_base_url()}/api/sensitive-attachments/downloadFile?filepath=${tableColumn['value']}&workerId=${rightStubObj['workerId']}&jobId=${rightStubObj['workerSubsidiaryIdOrWorkerSubsidiaryAction']}&problemId=${rightStubObj['jobSubsidiaryIdOrJobSubsidiaryAction']}&recordId=${rightStubObj['problemSubsidiaryIdOrProblemSubsidiaryAction']}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                        </div>
                                      </>
                                    ) : ''}
                                    {/* <a href='#' className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} onClick={() => downloadTempFile(tableColumn['value'], rightStubObj.tableColumns.filter((row) => row.column_name === 'filename')[0]['value'])}>{tableColumn['value']}</a> */}
                                  </dd>
                                </div>
                              )
                            } else if (tableColumn['label_name'] === 'Created by') {
                              return (
                                <Fragment key={index}>
                                  <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                                    <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                    <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                                      {cookies['username']}
                                    </dd>
                                  </div>
                                </Fragment>
                              )
                            } else if (tableColumn['column_name'] === 'tagged_users') {
                              return (
                                <Fragment key={index}>
                                  <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                                    <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                    <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                                      {tableColumn.value.length > 0 ? (
                                      <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                        {tableColumn.value.map((row, index) => {
                                          return (
                                            <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                              <div className="w-full">
                                                <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                              </div>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                      ) : (<></>)}
                                    </dd>
                                  </div>
                                </Fragment>
                                // <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                                //   <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                //   <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                                //     {tableColumn.value.length > 0 ? (
                                //       <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                //         {tableColumn.value.map((row, index) => {
                                //           return (
                                //             <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                //               <div className="w-full">
                                //                 <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                //               </div>
                                //             </li>
                                //           )
                                //         })}
                                //       </ul>
                                //     ) : (<></>)}
                                //   </dd>
                                // </div>
                              )
                            } else {
                              return (
                                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                                  <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                  <dd className="text-sm sm:col-span-2" style={{overflowWrap: "break-word"}}>
                                    {tableColumn['value']}
                                  </dd>
                                </div>
                              )
                            }
                          }
                        })}
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ) : 
            (
              <></>
            )
          }


          {/* list ordinary attachment, sensitive attachment */}
          {
            (
              (
                (
                rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'ordinary-attachments' || 
                rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction === 'sensitive-attachments'
                ) && rightStubObj.problemSubsidiaryIdOrProblemSubsidiaryAction === undefined
              )
              ||
              (
                (
                rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'ordinary-attachments' || 
                rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'sensitive-attachments'
                ) && rightStubObj.jobSubsidiaryIdOrJobSubsidiaryAction === undefined
              )
              ||
              (
                (
                rightStubObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments' || 
                rightStubObj.workerSubsidiarySlugOrWorkerAction === 'sensitive-attachments'
                ) && rightStubObj.workerSubsidiaryIdOrWorkerSubsidiaryAction === undefined
              )
            ) ? 
            (
              <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1">
                <div>
                  <div className="shadow overflow-hidden">
                    <div className="bg-gray-100 px-2 py-1 flex justify-between">
                      <span>
                        <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                        <p className="text-sm font-medium text-gray-500">{rightStubObj['noOfRecords'] ? rightStubObj['noOfRecords'] : 0} records</p>
                      </span>
                      {Math.ceil(rightStubObj['noOfRecords'] / 10) > 1 ? 
                        (
                          <span className="relative z-0 inline-flex shadow-sm">
                            <button
                              type="button"
                              className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToBackwardPage}
                            >
                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                              disabled
                            >
                              {page} of {Math.ceil(rightStubObj['noOfRecords'] / 10)}
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToForwardPage}
                            >
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                          </span>
                        ) : (<></>)}
                    </div>
                    <div className="border-t border-gray-200 p-0">
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="select"
                                    >
                                      Select
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="record_created"
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'date_record_created', page)}>
                                        Record created 
                                        {rightStubObj.sort['date_record_created'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['date_record_created'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="employer"
                                    >
                                        <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'employer_name', page)}>
                                        Employer
                                        {rightStubObj.sort['employer_name'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['employer_name'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="problem"
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'chief_problem', page)}>
                                        Problem 
                                        {rightStubObj.sort['chief_problem'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['chief_problem'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="submitted_by"
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'submitted_by', page)}>
                                        Submitted by
                                        {rightStubObj.sort['submitted_by'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['submitted_by'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="attachment_name"
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'attachment_name', page)}>
                                        Attachment name 
                                        {rightStubObj.sort['attachment_name'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['attachment_name'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                      key="file_size"
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort3(rightStubObj, 'file_size', page)}>
                                        File size (kb) 
                                        {rightStubObj.sort['file_size'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {rightStubObj.sort['file_size'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {rightStubObj.tableColumns !== undefined && rightStubObj.tableColumns.length > 0 ?
                                  (
                                    <Fragment>
                                      {rightStubObj.tableColumns[page-1].map((tableColumn, index) => {
                                        return (
                                          <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToAttachmentObject(event, tableColumn)}>
                                            {(rightStubObj.table === 'ORDINARY ATTACHMENTS' || (rightStubObj.table === 'SENSITIVE ATTACHMENTS' && (cookies['role'] === 'Admin' || cookies['role'] === 'Manager'))) ? 
                                              (
                                                <Fragment key={index}>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    <input
                                                      id={`select-${index}`}
                                                      aria-describedby="select-attachment"
                                                      type="checkbox"
                                                      value={tableColumn}
                                                      checked={tableColumn['value']}
                                                      onChange={(event) => rightStubObj.handleOnChangeCheckbox(event, rightStubObj, rightStubObj.tableColumns[page-1][index]['id'], rightStubObj.tableColumns[page-1][index])}
                                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                                      style={{marginBottom: '2px'}}
                                                    />
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">{format(new Date(rightStubObj.tableColumns[page-1][index]['date_record_created']), 'yyyy-MMM-dd HH:mm')}</td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['job'] === undefined || rightStubObj.tableColumns[page-1][index]['job'] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['job']['employer_name']}
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['problem'] === undefined || rightStubObj.tableColumns[page-1][index]['problem'] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['problem']['chief_problem']}
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['submitter'] === undefined || rightStubObj.tableColumns[page-1][index]['submitter'] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['submitter']['user_username']}
                                                    </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index] === undefined || rightStubObj.tableColumns[page-1][index] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['attachment_name']}
                                                    </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index] === undefined || rightStubObj.tableColumns[page-1][index] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['file_size']}
                                                    </td>
                                                </Fragment>
                                              ) : 
                                              (<></>)
                                            }

                                            {(rightStubObj.table === 'SENSITIVE ATTACHMENTS' && cookies['role'] !== 'Admin' && cookies['role'] !== 'Manager') ? 
                                              (
                                                <Fragment key={index}>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    <input
                                                      id={`select-${index}`}
                                                      aria-describedby="select-attachment"
                                                      type="checkbox"
                                                      value={tableColumn}
                                                      checked={tableColumn['value']}
                                                      onChange={(event) => rightStubObj.handleOnChangeCheckbox(event, rightStubObj, rightStubObj.tableColumns[page-1][index]['id'], rightStubObj.tableColumns[page-1][index])}
                                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                                      style={{marginBottom: '2px'}}
                                                    />
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">{format(new Date(rightStubObj.tableColumns[page-1][index]['date_record_created']), 'yyyy-MMM-dd HH:mm')}</td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['employer_name'] !== null ? 
                                                      rightStubObj.tableColumns[page-1][index]['employer_name'] : ''}
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['chief_problem'] !== null ? 
                                                      rightStubObj.tableColumns[page-1][index]['chief_problem'] : ''}
                                                  </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index]['user_username'] !== null ? 
                                                      rightStubObj.tableColumns[page-1][index]['user_username'] : ''}
                                                    </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index] === undefined || rightStubObj.tableColumns[page-1][index] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['attachment_name']}
                                                    </td>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    {rightStubObj.tableColumns[page-1][index] === undefined || rightStubObj.tableColumns[page-1][index] === null ? 
                                                      '' : rightStubObj.tableColumns[page-1][index]['file_size'] / 1000}
                                                    </td>
                                                </Fragment>
                                              ) : 
                                              (<></>)
                                            }
                                          </tr>
                                        )
                                      })}
                                    </Fragment>
                                  ) : '' }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (<></>)
          }

          {/* list jobs, problems, pdpa, facepic*/}
          {
            (
              ( 
                rightStubObj.page === 'List' &&
                rightStubObj.jobId === undefined && 
                (
                  rightStubObj.workerSubsidiarySlugOrWorkerAction !== 'ordinary-attachments' && 
                  rightStubObj.workerSubsidiarySlugOrWorkerAction !== 'sensitive-attachments'
                )
              )
            ) ? 
            (
              <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1">
                <div>
                  <div className="shadow overflow-hidden">
                    <div className="bg-gray-100 px-2 py-1 flex justify-between">
                      <span>
                        <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                        <p className="text-sm font-medium text-gray-500">{rightStubObj['noOfRecords'] ? rightStubObj['noOfRecords'] : 0} records</p>
                      </span>
                      {Math.ceil(rightStubObj['noOfRecords'] / 10) > 1 ? 
                        (
                          <span className="relative z-0 inline-flex shadow-sm">
                            <button
                              type="button"
                              className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToBackwardPage}
                            >
                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                              disabled
                            >
                              {page} of {Math.ceil(rightStubObj['noOfRecords'] / 10)}
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToForwardPage}
                            >
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                          </span>
                        ) : (<></>)}
                    </div>
                    <div className="border-t border-gray-200 p-0">
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    {(rightStubObj.workerSubsidiarySlugOrWorkerAction === 'facepic-attachments' || rightStubObj.workerSubsidiarySlugOrWorkerAction === 'pdpa-attachments' || rightStubObj.workerSubsidiarySlugOrWorkerAction === 'ordinary-attachments') ? 
                                      (
                                        <th
                                          scope="col"
                                          className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                          key="select"
                                        >
                                          Select
                                        </th>
                                      ) : 
                                      (<></>)
                                    }
                                    {rightStubObj.labelColumns !== undefined ? 
                                      (
                                        rightStubObj.labelColumns.map((label, index) => {
                                          return (
                                            <th
                                              scope="col"
                                              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase align-top"
                                              key={index}
                                            >
                                              <span className="group inline-flex cursor-pointer" onClick={() => rightStubObj.toggleSort2(rightStubObj, index, page)}>
                                                {label}
                                                {rightStubObj.sort[index] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                                {rightStubObj.sort[index] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                              </span>
                                            </th>
                                          )
                                        })
                                      ) : (<></>)}
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {rightStubObj['noOfRecords'] > 0 ?
                                    <Fragment>
                                      {rightStubObj.tableColumns[page - 1] !== undefined ? 
                                        (
                                          rightStubObj.tableColumns[page - 1].map((tableColumn, index) => {
                                            if (rightStubObj.table === 'JOBS' || rightStubObj.table === 'PROBLEMS') {
                                              return (
                                                <tr key={index} className="cursor-pointer hover:bg-gray-50 align-top" onClick={(event) => navigateToObject(event, tableColumn.id)}>
                                                  {rightStubObj.dashedLabelColumns.map((key, index2) => {
                                                    const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
                                                    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                                                    if (!rightStubObj.stringColumns[key] && String(rightStubObj.tableColumns[page - 1][index][key]).match(timestampRegex)) {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd HH:mm')}</td>
                                                    } else if (!rightStubObj.stringColumns[key] && String(rightStubObj.tableColumns[page - 1][index][key]).match(dateRegex)) {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd')}</td>
                                                    } else {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{rightStubObj.tableColumns[page - 1][index][key]}</td>
                                                    }
                                                  })}
                                                </tr>
                                              )
                                            } else {
                                              return (
                                                <tr key={index} className="cursor-pointer hover:bg-gray-50 align-top" onClick={(event) => navigateToObject(event, tableColumn.id)}>
                                                  <td className="px-2 py-1 text-sm text-gray-900 align-top">
                                                    <input
                                                      id={`select-${index}`}
                                                      aria-describedby="select-attachment"
                                                      type="checkbox"
                                                      value={tableColumn}
                                                      checked={tableColumn['value']}
                                                      onChange={(event) => rightStubObj.handleOnChangeCheckbox(event, rightStubObj, rightStubObj.tableColumns[page-1][index]['id'], rightStubObj.tableColumns[page-1][index])}
                                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                                      style={{marginBottom: '2px'}}
                                                    />
                                                  </td>
                                                  {rightStubObj.dashedLabelColumns.map((key, index2) => {
                                                    const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
                                                    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                                                    if (!rightStubObj.stringColumns[key] && String(rightStubObj.tableColumns[page - 1][index][key]).match(timestampRegex)) {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd HH:mm')}</td>
                                                    } else if (!rightStubObj.stringColumns[key] && String(rightStubObj.tableColumns[page - 1][index][key]).match(dateRegex)) {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd')}</td>
                                                    } else {
                                                      return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{rightStubObj.tableColumns[page - 1][index][key]}</td>
                                                    }
                                                  })}
                                                </tr>
                                              )
                                            }
                                          })
                                        ) : (<></>)
                                      }
                                    </Fragment>
                                    : '' }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (<></>)
          }
        </form>
      </>
    )
  } else {
    return (
      <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
    )
  }
}
