import { Fragment, useState, useEffect, useContext } from 'react'
import Header from '../../components/header'
import { format, isToday, isYesterday } from 'date-fns'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { useCookies } from 'react-cookie'
import { CheckIcon, PencilIcon, XIcon, DotsVerticalIcon, ChevronDoubleDownIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Combobox} from '@headlessui/react'
import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  SearchCircleIcon,
  MenuIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { fourtyTruncate, fiftyTruncate, searchUserByUsername, searchConversedUserByUsername, findDirectMessagesByFromIdAndToUsername, findDirectMessageConversationIdBetweenTwoUsers, findDirectMessageAttachmentsByDirectMessageConversationId, deepCopy, zeroUnreadDirectMessageConversation, zeroUnreadDirectMessageConversationWithUsername, makeUnreadDirectMessage, checkDeactivated, checkSystemDown, addAuditLog, checkCookieToken } from '../../scripts/helpers'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import { AuthContext } from '../../AuthProvider'
import Notification from '../../components/notification'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DirectMessagesShow(props) {
  const params = useParams();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const context = useContext(AuthContext);
  const [client, setClient] = useState(null);
  const [loadMoreMessagesButton, setLoadMoreMessagesButton] = useState(false);
  const [comboBoxValues, setComboBoxValues] = useState([]);
  const [conversationHistoryHeight, setConversationHistoryHeight] = useState('calc(100% - 251px)');
  const [conversations, setConversations] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [directMessageAttachments, setDirectMessageAttachments] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [filteredDropdown, setFilteredDropdown] = useState([]);
  const [conversedUser, setConversedUser] = useState(null);
  const [newMessagesReceived, setNewMessagesReceived] = useState(false);
  const [message, setMessage] = useState('');
  const [query, setQuery] = useState('');
  const theme = 'snow';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, false] }],
    ],
  };
  const placeholder = 'Write your message...';
  const formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'size', 'header'];
  const { quill, quillRef, Quill } = useQuill({ theme, modules, formats, placeholder });

  const username = params.username;

  const {socket, show, setShow, notifications, setNotifications, update} = context;

  const test = (content) => {
    const directMessagesToUpdate = deepCopy(directMessages);
    directMessagesToUpdate.push({
      created_by: content.fromId,
      creator: {user_username: content.from},
      date_last_updated: content.date,
      date_record_created: content.date,
      message: content.message
    })
    setDirectMessages(directMessagesToUpdate);
  };

  // any socket stuff
  useEffect(() => {
    if(socket) {
      socket.on('receive-dm', (content) => {
        if (content.type === 'dm') {
          if (content.from === username) {
            const directMessagesToUpdate = deepCopy(directMessages);
            directMessagesToUpdate.push({
              created_by: content.fromId,
              creator: {user_username: content.from},
              date_last_updated: content.date,
              date_record_created: content.date,
              message: content.message
            })
            setDirectMessages(directMessagesToUpdate);
            // zeroUnreadDirectMessageConversationWithUsername(username, cookies['userId']);
          } else {
            update(content);
            setShow(true);

            // Update to unread
            // makeUnreadDirectMessage(content.fromId, content.to);
            // set unread to true
            socket.emit('get-unread', {
              fromId: content.fromId,
              toUsername: content.to,
            }, content.to);
          }
        }
        // props.refreshConversationList();
      });
    }

    return (() => {
      if (socket) {
        socket.off('receive-dm');
      }
    });
  }, [directMessages, socket]);

  useEffect(() => {

  }, [directMessages]);

  useEffect(() => {
    return () => {
      setDirectMessageAttachments([]);
      setDirectMessages([]);
      setFilteredDropdown([]);
      setConversedUser(null);
      setNewMessagesReceived(false);
      setMessage('');
      socket.off('receive-dm');
    };
  }, []);

  const toggleConversations = (event) => {
    if (document.getElementById('conversations').className.indexOf('hidden') === -1) {
      document.getElementById('conversations').className += ' hidden';
    } else {
      document.getElementById('conversations').className = 'lg:block overflow-auto flex flex-shrink-0 border-r border-gray-200';
    }
  };

  const toggleAttachments = (event) => {
    if (document.getElementById('attachments').className.indexOf('hidden') === -1) {
      document.getElementById('attachments').className += ' hidden';
    } else {
      document.getElementById('attachments').className = 'lg:block overflow-auto flex flex-shrink-0 border-l border-gray-200';
    }
  };

  const discard = () => {
    quill.setText('');
    setAttachedFiles([]);
    document.getElementById('attachedFiles').value = '';
  };

  const handleComboBoxChange = () => {
    
  };

  function splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  const formatDate =(d) => {
    if (isToday(d)) {
      return `Today at ${format(d, 'HH:mm')}`;
    }

    if (isYesterday(d)) {
      return `Yesterday at ${format(d, 'HH:mm')}`;
    }

    return format(d, 'dd/MM/yyyy HH:mm');
  };

  const sendFile = (file, rawData, fileIndex, noOfFiles) => {
    return new Promise ((resolve, reject) => {
      client.binaryType = "arraybuffer";
      if (client.readyState === client.OPEN) {
        rawData = new Uint8Array(rawData, 0);
        
        const rawDataChunks = splitArrayIntoChunksOfLen(rawData, 50000);

        rawDataChunks.forEach((rawDataChunk, index) => {
          const fileJson = JSON.stringify({
            lastModified: file.lastModified,
            name: file.name,
            size: file.size, 
            type: file.type,
            chunkLength: rawDataChunks.length,
            index: index,
            userId: cookies['userId'],
            websocketClient: `${cookies['username']}_${username}`,
            fileIndex: fileIndex,
            noOfFiles: noOfFiles
          });

          const enc  = new TextEncoder();
          const buf1 = enc.encode('!');
          const buf2 = enc.encode(fileJson);
          const buf3 = enc.encode("\r\n\r\n");
          const buf4 = rawDataChunk;
          
          let sendData = new Uint8Array(buf1.byteLength + buf2.byteLength + buf3.byteLength + buf4.byteLength);

          sendData.set(new Uint8Array(buf1), 0);
          sendData.set(new Uint8Array(buf2), buf1.byteLength);
          sendData.set(new Uint8Array(buf3), buf1.byteLength + buf2.byteLength);
          sendData.set(new Uint8Array(buf4), buf1.byteLength + buf2.byteLength + buf3.byteLength);

          client.send(sendData);
        });

        resolve(true);
      }
    });
  };

  const sendMessage = (keyCode) => {
    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        if (message !== '') {
          let messageToSave = message;
          if (keyCode === 'Enter') messageToSave = message.slice(0, -11);

          const newDate = new Date();
          const tempId = nanoid();

          // send DM to recipient
          socket.emit('send-dm', {
            id: tempId,
            message: messageToSave,
            from: cookies['username'],
            fromId: cookies['userId'],
            to: username,
            date: newDate,
            currentViewedUsername: username,
            type: 'dm'
          }, username);

          // props.refreshConversationList();

          const directMessagesToUpdate = deepCopy(directMessages);
          directMessagesToUpdate.push({
            created_by: cookies['userId'],
            creator: {user_username: cookies['username']},
            date_last_updated: newDate,
            date_record_created: newDate,
            message: messageToSave
          })
          setDirectMessages(directMessagesToUpdate);
          quill.setText('');

          setMessage('');
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - directMessages/show.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - directMessages/show.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };

  const scrollToBottomOfConversation = () => {
    let objDiv = document.getElementById("conversationHistory");
    if (objDiv !== null) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    setNewMessagesReceived(false);
  };

  const handleScroll = () => {
    let objDiv = document.getElementById("conversationHistory");
    if (objDiv !== null && (objDiv.scrollTop + objDiv.offsetHeight === objDiv.scrollHeight) && newMessagesReceived) setNewMessagesReceived(false);
  };

  const createMarkup = (message) => {
    return {__html: message};
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setAttachedFiles(files);
  };

  const refreshUser = () => {
    searchConversedUserByUsername(username).then(conversedUser => {
      conversedUser = conversedUser.data;
      setConversedUser(conversedUser);

      if (conversedUser.user_status !== 'Active') { 
        setConversationHistoryHeight('calc(100% - 38px)');
      } else {
        setConversationHistoryHeight('calc(100% - 251px)');
      }
    })
  };

  const refreshAttachmentList = () => {
    findDirectMessageConversationIdBetweenTwoUsers(props.fromUserId, props.toUsername).then(directMessageConversationIdResult => {
      if (directMessageConversationIdResult.data.directMessageConversationId === null) {
        setDirectMessageAttachments([]);
      } else {
        const directMessageConversationId = directMessageConversationIdResult.data.directMessageConversationId;
        findDirectMessageAttachmentsByDirectMessageConversationId(directMessageConversationId).then(directMessageAttachments => {
          setDirectMessageAttachments(directMessageAttachments.data);
        });
      }
    });
  };

  useEffect(() => {
    refreshAttachmentList();
    refreshUser();
  }, []);

  useEffect(() => {
    scrollToBottomOfConversation();
  }, [directMessages]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setMessage(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (query.length >= 3) {
      // if query is more than 3, search database of users for username that matches query
      searchUserByUsername(query).then(result => {
        result = result.data;
        setFilteredDropdown(result);
      });
    } else {
      setFilteredDropdown([]);
    }
  }, [query]);

  useEffect(() => {
    if (username !== undefined) {
      let objDiv = document.getElementById("conversationHistory");
      if (objDiv !== null) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }

      // find direct messages
      findDirectMessagesByFromIdAndToUsername(props.fromUserId, props.toUsername, directMessages.length).then(results => {
        setLoadMoreMessagesButton(results.data.count !== results.data.rows.length);

        const reversed = [];
        for (let i = results.data.rows.length - 1; i >= 0; i -= 1) {
          reversed.push(results.data.rows[i]);
        }
        setDirectMessages(reversed);

        let objDiv = document.getElementById("conversationHistory");
        if (objDiv !== null) objDiv.scrollTop = objDiv.scrollHeight;
      });

      refreshUser();
    }
  }, [username]);

  return (
    <Fragment> 
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-2 lg:px-8 py-1.5">
            <div>
              <button className="bg-sky-600 hover:bg-sky-700 p-2" onClick={event => toggleConversations(event)}>
                <h3 className="text-sm font-medium text-white">Conversations ({props.conversationCount})</h3>
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 relative z-0 flex overflow-hidden">
          {username !== undefined ?
            <main className="flex-1 relative z-0 focus:outline-none">
              {/* Start main area*/}
              <div className="bg-gray-100 px-2 py-1 border-t border-b border-gray-200 sticky top-0">
                {conversedUser !== null ? 
                  <h3 className="text-lg leading-6 font-bold text-gray-900">CONVERSATION WITH {conversedUser.user_username.toUpperCase()} {conversedUser.user_status !== 'Active' ? <sup className='text-sm bg-red-500 text-white p-1 font-light' style={{fontSize: '0.65rem'}}>{conversedUser.user_status} - Messaging Disabled</sup> : '' }</h3>
                : '' }
              </div>
              <ul role="list" className="divide-y divide-gray-200 overflow-auto space-y-1 pb-1.5" style={{height: conversationHistoryHeight}} id="conversationHistory" onScroll={handleScroll}>
                {loadMoreMessagesButton && (
                  <li className="px-4 sm:px-6 md:px-6 lg:px-3 py-2 mt-1">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center border border-transparent bg-sky-600 hover:bg-sky-700 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 w-[100%]"
                      onClick={() => {
                        let objDiv = document.getElementById("conversationHistory");

                        // find direct messages
                        findDirectMessagesByFromIdAndToUsername(props.fromUserId, props.toUsername, directMessages.length).then(results => {
                          setLoadMoreMessagesButton(results.data.count !== results.data.rows.length);

                          const reversed = [];
                          for (let i = results.data.rows.length - 1; i >= 0; i -= 1) {
                            reversed.push(results.data.rows[i]);
                          }
                          setDirectMessages(reversed);
                        });
                      }}
                    >
                      Load more messages
                    </button>
                  </li>
                )}
                {directMessages.map((directMessage, index) => {
                  return (
                    <li className="px-4 sm:px-6 md:px-6 lg:px-3 py-1" key={index}>
                        <div className="flex flex-col">
                          <h3 className="font-medium">
                            <span className="text-sm text-gray-900">{directMessage.creator.user_username} <span className="font-light text-xs">{formatDate(new Date(directMessage.date_record_created))}</span></span>
                          </h3>
                          <div className="text-sm text-gray-700" dangerouslySetInnerHTML={createMarkup(directMessage.message)}></div>
                        </div>
                    </li>
                  )
                })}
              </ul>
              {conversedUser !== null && conversedUser.user_status === 'Active' ?
              <div className="min-h-[250px] h-[250px]">
                {newMessagesReceived ? 
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      className="mb-12 absolute items-center px-2.5 py-1.5 border border-transparent text-xs font-medium text-sky-700 bg-sky-100 hover:bg-sky-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      onClick={scrollToBottomOfConversation}
                    >
                      New messages received
                    </button>
                  </div>
                : ''}
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1 flex justify-between">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">MESSAGE</h3>
                    <div className="flex flex-row">
                      {/* <input type="file" multiple onChange={(event) => handleFileChange(event)} id="attachedFiles" /> */}
                      <div className="grid gap-1 grid-cols-2">
                        {/* <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full">Attach</button> */}
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={discard}>Discard</button>
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={sendMessage}>Send</button>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-200">
                    <div className="bg-white shadow overflow-hidden">
                      <div style={{ width: '100%', height: 218 }}>
                        <div ref={quillRef} onKeyDown={(e) => { if (e.key === 'Enter' && !(e.key === 'Enter' && e.shiftKey)) {sendMessage(e.key)}}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
                : 
              <div className="min-h-[250px] h-[250px] hidden">
                {newMessagesReceived ? 
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      className="mb-12 absolute items-center px-2.5 py-1.5 border border-transparent text-xs font-medium text-sky-700 bg-sky-100 hover:bg-sky-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      onClick={scrollToBottomOfConversation}
                    >
                      New messages received
                    </button>
                  </div>
                : ''}
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1 flex justify-between">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">MESSAGE</h3>
                    <div className="flex flex-row">
                      {/* <input type="file" multiple onChange={(event) => handleFileChange(event)} id="attachedFiles" /> */}
                      <div className="grid gap-1 grid-cols-2">
                        {/* <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full">Attach</button> */}
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={discard}>Discard</button>
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={sendMessage}>Send</button>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-200">
                    <div className="bg-white shadow overflow-hidden">
                      <div style={{ width: '100%', height: 218 }}>
                        <div ref={quillRef} onKeyDown={(e) => { if (e.key === 'Enter' && !(e.key === 'Enter' && e.shiftKey)) {sendMessage(e.key)}}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>   
                }  
              {/* End main area */}
            </main>
          : ''}
        </div>
      </div>
    </Fragment>
  )
};