import { Fragment, useState, useContext, useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { InboxIcon } from '@heroicons/react/outline'
import {isBefore, isAfter} from 'date-fns'
import { XIcon,  UserIcon, UserGroupIcon } from '@heroicons/react/solid'
import _ from 'lodash'
import { deepCopy } from '../scripts/helpers'
import { AuthContext } from '../AuthProvider'

export default function Notification(props) {
  const {notifications} = props;
  const context = useContext(AuthContext);
  const show2 = useRef({});
  const {disappear, show} = context;

  const createMarkup = (message) => {
    return {__html: message};
  };

  if (show && Object.keys(show.current).length > 0) {
    Object.keys(show.current).forEach(key => {
      show2.current[key] = true;
    });
  }
  
  if (Object.keys(notifications).length > 0) {
    let updates = Object.values(notifications);
    updates.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return (
      <div className="pointer-events-none fixed inset-0 flex flex-col gap-[1rem] items-end px-4 py-6 sm:items-start sm:p-6 z-[100]" id="hi">
        {updates.map((update, index) => (
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end" key={index}>
            <Transition
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {update.type === 'dm' ?  <UserIcon className="h-6 w-6 text-gray-400" aria-hidden="true" /> : <UserGroupIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />}
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      {update.type === 'gc' && <p className="text-sm mb-1 font-medium text-gray-900">{update.groupChatName}</p>}
                      <p className="text-sm text-gray-500">
                        <strong>{update.from}</strong> <span dangerouslySetInnerHTML={createMarkup(update.message)}></span>
                      </p>
                      <div className="mt-3 flex space-x-7">
                        <button
                          type="button"
                          className="bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={() => {
                            if (update.type === 'gc') {
                              window.location.href=`/group-chats/${update.to}`
                            } else {
                              window.location.href=`/direct-messages/${update.from}`
                            }
                          }}
                        >
                          Reply
                        </button>
                      </div>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => {
                          disappear(update);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        ))}
      </div>
    )
  } else {
    return <></>
  }
}
 