import { useState, useEffect, Fragment } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid'
import { getUsernamesByQuery } from '../scripts/helpers'
import { el } from 'date-fns/locale';

export default function GroupChatUsernameComboBox(props) {
  const comboBoxObj = {...props};
  const [query, setQuery] = useState('');
  const [comboBoxValues, setComboBoxValues] = useState([]);
  const [usernames, setUsernames] = useState([]);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (query.length >= 3) {
      getUsernamesByQuery(query).then(results => {
        setUsernames(results.data);
      })
    } 
  }, [query]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // const filteredDropdown = query.length < 3
  //     ? []
  //     : comboBoxValues.filter((comboBoxValue) => {
  //         return comboBoxValue[Object.keys(comboBoxValue)[0]].toLowerCase().includes(query.toLowerCase())
  //       });

  return (
    <Fragment>
      <Combobox as="div" onChange={(event) => {props.handleComboBoxChange(event); setQuery(''); document.getElementsByClassName('user_username')[0].value = '';}}>
        <div className="relative mt-1">
          <Combobox.Input
            className={`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm user_username`}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            id='user_username'
            name='user_username'
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
            <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
          
          {usernames.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {usernames.map((username, index) => (
                <Combobox.Option
                  key={index}
                  value={username.user_username}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-0 pl-3 pr-9',
                      active ? 'bg-sky-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <Fragment>
                      <div className="flex">
                        <span
                          className={classNames(
                            'ml-2 truncate text-gray-900',
                            active ? 'text-sky-200' : 'text-gray-900'
                          )}
                        >
                          {username.user_username}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-sky-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </Fragment>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </Fragment>
    )

  // if (Object.keys(comboBoxObj).length > 0) {
  //   return (
  //   <Fragment>
  //     <Combobox as="div" onChange={(event) => {}}>
  //       <div className="relative mt-1">
  //         <Combobox.Input
  //           className={`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm`}
  //           onChange={(event) => {
  //             setQuery(event.target.value);
  //           }}
  //           id='user_username'
  //           name='user_username'
  //         />
  //         <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
  //           <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
  //         </Combobox.Button>
          
  //         {filteredDropdown.length > 0 && (
  //           <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
  //             {filteredDropdown.map((comboBoxValue, index) => (
  //               <Combobox.Option
  //                 key={index}
  //                 value={comboBoxValue}
  //                 className={({ active }) =>
  //                   classNames(
  //                     'relative cursor-default select-none py-0 pl-3 pr-9',
  //                     active ? 'bg-sky-600 text-white' : 'text-gray-900'
  //                   )
  //                 }
  //               >
  //                 {({ active, selected }) => (
  //                   <Fragment>
  //                     <div className="flex">
  //                       <span
  //                         className={classNames(
  //                           'ml-2 truncate text-gray-900',
  //                           active ? 'text-sky-200' : 'text-gray-900'
  //                         )}
  //                       >
  //                         {comboBoxValue[Object.keys(comboBoxValue)[0]]}
  //                       </span>
  //                     </div>

  //                     {selected && (
  //                       <span
  //                         className={classNames(
  //                           'absolute inset-y-0 right-0 flex items-center pr-4',
  //                           active ? 'text-white' : 'text-sky-600'
  //                         )}
  //                       >
  //                         <CheckIcon className="h-5 w-5" aria-hidden="true" />
  //                       </span>
  //                     )}
  //                   </Fragment>
  //                 )}
  //               </Combobox.Option>
  //             ))}
  //           </Combobox.Options>
  //         )}
  //       </div>
  //     </Combobox>
  //   </Fragment>
  //   )
  // } else {
  //   return (
  //     <Fragment>
  //     </Fragment>
  //   )
  // }
}