import { useState, useEffect, useContext } from "react"
import Header from '../../components/header'
import Loading from '../../components/loading'
import axios from "axios"
import { addAuditLog, exportTable, listenToNotifications } from '../../scripts/helpers'
import { AuthContext } from "../../AuthProvider";
import { useCookies } from "react-cookie"
import { backend_base_url } from "../../utils/helper"

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
};

export default function ExportIndex() {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [tableAndColumnNames, setTableAndColumnNames] = useState({});
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;

  const downloadTable = (tableSlug, tableAndColumnNameKey) => {
    exportTable(tableSlug).then(result => {
      addAuditLog({
        table_name: tableAndColumnNameKey,
        action_type: 'Export',
        action_description: `Exported ${tableSlug}.`,
        user_id: cookies['userId']
      });
      
      window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}.csv`;
    })
  };

  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/getPrivileges`, {withCredentials: true})
      .then(result => {
        const arrangedPrivilegeTables = {};

        result.data.forEach((row) => {
          if (arrangedPrivilegeTables[row.table_name] === undefined) {
            arrangedPrivilegeTables[row.table_name] = {};
            arrangedPrivilegeTables[row.table_name]['tableName'] = row.table_name;
            arrangedPrivilegeTables[row.table_name]['tableSlug'] = row.table_slug;
            arrangedPrivilegeTables[row.table_name]['columns'] = [];
          }
        });

        setTableAndColumnNames(arrangedPrivilegeTables);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  } else {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="mt-5 pb-5 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              EXPORT
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-y-5 gap-x-4">
            <div className="col-span-6">
              {
                Object.keys(tableAndColumnNames).map((tableAndColumnNameKey, index) => 
                    <div className="border-b border-gray-300 max-w-7xl mx-auto lg:block" key={index}>
                      {/* <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between"> */}
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <a id={tableAndColumnNames[tableAndColumnNameKey].tableName.replace(/\s+/g, '')}>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnNameKey}</h3>
                        </a>
                        <div className="mt-3 flex sm:mt-0 sm:ml-4">
                          <button
                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                            onClick={() => downloadTable(tableAndColumnNames[tableAndColumnNameKey].tableSlug, tableAndColumnNameKey)}
                          >
                            Export
                          </button>
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}