import { useState, useEffect, useContext } from "react"
import Header from '../../components/header'
import Loading from '../../components/loading'
import { Link } from "react-router-dom"
import axios from "axios"
import { Disclosure } from '@headlessui/react'
import { AuthContext } from "../../AuthProvider"
import { listenToNotifications } from "../../scripts/helpers"
import { backend_base_url } from "../../utils/helper"

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
};

export default function DefaultValuesIndex() {
  const [tableAndColumnDefaultValues, setTableAndColumnDefaultValues] = useState({});
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/getColumnDefaultValues`, {withCredentials: true})
      .then(result => {
        const columnDefaultValues = result.data;

        const tableAndColumnDefaultValuesToUpdate = {...tableAndColumnDefaultValues};

        columnDefaultValues.forEach((columnVisibility, index) => {
          if (tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']] === undefined) {
            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']] = {};
            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']]['table_name'] = columnVisibility['table_name'];
            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']]['table_slug'] = columnVisibility['table_slug'];
            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']]['default_table_slug'] = columnVisibility['default_table_slug'];
            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']]['rows'] = [];
          }

            tableAndColumnDefaultValuesToUpdate[columnVisibility['table_name']]['rows'].push({
              column_name: columnVisibility['column_name'],
              default_table_slug: columnVisibility['default_table_slug'],
            })
        });

        setTableAndColumnDefaultValues(tableAndColumnDefaultValuesToUpdate);
        setLoading(false);
      })
  }, []);

  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  } else {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="mt-5 pb-5 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              DEFAULT VALUES
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-y-5 gap-x-4">
            <div className="col-span-6">
              {
                Object.keys(tableAndColumnDefaultValues).map((tableAndColumnNameKey, index) => 
                  <div className="border-b border-gray-300 max-w-7xl mx-auto lg:block" key={index}>
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <a id={tableAndColumnDefaultValues[tableAndColumnNameKey]['table_name'].replace(/\s+/g, '')}>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnNameKey}</h3>
                      </a>
                      <div className="mt-3 flex sm:mt-0 sm:ml-4">
                        <Link
                          to={tableAndColumnDefaultValues[tableAndColumnNameKey]['default_table_slug'] + '/edit'}
                          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                    {/* <div className="mt-5 flex flex-col">
                      <div className="-my-2 overflow-x-auto">
                        <div className="py-2 align-top inline-block min-w-full">
                          <div className="shadow overflow-hidden border-b border-gray-300">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Field name
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Default Value
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {tableAndColumnDefaultValues[tableAndColumnNameKey]['rows'].map((row, index2) => 
                                  <tr key={index2}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['default_value']}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}