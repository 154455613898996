import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate, useParams } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, deleteImportObjects, getObjects, downloadFiles, addAuditLog, getTableNameFromTableSlug, getImportAttachments, listenToNotifications } from '../../scripts/helpers'

import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import AlertNotification from '../../components/alertNotification'
import Modal from '../../components/modal'
import { format } from 'date-fns'
import { AuthContext } from '../../AuthProvider'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import _ from 'lodash'
import { backend_base_url } from '../../utils/helper'

export default function ImportList() {
  const tableSlug2 = 'imports';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [objects, setObjects] = useState([]);
  const [objectIdsSelected, setObjectIdsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);
  const [deleteSingle, setDeleteSingle] = useState('');
  const [deletePlural, setDeletePlural] = useState('');
  const [tableName, setTableName] = useState('');
  const params = useParams();
  const [sort, setSort] = useState({});
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const {tableSlug} = params;

  const privilegeObject = {
    tableSlug: tableSlug2, 
    token: cookies['token']
  };

  const navigate = useNavigate();

  const toggleSort = (column) => {
    let sortToUpdate = {...sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    let objectsToUpdate = [...objects];

    if (objectsToUpdate[column] === undefined) {
      objectsToUpdate[column] = false;
    }

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if (sortToUpdate[column] === false) {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['asc']);
    } else {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['desc']);
    }

    sortToUpdate[column] = !sortToUpdate[column];
    setSort(sortToUpdate);
    setObjects(objectsToUpdate);
    setLoading(false);
  };

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(result => {
      const resultPrivileges = result;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['List']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);

      getImportAttachments(tableSlug).then(result => {
        setObjects(result.data);
        setObjectIdsSelected(new Array(result.data.length).fill(false));
      });

      getTableNameFromTableSlug(tableSlug).then(result => {
        setTableName(result.data.table_name);
      })
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug2, privileges, 'List');
      setActions(actionsToUpdate);
    }
  }, [privileges]);
  
  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to edit.',
          type: 'Danger'
        });

         
      } else if (objectsSelected.length >= 2) {
        setOutcome({
          message: 'You can select only 1 record to edit.',
          type: 'Danger'
        });

         
      } else {
        navigate(`/${tableSlug2}/${objectsSelected[0]['id']}/edit`);
      }
    } else if (buttonClicked === 'Delete') {
      // alert users if they have not selected anything
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to delete.',
          type: 'Danger'
        });

         
      } else {
        setShowDeleteConfirmation(true);
      }
    } else if (buttonClicked === 'Download') {
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to download.',
          type: 'Danger'
        });

        
      } else {
        const fileNames = objectsSelected.map((row) => row.file_path.substring(row.file_path.lastIndexOf('/') + 1));
        downloadFiles('attachments', fileNames, cookies['userId']).then(result => {
          window.location.href=`${backend_base_url()}/api/attachments/downloads/downloadFile?filename=${result.data}`;
        });

        objectsSelected.forEach((row) => {
          addAuditLog({
            table_name: 'imports',
            action_type: 'Download',
            record_id: row.id,
            action_description: `Downloaded file: ${row.filename}.`,
            user_id: cookies['userId']
          });
        });
      }
    } else if (buttonClicked === 'Import') {
      navigate(`/imports/${tableSlug}/import`);
    } else if (buttonClicked === 'Delete') {
      setDeleteSingle('import');
      setDeletePlural('imports');
      setShowDeleteConfirmation(true);
    }
  };

  const handleOnChangeCheckbox = (position) => {
    const objectsSelectedToUpdate = [];
    const objectIdsSelectedToUpdate = objectIdsSelected.map(
      (checked, index) => {
        let result = false;

        if (index === position) {
          result = !checked;
        } else {
          result = checked;
        }

        if (result === true) {
          objectsSelectedToUpdate.push(objects[index]);
        }

        return result;
      }
    );

    setObjectIdsSelected(objectIdsSelectedToUpdate);
    setObjectsSelected(objectsSelectedToUpdate);
  };

  const navigateToObject = (event, object) => {
    if (event.target.checked === undefined) {
      navigate(`/${tableSlug2}/${object.table_slug}/${object.id}`)
    }
  };

  const deleteSelected = () => {
    deleteImportObjects(tableSlug, objectsSelected)
      .then(result => {
        disableShowDeleteConfirmation();

        const objSelected = [];
        objectsSelected.map(obj => objSelected.push(obj.filename));

        objectsSelected.forEach((row) => {
          addAuditLog({
            table_name: 'Import',
            action_type: 'Delete',
            action_description: `Deleted import attachment: ${row.filename}.`,
            user_id: cookies['userId']
          }).then(() => {
            window.location.reload();
          });
        });
      })
      .catch(err => {
        setOutcome({
            message: err,
            type: 'Danger'
          });

          
      });
  };

  return (
    <>
      <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={objectsSelected.map((object) => object['id'])} single='import attachment' plural='import attachments' />
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5">
            <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">
                IMPORTS FOR {tableName.toUpperCase()}
              </h3>
              <a href={`${backend_base_url()}/api/attachments/downloadTemplateFile?filename=${tableSlug}.csv`} className="text-sky-500 font-medium hover:text-sky-700">Column structure for {tableName.toLowerCase()}</a>
            </div>
            <div className="mt-5 flex flex-col">
              <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-top inline-block min-w-full">
                  <div className="shadow overflow-hidden border-gray-300">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Select
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('date_record_created')}>
                              Record created
                              {sort['date_record_created'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['date_record_created'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('file_name')}>
                              File name
                              {sort['file_name'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['file_name'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('file_size')}>
                              File size (kb)
                              {sort['file_size'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['file_size'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {objects.map((object, index) => (
                          <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToObject(event, object)}>
                            <td className="border border-gray-300 px-2 pt-0 text-center text-sm text-gray-900">
                              <input
                                id={`select-user-${index}`}
                                aria-describedby="select-user"
                                type="checkbox"
                                value={object}
                                checked={objectIdsSelected[index] !== undefined ? objectIdsSelected[index] : false}
                                onChange={() => handleOnChangeCheckbox(index)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300 mb-0.5"
                              />
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{format(new Date(object['date_record_created']), 'yyyy-MMM-dd HH:mm')}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['filename']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['file_size']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}