import {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'

import { checkCookieToken, tokenIsValid } from './scripts/helpers'

export default function Protected({ children }) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);

  useEffect(() => {
    // check whether user is authenticated
    if (checkCookieToken(document.cookie)) {
      tokenIsValid(cookies['token']).then(result => {
      }).catch(error => {
        Object.keys(cookies).forEach(cookie => {
          removeCookie(cookie);
        });
        navigate('/login');
      });
    } else {
      navigate('/login');
    }
  }, [children]);

  return children;
};