/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { getProblemsByWorkerTwid } from '../scripts/helpers'


export default function TagWorkerModal(props) {
  const [open, setOpen] = useState(false);
  const [currentWorker, setCurrentWorker] = useState({});
  const [taggedProblems, setTaggedProblems] = useState({});
  const [confirmTaggedProblems, setConfirmTaggedProblems] = useState({});
  // const { objectsSelected, single, plural } = props;

  useEffect(() => {
    // setOpen(props.showDeleteConfirmation);
    if (props.taggedWorker !== '') {
      getProblemsByWorkerTwid(props.taggedWorker).then(worker => {
        worker = worker.data;

        let currentTaggedProblems = {};

        for (let i = 0; i < props.rightStubObj.tableColumns.length; i += 1) {
          if (props.rightStubObj.tableColumns[i]['column_name'] === 'tagged_workers') {
            currentTaggedProblems = props.rightStubObj.tableColumns[i]['value'];

            break;
          }
        }
        
        const confirmTaggedProblemsToUpdate = {...confirmTaggedProblems};
        const taggedProblemsToUpdate = {};
        const hostProblemId = parseInt(props.problemId);

        worker.jobs.forEach((job) => {
          job.problems.forEach((problem) => {
            if (problem.id === hostProblemId) {
              taggedProblemsToUpdate[problem.id] = {
                worker: `${worker.twid} ${worker.name_of_worker}`,
                job: job.employer_name,
                problem: problem.chief_problem,
                problemId: problem.id,
                checked: true,
                disabled: true
              };
            } else {
              if (currentTaggedProblems[problem.id]) {
                taggedProblemsToUpdate[problem.id] = {
                  worker: `${worker.twid} ${worker.name_of_worker}`,
                  job: job.employer_name,
                  problem: problem.chief_problem,
                  problemId: problem.id,
                  checked: true
                };
                confirmTaggedProblemsToUpdate[problem.id] = {
                  job: job.employer_name,
                  problem: problem.chief_problem,
                  worker: `${worker.twid} ${worker.name_of_worker}`
                };
              } else {
                taggedProblemsToUpdate[problem.id] = {
                  worker: `${worker.twid} ${worker.name_of_worker}`,
                  job: job.employer_name,
                  problem: problem.chief_problem,
                  problemId: problem.id,
                  checked: false
                };
              }
            }

            setCurrentWorker(`${worker.twid} ${worker.name_of_worker}`);
          });
        });
        
        setTaggedProblems(taggedProblemsToUpdate);
        setConfirmTaggedProblems(confirmTaggedProblemsToUpdate);
        setOpen(true);
      });
    }
  }, [props.taggedWorker]);

  const cancelButtonRef = useRef(null);

  const closeModal = () => {
    setOpen(false);
    props.setTaggedWorker('');
    setCurrentWorker({});
    setTaggedProblems([]);
    setConfirmTaggedProblems({});
  }

  const confirmModal = () => {
    // props.confirmDelete();

    const rightStubObjToUpdate = {...props.rightStubObj};
    const tableColumnsToUpdate = [...props.rightStubObj.tableColumns];

    for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
      if (tableColumnsToUpdate[i]['column_name'] === 'tagged_workers') {
        // roll through checked and check the values
        const taggedWorkersValues = tableColumnsToUpdate[i]['value'];

        Object.keys(confirmTaggedProblems).forEach((confirmTaggedProblemId) => {
          if (taggedWorkersValues[confirmTaggedProblemId] === undefined) {
            taggedWorkersValues[confirmTaggedProblemId] = confirmTaggedProblems[confirmTaggedProblemId];
          }
        });

        Object.keys(taggedWorkersValues).forEach((currentTaggedProblemId) => {
          if (confirmTaggedProblems[currentTaggedProblemId] === undefined && taggedWorkersValues[currentTaggedProblemId]['worker'] === currentWorker) {
            delete(taggedWorkersValues[currentTaggedProblemId]);
          }
        });
        
        break;
      }
    }

    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    props.setRightStubObj(rightStubObjToUpdate);

    setOpen(false);
    props.setTaggedWorker('');
    setCurrentWorker({});
    setTaggedProblems([]);
    setConfirmTaggedProblems({});
  }

  const toggleTaggedProblem = (event, taggedProblem) => {
    if (event.target.checked) {
      const confirmTaggedProblemsToUpdate = {...confirmTaggedProblems};
      if (props.rightStubObj.clanWorkers !== undefined) {
        if (props.rightStubObj.clanWorkers[taggedProblem.problemId]) {
          confirmTaggedProblemsToUpdate[taggedProblem.problemId] = {
            job: taggedProblem.job,
            problem: taggedProblem.problem,
            worker: taggedProblem.worker,
            clanId: props.rightStubObj.clanWorkers[taggedProblem.problemId].clanId
          };
        } else {
          confirmTaggedProblemsToUpdate[taggedProblem.problemId] = {
            job: taggedProblem.job,
            problem: taggedProblem.problem,
            worker: taggedProblem.worker
          };
        }
      } else {
        confirmTaggedProblemsToUpdate[taggedProblem.problemId] = {
          job: taggedProblem.job,
          problem: taggedProblem.problem,
          worker: taggedProblem.worker
        };
      }
      
      
      setConfirmTaggedProblems(confirmTaggedProblemsToUpdate);
    } else {
      const confirmTaggedProblemsToUpdate = {...confirmTaggedProblems};
      delete(confirmTaggedProblemsToUpdate[taggedProblem.problemId]);
      setConfirmTaggedProblems(confirmTaggedProblemsToUpdate);

      const taggedProblemsToUpdate = {...taggedProblems};
      taggedProblemsToUpdate[taggedProblem.problemId].checked = false;
      setTaggedProblems(taggedProblemsToUpdate);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-top sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:w-full" style={{maxWidth: '50rem'}}>
              <div className="bg-white px-4 pt-5 pb-5 sm:p-6 sm:pb-6">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div> */}
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="shadow overflow-hidden">
                      <div className="bg-gray-100 px-2 py-1">
                        <h3 className="text-lg leading-6 font-bold text-gray-900">SELECT PROBLEM TO COPY THIS CASE DISCUSSION TO</h3>
                      </div>
                      <div className="border-t border-gray-200 p-0">
                        <div className="flex flex-col">
                          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                              <div className="shadow overflow-hidden border-b border-gray-200">
                                <table className="min-w-full divide-y divide-gray-200">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Worker
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Job
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Problem
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                      >
                                        Tag
                                      </th>
                                    </tr>
                                  </thead>
                                  {Object.keys(taggedProblems).length > 0 ? 
                                    (
                                      <tbody className="bg-white divide-y divide-gray-200">
                                        {Object.keys(taggedProblems).map((problemId, index) => {
                                          return (
                                            <tr key={index}>
                                              <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{taggedProblems[problemId].worker}</td>
                                              <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{taggedProblems[problemId].job}</td>
                                              <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{taggedProblems[problemId].problem}</td>
                                              <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">
                                                {taggedProblems[problemId].checked ? 
                                                  ( 
                                                    taggedProblems[problemId]['disabled'] === true ? 
                                                    (
                                                      <>Current</>
                                                    ) : 
                                                    (
                                                      <input
                                                        type="checkbox"
                                                        name="checkbox"
                                                        id="checkbox"
                                                        checked
                                                        onChange={(event) => toggleTaggedProblem(event, taggedProblems[problemId])}
                                                        className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                                        style={{marginTop: '-0.25rem'}}
                                                      />
                                                    )
                                                  ) : 
                                                  (
                                                    <input
                                                      type="checkbox"
                                                      name="checkbox"
                                                      id="checkbox"
                                                      onChange={(event) => toggleTaggedProblem(event, taggedProblems[problemId])}
                                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                                      style={{marginTop: '-0.25rem'}}
                                                    />
                                                  )}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    ) : 
                                    (
                                      <tbody>
                                        <tr>
                                          <td colSpan='4' className='text-center p-4 bg-gray-50'>
                                            This worker has no active problem to copy to.
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="bg-gray-50 px-4 pt-4 pb-4 sm:px-6 sm:pb-4">
                {Object.keys(taggedProblems).length > 0 ? 
                  <Fragment>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                    onClick={confirmModal}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  </Fragment> : 
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
