import { useState, useEffect, useRef, Fragment, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, assignPdpa, prepareTableColumnsWithPrivilegeAndInputType, getAllTableAndColumns, editObject, getObject, displayValueByColumnName, listenToNotifications, addAuditLog } from '../../scripts/helpers'
import ImageEditor from '@toast-ui/react-image-editor'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AlertNotification from '../../components/alertNotification'
import SelectOption from '../../components/selectOption'
import ComboBox from '../../components/comboBox'
import { AuthContext } from '../../AuthProvider'
import { backend_base_url, frontend_base_url } from '../../utils/helper'

export default function PdpaAttachmentsAssign() {
  const editorRef = useRef();
  const tableSlug = 'pdpa-attachments';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [editorInstance, setEditorInstance] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [taggedWorker, setTaggedWorker] = useState('');

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const params = useParams();

  const myTheme = {
    // Theme object to extends default dark theme.
    // download: false
  };

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Edit']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);

      prepareTableColumnsWithPrivilegeAndInputType(tableSlug, privilegesToUpdate, 'Assign', params.id).then(preparedTableColumns => {
        for (let i = 0; i < preparedTableColumns.length; i += 1) {
          if (preparedTableColumns[i]['column_name'] === 'tagged_workers') {
            preparedTableColumns[i]['is_nullable'] = false;
            break;
          }
        }
        setTableColumns(preparedTableColumns);
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'Edit');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Save') {
      const result = checkCompulsoryFields(tableColumns);
      if (result.allCompulsoryFieldsHaveBeenFilled) {
        const tableColumnsToSubmit = [...tableColumns];
        tableColumnsToSubmit.push({
          token: cookies['token']
        });
        
        assignPdpa(tableColumnsToSubmit, params.id).then(result => {
          addAuditLog({
            table_name: 'PDPA Attachment',
            action_type: 'Assign',
            record_id: params.id,
            action_description: `Assigned pdpa attacment with id: ${params.id} to worker id: ${result.data.worker_id}.`,
            worker_id: result.data.worker_id,
            user_id: cookies['userId']
          })
          
          navigate(`/workers/${result.data.worker_id}/pdpa-attachments/${params.id}`);
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });

          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
        });
      } else {
        const emptyFields = result.emptyFields;

        emptyFields.forEach((emptyField) => {
          if (emptyField === 'tagged_workers') {
            document.getElementById(emptyField).className = 'block w-full border-red-300 bg-red-300 text-gray-400 sm:text-sm';
          } else {
            if (document.getElementById(emptyField)) {
              document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
            } else {
              if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
              }
            }
          }
        });

        setOutcome({
          message: 'Compulsory fields have not been filled.',
          type: 'Danger'
        });

         
      }
    } else if (buttonClicked === 'Abandon') {
      navigate(`/${tableSlug}/${params.id}`);
    }
  };

  const handleChange = (column, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    const selectedDropdownToUpdate = [...selectedDropdown];

    if (event.target !== undefined && event.target.type !== 'checkbox') {
      event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
    }

    // if checkbox, handle differently
    if (column['input_type'] === 'checkbox') {
      tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.checked;
    } else if (column['column_name'] === 'tableAndColumn_id') {
      const filteredSelectedDropdownToUpdate = selectedDropdownToUpdate.map((selectedDropdownToUpdateRow) => {
        return selectedDropdownToUpdateRow.column_name;
      });

      if (filteredSelectedDropdownToUpdate.indexOf(event.column_name) === -1) {
        tableColumnsToUpdate[column['sequence'] - 1]['value'].push(event);
        selectedDropdownToUpdate.push(event);
      }

      document.getElementsByClassName('w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm')[0].value = '';
    } else {
      tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
    }

    setTableColumns(tableColumnsToUpdate);
    setSelectedDropdown(selectedDropdownToUpdate);
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const tableColumnsToUpdate = [...tableColumns];
    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
      if (column === 'chief_problem') {
        tableColumnsToUpdate[tableColumn.sequence - 1].problem_id = '';
      }
    } else {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
      if (column === 'chief_problem') {
        tableColumnsToUpdate[tableColumn.sequence - 1].problem_id = event.id;
      }
    }
    setTableColumns(tableColumnsToUpdate);
  };
  
  const handleComboBoxChange = (rightStubObj, column, event, referencedColumnName, referencedTableSlug, storeAsColumnName, value) => {
    if (column.label_name === 'Assign to worker') {
      const tableColumnsToUpdate = [...tableColumns];

      for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
        if (tableColumnsToUpdate[i]['column_name'] === 'tagged_workers') {
          tableColumnsToUpdate[i]['value'] = event.name_of_worker;
          break;
        }
      }

      setTableColumns(tableColumnsToUpdate);
    } else {
      const tableColumnsToUpdate = [...tableColumns];

      const columnNameToStoreAs = storeAsColumnName !== null ? storeAsColumnName : '';
      const columnNameToSearch = referencedColumnName !== null ? referencedColumnName : '';
      const columnValueToSearch = event !== null ? event[Object.keys(event)[0]] : '';
      
      if (value !== undefined) {
        if (referencedColumnName === 'tagged_workers') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_workers') {
              delete(tableColumnsToUpdate[i]['value'][value]);
              break;
            }
          }
        } else if (referencedColumnName === 'tagged_users') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_users') {
              delete(tableColumnsToUpdate[i]['value'][value]);
              break;
            }
          }
        }
        
      } else {
        if (referencedColumnName === 'name_of_worker') {
          setTaggedWorker(event.name_of_worker.slice(0,10));
        } else if (referencedColumnName === 'user_username') {
          for (let i = 0; i < tableColumnsToUpdate.length; i += 1) {
            if (tableColumnsToUpdate[i]['column_name'] === 'tagged_users') {
              tableColumnsToUpdate[i]['value'][event.user_username] = true;
              break;
            }
          }
        } 
      }
      
      setTableColumns(tableColumnsToUpdate);
    }
  };

  const deleteButton = (rightStubObj, column, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    tableColumnsToUpdate[column['sequence'] - 1].value = '';
    
    setTableColumns(tableColumnsToUpdate);
  };

  
  if (tableColumns.length > 0) {
    return (
      <>
        <Header />
        <form onSubmit={(event) => handleFormSubmit(event)}>
          <SubHeader actions={actions} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
          <div>
            <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
              <div>
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">ASSIGN PDPA ATTACHMENT</h3>
                  </div>
                  <div className="border-t border-gray-200 p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="items-top sm:grid pb-4 sm:py-4 sm:grid-cols-3 sm:gap-4 px-6">
                        {tableColumns.map((tableColumn, index) => {
                          if (tableColumn.column_name === 'tag_worker') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2 bg-red-50">
                                  <ComboBox tableColumns={tableColumns} reference={tableColumn['reference']} mode='Assign' handleComboBoxChange={handleComboBoxChange} deleteButton={deleteButton} tableColumn={tableColumn} />
                                </dd>
                              </Fragment>
                            )
                          } else if (tableColumn.column_name === 'tagged_workers') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  {tableColumn.value !== '' ? 
                                    <div className="border border-gray-300 pl-3 pr-3 py-3 items-center justify-between flex">
                                      <div className="w-full">
                                        <p className="ml-0 mb-0 font-bold flex-1">{tableColumn.value}</p>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" onClick={(event) => deleteButton(null, tableColumn, event)}>
                                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                      </div>
                                    </div>
                                    : 
                                    <input
                                      type='text'
                                      className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                      id='tagged_workers'
                                      disabled
                                    />
                                  }
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          } else if (tableColumn.column_name === 'file_path') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                                  <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/pdpa-attachments/${tableColumns.find(tableColumn => tableColumn.column_name === 'filename').value}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>
                                  <div className="mt-3 mb-3">
                                  <a href={`${backend_base_url()}/api/pdpa-attachments/downloadFile?filepath=${tableColumn['value']}&recordId=${tableColumns.find(tableColumn => tableColumn.column_name === 'id').value}&userId=${cookies['userId']}`} className="items-center mt-2 px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-gray-500 hover:bg-gray-600">Download</a>
                                  </div>
                                </dd>
                              </Fragment>
                            )
                          } else if (tableColumn.column_name === 'pdpa_status') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <SelectOption tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={tableColumn['value']}/>
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          } else if (tableColumn.column_name === 'attachment_emailed_by') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                                  {tableColumn['value']}
                                </dd>
                              </Fragment>
                            )
                          } 
                        })}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  } else {
    return <></>
  }
}