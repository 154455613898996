import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SelectOption(props) {
  const {rightStubObj, tableColumn, column, values, selectedValue} = props;
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    updateSelectedValue();
  }, [selectedValue]);

  const updateSelectedValue = () => {
    if (selectedValue === '') {
      setSelected(values[0]);
    } else {
      for (let i = 0; i < values.length; i += 1) {
        if (values[i]['dropdown_item'] === selectedValue) {
          setSelected(values[i]);
          break;
        }
      }
    }
  };

  const handleChange = (rightStubObj, tableColumn, column, event) => {
    setSelected(event);
    props.handleDropdownChange(rightStubObj, tableColumn, column, event);
  };

  if (selected !== undefined) {
    return (
      <Listbox value={selected} onChange={((event) => handleChange(rightStubObj, tableColumn, column, event))}>
        {({ open }) => (
          <>
            <div className="relative w-full">
              <Listbox.Button className={`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column} h-[38px]`}>
                <span className="block truncate">{selected.dropdown_item}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {values.map((value, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-sky-600' : 'text-gray-900',
                          'cursor-default select-none relative py-0 pl-3 pr-9'
                        )
                      }
                      value={value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {value.dropdown_item}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-sky-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    )
  } else {
    return (
      <></>
    )
  }
}