import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { useParams, useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivilegeAndInputType, getAllTableAndColumns, editObject, getUserFromToken, updatePassword, listenToNotifications } from '../../scripts/helpers'
import {addAuditLog} from '../../scripts/helpers';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'

export default function PasswordsEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const [hideExistingPassword, setHideExistingPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideNewPassword2, setHideNewPassword2] = useState(true);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const [fields, setFields] = useState({
    existingPassword: '',
    newPassword: '',
    newPassword2: ''
  });
  const [actions, setActions] = useState([
    {
      name: 'Save',
      href: `#`,
      class: 'bg-blue-500 hover:bg-blue-600',
      position: 'left',
      hidden: false,
    },
    {
      name: 'Abandon',
      href: '#',
      class: 'bg-red-500 hover:bg-red-600',
      position: 'left',
      hidden: false,
    }
  ]);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});

  const checkCompulsoryFields = () => {
    let compulsoryFieldsHaveBeenFilled = true;

    Object.keys(fields).forEach(key => {
      if (fields[key] === '') {
        compulsoryFieldsHaveBeenFilled = false;
        document.getElementById(key).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
      }
    });

    return compulsoryFieldsHaveBeenFilled;
  };

  const handleField = (event) => {
    const fieldsToUpdate = {...fields};
    fieldsToUpdate[event.target.id] = event.target.value;
    setFields(fieldsToUpdate);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Save') {
      document.getElementById('existingPassword').className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      document.getElementById('newPassword').className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      document.getElementById('newPassword2').className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';

      if (!checkCompulsoryFields()) {
        setOutcome({
          message: 'Please fill up all compulsory fields.',
          type: 'Danger'
        });

        
      } else {    
        // check password inputs and whether it matches pattern
        const passwordPattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*/);
        const fieldKeys = Object.keys(fields);
        for (let i = 0; i < fieldKeys.length; i += 1) {
          if (!fields[fieldKeys[i]].match(passwordPattern)) {
            document.getElementById(fieldKeys[i]).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
            
            setOutcome({
              message: `${fieldKeys[i]} does not match password pattern.`,
              type: 'Danger'
            });
    
            
            
            return;
          }
        }

        // check that newPassword and newPassword2 are the same
        if (fields.newPassword !== fields.newPassword2) {
          document.getElementById('newPassword').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
          document.getElementById('newPassword2').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
            
          setOutcome({
            message: `New passwords provided do not match.`,
            type: 'Danger'
          });
  
          

          return;
        }

        updatePassword(cookies['token'], fields).then(result => {
          setOutcome({
            message: `Password changed.`,
            type: 'Success'
          });
          
          addAuditLog({
            table_name: 'Users',
            record_id: cookies['userId'],
            action_type: 'Update',
            action_description: 'Updated password.',
            user_id: cookies['userId']
          });
  
          setTimeout(() => {
            setOutcome({});
            navigate(`/profile`);
          }, 3000);          
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });

          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

          
        });
      }
    } else {
      navigate('/');
    }
  };

  const toggleExistingPasswordVisibility = () => {
    const passwordInput = document.getElementById('existingPassword');

    if (hideExistingPassword) {
      document.getElementById('existingPassword').type = 'text';
      setHideExistingPassword(false);
    } else {
      document.getElementById('existingPassword').type = 'password';
      setHideExistingPassword(true);
    }
  };

  const toggleNewPasswordVisibility = () => {
    const passwordInput = document.getElementById('newPassword');

    if (hideNewPassword) {
      document.getElementById('newPassword').type = 'text';
      setHideNewPassword(false);
    } else {
      document.getElementById('newPassword').type = 'password';
      setHideNewPassword(true);
    }
  };
  
  const toggleNewPassword2Visibility = () => {
    const passwordInput = document.getElementById('newPassword2');

    if (hideNewPassword2) {
      document.getElementById('newPassword2').type = 'text';
      setHideNewPassword2(false);
    } else {
      document.getElementById('newPassword2').type = 'password';
      setHideNewPassword2(true);
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5 shadow">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">CHANGE PASSWORD</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Existing Password *</dt>
                  <dd className="relative mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <input
                      type='password'
                      name='existingPassword'
                      id='existingPassword'
                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      onChange={(event) => handleField(event)}
                      value={fields.existingPassword}
                    />
                    
                    {hideExistingPassword ? (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleExistingPasswordVisibility}>
                        <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    ) : (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleExistingPasswordVisibility}>
                        <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                  </dd>
                  <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">New Password *</dt>
                  <dd className="relative mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <input
                      type='password'
                      name='newPassword'
                      id='newPassword'
                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      onChange={(event) => handleField(event)}
                      value={fields.newPassword}
                    />
                    
                    {hideNewPassword ? (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleNewPasswordVisibility}>
                        <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    ) : (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleNewPasswordVisibility}>
                        <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                  </dd>
                  <dt className="mt-0 text-sm font-medium text-gray-500"></dt>
                  <dd className="relative mt-0 text-sm sm:mt-0 sm:col-span-2">
                    <p className="mt-0 text-sm text-gray-500">Password must be at least 8 - 24 characters long, contain at least one uppercase letter, one lowercase letter, one numeral and one special character e.g. @#$%^&+*!=.</p>
                  </dd>
                  <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Re-type New Password *</dt>
                  <dd className="relative mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <input
                      type='password'
                      name='newPassword2'
                      id='newPassword2'
                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      onChange={(event) => handleField(event)}
                      value={fields.newPassword2}
                    />
                    
                    {hideNewPassword2 ? (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleNewPassword2Visibility}>
                        <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    ) : (
                      <div className="hover:cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3" onClick={toggleNewPassword2Visibility}>
                        <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}