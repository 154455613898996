import { useState, useEffect, Fragment, useRef, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { getMonth, getYear, format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, XIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { getObject, displayValueByColumnName, findOtherRecordIds, getSubsidiaryColumnsToDisplay, fiftyTruncate, listenToNotifications } from '../scripts/helpers'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import _ from 'lodash'

import Badge from '../components/badge'
import Loading from '../components/loading'
import SelectOption from '../components/selectOption'
import ComboBox from '../components/comboBox'
import e from '@toast-ui/react-image-editor'
import { AuthContext } from '../AuthProvider'
import { frontend_base_url } from '../utils/helper'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function RightStub(props) {
  const {rightStubObj} = props;
  const params = useParams();
  const {workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction, problemSubsidiaryAction} = params;
  
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [otherRecordIds, setOtherRecordIds] = useState([]);
  const [originalPoster, setOriginalPoster] = useState('');
  const [query, setQuery] = useState('');

  const [open, setOpen] = useState(true);
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [currentWeekCounter, setCurrentWeekCounter] = useState();
  const [diaryView, setDiaryView] = useState(false);
  const [displayedRecords, setDisplayedRecords] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const cancelButtonRef = useRef(null);

  const toggleDiary = () => {
    setDiaryView(!diaryView);
  };

  const navigate = useNavigate();

  const navigateToObject = (event, objectId) => {
    if (event.target.checked === undefined) {
      if (rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction !== undefined) {
        navigate(`/workers/${rightStubObj.workerId}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${rightStubObj.jobId}/${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/${rightStubObj.problemId}/${rightStubObj.problemSubsidiarySlugOrJobSubsidiaryAction}/${objectId}`)
      } else if (rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined) {
        navigate(`/workers/${rightStubObj.workerId}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${rightStubObj.jobId}/${rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction}/${objectId}`)
      } else if (rightStubObj.workerSubsidiarySlugOrWorkerAction !== undefined) {
        navigate(`/workers/${rightStubObj.workerId}/${rightStubObj.workerSubsidiarySlugOrWorkerAction}/${objectId}`)
      }
    }
  };

  const navigateToBackwardRecord = () => {
    if (problemSubsidiarySlugOrJobSubsidiaryAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(problemSubsidiaryIdOrProblemSubsidiaryAction)) - 1;
        if (currentRecordIndex >= 0) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${otherRecordIds[currentRecordIndex]}`);
        }
      } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(jobSubsidiaryIdOrJobSubsidiaryAction)) - 1;
        if (currentRecordIndex >= 0) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${otherRecordIds[currentRecordIndex]}`);
        }
      } else if (workerSubsidiarySlugOrWorkerAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) - 1;
        if (currentRecordIndex >= 0) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${otherRecordIds[currentRecordIndex]}`)
        }
      }
  };

  const navigateToForwardRecord = () => {
    if (problemSubsidiarySlugOrJobSubsidiaryAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(problemSubsidiaryIdOrProblemSubsidiaryAction)) + 1;
        if (currentRecordIndex !== otherRecordIds.length) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${jobSubsidiaryIdOrJobSubsidiaryAction}/${problemSubsidiarySlugOrJobSubsidiaryAction}/${otherRecordIds[currentRecordIndex]}`);
        }
      } else if (jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(jobSubsidiaryIdOrJobSubsidiaryAction)) + 1;
        if (currentRecordIndex !== otherRecordIds.length) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${workerSubsidiaryIdOrWorkerSubsidiaryAction}/${jobSubsidiarySlugOrWorkerSubsidiaryAction}/${otherRecordIds[currentRecordIndex]}`);
        }
      } else if (workerSubsidiarySlugOrWorkerAction !== undefined) {
        const currentRecordIndex = otherRecordIds.indexOf(parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) + 1;
        if (currentRecordIndex !== otherRecordIds.length) {
          navigate(`/workers/${workerId}/${workerSubsidiarySlugOrWorkerAction}/${otherRecordIds[currentRecordIndex]}`)
        }
      }
  };

  const navigateToForwardPage = () => {
    if (page < Math.ceil(rightStubObj.noOfRecords / 10)) {
      setPage(page + 1);
      setSort({});
    }
  };

  const navigateToBackwardPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setSort({});
    }
  };

  const navigateToPreviousWeek = () => {
    if (currentWeekCounter > 0) {
      setCurrentWeekCounter(currentWeekCounter - 1)
    }
  };

  const navigateToForwardWeek = () => {
    if (currentWeekCounter < rightStubObj.diffWeeks.length - 1) {
      setCurrentWeekCounter(currentWeekCounter + 1)
    }
  };

  const toggleSort = (index, page) => {
    let sortToUpdate = {...sort};

    const column = rightStubObj.dashedLabelColumns[index];

    if (Object.keys(sortToUpdate).length === 1) {
      if (index !== parseInt(Object.keys(sortToUpdate)[0])) {
        sortToUpdate = {};
      }
    }
    
    let rightStubObjToUpdate = {...rightStubObj};
    let tableColumnsToUpdate = [...rightStubObj.tableColumns];
    let innerTableColumnsToUpdate = [...rightStubObj.tableColumns[page-1]];

    if (sortToUpdate[index] === undefined) {
      sortToUpdate[index] = false;
    }

    if (sortToUpdate[index] === false) {
      // searchResultsToUpdate.sort((a,b) => (a[column] > b[column] ? 1 : -1));
      innerTableColumnsToUpdate = _.orderBy(innerTableColumnsToUpdate, [column], ['asc']);
    } else {
      // searchResultsToUpdate.sort((a,b) => (a[column] < b[column] ? 1 : -1));
      innerTableColumnsToUpdate = _.orderBy(innerTableColumnsToUpdate, [column], ['desc']);
    }

    sortToUpdate[index] = !sortToUpdate[index];

    setSort(sortToUpdate);
    tableColumnsToUpdate[page-1] = innerTableColumnsToUpdate;
    rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
    rightStubObj.setRightStubObj(rightStubObjToUpdate);
  };

  useEffect(() => {
    if (Object.keys(rightStubObj).length > 0) {
      if (rightStubObj.page === 'View' || rightStubObj.page === 'Edit') {
        if (rightStubObj.page === 'Edit') {
          document.addEventListener("wheel", function(event){
            if(document.activeElement.type === "number"){
                document.activeElement.blur();
            }
          });
        }
        
        if (rightStubObj.table === 'Sensitive Discussion') {
          for (let index = 0; index < rightStubObj.tableColumns.length; index++) {
            if (rightStubObj.tableColumns[index]['column_name'] === 'created_by') {
              setOriginalPoster(rightStubObj.tableColumns[index]['value']);
              break;
            }
          }
        }
        rightStubObj.tableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined && tableColumn.reference_synced === false && tableColumn.value !== null) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const storedAsColumnName = storedAs.data.column_name;

                displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn.value).then((displayValue) => {
                  const rightStubObjToUpdate = {...rightStubObj};
                  const tableColumnsToUpdate = [...rightStubObj.tableColumns];

                  tableColumn['reference_synced'] = true;

                  tableColumnsToUpdate[tableColumn['sequence'] - 1].value = displayValue.data[Object.keys(displayValue.data)[0]];

                  rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                  rightStubObj.setRightStubObj(rightStubObjToUpdate);
                })
              });
            });
          }
        });

        // if in view, find other records to paginate
        if (rightStubObj.page === 'View' && workerSubsidiarySlugOrWorkerAction !== undefined) {
          findOtherRecordIds(workerId, workerSubsidiarySlugOrWorkerAction, workerSubsidiaryIdOrWorkerSubsidiaryAction, jobSubsidiarySlugOrWorkerSubsidiaryAction, jobSubsidiaryIdOrJobSubsidiaryAction, problemSubsidiarySlugOrJobSubsidiaryAction, problemSubsidiaryIdOrProblemSubsidiaryAction, cookies['token']).then(result => {
            setOtherRecordIds(result.data.map((row) => row.id));
          });
        }
      } else if (rightStubObj.page === 'Add') {
        document.addEventListener("wheel", function(event){
          if(document.activeElement.type === "number"){
              document.activeElement.blur();
          }
        });
        
        rightStubObj.tableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined && tableColumn.reference_synced === false) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const rightStubObjToUpdate = {...rightStubObj};
                const tableColumnsToUpdate = [...rightStubObj.tableColumns];
                const storedAsColumnName = storedAs.data.column_name;
                tableColumn['reference_synced'] = true;
                tableColumnsToUpdate[tableColumn['sequence'] - 1].value = '';

                rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                rightStubObj.setRightStubObj(rightStubObjToUpdate);
              });
            });
          }
        });
      } else if (rightStubObj.page === 'List') {
        rightStubObj.tableColumns.forEach((tableColumn) => {
          if (tableColumn.reference !== undefined) {
            getObject('tableAndColumns', tableColumn['reference']['view']).then(referenced => {
              const referencedTableSlug = referenced.data.table_slug;
              const referencedColumnName = referenced.data.column_name;

              getObject('tableAndColumns', tableColumn['reference']['store']).then(storedAs => {
                const storedAsColumnName = storedAs.data.column_name;

                displayValueByColumnName(referencedTableSlug, referencedColumnName, storedAsColumnName, tableColumn.value).then((displayValue) => {

                  const rightStubObjToUpdate = {...rightStubObj};
                  const tableColumnsToUpdate = [...rightStubObj.tableColumns];

                  tableColumnsToUpdate[tableColumn['sequence'] - 1].value = displayValue.data[Object.keys(displayValue.data)[0]];
                  
                  rightStubObjToUpdate.tableColumns = tableColumnsToUpdate;
                  rightStubObj.setRightStubObj(rightStubObjToUpdate);
                })
              });
            });
          }
        });
        
        setCurrentWeekCounter(rightStubObj.currWeekCounter);
      }
    }

    setDiaryView(false);
  }, [rightStubObj]);

  if (Object.keys(rightStubObj).length > 0) {
    if (rightStubObj.page === 'View') {
      return (
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
              {workerSubsidiarySlugOrWorkerAction !== undefined ? 
                (problemSubsidiarySlugOrJobSubsidiaryAction !== undefined && problemSubsidiaryIdOrProblemSubsidiaryAction !== undefined ? 
                  (
                    <span className="relative z-0 inline-flex shadow-sm">
                      <button
                        type="button"
                        className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                        onClick={navigateToBackwardRecord}
                      >
                        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                        disabled
                      >
                        {otherRecordIds.indexOf(parseInt(problemSubsidiaryIdOrProblemSubsidiaryAction)) + 1} of {otherRecordIds.length}
                      </button>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                        onClick={navigateToForwardRecord}
                      >
                        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </button>
                    </span>
                  ) : (jobSubsidiarySlugOrWorkerSubsidiaryAction !== undefined && jobSubsidiaryIdOrJobSubsidiaryAction !== undefined && problemSubsidiarySlugOrJobSubsidiaryAction === undefined ? 
                        (
                          <span className="relative z-0 inline-flex shadow-sm">
                            <button
                              type="button"
                              className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToBackwardRecord}
                            >
                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                              disabled
                            >
                              {otherRecordIds.indexOf(parseInt(jobSubsidiaryIdOrJobSubsidiaryAction)) + 1} of {otherRecordIds.length}
                            </button>
                            <button
                              type="button"
                              className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                              onClick={navigateToForwardRecord}
                            >
                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </button>
                          </span>
                        ) : (workerSubsidiarySlugOrWorkerAction !== undefined && workerSubsidiaryIdOrWorkerSubsidiaryAction !== undefined && jobSubsidiarySlugOrWorkerSubsidiaryAction === undefined ? 
                              (
                                <span className="relative z-0 inline-flex shadow-sm">
                                  <button
                                    type="button"
                                    className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                                    onClick={navigateToBackwardRecord}
                                  >
                                    <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                  </button>
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                                    disabled
                                  >
                                    {otherRecordIds.indexOf(parseInt(workerSubsidiaryIdOrWorkerSubsidiaryAction)) + 1} of {otherRecordIds.length}
                                  </button>
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                                    onClick={navigateToForwardRecord}
                                  >
                                    <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                  </button>
                                </span>
                              ) : (<></>)
                        )
                  ))
              : <></>}
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {rightStubObj.tableColumns.map((tableColumn, index) => {
                  if (tableColumn['column_name'] !== 'problem_clan_checkbox') {
                    if (tableColumn['privilege'] === 'Yes') {
                      if (tableColumn['label_name'] === 'Attachment path') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              <a href={`${tableColumn['value']}`} download className="text-sky-500 font-medium hover:text-sky-700">{tableColumn['value']}</a>
                            </dd>
                          </div>
                        )
                      } else if (tableColumn['column_name'] === 'tagged_workers') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              {tableColumn.value.length > 0 ? (
                                <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                  {tableColumn.value.map((row, index) => {
                                    return (
                                      <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm hover:bg-gray-50 cursor-pointer" key={index} onClick={() => (navigate(row[3]))}>
                                        <div className="w-full">
                                          <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                          <p className="ml-0 mb-0 flex-1">{row[1]}</p>
                                          <p className="ml-0 mb-0 flex-1">{row[2]}</p>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ul>
                              ) : (<></>)}
                            </dd>
                          </div>
                        )
                      } else if (tableColumn['column_name'] === 'tagged_users') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              {tableColumn.value.length > 0 ? (
                                <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                  {tableColumn.value.map((row, index) => {
                                    return (
                                      <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                        <div className="w-full">
                                          <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ul>
                              ) : (<></>)}
                            </dd>
                          </div>
                        )
                      } else if (tableColumn['column_name'] === 'path_current_facepic') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              {tableColumn['value'] && <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/facepic-attachments/${tableColumn['value'].split('/')[tableColumn['value'].split('/').length - 1]}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>}
                            </dd>
                          </div>
                        )
                      } else if (tableColumn['column_name'] === 'path_current_pdpa') {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              {tableColumn['value'] && <a target="_blank" rel="noopener noreferrer" href={`${frontend_base_url()}/pdpa-attachments/${tableColumn['value'].split('/')[tableColumn['value'].split('/').length - 1]}`} className="text-sky-500 font-medium hover:text-sky-700" style={{overflowWrap: "break-word"}} >{tableColumn['value']}</a>}
                            </dd>
                          </div>
                        )
                      } else {
                        return (
                          <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                            <dt className="text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">
                              {tableColumn['value'] === true ? (<CheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />) : (tableColumn['value'])}
                            </dd>
                          </div>
                        )
                      }
                    }
                  }
                })}
              </dl>
            </div>
          </div>
        </div>
      )
    } else if (rightStubObj.page === 'Edit') {
      return (
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                  {rightStubObj.tableColumns.map((tableColumn, index) => {
                    if (tableColumn['column_name'] !== 'problem_clan_checkbox') {
                      if (tableColumn['privilege'] === 'Yes') {
                        if (tableColumn['textarea']) {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {/* <input
                                  type="checkbox"
                                  name={tableColumn['column_name']}
                                  id={tableColumn['column_name']}
                                  onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                  className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                /> */}
                                <textarea
                                  rows={5}
                                  name={tableColumn['column_name']}
                                  id={tableColumn['column_name']}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                  value={tableColumn['value']}
                                />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['input_type'] === 'checkbox') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type="checkbox"
                                  name={tableColumn['column_name']}
                                  id={tableColumn['column_name']}
                                  onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                  onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                  className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                  style={{marginTop: '0.08rem'}}
                                />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['input_type'] === 'datetime-local') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <DatePicker 
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
  
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
  
                                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                    </div>
                                  )}
                                  showTimeSelect
                                  selected={tableColumn['value']} 
                                  onChange={(event) => rightStubObj.handleCalendarChange(rightStubObj, tableColumn, event)} 
                                  dateFormat='yyyy-MMM-dd HH:mm' 
                                  autoComplete='off'
                                  id={tableColumn['column_name']}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['input_type'] === 'date') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <DatePicker 
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
  
                                      <select
                                        className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
  
                                      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </button>
                                    </div>
                                  )}
                                  selected={tableColumn['value']} 
                                  onChange={(event) => rightStubObj.handleCalendarChange(rightStubObj, tableColumn, event)} 
                                  dateFormat='yyyy-MMM-dd' 
                                  autoComplete='off'
                                  id={tableColumn['column_name']}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['dropdowns'] !== undefined && tableColumn['dropdowns'].length > 0) {
                          if (rightStubObj.table === 'Benefit' && tableColumn['column_name'] === 'bene_class') {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.changeBenefitClass} selectedValue={tableColumn['value']}/>
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          } else {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleDropdownChange} selectedValue={tableColumn['value']}/>
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          }
                        } else if (tableColumn['input_type'] === 'combobox') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <ComboBox reference={tableColumn['reference']} mode={rightStubObj.page} rightStubObj={rightStubObj} handleComboBoxChange={rightStubObj.handleComboBoxChange} deleteButton={rightStubObj.deleteButton} tableColumn={tableColumn} />
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['input_type'] === 'float') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {tableColumn['float_step'] === '.01' ? 
                                  (
                                    <input
                                      type='number'
                                      step={tableColumn['float_step']}
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      value={tableColumn['value']}
                                      onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                      onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                    />
                                  ) : (
                                    <input
                                      type='number'
                                      min='0'
                                      step={tableColumn['float_step']}
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      value={tableColumn['value']}
                                      onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                      onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                    />
                                  )
                                }
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type={tableColumn['input_type']}
                                  name={tableColumn['column_name']}
                                  id={tableColumn['column_name']}
                                  value={tableColumn['value']}
                                  onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                  onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        }
                      } else if (tableColumn['privilege'] === 'View') {
                        if (tableColumn['input_type'] === 'checkbox' && problemSubsidiarySlugOrJobSubsidiaryAction !== 'ordinary-case-discussions' && problemSubsidiarySlugOrJobSubsidiaryAction !== 'hearings-progress' && problemSubsidiarySlugOrJobSubsidiaryAction !== 'sensitive-discussions') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {tableColumn['value'] === true ? 
                                  (
                                    <input
                                      type="checkbox"
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      checked
                                      disabled
                                      style={{marginTop: '0.08rem'}}
                                    />
                                  ) : 
                                  (
                                    <input
                                      type="checkbox"
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      disabled
                                      style={{marginTop: '0.08rem'}}
                                    />
                                  )}
                                
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else {
                          if (tableColumn['column_name'] === 'tagged_workers' && tableColumn.value !== undefined) {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  {tableColumn.value.length > 0 ? (
                                    <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                      {tableColumn.value.map((row, index) => {
                                        return (
                                          <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm hover:bg-gray-50 cursor-pointer" key={index} onClick={() => (navigate(row[3]))}>
                                            <div className="w-full">
                                              <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                                              <p className="ml-0 mb-0 flex-1">{row[1]}</p>
                                              <p className="ml-0 mb-0 flex-1">{row[2]}</p>
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  ) : (<></>)}
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          } else if (tableColumn['column_name'] === 'tagged_users' && tableColumn.value !== undefined) {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  {tableColumn.value !== undefined && Object.keys(tableColumn.value).length > 0 ? (
                                    <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                      {Object.keys(tableColumn.value).map((key, index) => {
                                        return (
                                          <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                            <div className="w-full">
                                              <p className="ml-0 mb-0 font-bold flex-1">{key}</p>
                                            </div>
                                            <div className="ml-4 flex-shrink-0">
                                              {key !== originalPoster ? <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={(event) => rightStubObj.handleComboBoxChange(rightStubObj, null, null, tableColumn['column_name'], null, null, key)} /> : '' }
                                            </div>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  ) : (
                                    <Fragment>
                                      <input
                                        type='text'
                                        className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                        disabled
                                      />
                                    </Fragment>
                                  )}
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                              // <Fragment key={index}>
                              //   <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                              //   <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              //     {tableColumn.value.length > 0 ? (
                              //       <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                              //         {tableColumn.value.map((row, index) => {
                              //           return (
                              //             <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                              //               <div className="w-full">
                              //                 <p className="ml-0 mb-0 font-bold flex-1">{row[0]}</p>
                              //               </div>
                              //             </li>
                              //           )
                              //         })}
                              //       </ul>
                              //     ) : (<></>)}
                              //     {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              //   </dd>
                              // </Fragment>
                            )
                          } else {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <input
                                    type='text'
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                    placeholder={tableColumn['value']}
                                    disabled
                                  />
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          }
                        }
                      }
                    }
                  })}
                </div>
              </dl>
            </div>
          </div>
        </div>
      )
    } else if (rightStubObj.page === 'List') {
      return (
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <span>
                <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page.toUpperCase()} {rightStubObj.table.toUpperCase()}</h3>
                <p className="text-sm font-medium text-gray-500">{rightStubObj['noOfRecords']} records</p>
              </span>
              {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'client-appointments' && !diaryView ?
                <button
                  type="button"
                  className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                  onClick={toggleDiary}
                >
                  Diary
                </button>
              : (<></>)}
              {rightStubObj.workerSubsidiarySlugOrWorkerAction === 'client-appointments' && diaryView ?
                <button
                  type="button"
                  className="inline-flex items-center px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                  onClick={toggleDiary}
                >
                  List
                </button>
              : (<></>)}
              
              {!diaryView && Math.ceil(rightStubObj['noOfRecords'] / 10) > 1 ? 
                (
                  <span className="relative z-0 inline-flex shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToBackwardPage}
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                      disabled
                    >
                      {page} of {Math.ceil(rightStubObj['noOfRecords'] / 10)}
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToForwardPage}
                    >
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                  </span>
                ) : (<></>)
              }

              {diaryView && rightStubObj.diffWeeks.length > 1 ? 
                (
                  <span className="relative z-0 inline-flex shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToPreviousWeek}
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                      disabled
                    >
                      {currentWeekCounter + 1} of {rightStubObj.diffWeeks.length}
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={navigateToForwardWeek}
                    >
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                  </span>
                ) : (<></>)}
            </div>
            <div className="border-t border-gray-200 p-0">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200">
                      {diaryView && rightStubObj.workerSubsidiarySlugOrWorkerAction === 'client-appointments' ? 
                        (
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {rightStubObj.diaryColumns.map((label, index) => {
                                  return (
                                    <th 
                                      scope="col" 
                                      className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase"
                                      key={index}
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => toggleSort(index, page)}>
                                        {label.label_name} 
                                        {sort[index] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {sort[index] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {rightStubObj['noOfRecords'] > 0 ?
                              <Fragment>
                                {/* {Object.keys(rightStubObj['diaryData'][rightStubObj.diffWeeks[rightStubObj['currWeekCounter']]])} */}
                                {Object.keys(rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]]).map(key => 
                                  rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['brief_details'].length > 0 ? 
                                  (
                                    <tr key={key} className="align-top">
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['day']}</td>
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['date'], 'yyyy-MMM-dd')}</td>
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">
                                        {rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['brief_details'].map(detail => {
                                          return (
                                            <div key={detail.id} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToObject(event, detail.id)}><strong>{format(detail['date'], 'HH:mm')}</strong><p>{detail['title']}</p><p>{detail['location']}</p><p>{detail['accompanying']}</p></div>
                                          )
                                        })}
                                      </td>
                                    </tr>
                                  ) : 
                                  (
                                    <tr key={key} className="cursor-pointer align-top">
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['day']}</td>
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(rightStubObj['diaryData'][rightStubObj.diffWeeks[currentWeekCounter]][key]['date'], 'yyyy-MMM-dd')}</td>
                                      <td className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">
                                        
                                      </td>
                                    </tr>
                                  )  
                                )}
                              </Fragment>
                              : '' }
                            </tbody>
                          </table>
                        ) : 
                        (
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                {rightStubObj.labelColumns.map((label, index) => {
                                  return (
                                    <th 
                                      scope="col" 
                                      className="sticky px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase"
                                      key={index}
                                    >
                                      <span className="group inline-flex cursor-pointer" onClick={() => toggleSort(index, page)}>
                                        {label} 
                                        {sort[index] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                        {sort[index] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                                      </span>
                                    </th>
                                  )
                                })}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {rightStubObj['noOfRecords'] > 0 ?
                              <Fragment>
                                {rightStubObj.tableColumns[page - 1] !== undefined ? 
                                  (
                                    rightStubObj.tableColumns[page - 1].map((tableColumn, index) => {
                                      return (
                                        <tr key={index} className="cursor-pointer hover:bg-gray-50 align-top" onClick={(event) => navigateToObject(event, tableColumn.id)}>
                                          {rightStubObj.dashedLabelColumns.map((key, index2) => {
                                            const timestampRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
                                            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

                                            if (String(rightStubObj.tableColumns[page - 1][index][key]).match(timestampRegex) && rightStubObj.dateOrDateTimeColumns[key]) {
                                              return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd HH:mm')}</td>
                                            } else if (String(rightStubObj.tableColumns[page - 1][index][key]).match(dateRegex) && rightStubObj.dateOrDateTimeColumns[key]) {
                                              return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{format(new Date(rightStubObj.tableColumns[page - 1][index][key]), 'yyyy-MMM-dd')}</td>
                                            } else {
                                              return <td key={index2} className="px-2 py-1 text-sm text-gray-900 whitespace-pre-wrap">{rightStubObj.tableColumns[page - 1][index][key] !== null && fiftyTruncate(rightStubObj.tableColumns[page - 1][index][key])}</td>
                                            }
                                          })}
                                        </tr>
                                      )
                                    })
                                  ) : (<></>)
                                }
                              </Fragment>
                              : '' }
                            </tbody>
                          </table>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (rightStubObj.page === 'Add') {
      return (
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">{rightStubObj.page && rightStubObj.page.toUpperCase()} {rightStubObj.table && rightStubObj.table.toUpperCase()}</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                  {rightStubObj.tableColumns.map((tableColumn, index) => {
                    if (tableColumn['hidden'] === undefined) {
                    if (tableColumn['privilege'] === 'Yes') {
                      if (tableColumn['textarea']) {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              {/* <input
                                type="checkbox"
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                              /> */}
                              <textarea
                                rows={5}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'checkbox') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type="checkbox"
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300 float-left"
                                style={{marginTop: '0.08rem'}}
                              />
                              {tableColumn['detail'] !== undefined ? 
                                (
                                  <p className='ml-6'>{tableColumn['detail']}</p>
                                ) : (<></>)}
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'file') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type="file"
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                onChange={(event) => rightStubObj.handleFileChange(rightStubObj, tableColumn, event)}
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'datetime-local') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <DatePicker 
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={getYear(date)}
                                      onChange={({ target: { value } }) => changeYear(value)}
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={months[getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                  </div>
                                )}
                                showTimeSelect
                                selected={tableColumn['value']} 
                                onChange={(event) => rightStubObj.handleCalendarChange(rightStubObj, tableColumn, event)} 
                                dateFormat='yyyy-MMM-dd HH:mm' 
                                autoComplete='off'
                                id={tableColumn['column_name']}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'date') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <DatePicker 
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={getYear(date)}
                                      onChange={({ target: { value } }) => changeYear(value)}
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      value={months[getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </button>
                                  </div>
                                )}
                                selected={tableColumn['value']} 
                                onChange={(event) => rightStubObj.handleCalendarChange(rightStubObj, tableColumn, event)} 
                                dateFormat='yyyy-MMM-dd' 
                                autoComplete='off'
                                id={tableColumn['column_name']}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'combobox') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <ComboBox reference={tableColumn['reference']} mode={rightStubObj.page} rightStubObj={rightStubObj} handleComboBoxChange={rightStubObj.handleComboBoxChange} deleteButton={rightStubObj.deleteButton} tableColumn={tableColumn} />
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['dropdowns'].length > 0) {
                        if (rightStubObj.table === 'Benefit' && tableColumn['column_name'] === 'bene_class') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.changeBenefitClass} selectedValue={tableColumn['value']}/>
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <SelectOption rightStubObj={rightStubObj} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={rightStubObj.handleDropdownChange} selectedValue={tableColumn['value']}/>
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        }
                      } else if (tableColumn['input_type'] === 'float') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              {tableColumn['float_step'] === '.01' ? 
                                (
                                  <input
                                    type='number'
                                    step={tableColumn['float_step']}
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    value={tableColumn['value']}
                                    onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                    onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  />
                                ) : (
                                  <input
                                    type='number'
                                    min='0'
                                    step={tableColumn['float_step']}
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    value={tableColumn['value']}
                                    onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                    onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  />
                                )
                              }
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else if (tableColumn['input_type'] === 'list') {
                        if (tableColumn['column_name'] === 'tagged_workers') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {Object.keys(tableColumn.value).length > 0 ? (
                                  <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                    {Object.keys(tableColumn.value).map((key, index) => {
                                      return (
                                        <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                          <div className="w-full">
                                            <p className="ml-0 mb-0 font-bold flex-1">{tableColumn.value[key]['worker']}</p>
                                            <p className="ml-0 mb-0 flex-1">{tableColumn.value[key]['job']}</p>
                                            <p className="ml-0 mb-0 flex-1">{tableColumn.value[key]['problem']}</p>
                                          </div>
                                          <div className="ml-4 flex-shrink-0">
                                            <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={(event) => rightStubObj.handleComboBoxChange(rightStubObj, null, null, tableColumn['column_name'], null, null, key)} />
                                          </div>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                ) : (
                                  <Fragment>
                                    <input
                                      type='text'
                                      className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                      disabled
                                    />
                                  </Fragment>
                                )}
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        } else if (tableColumn['column_name'] === 'tagged_users') {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {Object.keys(tableColumn.value).length > 0 ? (
                                  <ul role="list" className="border border-gray-300 divide-y divide-gray-300">
                                    {Object.keys(tableColumn.value).map((key, index) => {
                                      return (
                                        <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                          <div className="w-full">
                                            <p className="ml-0 mb-0 font-bold flex-1">{key}</p>
                                          </div>
                                          <div className="ml-4 flex-shrink-0">
                                            <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={(event) => rightStubObj.handleComboBoxChange(rightStubObj, null, null, tableColumn['column_name'], null, null, key)} />
                                          </div>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                ) : (
                                  <Fragment>
                                    <input
                                      type='text'
                                      className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                      disabled
                                    />
                                  </Fragment>
                                )}
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        }
                      } else {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type={tableColumn['input_type']}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                value={tableColumn['value']}
                                onKeyDown={(e) => {if (e.code === 'Enter') e.preventDefault()}}
                                onChange={(event) => rightStubObj.handleChange(rightStubObj, tableColumn, event)}
                                className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      }
                    } else if (tableColumn['privilege'] === 'View') {
                      if (tableColumn['label_name'] === 'Created by' || tableColumn['label_name'] === 'Submitted by') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type='text'
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={cookies['username']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      } else {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type='text'
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={tableColumn['value']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      }
                    }
                    }
                  })}
                </div>
              </dl>
            </div>
          </div>
        </div>
      )
    } 
  } else {
    return (
      <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>
    )
  }
}