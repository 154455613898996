import { Fragment, useState, useEffect, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Disclosure, Transition, Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { getMonth, getYear, format } from 'date-fns'
import { searchWorkersBeforeCreating, generateTwid, generateTempTwid, getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivilegeAndInputType, addObject, listenToNotifications } from '../scripts/helpers'
import DatePicker from 'react-datepicker'

import Header from '../components/header'
import SubHeader from '../components/subheader'
import AlertNotification from '../components/alertNotification'
import SelectOption from '../components/selectOption'
import TopAndMiddleNavigation from '../components/workers/topAndMiddleNavigation'
import {addAuditLog} from '../../src/scripts/helpers';
import { AuthContext } from '../AuthProvider'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ComposeTwid() {
  const tableSlug = 'workers';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [checkWorkers, setCheckWorkers] = useState(false);
  const [twidPlaceholder, setTwidPlaceholder] = useState('');
  const [matchTitle, setMatchTitle] = useState('MATCH RESULTS');
  const [showMatchResults, setShowMatchResults] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const [searchResults, setSearchResults] = useState([]);
  const [actions, setActions] = useState([
    { name: 'Check for matches', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
  ]);
  const [last4CharFin, setLast4CharFin] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const navigate = useNavigate();

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const handleChange = (column, event) => {
    if (column === 'last4CharFin') {
      event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      if (event.target.value.length <= 4) {
        setLast4CharFin(event.target.value);
      }
    } else if (column === 'yearOfBirth') {
      event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      if (event.target.value.length <= 4) {
        setYearOfBirth(event.target.value);
      }
    } else {
      const tableColumnsToUpdate = [...tableColumns];
      const selectedDropdownToUpdate = [...selectedDropdown];

      if (event.target !== undefined && event.target.type !== 'checkbox') {
        event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
      }

      // if checkbox, handle differently
      if (column['input_type'] === 'checkbox') {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.checked;
      } else if (column['column_name'] === 'tableAndColumn_id') {
        const filteredSelectedDropdownToUpdate = selectedDropdownToUpdate.map((selectedDropdownToUpdateRow) => {
          return selectedDropdownToUpdateRow.column_name
        });

        if (filteredSelectedDropdownToUpdate.indexOf(event.column_name) === -1) {
          tableColumnsToUpdate[column['sequence'] - 1]['value'].push(event);
          selectedDropdownToUpdate.push(event);
        }

        document.getElementsByClassName('w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm')[0].value = '';
      } else {
        tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
      }

      setTableColumns(tableColumnsToUpdate);
      setSelectedDropdown(selectedDropdownToUpdate);
    }
  };

  const handleCalendarChange = (column, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    tableColumnsToUpdate[column['sequence'] - 1].value = event;
    setTableColumns(tableColumnsToUpdate);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const buttonClicked = event.nativeEvent.submitter.innerHTML;
    if (buttonClicked === 'Check for matches') {
      setOutcome({});
      const last4CharFinPattern = new RegExp(/\d{3}[a-zA-Z]{1}/, "i");
      const yearOfBirthPattern = new RegExp(/\d{4}/, "i");
      if (last4CharFin !== '' && !last4CharFin.match(last4CharFinPattern)) {
        setOutcome({
          message: 'Last 3 numerals + letter of FIN does not match pattern.',
          type: 'Danger'
        });

        document.getElementById('last4CharFin').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

        
      } else if (yearOfBirth !== '' && !yearOfBirth.match(yearOfBirthPattern)) {
        setOutcome({
          message: 'Year of birth does not match pattern.',
          type: 'Danger'
        });

        document.getElementById('yearOfBirth').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

        
      } else {
        if (yearOfBirth === '') {
          setOutcome({
            message: 'Compulsory fields have not been filled.',
            type: 'Danger'
          });

          document.getElementById('yearOfBirth').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

          
        }

        if (last4CharFin === '') {
          setOutcome({
            message: 'Compulsory fields have not been filled.',
            type: 'Danger'
          });

          document.getElementById('last4CharFin').className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

          
        } 

        if (yearOfBirth !== '' && last4CharFin !== '') {
          const columns = {};
          columns.yearOfBirth = yearOfBirth;
          columns.last4CharFin = last4CharFin;
          searchWorkersBeforeCreating(columns).then(searchResults => {
            const searchResultsToUpdate = [];
            const result = searchResults.data;

            if (result.length > 0) {
              setMatchTitle("ARE YOU SURE WORKER ISN'T AMONG THESE?");
              result.forEach((resultRow) => {
                
                if (resultRow.facepicAttachments.length > 0) {
                  resultRow.path_current_facepic = `/facepics/${resultRow.facepicAttachments[0]['filename']}`;
                } else {
                  resultRow.path_current_facepic = null;
                }

                searchResultsToUpdate.push(resultRow);
              });
            } else {
             setMatchTitle('MATCH RESULTS'); 
            }

            setShowMatchResults(true);
            setSearchResults(searchResultsToUpdate);
            setCheckWorkers(true);

            if (cookies['role'] !== 'Recorder') {
              setActions([
                { name: 'Check for matches', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
                { name: 'Create new Twid', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
                { name: 'Generate temp Twid', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
                { name: 'Abandon', href: '/', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false }
              ]);
            } else {
              setActions([
                { name: 'Check for matches', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
                { name: 'Create new Twid', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
                { name: 'Abandon', href: '/', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false }
              ]);
            }
          })
        }
      }
    } else if (buttonClicked === 'Generate temp Twid') {
      generateTempTwid().then(result => {
        setTwidPlaceholder(result);
        setActions([
          { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
          { name: 'Abandon', href: '/', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
        ]);
      });
    } else if (buttonClicked === 'Create new Twid') {
      generateTwid(last4CharFin, yearOfBirth).then(result => {
        setTwidPlaceholder(result);
        setActions([
          { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
          { name: 'Abandon', href: '/', class: 'bg-red-500 hover:bg-red-600', position: 'left', hidden: false, disabled: false },
        ]);
      });
    } else if (buttonClicked === 'Save') {
      const result = checkCompulsoryFields(tableColumns);
        if (result.allCompulsoryFieldsHaveBeenFilled) {
          const tableColumnsToSubmit = [...tableColumns];
          tableColumnsToSubmit.push({
            token: cookies['token']
          });

          addObject(tableSlug, tableColumnsToSubmit).then(result => {
            addAuditLog({
              table_name: 'Worker',
              action_type: 'Create',
              record_id: result.data.id,
              action_description: `Created worker with id: ${result.data.id}.`,
              user_id: cookies['userId'],
              worker_id: result.data.id
            });

            navigate(`/${tableSlug}/${result.data.id}`);
          }).catch(err => {
            setOutcome({
              message: err.response.data.message,
              type: 'Danger'
            });

            document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

            
          });
        } else {
          const emptyFields = result.emptyFields;

          emptyFields.forEach((emptyField) => {
            if (document.getElementById(emptyField)) {
              document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
            } else {
              if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
                document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
              }
            }
          });

          setOutcome({
            message: 'Compulsory fields have not been filled.',
            type: 'Danger'
          });

           
        }
    }
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const tableColumnsToUpdate = [...tableColumns];
    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
    } else {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
    }
    setTableColumns(tableColumnsToUpdate);
  };

  useEffect(() => {
    if (cookies['role'] !== 'Recorder') {
      setActions([
        { name: 'Check for matches', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
        { name: 'Generate temp Twid', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', hidden: false, disabled: false },
      ]);
    }
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Add']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivilegeAndInputType(tableSlug, privilegesToUpdate, 'Add').then(preparedTableColumns => {
        for (let i = 0; i < preparedTableColumns.length; i += 1) {
          if (preparedTableColumns[i]['column_name'] === 'twid') {
            if (twidPlaceholder.indexOf('GEN') === -1) {
              preparedTableColumns[i]['value'] = `${twidPlaceholder.substring(0, 4)} ${twidPlaceholder.substring(4, 6)} ${twidPlaceholder.substring(6, 8)}`;
            } else {
              preparedTableColumns[i]['value'] = twidPlaceholder;
            }
            break;
          }
        }

        setTableColumns(preparedTableColumns);
      });
    }).catch(err => {
      navigate('/');
    });
  }, [twidPlaceholder]);

  return (
    <>
      <Header />
      <form onSubmit={handleFormSubmit}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        {twidPlaceholder === '' ? (
          <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <div className="mt-5 shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">COMPOSE TWID</h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="mt-0 text-sm font-medium text-gray-500">Last 3 numerals + letter of FIN *</dt>
                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                      <input
                        type='text'
                        name='last4CharFin'
                        id='last4CharFin'
                        value={last4CharFin}
                        onChange={(event) => handleChange('last4CharFin', event)}
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      />
                    </dd>
                    <dt className="mt-0 text-sm font-medium text-gray-500">Year of birth (4 digits) *</dt>
                    <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                      <input
                        type='number'
                        name='yearOfBirth'
                        id='yearOfBirth'
                        value={yearOfBirth}
                        onChange={(event) => handleChange('yearOfBirth', event)}
                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                      />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="mt-5 shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">{matchTitle}</h3>
                {showMatchResults ? (
                  <p className="text-sm font-medium text-gray-500">{searchResults.length} results</p>
                ) : (<></>)}
              </div>
              <div className="border-t border-gray-200 p-0">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Facepic
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Twid
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name of worker
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Date of birth
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {searchResults.map((searchResult, index) => (
                              <tr key={index}>
                                <td className="px-2 py-1 text-sm text-gray-900">{searchResult.path_current_facepic !== null ? <img src={`${searchResult.path_current_facepic}`} className="w-10" /> : <img src='/user.png' className="w-10" />}</td>
                                <td className="px-2 py-1 text-sm text-gray-900">{searchResult.twid}</td>
                                <td className="px-2 py-1 text-sm text-gray-900"><Link to={`/workers/${searchResult.id}`} className="text-sky-500 font-medium hover:text-sky-700">{searchResult.name_of_worker}</Link></td>
                                <td className="px-2 py-1 text-sm text-gray-900">{format(new Date(searchResult.date_of_birth), 'yyyy-MMM-dd')}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <TopAndMiddleNavigation page='composeTwid' />

            <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-20">
              {/* Left side of navigation stub */}
              <div>
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">NAVIGATION STUB</h3>
                  </div>
                </div>
                <div className="mt-5 shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">PROBLEMS LINKED TO THIS JOB</h3>
                  </div>
                </div>
                <div className="mt-5 shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">JOBS LINKED TO THIS WORKER</h3>
                  </div>
                </div>
              </div>
              {/* Right side of navigation stub */}
              <div>
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">ADD WORKER</h3>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="items-center sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                        {tableColumns.map((tableColumn, index) => {
                          if (tableColumn['privilege'] === 'Yes') {
                            if (tableColumn['input_type'] === 'checkbox') {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                    <input
                                      type="checkbox"
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      onChange={(event) => handleChange(tableColumn, event)}
                                      className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                    />
                                    {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            } else if (tableColumn['input_type'] === 'date') {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                    <DatePicker 
                                      renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                      }) => (
                                        <div
                                          style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                            <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </button>
                                          <select
                                            className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                            value={getYear(date)}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                          >
                                            {years.map((option) => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </select>

                                          <select
                                            className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                            value={months[getMonth(date)]}
                                            onChange={({ target: { value } }) =>
                                              changeMonth(months.indexOf(value))
                                            }
                                          >
                                            {months.map((option) => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </select>

                                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                            <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </button>
                                        </div>
                                      )}
                                      selected={tableColumn['value']} 
                                      onChange={(event) => handleCalendarChange(tableColumn, event)} 
                                      dateFormat='yyyy-MMM-dd' 
                                      id={tableColumn['column_name']}
                                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                    {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            } else if (tableColumn['dropdowns'].length > 0) {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <SelectOption tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={tableColumn['value']}/>
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                    <input
                                      type={tableColumn['input_type']}
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      value={tableColumn['value']}
                                      onChange={(event) => handleChange(tableColumn, event)}
                                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                    />
                                    {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            }
                          } else if (tableColumn['privilege'] === 'View') {
                            if (tableColumn['label_name'] === 'Created by') {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                    <input
                                      type='text'
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                      placeholder={cookies['username']}
                                      disabled
                                    />
                                    {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <dt className="mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                    <input
                                      type={tableColumn['input_type']}
                                      name={tableColumn['column_name']}
                                      id={tableColumn['column_name']}
                                      className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                      placeholder={tableColumn['value']}
                                      disabled
                                    />
                                    {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                  </dd>
                                </>
                              )
                            }
                          }
                        })}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  )
}