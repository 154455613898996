import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { populateNavigationStub } from '../scripts/helpers'
import { useNavigate, useLocation } from 'react-router-dom'


import Badge from '../components/badge'
import { backend_base_url } from '../utils/helper'

export default function NavigationStub2(props) {
  const {navigationStubObj} = props;
  const { pathname } = useLocation();

  const [navigationStub, setNavigationStub] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (Object.keys(navigationStubObj).length > 0) {
      populateNavigationStub(navigationStubObj.workerId, navigationStubObj.jobId, navigationStubObj.problemId).then(result => {
        if (navigationStubObj.jobId !== undefined) {
          result.data['problems'] = result.data['problems'].filter((problem) => (problem.worker_id === parseInt(navigationStubObj.workerId) && problem.job_id === parseInt(navigationStubObj.jobId)));
        }

        setNavigationStub(result.data);
      });
    }
  }, [navigationStubObj]);
  
  if (Object.keys(navigationStub).length > 0) {
    return (
      <>
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">NAVIGATION STUB</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                <div className="flex py-1 grid grid-cols-3 gap-4 px-2 sm:grid sm:grid-cols-4 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {navigationStub['facepicAttachments'].length > 0 ? (
                      <img
                        className="inline-block h-auto w-auto"
                        src={`${backend_base_url()}/api/facepic-attachments/downloadFile?filename=${navigationStub['facepicAttachments'][0]['file_path'].substring(navigationStub['facepicAttachments'][0]['file_path'].lastIndexOf('/') + 1)}`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="inline-block h-auto w-auto"
                        src='/user.png'
                        alt=""
                      />
                    )}
                  </dt>
                  <dd className="mt-1 text-sm font-medium text-gray-500 sm:mt-0 sm:col-span-1">Worker flag</dd>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">{navigationStub['worker_flag']}</dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Twid</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">{navigationStub['twid']}</dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-bold text-gray-500">Name of worker</dt>
                  <dd className="text-sm font-bold text-gray-900 sm:col-span-2">{navigationStub['name_of_worker']}</dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Singapore phone number</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['sgPhoneNumbers'].length > 0 ? (
                      navigationStub['sgPhoneNumbers'][0]['sg_phone_number']
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Digital address</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['digitalContacts'].length > 0 ? (
                      navigationStub['digitalContacts'][0]['digital_address']
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Digital contact type</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['digitalContacts'].length > 0 ? (
                      navigationStub['digitalContacts'][0]['digital_contact_type']
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Ticket number</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['ticketNumbers'].length > 0 ? (
                      navigationStub['ticketNumbers'][0]['ticket_number']
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-bold text-gray-500">Employer</dt>
                  <dd className="text-sm font-bold text-gray-900 sm:col-span-2">
                    {navigationStub['jobs'].length > 0 && navigationStubObj.level !== 'workers' ? (
                      navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0] !== undefined ? navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0]['employer_name'] : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Workpass type</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['jobs'].length > 0 && navigationStubObj.level !== 'workers' ? (
                      navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0] !== undefined ? navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0]['workpass_type'] : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">When job started</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['jobs'].length > 0 && navigationStubObj.level !== 'workers' ? (
                      navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0] !== undefined ? navigationStub['jobs'].filter(job => job.id === parseInt(navigationStubObj.jobId))[0]['when_job_started'] : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Problem registration date</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['problems'].length > 0 && navigationStubObj.level === 'problems' ? (
                      navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0] !== undefined ? format(new Date(navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0]['date_problem_registered']), 'yyyy-MMM-dd') : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-bold text-gray-500">Chief problem</dt>
                  <dd className="text-sm font-bold text-gray-900 sm:col-span-2">
                    {navigationStub['problems'].length > 0 && navigationStubObj.level === 'problems' ? (
                      navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0] !== undefined ? navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0]['chief_problem'] : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Problem flag</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                  {navigationStub['problems'].length > 0 && navigationStubObj.level === 'problems' ? (
                    navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0] !== undefined ? navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0]['chief_problem_flag'] : (<></>)
                  ) : (
                    ''
                  )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Date of accident</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['injuries'].filter((injury) => (injury.problem_id == navigationStubObj.problemId && injury.job_id == navigationStubObj.jobId && injury.worker_id == navigationStubObj.workerId)).length > 0 && navigationStubObj.level === 'problems' ? (
                      format(new Date(navigationStub['injuries'].filter(injury => injury.problem_id == navigationStubObj.problemId && injury.job_id == navigationStubObj.jobId && injury.worker_id == navigationStubObj.workerId).sort((a,b) => a.id > b.id)[0].date_of_accident), 'yyyy-MMM-dd')
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Problem status</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2 whitespace-pre-wrap">
                    {navigationStub['problems'].length > 0 && navigationStubObj.level === 'problems' ? (
                      navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0] !== undefined ? navigationStub['problems'].filter(problem => problem.id === parseInt(navigationStubObj.problemId))[0]['problem_status'] : (<></>)
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {(navigationStubObj.level === 'problems' || navigationStubObj.level === 'jobs') ? (
            <div className="mt-5 shadow overflow-hidden">
              <div className="bg-gray-100 px-2 py-1">
                <h3 className="text-lg leading-6 font-bold text-gray-900">PROBLEMS LINKED TO THIS JOB</h3>
              </div>
              <div className="border-t border-gray-200 p-0">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Problem sequence
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Chief problem
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Problem status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {(navigationStubObj['level'] === 'problems' || navigationStubObj['level'] === 'jobs') && navigationStub['problems'].length > 0 ? (
                              navigationStub['problems'].map((problem, index) => {
                                return (
                                  <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigate(`/workers/${navigationStubObj.workerId}/jobs/${navigationStubObj.jobId}/problems/${problem.id}`)}>
                                    <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{problem.problem_sequence}</td>
                                    <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{problem.chief_problem}</td>
                                    <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{problem.problem_status}</td>
                                  </tr>
                                )
                              })
                            ) : (
                              ''
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="mt-5 shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">JOBS LINKED TO THIS WORKER</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-top inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Job sequence
                            </th>
                            <th
                              scope="col"
                              className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Employer
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {navigationStub['jobs'].length > 0 ? (
                            navigationStub['jobs'].map((job, index) => {
                              return (
                                <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigate(`/workers/${navigationStubObj.workerId}/jobs/${job.id}`)}>
                                  <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{job.job_sequence}</td>
                                  <td className="px-2 py-1 align-top text-sm text-gray-900 whitespace-pre-wrap">{job.employer_name}</td>
                                </tr>
                              )
                            })
                          ) : (
                            ''
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}
