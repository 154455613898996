import { Fragment, useState, useEffect, Component, useContext } from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { Link, useParams } from "react-router-dom"
import axios from 'axios'


import Header from '../../components/header';
import SubHeader from '../../components/subheader';
import AlertNotification from '../../components/alertNotification';
import EditPrivilegeTable from '../../components/privileges/editPrivilegeTable';
import { AuthContext } from '../../AuthProvider';
import { addAuditLog, checkDeactivated, checkSystemDown, listenToNotifications, checkCookieToken } from '../../scripts/helpers';
import { useCookies } from 'react-cookie';
import { backend_base_url } from '../../utils/helper';

export default function PrivilegesEdit() {
  const [tableAndColumnNames, setTableAndColumnNames] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [actions, setActions] = useState([]);
  const [modifiedPrivileges, setModifiedPrivileges] = useState({});
  const [outcome, setOutcome] = useState({});
  const params = useParams();
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    getColumnNames();
    setActions([
      { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', disabled: true, type: 'submit'},
      { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', disabled: true, click: resetPrivileges, type: 'button'},
    ]);
  }, [0]);

  // populate privilege table depending on table slug
  const getColumnNames = () => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/${params.tableSlug}/getPrivileges?sort=id,asc`, {withCredentials: true})
    .then(result => {
      result.data.forEach((rowObject, index) => {
        const arrangedPrivileges = [];

        result.data[index]['privileges'].forEach((privilegeObject, index2) => {
          if (privilegeObject.role_id === 1) {
              if (privilegeObject.mode === 'List') {
                arrangedPrivileges[0] = privilegeObject;
              } else if (privilegeObject.mode === 'Add') {
                arrangedPrivileges[1] = privilegeObject;
              } else if (privilegeObject.mode === 'View') {
                arrangedPrivileges[2] = privilegeObject;
              } else if (privilegeObject.mode === 'Edit') {
                arrangedPrivileges[3] = privilegeObject;
              } else if (privilegeObject.mode === 'Delete') {
                arrangedPrivileges[4] = privilegeObject;
              }
          } else if (privilegeObject.role_id === 2) {
            if (privilegeObject.mode === 'List') {
              arrangedPrivileges[5] = privilegeObject;
            } else if (privilegeObject.mode === 'Add') {
              arrangedPrivileges[6] = privilegeObject;
            } else if (privilegeObject.mode === 'View') {
              arrangedPrivileges[7] = privilegeObject;
            } else if (privilegeObject.mode === 'Edit') {
              arrangedPrivileges[8] = privilegeObject;
            } else if (privilegeObject.mode === 'Delete') {
              arrangedPrivileges[9] = privilegeObject;
            }
          } else if (privilegeObject.role_id === 3) {
            if (privilegeObject.mode === 'List') {
              arrangedPrivileges[10] = privilegeObject;
            } else if (privilegeObject.mode === 'Add') {
              arrangedPrivileges[11] = privilegeObject;
            } else if (privilegeObject.mode === 'View') {
              arrangedPrivileges[12] = privilegeObject;
            } else if (privilegeObject.mode === 'Edit') {
              arrangedPrivileges[13] = privilegeObject;
            } else if (privilegeObject.mode === 'Delete') {
              arrangedPrivileges[14] = privilegeObject;
            }
          } else if (privilegeObject.role_id === 4) {
            if (privilegeObject.mode === 'List') {
              arrangedPrivileges[15] = privilegeObject;
            } else if (privilegeObject.mode === 'Add') {
              arrangedPrivileges[16] = privilegeObject;
            } else if (privilegeObject.mode === 'View') {
              arrangedPrivileges[17] = privilegeObject;
            } else if (privilegeObject.mode === 'Edit') {
              arrangedPrivileges[18] = privilegeObject;
            } else if (privilegeObject.mode === 'Delete') {
              arrangedPrivileges[19] = privilegeObject;
            }
          } else if (privilegeObject.role_id === 5) {
            if (privilegeObject.mode === 'List') {
              arrangedPrivileges[20] = privilegeObject;
            } else if (privilegeObject.mode === 'Add') {
              arrangedPrivileges[21] = privilegeObject;
            } else if (privilegeObject.mode === 'View') {
              arrangedPrivileges[22] = privilegeObject;
            } else if (privilegeObject.mode === 'Edit') {
              arrangedPrivileges[23] = privilegeObject;
            } else if (privilegeObject.mode === 'Delete') {
              arrangedPrivileges[24] = privilegeObject;
            }
          }
        });

        result.data[index]['privileges'] = arrangedPrivileges;
      })
      
      setTableAndColumnNames(result.data);
    });
  };

  // modifyPrivilege is called whenever user clicks any of the privileges
  // to toggle between yes/no/View
  const modifyPrivilege = (privilegeObject, e) => {
    // toggle between No, Yes and View
    if (privilegeObject.mode === 'List' || privilegeObject.mode === 'Delete' || privilegeObject.mode === 'View') {
      switch (privilegeObject.privilege) {
        case 'No':
          privilegeObject.privilege = 'Yes';
          break;
        case 'Yes':
          privilegeObject.privilege = 'No';
          break;
      }
    } else {
      switch (privilegeObject.privilege) {
        case 'No':
          privilegeObject.privilege = 'Yes';
          break;
        case 'Yes':
          privilegeObject.privilege = 'View';
          break;
        case 'View':
          privilegeObject.privilege = 'No';
          break;
      }
    }

    const modifiedPrivilegesToUpdate = {...modifiedPrivileges};
    modifiedPrivilegesToUpdate[privilegeObject.id] = privilegeObject.privilege;
    setModifiedPrivileges(modifiedPrivilegesToUpdate);

    setActions([
      { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', disabled: false, type: 'submit'},
      { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', disabled: false, click: resetPrivileges, type: 'button'},
    ]);
  };

  // updatePrivilege is called whenever user is done amending privileges
  // and want to finalise it into the database
  const updatePrivileges = () => {
    axios.put(`${backend_base_url()}/api/privileges/bulkUpdate`, {
        modifiedPrivileges: JSON.stringify(modifiedPrivileges),
      }, {
        withCredentials: true,
        headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
      })
      .then(result => {
        addAuditLog({
          table_name: 'Privilege',
          action_type: 'Update',
          action_description: `Updated privilege: ${tableAndColumnNames[0]['table_name']}.`,
          user_id: cookies['userId']
        });
        
        setModifiedPrivileges({});

        setActions([
          { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', disabled: true, type: 'submit'},
          { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', disabled: true, click: resetPrivileges, type: 'button'},
        ]);
      });
  };

  // resetPrivileges is called whenever user wants to undo his/her changes
  const resetPrivileges = () => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/${params.tableSlug}/getPrivileges?sort=id,asc`, {withCredentials: true})
      .then(result => {
        result.data.forEach((rowObject, index) => {
          const arrangedPrivileges = [];
          
          result.data[index]['privileges'].forEach((privilegeObject, index2) => {
            if (privilegeObject.role_id === 1) {
                if (privilegeObject.mode === 'List') {
                  arrangedPrivileges[0] = privilegeObject;
                } else if (privilegeObject.mode === 'Add') {
                  arrangedPrivileges[1] = privilegeObject;
                } else if (privilegeObject.mode === 'View') {
                  arrangedPrivileges[2] = privilegeObject;
                } else if (privilegeObject.mode === 'Edit') {
                  arrangedPrivileges[3] = privilegeObject;
                } else if (privilegeObject.mode === 'Delete') {
                  arrangedPrivileges[4] = privilegeObject;
                }
              } else if (privilegeObject.role_id === 2) {
                if (privilegeObject.mode === 'List') {
                  arrangedPrivileges[5] = privilegeObject;
                } else if (privilegeObject.mode === 'Add') {
                  arrangedPrivileges[6] = privilegeObject;
                } else if (privilegeObject.mode === 'View') {
                  arrangedPrivileges[7] = privilegeObject;
                } else if (privilegeObject.mode === 'Edit') {
                  arrangedPrivileges[8] = privilegeObject;
                } else if (privilegeObject.mode === 'Delete') {
                  arrangedPrivileges[9] = privilegeObject;
                }
              } else if (privilegeObject.role_id === 3) {
                if (privilegeObject.mode === 'List') {
                  arrangedPrivileges[10] = privilegeObject;
                } else if (privilegeObject.mode === 'Add') {
                  arrangedPrivileges[11] = privilegeObject;
                } else if (privilegeObject.mode === 'View') {
                  arrangedPrivileges[12] = privilegeObject;
                } else if (privilegeObject.mode === 'Edit') {
                  arrangedPrivileges[13] = privilegeObject;
                } else if (privilegeObject.mode === 'Delete') {
                  arrangedPrivileges[14] = privilegeObject;
                }
              } else if (privilegeObject.role_id === 4) {
                if (privilegeObject.mode === 'List') {
                  arrangedPrivileges[15] = privilegeObject;
                } else if (privilegeObject.mode === 'Add') {
                  arrangedPrivileges[16] = privilegeObject;
                } else if (privilegeObject.mode === 'View') {
                  arrangedPrivileges[17] = privilegeObject;
                } else if (privilegeObject.mode === 'Edit') {
                  arrangedPrivileges[18] = privilegeObject;
                } else if (privilegeObject.mode === 'Delete') {
                  arrangedPrivileges[19] = privilegeObject;
                }
              } else if (privilegeObject.role_id === 5) {
                if (privilegeObject.mode === 'List') {
                  arrangedPrivileges[20] = privilegeObject;
                } else if (privilegeObject.mode === 'Add') {
                  arrangedPrivileges[21] = privilegeObject;
                } else if (privilegeObject.mode === 'View') {
                  arrangedPrivileges[22] = privilegeObject;
                } else if (privilegeObject.mode === 'Edit') {
                  arrangedPrivileges[23] = privilegeObject;
                } else if (privilegeObject.mode === 'Delete') {
                  arrangedPrivileges[24] = privilegeObject;
                }
              }
          });

          result.data[index]['privileges'] = arrangedPrivileges;
        })
        
        setTableAndColumnNames(result.data);
        
        setModifiedPrivileges({});

        setActions([
          { name: 'Save', href: '#', class: 'bg-blue-500 hover:bg-blue-600', position: 'left', disabled: true, type: 'submit'},
          { name: 'Reset', href: '#', class: 'bg-red-500 hover:bg-red-600', position: 'left', disabled: true, click: resetPrivileges, type: 'button'},
        ]);
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        updatePrivileges();
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - privileges/edit.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - privileges/edit.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={handleFormSubmit}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <EditPrivilegeTable tableAndColumnNames={tableAndColumnNames} modifyPrivilege={modifyPrivilege} />
      </form>
    </>
  )
}