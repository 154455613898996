import { useState, useEffect } from 'react'
import { populateNavigationStub } from '../scripts/helpers'

import Badge from '../components/badge'

export default function MiniNavigationStub(props) {
  const {workerId, jobId, problemId, level, rightStubObj} = props;

  const [navigationStub, setNavigationStub] = useState({});

  useEffect(() => {
    populateNavigationStub(workerId).then(result => {
      result.data['problems'] = result.data['problems'].filter((problem) => (problem.worker_id === parseInt(workerId) && problem.job_id === parseInt(jobId)));
      setNavigationStub(result.data);
    });
  }, [rightStubObj]);
  
  if (Object.keys(navigationStub).length > 0) {
    return (
      <>
        <div>
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">MINI NAVIGATION STUB</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Twid</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2">{navigationStub['twid']}</dd>
                </div>
                <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Name of worker</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2">{navigationStub['name_of_worker']}</dd>
                </div>
                <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Employer</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2">
                    {rightStubObj.page !== 'Add' && rightStubObj !== undefined && navigationStub['jobs'].length > 0 && rightStubObj.workerSubsidiarySlugOrWorkerAction === 'jobs' && jobId !== undefined ? 
                    (
                      (navigationStub['jobs'].filter(job => job.id === parseInt(jobId))[0] !== undefined ? (navigationStub['jobs'].filter(job => job.id === parseInt(jobId))[0]['employer_name']) : (<></>))
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
                <div className="flex items-center py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Chief problem</dt>
                  <dd className="text-sm text-gray-900 sm:col-span-2">
                    {rightStubObj.page !== 'Add' && rightStubObj !== undefined && navigationStub['problems'].length > 0 && rightStubObj.jobSubsidiarySlugOrWorkerSubsidiaryAction === 'problems' && problemId !== undefined ? 
                    (
                      (navigationStub['problems'].filter(problem => problem.id === parseInt(problemId))[0] !== undefined ? (navigationStub['problems'].filter(problem => problem.id === parseInt(problemId))[0]['chief_problem']) : (<></>))
                    ) : (
                      ''
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}