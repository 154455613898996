import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, deleteObjects, getObjects, listenToNotifications } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import Modal from '../../components/modal'
import Loading from '../../components/loading'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'

export default function ReferencesIndex() {
  const tableSlug = 'references';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [objects, setObjects] = useState([]);
  const [objectIdsSelected, setObjectIdsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  
  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(result => {
      const resultPrivileges = result;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['List']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);

      // get objects to populate
      getObjects(tableSlug).then(result => {
        setObjects(result);
        setObjectIdsSelected(new Array(result.length).fill(false));
      }).catch(err => {
        navigate('/');
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'List');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;

    if (buttonClicked === 'Edit') {
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to edit.',
          type: 'Danger'
        });

         
      } else if (objectsSelected.length >= 2) {
        setOutcome({
          message: 'You can select only 1 record to edit.',
          type: 'Danger'
        });

         
      } else {
        navigate(`/${tableSlug}/${objectsSelected[0]['id']}/edit`);
      }
    } else if (buttonClicked === 'Delete') {
      // alert users if they have not selected anything
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to delete.',
          type: 'Danger'
        });

         
      } else {
        setShowDeleteConfirmation(true);
      }
    }
  };

  const handleOnChangeCheckbox = (position) => {
    const objectsSelectedToUpdate = [];
    const objectIdsSelectedToUpdate = objectIdsSelected.map(
      (checked, index) => {
        let result = false;

        if (index === position) {
          result = !checked;
        } else {
          result = checked;
        }

        if (result === true) {
          objectsSelectedToUpdate.push(objects[index]);
        }

        return result;
      }
    );

    setObjectIdsSelected(objectIdsSelectedToUpdate);
    setObjectsSelected(objectsSelectedToUpdate);
  };

  const navigateToObject = (event, objectId) => {
    if (event.target.checked === undefined) {
      navigate(`/${tableSlug}/${objectId}`)
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObjects(tableSlug, objectsSelected)
    .then(result => {
      disableShowDeleteConfirmation();
      getObjects(tableSlug)
      .then(result => {
        setObjects(result);
        setObjectIdsSelected(new Array(result.length).fill(false));
        setObjectsSelected([]);
      })
    })
    .catch(err => {
      setOutcome({
          message: err,
          type: 'Danger'
        });

        
    });
  };

  const showTable = () => {
    if (objectIdsSelected.length === 0) {
      return <div style={{height: `calc(100vh - 126px)`}}><Loading /></div>;
    } else {
      return (
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5">
            <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">
                REFERENCES
              </h3>
            </div>
            <div className="mt-5 flex flex-col">
              <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-top inline-block min-w-full">
                  <div className="shadow overflow-hidden border-gray-300">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Select
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Id
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Store as
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Add as
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            View as
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Edit as
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Multiple
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {objects.map((object, index) => (
                          <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToObject(event, object.id)}>
                            <td className="border border-gray-300 px-2 pt-0 text-center text-sm text-gray-900">
                              <input
                                id={`select-user-${index}`}
                                aria-describedby="select-user"
                                type="checkbox"
                                value={object}
                                checked={objectIdsSelected[index]}
                                onChange={() => handleOnChangeCheckbox(index)}
                                className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300 mb-0.5"
                              />
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['id']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['store']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['add']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['view']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['edit']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['multiple'] ? 'true' : 'false'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        {showTable()}
      </form>
    </>
  )
}