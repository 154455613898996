import { useState, useEffect, Fragment } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid'
import { getObject, getValuesByColumnName } from '../scripts/helpers'
import { el } from 'date-fns/locale';

export default function ComboBox2(props) {
  const comboBoxObj = {...props};
  
  const [comboBoxValues, setComboBoxValues] = useState([]);
  const [referencedColumnName, setReferencedColumnName] = useState([]);
  const [referencedTableSlug, setReferencedTableSlug] = useState([]);
  const [storeAsColumnName, setStoreAsColumnName] = useState();
  const [query, setQuery] = useState('');
  const [referencedDataTableSlug, setReferencedDataTableSlug] = useState('');
  const [referencedDataColumnName, setReferencedDataColumnName] = useState('');

  useEffect(() => {
    let mode;
    if (props.mode === 'Assign') {
      mode = 'add';
    } else {
      mode = props.mode.toLowerCase();
    }

    getObject('tableAndColumns', props['reference'][mode]).then(referenced => {
      // get all values
      setReferencedColumnName(referenced.data.column_name);
      setReferencedTableSlug(referenced.data.table_slug);

      getObject('tableAndColumns', props['reference']['store']).then(storeAs => {
        // get all values
        setStoreAsColumnName(storeAs.data.column_name);
        setReferencedDataTableSlug(referenced.data.table_slug);
        setReferencedDataColumnName(referenced.data.column_name);
      });
    });
  }, []);

  useEffect(() => {
    if (query.length === 3) {
      getValuesByColumnName(referencedDataTableSlug, referencedDataColumnName, query).then(values => {
        setComboBoxValues(values.data);
      });
    } 
  }, [query]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const filteredDropdown = query.length < 3
      ? []
      : comboBoxValues.filter((comboBoxValue) => {
          return comboBoxValue[Object.keys(comboBoxValue)[0]].toLowerCase().includes(query.toLowerCase())
        });

  if (Object.keys(comboBoxObj).length > 0) {
    return (
    <Fragment>
      <Combobox as="div" onChange={(event) => {
        comboBoxObj.handleComboBoxChange(comboBoxObj.rightStubObj, comboBoxObj.tableColumn, event, referencedColumnName, referencedTableSlug, storeAsColumnName);
      }}>
        <div className="relative">
          <Combobox.Input
            className={`w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm ${comboBoxObj.tableColumn['column_name']}`}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            id={comboBoxObj.tableColumn['column_name']}
            name={comboBoxObj.tableColumn['column_name']}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
            <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
          
          {filteredDropdown.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredDropdown.map((comboBoxValue, index) => (
                <Combobox.Option
                  key={index}
                  value={comboBoxValue}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-0 pl-3 pr-9',
                      active ? 'bg-sky-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <Fragment>
                      <div className="flex">
                        <span
                          className={classNames(
                            'ml-2 truncate text-gray-900',
                            active ? 'text-sky-200' : 'text-gray-900'
                          )}
                        >
                          {comboBoxValue[Object.keys(comboBoxValue)[0]]}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-sky-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </Fragment>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      <div>
        {comboBoxObj.rightStubObj !== undefined && (comboBoxObj.tableColumn.value !== '' && comboBoxObj.rightStubObj.table !== 'Ordinary Case Discussion' && comboBoxObj.rightStubObj.table !== 'Hearings Progress' && comboBoxObj.rightStubObj.table !== 'Sensitive Discussion') ? 
        (
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 mr-2 mt-2 border border-transparent text-sm font-medium shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            onClick={(event) => comboBoxObj.deleteButton(comboBoxObj.rightStubObj, comboBoxObj.tableColumn, event)}
          >
            <XIcon className="h-4 w-4 text-white" aria-hidden="true" /> <span className="ml-2 mr-2">{comboBoxObj.tableColumn.value}</span>
          </button>
        ) : 
        (
          <Fragment></Fragment>
        )}
      </div>
    </Fragment>
    )
  } else {
    return (
      <Fragment>
      </Fragment>
    )
  }
}