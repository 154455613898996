import { Fragment, useState, useEffect, useContext } from 'react'
import Header from '../../components/header'
import DirectMessageConversationsShow from '../directMessageConversations/show'
import DirectMessagesShow from '../directMessages/show'
import { useCookies } from 'react-cookie'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { CheckIcon, PencilIcon, XIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Combobox} from '@headlessui/react'
import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  SearchCircleIcon,
  MenuIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { fourtyTruncate, fiftyTruncate, searchUserByUsername, findAllDirectMessageConversationsByUserToken, deepCopy } from '../../scripts/helpers'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import { AuthContext } from '../../AuthProvider'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DirectMessagesIndex(props) {
  const context = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [comboBoxValues, setComboBoxValues] = useState([]);
  const [directMessageConversations, setDirectMessageConversations] = useState([]);
  const [filteredDropdown, setFilteredDropdown] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [directMessageConversationId, setDirectMessageConversationId] = useState(null);
  const [query, setQuery] = useState('');
  const theme = 'snow';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ],
  };
  const placeholder = 'Write your message...';
  const formats = ['bold', 'italic', 'underline', 'strike', 'blockquote'];
  const { quill, quillRef, Quill } = useQuill({ theme, modules, formats, placeholder });
  const params = useParams();

  const {username} = params;
  const {socket, show, setShow, notifications, setNotifications, update} = context;

  const toggleConversations = (event) => {
    if (document.getElementById('conversations').className.indexOf('hidden') === -1) {
      document.getElementById('conversations').className += ' hidden';
    } else {
      document.getElementById('conversations').className = 'lg:block overflow-auto flex flex-shrink-0';
    }
  };

  const toggleAttachments = (event) => {
    if (document.getElementById('attachments').className.indexOf('hidden') === -1) {
      document.getElementById('attachments').className += ' hidden';
    } else {
      document.getElementById('attachments').className = 'lg:block overflow-auto flex flex-shrink-0';
    }
  };

  const discard = () => {
    quill.setText('');
  };

  const handleComboBoxChange = () => {
  };

  const setCurrentDirectMessageConversationId = (id) => {
    // setDirectMessageConversationId(id);
  };

  const refreshConversationList = () => {
    findAllDirectMessageConversationsByUserToken(cookies['token']).then(directMessageConversationsResult => {
      directMessageConversationsResult = directMessageConversationsResult.data;
      setDirectMessageConversations(directMessageConversationsResult);
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on('receive-dm', (content) => {
        if (content.from !== username) {
          update(content);
          setShow(true);
        } else {
          // refreshConversationList();
        }
      });
    }
  }, [socket]);

  // useEffect(() => {
  //   return () => {
  //     socket.off('dm-saved');
  //     socket.off('receive-dm');
  //   };
  // }, []);

  useEffect(() => {
    // find all conversations between current user and all other users
    refreshConversationList();
    if (username === cookies['username']) window.location.replace('/direct-messages');
    document.getElementsByTagName("BODY")[0].style.height = '100%';
    return () => {
      document.getElementsByTagName("BODY")[0].style.height = 'auto';
    }
  }, []);

  useEffect(() => {
    if (query.length >= 3) {
      // if query is more than 3, search database of users for username that matches query
      searchUserByUsername(query, cookies['username']).then(result => {
        result = result.data;
        setFilteredDropdown(result);
      });
    } else {
      setFilteredDropdown([]);
    }
  }, [query]);

  useEffect(() => {
    if (username !== undefined) {
      var objDiv = document.getElementById("conversationHistory");
      if (objDiv !== null) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }
  }, [username]);

  return (
    <Fragment>
      <Header />
      <div className="flex" style={{height: "calc(100% - 64px)"}}>
        {/* Static sidebar for desktop */}
        <div className="lg:block overflow-auto flex flex-shrink-0 border-r border-gray-200" id="conversations">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex-1 flex flex-col min-h-0 bg-gray-100">
              <div className="bg-gray-100 px-2 py-1 border-t sticky top-0">
                <h3 className="text-lg leading-6 font-bold text-gray-900">DIRECT MESSAGES ({directMessageConversations.length})</h3>
              </div>
              <Combobox as="div" onChange={(event) => handleComboBoxChange()}>
                <div className="relative mt-0">
                  <Combobox.Input
                    className={`w-full border border-gray-200 bg-white py-2 pl-3 pr-10 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm`}
                    placeholder='Search user'
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
                    <SearchCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Combobox.Button>
                  
                  {filteredDropdown.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredDropdown.map((comboBoxValue, index) => (
                        <Combobox.Option
                          key={index}
                          value={comboBoxValue}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-default select-none py-0 pl-3 pr-9',
                              active ? 'bg-sky-600 text-white' : 'text-gray-900'
                            )
                          }
                        >
                          {({ active, selected }) => (
                            <Fragment>
                              <a href={`/direct-messages/${comboBoxValue[Object.keys(comboBoxValue)[0]]}`}>
                                <div className="flex">
                                  <span
                                    className={classNames(
                                      'ml-2 truncate text-gray-900',
                                      active ? 'text-sky-200' : 'text-gray-900'
                                    )}
                                  >
                                    {comboBoxValue[Object.keys(comboBoxValue)[0]]}
                                  </span>
                                </div>
                              </a>

                              {selected && (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-sky-600'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              )}
                            </Fragment>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </div>
              </Combobox>
              <div className="bg-white flex-1 flex flex-col pb-4 overflow-y-auto">
                <nav className="bg-white flex-1" aria-label="Sidebar">
                  <div className="space-y-0 divide-y divide-gray-300">
                    {directMessageConversations.map((directMessageConversation, index) => {
                      return (
                        <DirectMessageConversationsShow directMessageConversation={directMessageConversation} setCurrentDirectMessageConversationId={setCurrentDirectMessageConversationId} key={index} />
                      )
                    })}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {username !== undefined ? 
          (
            <DirectMessagesShow fromUserId={cookies['userId']} toUsername={username} conversationCount={directMessageConversations.length} refreshConversationList={refreshConversationList} directMessageConversationId={directMessageConversationId} setDirectMessageConversations={setDirectMessageConversations} />
          ) : (<></>)}
      </div>
    </Fragment>
  )
};