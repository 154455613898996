import { useState, useEffect, useContext } from "react";
import { useCookies } from 'react-cookie'
import { useNavigate, useParams } from 'react-router-dom'
import { getPrivilegeForCurrentPage, prepareTableColumnsWithPrivileges, populateActions, listenToNotifications } from '../../scripts/helpers'
import { format } from "date-fns";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SubHeader from '../../components/subheader'
import { getObject } from "../../scripts/helpers";
import { AuthContext } from "../../AuthProvider";

export default function SettingsView() {
  const tableSlug = 'settings';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [settings, setSettings] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(result => {
      const resultPrivileges = result;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['View']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      prepareTableColumnsWithPrivileges(tableSlug, privilegesToUpdate, 'View', 0).then(preparedTableColumns => {
        setTableColumns(preparedTableColumns);
        setLoading(false);
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'View');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML;
    
    if (buttonClicked === 'Edit') {
      navigate(`/${tableSlug}/edit`);
    }
  };
  
  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  }
  return (
    <>
      <Header/>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <div className="mt-5 mb-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2">
          <div className="shadow overflow-hidden">
            <div className="bg-gray-100 px-2 py-1 flex justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">VIEW SETTINGS (NOT APPLICABLE TO ADMIN)</h3>
            </div>
            <div className="border-t border-gray-200 p-0">
              <dl className="divide-y divide-gray-200">
                {tableColumns.map((tableColumn, index) => {
                  if (tableColumn.column_name === "system_down_end_datetime" || tableColumn.column_name === "system_down_start_datetime") {
                    return (
                      <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                        <dt className="text-sm font-medium text-gray-500">{tableColumn.label_name}</dt>
                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                        {tableColumn.value && format(new Date(tableColumn.value), 'yyyy-MMM-dd HH:mm')}
                        </dd>
                      </div>
                    )  
                  }
                  if (tableColumn.column_name === "account_deactivation_duration" || tableColumn.column_name === "problem_active_to_dormant_duration") {
                    return (
                      <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                      <dt className="text-sm font-medium text-gray-500">{tableColumn.label_name}</dt>
                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                        {tableColumn.value && `${tableColumn.value} days`}
                      </dd>
                    </div>
                    )  
                  }
                  return (
                    <div className="flex items-top py-1 grid grid-cols-2 gap-4 px-2 sm:grid sm:grid-cols-3 sm:gap-4" key={index}>
                      <dt className="text-sm font-medium text-gray-500">{tableColumn.label_name}</dt>
                      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                        {tableColumn.value && `${tableColumn.value} minutes`}
                      </dd>
                    </div>
                  )
                })}
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}