import { Fragment, useState, useContext, useEffect } from 'react'
import { Disclosure, Menu, Transition, Dialog } from '@headlessui/react'
import { MenuIcon, XIcon, ZoomInIcon } from '@heroicons/react/outline'
import { HomeIcon } from '@heroicons/react/solid'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../AuthProvider";
import { useCookies, set } from 'react-cookie';
import axios from 'axios';
import Badge from './badge'
import {addAuditLog, checkCookieToken, checkDeactivated, checkSystemDown, countUnreadDirectMessageConversation, detectBrowser, getIP, zeroUnreadDirectMessageConversationWithUsername, zeroUnreadGroupChatConversationWithUsername} from '../scripts/helpers';

const navigation = [
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionUsername, sessionRole, token, socket } = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [menu, setMenu] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadDmCount, setUnreadDmCount] = useState(0);
  const [unreadGcCount, setUnreadGcCount] = useState(0);

  useEffect(() => {
    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - header.js - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - header.js - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }

    const locationPath = location.pathname.split('/');
    if (locationPath[1] === 'direct-messages' && locationPath[2]) {
      if (cookies['userId']) {
        zeroUnreadDirectMessageConversationWithUsername(locationPath[2], cookies['userId']).then((result) => {
          countUnreadDirectMessageConversation(cookies['userId']).then(result => {
            
            setUnreadCount(result.data.totalCount);
            setUnreadDmCount(result.data.dmCount);
            setUnreadGcCount(result.data.gcCount);
          }).catch(err => {
            window.location.href=`/${locationPath[1]}`
          })
        }).catch(err => {
          window.location.href=`/${locationPath[1]}`
        })
      }
    } else if (locationPath[1] === 'group-chats' && locationPath[2]) {
      if (cookies['userId']) {
        zeroUnreadGroupChatConversationWithUsername(locationPath[2], cookies['userId']).then((result) => {
          countUnreadDirectMessageConversation(cookies['userId']).then(result => {
            
            setUnreadCount(result.data.totalCount);
            setUnreadDmCount(result.data.dmCount);
            setUnreadGcCount(result.data.gcCount);
          }).catch(err => {
            window.location.href=`/${locationPath[1]}`
          })
        }).catch(err => {
          window.location.href=`/${locationPath[1]}`
        })
      }
    } else {
      if (cookies['userId']) {
        countUnreadDirectMessageConversation(cookies['userId']).then(result => {
          setUnreadCount(result.data.totalCount);
          setUnreadDmCount(result.data.dmCount);
          setUnreadGcCount(result.data.gcCount);
        }).catch(err => {
          window.location.href=`/${locationPath[1]}`
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (socket) {
      socket.on('dm-saved', (content) => {
        if (content) {
          if (location.pathname !== `/direct-messages/${content.from}`) {
            if (cookies['userId']) {
              countUnreadDirectMessageConversation(cookies['userId']).then(result => {
            
                setUnreadCount(result.data.totalCount);
                setUnreadDmCount(result.data.dmCount);
                setUnreadGcCount(result.data.gcCount);
              }).catch(err => {
                console.info(err)
              })
            }
          }
        }
      });

      socket.on('get-unread', (content) => {
        if (cookies['userId']) {
          countUnreadDirectMessageConversation(cookies['userId']).then(result => {
            
            setUnreadCount(result.data.totalCount);
            setUnreadDmCount(result.data.dmCount);
            setUnreadGcCount(result.data.gcCount);
          }).catch(err => {
            console.info(err)
          })
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    const locationPath = location.pathname.split('/');
    
    if (cookies['userId']) {
      countUnreadDirectMessageConversation(cookies['userId']).then(result => {
        setUnreadCount(result.data.totalCount);
        setUnreadDmCount(result.data.dmCount);
        setUnreadGcCount(result.data.gcCount);
      }).catch(err => {
        window.location.href=`/${locationPath[1]}`
      })
    }
  }, []);

  const {title, sticky} = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = () => {
    if (checkCookieToken(document.cookie)) {
      const userId = cookies['userId'];
      addAuditLog({
        table_name: 'Auth',
        action_type: 'Logout',
        action_description: `From ${detectBrowser(navigator)}.`,
        user_id: userId
      }).then(result => {
        removeCookie('token', {path:'/'});
        removeCookie('role', {path:'/'});
        removeCookie('userId', {path:'/'});
        removeCookie('username', {path:'/'});
        removeCookie('announcementsSynced', {path:'/'});
        window.location.replace("/login")
      })
    } else {
      window.location.replace("/login")
    }
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (cookies['role']) {
      case 'Admin':
        setMenu([
          [
          { name: 'Search users', href: '/search-users', current: false },
          { name: <>Direct messages {unreadDmCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/direct-messages', current: false },
          { name: <>Group chats {unreadGcCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/group-chats', current: false },
          { name: 'Templates', href: '/templates', current: false },
          // { name: 'Assign facepic', href: '/facepic-attachments', current: false },
          { name: 'Assign PDPA', href: '/pdpa-attachments', current: false },
          { name: 'My history', href: '/my-history', current: false },
          ],
          [
          { name: 'Preset reports', href: '/preset-reports', current: false },
          { name: 'Preset skins', href: '/preset-skins', current: false },
          { name: 'Problem clans', href: '/problem-clans', current: false },
          ],
          [
          { name: 'Export', href: '/export', current: false },
          { name: 'Reports', href: '/reports', current: false },
          ],
          [
          { name: 'Import', href: '/imports', current: false },
          { name: 'Announcements', href: '/announcements', current: false },
          { name: 'Users', href: '/users', current: false },
          { name: 'Privileges', href: '/privileges', current: false },
          // { name: 'Column visibility', href: '/column-visibilities', current: false },
          { name: 'Default values', href: '/default-values', current: false },
          // { name: 'References', href: '/references', current: false },
          { name: 'Dropdowns', href: '/dropdowns', current: false },
          { name: 'Settings', href: '/settings', current: false },
          { name: 'Audit log', href: '/audit-log', current: false },
          { name: 'All group chats', href: '/all-group-chats', current: false },
          ],
        ]);
        break;
      case 'Manager':
        setMenu([
          [
          { name: 'Search users', href: '/search-users', current: false },
          { name: <>Direct messages {unreadDmCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/direct-messages', current: false },
          { name: <>Group chats {unreadGcCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/group-chats', current: false },
          { name: 'Templates', href: '/templates', current: false },
          // { name: 'Assign facepic', href: '/facepic-attachments', current: false },
          { name: 'Assign PDPA', href: '/pdpa-attachments', current: false },
          { name: 'My history', href: '/my-history', current: false },
          ],
          [
          { name: 'Preset reports', href: '/preset-reports', current: false },
          { name: 'Preset skins', href: '/preset-skins', current: false },
          { name: 'Problem clans', href: '/problem-clans', current: false },
          ],
          [
          { name: 'Export', href: '/export', current: false },
          { name: 'Reports', href: '/reports', current: false },
          ],
        ]);
        break;
      case 'Specialist':
        setMenu([
          [
          { name: 'Search users', href: '/search-users', current: false },
          { name: <>Direct messages {unreadDmCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/direct-messages', current: false },
          { name: <>Group chats {unreadGcCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/group-chats', current: false },
          { name: 'Templates', href: '/templates', current: false },
          // { name: 'Assign facepic', href: '/facepic-attachments', current: false },
          { name: 'Assign PDPA', href: '/pdpa-attachments', current: false },
          { name: 'My history', href: '/my-history', current: false },
          ],
          [
          { name: 'Preset reports', href: '/preset-reports', current: false },
          { name: 'Preset skins', href: '/preset-skins', current: false },
          { name: 'Problem clans', href: '/problem-clans', current: false },
          ]
        ]);
        break;
      case 'Associate':
      case 'Recorder':
        setMenu([
          [
          { name: 'Search users', href: '/search-users', current: false },
          { name: <>Direct messages {unreadDmCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/direct-messages', current: false },
          { name: <>Group chats {unreadGcCount > 0 ? <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">•</span> : ''}</>, href: '/group-chats', current: false },
          { name: 'Templates', href: '/templates', current: false },
          // { name: 'Assign facepic', href: '/facepic-attachments', current: false },
          { name: 'Assign PDPA', href: '/pdpa-attachments', current: false },
          { name: 'My history', href: '/my-history', current: false },
          ],
        ]);
        break;
    }
  }, [cookies, unreadGcCount, unreadDmCount]);

  return (
    <Fragment>
      {/* Side Menu stack */}
      <Transition.Root show={menuOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={toggleMenu}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 left-0 pr-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">Menu Stack</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                            onClick={() => setMenuOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      {menu.map((subMenu, index) => (
                        <nav aria-label="Sidebar" key={index}>
                          {subMenu.map((menu, index2) => (
                            <Link
                              key={index2}
                              to={menu.href}
                              className={classNames(
                                menu.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'flex items-center px-3 py-2 text-sm font-medium'
                              )}
                              aria-current={menu.current ? 'page' : undefined}
                            >
                              <span className="truncate">{menu.name}</span>
                            </Link>
                          ))}
                          <hr />
                        </nav>
                        
                      ))}
                      
                        
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Navbar */}
      <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
        {({ setMenuOpen }) => (
          <Fragment>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center">
                  <Link to='/'><Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <HomeIcon className="block h-6 w-6" aria-hidden="true" />
                  </Disclosure.Button></Link>
                  <div onClick={toggleMenu}>
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {menuOpen ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <>
                          <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                          {unreadCount > 0 && (
                            <span className="inline-flex items-center bg-red-100 px-1 py-0.5 text-xs font-medium text-red-800 mt-[-20px]">
                              {unreadCount}
                            </span>
                          )}
                        </>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        {cookies['username']}
                        <span className="inline-flex items-center ml-3 px-2 py-0.5 text-xs font-medium bg-sky-100 text-sky-800">
                          {cookies['role']}
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/profile"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              My profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/change-password"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Change password
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              to=""
                              className={classNames(active ? 'bg-gray-100 hover:cursor-pointer' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              onClick={logout}
                            >
                              Logout
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Disclosure>
    </Fragment>
  )
}