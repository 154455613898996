import { useState, useEffect, useContext } from "react"
import Header from '../../components/header'
import { Link } from "react-router-dom"
import axios from "axios"
import { Disclosure } from '@headlessui/react'
import { AuthContext } from "../../AuthProvider"
import { listenToNotifications } from "../../scripts/helpers"
import { backend_base_url } from "../../utils/helper"


const navigation = [
  {
    name: 'Worker',
    current: false,
    children: [
      { name: 'Bank Account', href: '#BankAccount' },
      { name: 'Client Calendar', href: '#ClientCalendar' },
      { name: 'Digital Contact', href: '#DigitalContact' },
      { name: 'Facepic Attachment', href: '#FacepicAttachment' },
      { name: 'Family Member', href: '#FamilyMember' },
      { name: 'Home Country Address', href: '#HomeCountryAddress' },
      { name: 'Home Country Phone Number', href: '#HomeCountryPhoneNumber' },
      { name: 'Language', href: '#Language' },
      { name: 'Next Of Kin', href: '#NextOfKin' },
      { name: 'Nickname', href: '#Nickname' },
      { name: 'Passport', href: '#Passport' },
      { name: 'SG Address', href: '#SingaporeAddress' },
      { name: 'SG Friend', href: '#SingaporeFriend' },
      { name: 'SG Phone Number', href: '#SingaporePhoneNumber' },
      { name: 'Ticket Number', href: '#TicketNumber' },
      { name: 'Twid Card', href: '#TwidCard' },
      { name: 'Worker', href: '#Worker' },
    ],
  },
  {
    name: 'Job',
    current: false,
    children: [
      { name: 'Accommodation', href: '#Accommodation' },
      { name: 'Agent', href: '#Agent' },
      { name: 'Benefit', href: '#Benefit' },
      { name: 'Current Pass', href: '#CurrentPass' },
      { name: 'Employer', href: '#Employer' },
      { name: 'Employment Contract', href: '#EmploymentContract' },
      { name: 'IPA Details', href: '#IPADetails' },
      { name: 'Transfer Repat', href: '#TransferRepat' },
      { name: 'Verbal Assurances', href: '#VerbalAssurances' },
      { name: 'Workplace', href: '#Workplace' },
      { name: 'Work History', href: '#WorkHistory' },
    ],
  },
  {
    name: 'Problem',
    current: false,
    children: [
      { name: 'Aggravating Issue', href: '#AggravatingIssue' },
      { name: 'Auxiliary Case Worker', href: '#AuxiliaryCaseWorker' },
      { name: 'Case Discussion', href: '#CaseDiscussion' },
      { name: 'Criminal Case Milestone', href: '#CriminalCaseMilestone' },
      { name: 'Illness', href: '#Illness' },
      { name: 'Injury', href: '#Injury' },
      { name: 'Interpreter', href: '#Interpreter' },
      { name: 'Insurer', href: '#Insurer' },
      { name: 'Hearings Progress', href: '#HearingsProgress' },
      { name: 'Hospital', href: '#Hospital' },
      { name: 'Lawyer', href: '#Lawyer' },
      { name: 'Lead Case Worker', href: '#LeadCaseWorker' },
      { name: 'Legal Medical Funding', href: '#LegalMedicalFunding' },
      { name: 'Manpower Officer', href: '#ManpowerOfficer' },
      { name: 'MC Status', href: '#MCStatus' },
      { name: 'Other Complaint', href: '#OtherComplaint' },
      { name: 'Other Problems', href: '#OtherProblems' },
      { name: 'Police Report', href: '#PoliceReport' },
      { name: 'Salary Claim Status', href: '#SalaryClaimStatus' },
      { name: 'Salary History', href: '#SalaryHistory' },
      { name: 'Sensitive Discussion', href: '#SensitiveDiscussion' },
      { name: 'Warrant To Act', href: '#WarrantToAct' },
      { name: 'Wica Claim', href: '#WicaClaim' },
      { name: 'Wica Status', href: '#WicaStatus' },
    ],
  },
  {
    name: 'Standalone',
    current: false,
    children: [
      { name: 'Overview', href: '#' },
      { name: 'Members', href: '#' },
      { name: 'Calendar', href: '#' },
      { name: 'Settings', href: '#' },
    ],
  },
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
};

export default function ColumnVisibilitiesIndex() {
  const [tableAndColumnVisibilities, setTableAndColumnVisibilities] = useState({});
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    axios.get(`${backend_base_url()}/api/tableAndColumns/getColumnVisibilities`, {withCredentials: true})
      .then(result => {
        const columnVisibilities = result.data;

        const tableAndColumnVisibilitiesToUpdate = {...tableAndColumnVisibilities};

        columnVisibilities.forEach((columnVisibility, index) => {
          if (tableAndColumnVisibilitiesToUpdate[columnVisibility['table_name']] === undefined) {
            tableAndColumnVisibilitiesToUpdate[columnVisibility['table_name']] = {};
            tableAndColumnVisibilitiesToUpdate[columnVisibility['table_name']]['table_name'] = columnVisibility['table_name'];
            tableAndColumnVisibilitiesToUpdate[columnVisibility['table_name']]['table_slug'] = columnVisibility['table_slug'];
            tableAndColumnVisibilitiesToUpdate[columnVisibility['table_name']]['rows'] = [];
          }
        });

        setTableAndColumnVisibilities(tableAndColumnVisibilitiesToUpdate);
      })
  }, []);

  if (Object.keys(tableAndColumnVisibilities).length === 0) {
    return (<></>)
  } else {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="mt-5 pb-5 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              COLUMN VISIBILITIES
            </h3>
          </div>
          <div className="grid grid-cols-6 gap-y-5 gap-x-4">
            <div className="col-span-1">
              <div className="flex flex-col flex-grow border-r border-gray-300 bg-white overflow-y-auto sticky top-16 z-40">
                <div className="mt-1 flex-grow flex flex-col">
                  <nav
                    className="flex-1 px-2 space-y-1 bg-white"
                    aria-label="Sidebar"
                  >
                    {navigation.map((item) =>
                      !item.children ? (
                        <div key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-100 text-gray-900"
                                : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium"
                            )}
                          >
                            {item.name}
                          </a>
                        </div>
                      ) : (
                        <Disclosure
                          as="div"
                          key={item.name}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "bg-gray-100 text-gray-900"
                                    : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                  "group w-full flex items-center pr-2 py-2 text-left text-sm font-medium focus:outline-none focus:ring-2"
                                )}
                              >
                                <svg
                                  className={classNames(
                                    open
                                      ? "text-gray-400 rotate-90"
                                      : "text-gray-300",
                                    "mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                  )}
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M6 6L14 10L6 14V6Z"
                                    fill="currentColor"
                                  />
                                </svg>
                                {item.name}
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-1">
                                {item.children.map((subItem) => (
                                  <Disclosure.Button
                                    key={subItem.name}
                                    as="a"
                                    href={subItem.href}
                                    className="group w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                                  >
                                    {subItem.name}
                                  </Disclosure.Button>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    )}
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-span-5">
              {
                Object.keys(tableAndColumnVisibilities).map((tableAndColumnNameKey, index) => 
                  <div className="border-b border-gray-300 max-w-7xl mx-auto lg:block" key={index}>
                    <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between">
                      <a id={tableAndColumnVisibilities[tableAndColumnNameKey]['table_name'].replace(/\s+/g, '')}>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnNameKey}</h3>
                      </a>
                      <div className="mt-3 flex sm:mt-0 sm:ml-4">
                        <Link
                          to={tableAndColumnVisibilities[tableAndColumnNameKey]['table_slug'] + '/edit'}
                          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                    {/* <div className="mt-5 flex flex-col">
                      <div className="-my-2 overflow-x-auto">
                        <div className="py-2 align-top inline-block min-w-full">
                          <div className="shadow overflow-hidden border-b border-gray-300">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Field name
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container C
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container D
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container F
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container G
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container H
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container J
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container K
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container M
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container N
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Container P
                                  </th>
                                  <th
                                    scope="col"
                                    className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Calendar view
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {tableAndColumnVisibilities[tableAndColumnNameKey]['rows'].map((row, index2) => 
                                  <tr key={index2}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_c']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_d']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_f']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_g']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_h']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_j']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_k']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_m']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_n']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['container_p']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{row['calendar_view']}</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}