/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition, Menu, Listbox } from '@headlessui/react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/solid'
import GroupChatUsernameComboBox from './groupChatUsernameComboBox'
import { updateGroupChatConversation, searchUserByUsername, checkWhetherCurrentUserCanViewGroupChat, addAuditLog, deleteObject} from '../scripts/helpers'
import { useCookies } from 'react-cookie'
import Modal from './modal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ViewUserModal(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [currentUserIsGroupChatAdmin, setCurrentUserIsGroupChatAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(['Admin', 'Member']);
  const [outcome, setOutcome] = useState({});
  const [groupChatName, setGroupChatName] = useState('');
  const [groupChatDescription, setGroupChatDescription] = useState('');
  const [taggedUsernames, setTaggedUsernames] = useState([]);
  const { viewUsers, currentGroupChatSlug, groupChatConversation } = props;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    if (viewUsers !== 0) {
      setOpen(true);
      setTaggedUsernames(groupChatConversation.users.map((value) => {
        if (value.user_username === cookies['username']) {
          if (value.groupChatConversationUser.role === 'Admin') {
            setCurrentUserIsGroupChatAdmin(true);
          } else {
            setCurrentUserIsGroupChatAdmin(false);
          }
        }

        return {
          id: value.id,
          username: value.user_username,
          status: value.user_status,
          role: value.groupChatConversationUser.role
        }
      }));
      setGroupChatName(groupChatConversation.group_chat_name);
      setGroupChatDescription(groupChatConversation.group_chat_description);
    }
  }, [viewUsers]);

  useEffect(() => {
    if (groupChatConversation !== null) {
      setTaggedUsernames(groupChatConversation.users.map((value) => {
        if (value.user_username === cookies['username']) {
          if (value.groupChatConversationUser.role === 'Admin') {
            setCurrentUserIsGroupChatAdmin(true);
          } else {
            setCurrentUserIsGroupChatAdmin(false);
          }
        }
  
        return {
          id: value.id,
          username: value.user_username,
          status: value.user_status,
          role: value.groupChatConversationUser.role
        }
      }));
    }
  }, [groupChatConversation]);

  const closeModal = () => {
    setOpen(false);
  }

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    deleteObject("group-chat-conversations", groupChatConversation.id).then(result => {
      addAuditLog({
        table_name: 'Group Chat',
        action_type: 'Delete',
        action_description: `Deleted group chat with name: ${groupChatConversation.group_chat_name}.`,
        user_id: cookies['userId']
      });
      
      navigate(`/group-chats`);
    }).catch(err => {
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });
    });
  };

  const confirmModal = () => {
    setOpen(false);
  }

  const handleInputChange = (event) => {
  }

  const handleComboBoxChange = (event) => {
    console.log(event)
    if (taggedUsernames.indexOf(event) === -1) {
      const taggedUsernamesToUpdate = [...taggedUsernames];

      searchUserByUsername(event, cookies['username']).then(user => {
        taggedUsernamesToUpdate.push({username: event, status: user.data[0].user_status, role: 'Member', id: user.data[0].id});
        setTaggedUsernames(taggedUsernamesToUpdate);
      });
    }
  }

  const removeUsername = (removedUsername) => {
    const taggedUsernamesToUpdate = [...taggedUsernames];
    setTaggedUsernames(taggedUsernamesToUpdate.filter(user => user.username !== removedUsername));
  }

  const updateGroupChat = () => {
    updateGroupChatConversation(groupChatName, groupChatDescription, taggedUsernames, currentGroupChatSlug).then(resultingGroupChat => {
      // redirect to newly created group chat conversation
      addAuditLog({
        table_name: 'Group Chat',
        action_type: 'Update',
        record_id: resultingGroupChat.data.id,
        action_description: `Updated group chat with id: ${resultingGroupChat.data.id}.`,
        user_id: cookies['userId']
      });
      
      checkWhetherCurrentUserCanViewGroupChat(resultingGroupChat.data.slug, cookies['userId']).then(result => {
        props.refreshGroupChatConversation();
        props.refreshConversationList();
        if (result.data === false) {
          navigate('/group-chats');
        } else {
          navigate(`/group-chats/${resultingGroupChat.data.slug}`);
        }
      })
      
      closeModal();
    }).catch(err => {
      console.log(err);
    });
  };

  const handleChange = (event, user, taggedUsernamesIndex) => {
    const taggedUsernamesToUpdate = [...taggedUsernames];
    taggedUsernamesToUpdate[taggedUsernamesIndex].role = event;
    setTaggedUsernames(taggedUsernamesToUpdate);
  };

  return (
    <>
    <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={[]} single='group chat' plural='group chats' />
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-top sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-top sm:w-full" style={{maxWidth: '50rem'}}>
              <div className="bg-white px-4 pt-5 pb-5 sm:p-6 sm:pb-6">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="shadow">
                      <div className="bg-gray-100 px-2 py-1">
                        <h3 className="text-lg leading-6 font-bold text-gray-900">GROUP CHAT INFO</h3>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group name{currentUserIsGroupChatAdmin ? '*' : ''}</dt>
                            {currentUserIsGroupChatAdmin ?
                              <Fragment>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <input
                                    type='text'
                                    name='group_chat_name'
                                    id='group_chat_name'
                                    value={groupChatName}
                                    onChange={(event) => setGroupChatName(event.target.value)}
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                  />
                                </dd>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group description</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <textarea
                                    rows={5}
                                    name='group_chat_description'
                                    id='group_chat_description'
                                    className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                    onChange={(event) => setGroupChatDescription(event.target.value)}
                                    value={groupChatDescription}
                                  />
                                </dd>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Add a group member</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2 z-50">
                                  <GroupChatUsernameComboBox handleComboBoxChange={handleComboBoxChange} />
                                </dd>
                              </Fragment>
                            : 
                            <Fragment>
                              <dd className="text-sm sm:col-span-2 whitespace-pre-wrap">{groupChatName}</dd>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group description</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                {groupChatDescription}
                              </dd>
                            </Fragment>
                            }
                            {taggedUsernames.length > 0 ? 
                              <Fragment>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">Group members</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <ul role="list" className="mt-1 border border-gray-300 divide-y divide-gray-300">
                                    {taggedUsernames.map((user, index) => {
                                      return (
                                        <li className="pl-3 pr-3 py-3 flex items-center justify-between text-sm" key={index}>
                                          {currentUserIsGroupChatAdmin ? 
                                            <div className="w-full flex justify-between items-center">
                                              <p className="ml-0 mb-0 font-bold">{user.username} {user.status !== 'Active' ? <sup className='text-sm bg-red-500 text-white p-1 font-light' style={{fontSize: '0.65rem'}}>{user.status}</sup> : ''}</p>
                                              
                                              <Listbox value={user.role} onChange={((event) => handleChange(event, user, index))}>
                                                {({ open }) => (
                                                  <>
                                                    <div className="relative w-[150px]">
                                                      <Listbox.Button className={`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm`}>
                                                        <span className="block truncate">{user.role}</span>
                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                      </Listbox.Button>

                                                      <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                      >
                                                        <Listbox.Options className="z-50 absolute mt-1 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                          {values.map((value, index) => (
                                                            <Listbox.Option
                                                              key={index}
                                                              className={({ active }) =>
                                                                classNames(
                                                                  active ? 'text-white bg-sky-600' : 'text-gray-900',
                                                                  'cursor-default select-none relative py-0 pl-3 pr-9'
                                                                )
                                                              }
                                                              value={value}
                                                            >
                                                              {({ selected, active }) => (
                                                                <>
                                                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                    {value}
                                                                  </span>

                                                                  {selected ? (
                                                                    <span
                                                                      className={classNames(
                                                                        active ? 'text-white' : 'text-sky-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                      )}
                                                                    >
                                                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                  ) : null}
                                                                </>
                                                              )}
                                                            </Listbox.Option>
                                                          ))}
                                                        </Listbox.Options>
                                                      </Transition>
                                                    </div>
                                                  </>
                                                )}
                                              </Listbox>
                                            </div>
                                            :
                                            <div className="w-full flex justify-between items-center">
                                              <p className="ml-0 mb-0 font-bold">{user.username} {user.status !== 'Active' ? <sup className='text-sm bg-red-500 text-white p-1 font-light' style={{fontSize: '0.65rem'}}>{user.status}</sup> : ''}</p>
                                              <p className="ml-0 mb-0">{user.role}</p>
                                            </div>
                                          }
                                          {currentUserIsGroupChatAdmin ? 
                                            <div className="ml-4 flex-shrink-0">
                                              <XIcon className="h-5 w-5 text-gray-500 hover:text-sky-500 hover:cursor-pointer" aria-hidden="true" onClick={() => removeUsername(user.username)} />
                                            </div>
                                            : ''
                                          }
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </dd>
                              </Fragment>
                            : ''}
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
              </div>
              {currentUserIsGroupChatAdmin ? 
                <div className="bg-gray-50 px-4 pt-4 pb-4 sm:px-6 sm:pb-4">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
                    onClick={updateGroupChat}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowDeleteConfirmation(true)}
                  >
                    Delete
                  </button>
                </div>
              : ''}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}
