import { CheckCircleIcon, ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

export default function WarningNotification(props) {
  if (props.outcome.type === 'Warning') {
    return (
      <div className="max-w-7xl mx-auto px-2 mt-3 sm:px-6 lg:px-8 block sticky z-40" style={{top: '7.9rem'}}>
        <div className="bg-amber-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-white">{props.outcome.message}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex bg-amber-50 p-1.5 text-amber-500 hover:bg-amber-100 focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2 focus:ring-offset-amber-50"
                  onClick={() => {
                      props.setOutcome({});
                      window.history.replaceState({}, document.title);
                    }
                  }
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}