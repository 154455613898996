import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { getPrivilegeForCurrentPage, populateActions, deleteObjects, getObjects, listenToNotifications, addAuditLog } from '../../scripts/helpers'
import _ from 'lodash'

// components
import Header from '../../components/header'
import Loading from '../../components/loading'
import SubHeader from '../../components/subheader'
import Badge from '../../components/badge'
import Modal from '../../components/modal'
import AlertNotification from '../../components/alertNotification'
import { AuthContext } from '../../AuthProvider'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

export default function UsersIndex() {
  const tableSlug = 'users';
  const [cookies] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [objects, setObjects] = useState([]);
  const [objectIdsSelected, setObjectIdsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);
  const [sort, setSort] = useState({});

  const context = useContext(AuthContext);
  const {socket, show, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();

  const toggleSort = (column) => {
    let sortToUpdate = {...sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    let objectsToUpdate = [...objects];

    if (objectsToUpdate[column] === undefined) {
      objectsToUpdate[column] = false;
    }

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if (sortToUpdate[column] === false) {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['asc']);
    } else {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['desc']);
    }

    sortToUpdate[column] = !sortToUpdate[column];
    setSort(sortToUpdate);
    setObjects(objectsToUpdate);
    setLoading(false);
  };
  
  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(result => {
      const resultPrivileges = result;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['List']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);

      // get objects to populate
      getObjects(tableSlug).then(result => {
        setObjects(result);
        setLoading(false);
        setObjectIdsSelected(new Array(result.length).fill(false));
      }).catch(err => {
        navigate('/');
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'List');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const buttonClicked = event.nativeEvent.submitter.innerHTML.split(" ")[0];

    if (buttonClicked === 'Edit') {
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to edit.',
          type: 'Danger'
        });

         
      } else if (objectsSelected.length >= 2) {
        setOutcome({
          message: 'You can select only 1 record to edit.',
          type: 'Danger'
        });

         
      } else {
        navigate(`/${tableSlug}/${objectsSelected[0]['id']}/edit`);
      }
    } else if (buttonClicked === 'Delete') {
      // alert users if they have not selected anything
      if (objectsSelected.length === 0) {
        setOutcome({
          message: 'Please select at least 1 record to delete.',
          type: 'Danger'
        });
      } else {
        setShowDeleteConfirmation(true);
      }
    }
  };

  const handleOnChangeCheckbox = (position) => {
    const objectsSelectedToUpdate = [];
    const objectIdsSelectedToUpdate = objectIdsSelected.map(
      (checked, index) => {
        let result = false;

        if (index === position) {
          result = !checked;
        } else {
          result = checked;
        }

        if (result === true) {
          objectsSelectedToUpdate.push(objects[index]);
        }

        return result;
      }
    );

    setObjectIdsSelected(objectIdsSelectedToUpdate);
    setObjectsSelected(objectsSelectedToUpdate);
  };

  const navigateToObject = (event, objectId) => {
    if (event.target.checked === undefined) {
      navigate(`/${tableSlug}/${objectId}`)
    }
  };

  const disableShowDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const deleteSelected = () => {
    disableShowDeleteConfirmation();
    deleteObjects(tableSlug, objectsSelected)
    .then(result => {
      objectsSelected.forEach((row) => {
        addAuditLog({
          table_name: 'Users',
          action_type: 'Delete',
          action_description: `Deleted user: ${row.user_fullname}.`,
          user_id: cookies['userId']
        }).then(() => {
          window.location.reload();
        });
      });
      
      getObjects(tableSlug)
      .then(result => {
        setObjects(result);
        setObjectIdsSelected(new Array(result.length).fill(false));
        setObjectsSelected([]);
      })
    })
    .catch(err => {
      console.log(err);
      setOutcome({
        message: err.response.data.message,
        type: 'Danger'
      });
    });
  };

  const showTable = () => {
    return (
      <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
        <div className="mt-5">
          <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              USERS
            </h3>
          </div>
          <div className="mt-5 flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div className="py-2 align-top inline-block min-w-full">
                <div className="shadow overflow-hidden border-gray-300">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Select
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('date_record_created')}>
                            Record created
                            {sort['date_record_created'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['date_record_created'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('datetime_last_login')}>
                            Last logged in
                            {sort['datetime_last_login'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['datetime_last_login'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('id')}>
                            User key
                            {sort['id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_fullname')}>
                            Full name
                            {sort['user_fullname'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_fullname'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_altname')}>
                            Alt name
                            {sort['user_altname'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_altname'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_username')}>
                            Username
                            {sort['user_username'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_username'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_email_address')}>
                            Email address
                            {sort['user_email_address'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_email_address'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_role')}>
                            Role
                            {sort['user_role'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_role'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_status')}>
                            Status
                            {sort['user_status'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            {sort['user_status'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {objects.map((object, index) => (
                        <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={(event) => navigateToObject(event, object.id)}>
                          <td className="border border-gray-300 px-2 py-1 text-center text-sm text-gray-900">
                            <input
                              id={`select-user-${index}`}
                              aria-describedby="select-user"
                              type="checkbox"
                              value={object}
                              checked={objectIdsSelected[index]}
                              onChange={() => handleOnChangeCheckbox(index)}
                              className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300 mb-0.5"
                            />
                          </td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['date_record_created']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['datetime_last_login']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['id']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['user_fullname']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['user_altname']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['user_username']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['user_email_address']}</td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900"><Badge message={object['user_role']}/></td>
                          <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900"><Badge message={object['user_status']}/></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  } else {
    return (
      <>
        <Header />
        <form onSubmit={(event) => handleFormSubmit(event)}>
          <SubHeader actions={actions} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
          <Modal showDeleteConfirmation={showDeleteConfirmation} disableShowDeleteConfirmation={disableShowDeleteConfirmation} confirmDelete={deleteSelected} objectsSelected={objectsSelected.map((object) => object['user_fullname'])} single='user' plural='users' />
          {showTable()}
        </form>
      </>
    )
  }
}