import { Fragment, useState, useEffect, useContext } from 'react'
import Header from '../../components/header'
import { format, isToday, isYesterday } from 'date-fns'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { CheckIcon, PencilIcon, XIcon, DotsVerticalIcon, ChevronDoubleDownIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Combobox} from '@headlessui/react'
import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  SearchCircleIcon,
  MenuIcon,
  SpeakerphoneIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { fourtyTruncate, fiftyTruncate, searchUserByUsername, findGroupChatsByFromIdAndToUsername, searchGroupChatConversationByGroupChatName, findGroupChatAttachmentsByGroupChatConversationId, searchGroupChatAttachmentsByGroupChatSlug, searchGroupChatConversationByGroupChatSlug, findGroupChatsByGroupChatSlug, checkWhetherCurrentUserCanViewGroupChat, deepCopy, checkDeactivated, checkSystemDown, addAuditLog, checkCookieToken } from '../../scripts/helpers'
import { useQuill } from 'react-quilljs'
import 'quill/dist/quill.snow.css'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import ViewUserModal from '../../components/viewUserModal'
import { AuthContext } from '../../AuthProvider'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function GroupChatsShow(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [viewUsers, setViewUsers] = useState(0);
  const [client, setClient] = useState(null);
  const [loadMoreMessagesButton, setLoadMoreMessagesButton] = useState(false);
  const [comboBoxValues, setComboBoxValues] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [groupChatAttachments, setGroupChatAttachments] = useState([]);
  const [groupChatConversation, setGroupChatConversation] = useState(null);
  const [groupChats, setGroupChats] = useState([]);
  const [groupChatUserIds, setGroupChatUserIds] = useState([]);
  const [filteredDropdown, setFilteredDropdown] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [conversedUser, setConversedUser] = useState(null);
  const [groupUsernames, setGroupUsernames] = useState([]);
  const [newMessagesReceived, setNewMessagesReceived] = useState(false);
  const [message, setMessage] = useState('');
  const [query, setQuery] = useState('');
  const theme = 'snow';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, false] }],
    ],
  };
  const placeholder = 'Write your message...';
  const formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'size', 'header'];
  const { quill, quillRef, Quill } = useQuill({ theme, modules, formats, placeholder });

  const groupChatSlug = props.groupChatSlug;

  const {socket, show, setShow, notifications, setNotifications, update} = context;

  useEffect(() => {
    if(socket) {
      socket.on('receive-dm', (content) => {
        if (content.type === 'gc') {
          if (content.currentViewedGroupChat === groupChatSlug) {
            const groupChatsToUpdate = deepCopy(groupChats);
            groupChatsToUpdate.push({
              created_by: content.fromId,
              creator: {user_username: content.from},
              date_last_updated: content.date,
              date_record_created: content.date,
              message: content.message
            })
            setGroupChats(groupChatsToUpdate);
          } else {
            update(content);
            setShow(true);

            // Update to unread
            // makeUnreadDirectMessage(content.fromId, content.to);
            // set unread to true
            socket.emit('get-unread', {
              fromId: content.fromId,
              toGroupChat: content.to,
            }, content.to);
          }
        }
        // props.refreshConversationList();
      });
    }
    
    return (() => {
      if (socket) {
        socket.off('receive-dm');
      }
    });
  }, [groupChats, socket]);

  const toggleConversations = (event) => {
    if (document.getElementById('conversations').className.indexOf('hidden') === -1) {
      document.getElementById('conversations').className += ' hidden';
    } else {
      document.getElementById('conversations').className = 'lg:block overflow-auto flex flex-shrink-0 border-r border-gray-200';
    }
  };

  const toggleAttachments = (event) => {
    if (document.getElementById('attachments').className.indexOf('hidden') === -1) {
      document.getElementById('attachments').className += ' hidden';
    } else {
      document.getElementById('attachments').className = 'lg:block overflow-auto flex flex-shrink-0 border-l border-gray-200';
    }
  };

  const toggleViewUsers = () => {
    setViewUsers(viewUsers + 1);
  };

  const discard = () => {
    quill.setText('');
    setAttachedFiles([]);
    document.getElementById('attachedFiles').value = '';
  };

  const handleComboBoxChange = () => {
    
  };

  function splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [], i = 0, n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  const formatDate =(d) => {
    if (isToday(d)) {
      return `Today at ${format(d, 'HH:mm')}`;
    }

    if (isYesterday(d)) {
      return `Yesterday at ${format(d, 'HH:mm')}`;
    }

    return format(d, 'dd/MM/yyyy HH:mm');
  };

  // const sendFile = (file, rawData, fileIndex, noOfFiles) => {
  //   return new Promise ((resolve, reject) => {
  //     client.binaryType = "arraybuffer";
  //     if (client.readyState === client.OPEN) {
  //       rawData = new Uint8Array(rawData, 0);
        
  //       const rawDataChunks = splitArrayIntoChunksOfLen(rawData, 50000);

  //       rawDataChunks.forEach((rawDataChunk, index) => {
  //         const fileJson = JSON.stringify({
  //           lastModified: file.lastModified,
  //           name: file.name,
  //           size: file.size, 
  //           type: file.type,
  //           chunkLength: rawDataChunks.length,
  //           index: index,
  //           userId: cookies['userId'],
  //           websocketClient: `${groupChatSlug}_${cookies['userId']}`,
  //           fileIndex: fileIndex,
  //           noOfFiles: noOfFiles
  //         });

  //         const enc  = new TextEncoder();
  //         const buf1 = enc.encode('!');
  //         const buf2 = enc.encode(fileJson);
  //         const buf3 = enc.encode("\r\n\r\n");
  //         const buf4 = rawDataChunk;
          
  //         let sendData = new Uint8Array(buf1.byteLength + buf2.byteLength + buf3.byteLength + buf4.byteLength);

  //         sendData.set(new Uint8Array(buf1), 0);
  //         sendData.set(new Uint8Array(buf2), buf1.byteLength);
  //         sendData.set(new Uint8Array(buf3), buf1.byteLength + buf2.byteLength);
  //         sendData.set(new Uint8Array(buf4), buf1.byteLength + buf2.byteLength + buf3.byteLength);

  //         client.send(sendData);
  //       });

  //       resolve(true);
  //     }
  //   });
  // };

  const sendMessage = (keyCode) => {
    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        if (message !== '') {
          let messageToSave = message;
          if (keyCode === 'Enter') messageToSave = message.slice(0, -11);
          const newDate = new Date();

          // send DM to recipient
          socket.emit('send-gc', {
            message: messageToSave,
            from: cookies['username'],
            fromId: cookies['userId'],
            to: groupChatSlug,
            date: newDate,
            currentViewedGroupChat: groupChatSlug,
            groupChatName: groupChatConversation.group_chat_name,
            groupUsernames: groupUsernames,
            type: 'gc'
          }, groupChatSlug);

          const groupChatsToUpdate = deepCopy(groupChats);
          groupChatsToUpdate.push({
            created_by: cookies['userId'],
            creator: {user_username: cookies['username']},
            date_last_updated: newDate,
            date_record_created: newDate,
            message: messageToSave
          })
          setGroupChats(groupChatsToUpdate);
          
          quill.setText('');

          setMessage('');
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - groupChats/show.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - groupChats/show.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };

  // const sendMessage = () => {
  //   // client.send(JSON.stringify({
  //   //       type: 'message',
  //   //       from: cookies['username'],
  //   //       to: groupChatSlug,
  //   //       msg: message,
  //   //       token: cookies['token'],
  //   //       filesExists: false,
  //   //       groupChatConversation: groupChatConversation,
  //   //       groupChatUserIds: groupChatUserIds
  //   //     }));


  //   // const sendFilePromises = [];

  //   // if (attachedFiles.length > 0 && message !== '') {
  //   //   if (client.readyState === client.OPEN) {
  //   //     client.send(JSON.stringify({
  //   //       type: 'message',
  //   //       from: cookies['username'],
  //   //       to: groupChatSlug,
  //   //       msg: message,
  //   //       token: cookies['token'],
  //   //       filesExists: true,
  //   //       fileCount: attachedFiles.length,
  //   //       groupChatConversation: groupChatConversation,
  //   //       groupChatUserIds: groupChatUserIds
  //   //     }));

  //   //     let counter = 0;

  //   //     Object.values(attachedFiles).forEach((attachedFile, index) => {
  //   //       const reader = new FileReader();
  
  //   //       reader.onload = (event) => {
  //   //         let rawData = new ArrayBuffer();
  //   //         rawData = event.target.result;
  //   //         const filePromise = sendFile(attachedFile, rawData, index, attachedFiles.length);
  //   //         sendFilePromises.push(filePromise);
  //   //         counter++;

  //   //         if (counter === attachedFiles.length) {
  //   //           Promise.all(sendFilePromises).then(() => {
  //   //             document.getElementById('attachedFiles').value = '';
  //   //           });
  //   //         }
  //   //       }
  
  //   //       reader.readAsArrayBuffer(attachedFile);
  //   //     });
  //   //   }
  //   // } else if (attachedFiles.length > 0) {
  //   //   if (client.readyState === client.OPEN) {
  //   //     client.send(JSON.stringify({
  //   //       type: 'message',
  //   //       from: cookies['username'],
  //   //       to: groupChatSlug,
  //   //       msg: '',
  //   //       token: cookies['token'],
  //   //       filesExists: true,
  //   //       fileCount: attachedFiles.length,
  //   //       groupChatConversation: groupChatConversation,
  //   //       groupChatUserIds: groupChatUserIds
  //   //     }));

  //   //     let counter = 0;

  //   //     Object.values(attachedFiles).forEach((attachedFile, index) => {
  //   //       const reader = new FileReader();
  
  //   //       reader.onload = (event) => {
  //   //         let rawData = new ArrayBuffer();
  //   //         rawData = event.target.result;
  //   //         const filePromise = sendFile(attachedFile, rawData, index, attachedFiles.length);
  //   //         sendFilePromises.push(filePromise);
  //   //         counter++;

  //   //         if (counter === attachedFiles.length) {
  //   //           Promise.all(sendFilePromises).then(() => {
  //   //             document.getElementById('attachedFiles').value = '';
  //   //           });
  //   //         }
  //   //       }
  
  //   //       reader.readAsArrayBuffer(attachedFile);
  //   //     });
  //   //   }
  //   // } else {
  //   //   if (client.readyState === client.OPEN) {
  //   //     client.send(JSON.stringify({
  //   //       type: 'message',
  //   //       from: cookies['username'],
  //   //       to: groupChatSlug,
  //   //       msg: message,
  //   //       token: cookies['token'],
  //   //       filesExists: false,
  //   //       groupChatConversation: groupChatConversation,
  //   //       groupChatUserIds: groupChatUserIds
  //   //     }));
  //   //   }
  //   // }

  //   quill.setText('');
  // };

  useEffect(() => {
    scrollToBottomOfConversation();
  }, [groupChats]);

  const scrollToBottomOfConversation = () => {
    let objDiv = document.getElementById("conversationHistory");
    if (objDiv !== null) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }

    setNewMessagesReceived(false);
  };

  const handleScroll = () => {
    let objDiv = document.getElementById("conversationHistory");
    if (objDiv !== null && (objDiv.scrollTop + objDiv.offsetHeight === objDiv.scrollHeight) && newMessagesReceived) setNewMessagesReceived(false);
  };

  const createMarkup = (message) => {
    return {__html: message};
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setAttachedFiles(files);
  };

  const refreshGroupChatConversation = () => {
    searchGroupChatConversationByGroupChatSlug(props.groupChatSlug).then(result => {
      
      if (result.data.users !== undefined) {
        const groupChatUserIdsToUpdate = [];
        result.data.users.forEach(user => {
          groupChatUserIdsToUpdate.push(user.id);
        });
  
        setGroupChatUserIds(groupChatUserIdsToUpdate);
  
        setGroupChatConversation(result.data);
        setGroupUsernames(result.data.users.map(user => user.user_username));
      }
    });
  };

  const refreshAttachmentList = () => {
    searchGroupChatAttachmentsByGroupChatSlug(props.groupChatSlug).then(result => {
      setGroupChatAttachments(result.data);
    })
  };

  useEffect(() => {
    checkWhetherCurrentUserCanViewGroupChat(props.groupChatSlug, cookies['userId']).then(result => {
      if (result.data === false) {
        navigate('/group-chats');
      } else {
        refreshAttachmentList();
        refreshGroupChatConversation();
      }
    })
  }, []);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setMessage(quillRef.current.firstChild.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (groupChatSlug !== undefined) {
      let objDiv = document.getElementById("conversationHistory");
      if (objDiv !== null) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }

      // find group chats
      findGroupChatsByGroupChatSlug(groupChatSlug, groupChats.length).then(results => {
        setLoadMoreMessagesButton(results.data.count !== results.data.rows.length);

        const reversed = [];
        for (let i = results.data.rows.length - 1; i >= 0; i -= 1) {
          reversed.push(results.data.rows[i]);
        }
        setGroupChats(reversed);

        let objDiv = document.getElementById("conversationHistory");
        if (objDiv !== null) objDiv.scrollTop = objDiv.scrollHeight;
      });

      refreshGroupChatConversation();
    }
  }, [groupChatSlug]);

  return (
    <Fragment>
      <ViewUserModal viewUsers={viewUsers} currentGroupChatSlug={groupChatSlug} currentUsername={cookies['username']} groupChatConversation={groupChatConversation} refreshGroupChatConversation={refreshGroupChatConversation} refreshConversationList={props.refreshConversationList} />
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-2 lg:px-8 py-1.5">
            <div>
              <button className="bg-sky-600 hover:bg-sky-700 p-2" onClick={event => toggleConversations(event)}>
                <h3 className="text-sm font-medium text-white">Conversations ({props.conversationCount})</h3>
              </button>
            </div>
            {/* <div>
              <button className="bg-sky-600 hover:bg-sky-700 p-2" onClick={event => toggleAttachments(event)}>
                <h3 className="text-sm font-medium text-white">Attachments ({groupChatAttachments.length})</h3>
              </button>
            </div> */}
          </div>
        </div>
        <div className="flex-1 relative z-0 flex overflow-hidden">
          {groupChatSlug !== undefined ?
            <main className="flex-1 relative z-0 focus:outline-none">
              {/* Start main area*/}
              <div className="bg-gray-100 px-2 py-1 border-t border-b border-gray-200 sticky top-0 flex justify-between justify-center items-center">
                {groupChatConversation !== null ? 
                  <h3 className="text-lg font-bold text-gray-900">CONVERSATION WITH {groupChatConversation.group_chat_name.toUpperCase()}</h3>
                : '' }

                <button type="button" className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500" onClick={toggleViewUsers}>Group Chat Info</button>
              </div>
              <ul role="list" className="divide-y divide-gray-200 overflow-auto space-y-1 pb-1.5" style={{height: "calc(100% - 251px)"}} id="conversationHistory" onScroll={handleScroll}>
                {loadMoreMessagesButton && (
                  <li className="px-4 sm:px-6 md:px-6 lg:px-3 py-2 mt-1">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center border border-transparent bg-sky-600 hover:bg-sky-700 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 w-[100%]"
                      onClick={() => {
                        let objDiv = document.getElementById("conversationHistory");

                        // find group chats
                        findGroupChatsByGroupChatSlug(groupChatSlug, groupChats.length).then(results => {
                          setLoadMoreMessagesButton(results.data.count !== results.data.rows.length);
                          
                          const reversed = [];
                          for (let i = results.data.rows.length - 1; i >= 0; i -= 1) {
                            reversed.push(results.data.rows[i]);
                          }
                          setGroupChats(reversed);

                          let objDiv = document.getElementById("conversationHistory");
                          if (objDiv !== null) objDiv.scrollTop = objDiv.scrollHeight;
                        });
                      }}
                    >
                      Load more messages
                    </button>
                  </li>
                )}
                {groupChats && groupChats.map(groupChat => {
                  return (
                    <li className="px-4 sm:px-6 md:px-6 lg:px-3 py-1" key={groupChat.id}>
                      <div className="flex flex-col">
                        <h3 className="font-medium">
                          <span className="text-sm text-gray-900">{groupChat.creator.user_username} <span className="font-light text-xs">{formatDate(new Date(groupChat.date_record_created))}</span></span>
                        </h3>
                        <div className="text-sm text-gray-700" dangerouslySetInnerHTML={createMarkup(groupChat.message)}></div>
                      </div>
                    </li>
                  )
                })}
              </ul>
              <div className="min-h-[250px] h-[250px]">
                {newMessagesReceived ? 
                  <div className="flex items-center justify-center">
                    <button
                      type="button"
                      className="mb-12 absolute items-center px-2.5 py-1.5 border border-transparent text-xs font-medium text-sky-700 bg-sky-100 hover:bg-sky-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                      onClick={scrollToBottomOfConversation}
                    >
                      New messages received
                    </button>
                  </div>
                : ''}
                {groupChatConversation !== null ?
                <div className="shadow overflow-hidden">
                  <div className="bg-gray-100 px-2 py-1 flex justify-between">
                    <h3 className="text-lg leading-6 font-bold text-gray-900">MESSAGE</h3>
                    <div className="flex flex-row">
                      {/* <input type="file" multiple onChange={(event) => handleFileChange(event)} id="attachedFiles" /> */}
                      <div className="grid gap-1 grid-cols-2">
                        {/* <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full">Attach</button> */}
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={discard}>Discard</button>
                        <button type="button" className="px-2.5 border border-transparent text-xs font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 h-full" onClick={sendMessage}>Send</button>
                      </div>
                    </div>
                  </div>
                  <div className="border-gray-200">
                    <div className="bg-white shadow overflow-hidden">
                      <div style={{ width: '100%', height: 218 }}>
                        <div ref={quillRef} onKeyDown={(e) => { if (e.key === 'Enter' && !(e.key === 'Enter' && e.shiftKey)) {sendMessage(e.key)}}} />
                      </div>
                    </div>
                  </div>
                </div>
                : ''}
              </div>   
              {/* End main area */}
            </main>
          : ''}
        </div>
      </div>
    </Fragment>
  )
};