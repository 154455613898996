import { useState, useEffect, Fragment, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { useParams, useNavigate } from 'react-router-dom'
import { addAuditLog, getPrivilegeForCurrentPage, populateActions, checkCompulsoryFields, prepareTableColumnsWithPrivilegeAndInputType, getAllTableAndColumns, updateProfile, getUserFromToken, listenToNotifications } from '../../scripts/helpers'

// components
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import AlertNotification from '../../components/alertNotification'
import SelectOption from '../../components/selectOption'
import { AuthContext } from '../../AuthProvider'

export default function ProfilesEdit() {
  const tableSlug = 'users';
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const [actions, setActions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedDropdown, setSelectedDropdown] = useState([]);

  const privilegeObject = {
    tableSlug: tableSlug, 
    token: cookies['token']
  };
  const navigate = useNavigate();
  const params = useParams();

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  useEffect(() => {
    // check whether current logged in user can even be on this current page
    // they may have typed the link themselves in the browser url bar
    getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
      const resultPrivileges = privilegesResult;
      const privilegesToUpdate = {};
      resultPrivileges.forEach((privilege) => {
        if (privilegesToUpdate[privilege['mode']] === undefined) {
          privilegesToUpdate[privilege['mode']] = {};
        }

        privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
      });

      // if user is not authorised to view this page, redirect back to home page
      if (privilegesToUpdate['Edit']['id'] === 'No') {
        navigate('/');
      }

      setPrivileges(privilegesToUpdate);
      getUserFromToken(cookies['token']).then((result) => {
        prepareTableColumnsWithPrivilegeAndInputType(tableSlug, privilegesToUpdate, 'Edit', result.data.id).then(preparedTableColumns => {
          preparedTableColumns.splice(0, 1);
          preparedTableColumns.splice(1, 3);
          preparedTableColumns.splice(4, 2);
          preparedTableColumns.splice(5, 6);
          // preparedTableColumns.splice(6, 7);
          preparedTableColumns.forEach((row, index) => {
            preparedTableColumns[index]['sequence'] = index + 1;
          })

          setTableColumns(preparedTableColumns);
  
          for (let i = 0; i < preparedTableColumns.length; i += 1) {
            if (preparedTableColumns[i]['column_name'] === 'tableAndColumn_id') {
              const selectedDropdownToUpdate = [];
              preparedTableColumns[i]['value'].forEach((value) => {
                selectedDropdownToUpdate.push(value);
              })
              
              setSelectedDropdown(selectedDropdownToUpdate);
              break;
            }
          }
        });
      });
    }).catch(err => {
      navigate('/');
    });
  }, []);

  useEffect(() => {
    if (Object.keys(privileges).length > 0) {
      const actionsToUpdate = populateActions(tableSlug, privileges, 'Edit');
      setActions(actionsToUpdate);
    }
  }, [privileges]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    const buttonClicked = event.nativeEvent.submitter.innerHTML;
    
    if (buttonClicked === 'Save') {
      const result = checkCompulsoryFields(tableColumns);
      if (result.allCompulsoryFieldsHaveBeenFilled) {

        const tableColumnsToSubmit = [...tableColumns];
        tableColumnsToSubmit.push({
          token: cookies['token']
        });

        updateProfile(tableColumnsToSubmit, cookies['token']).then(result => {
          addAuditLog({
            table_name: 'Users',
            record_id: cookies['userId'],
            action_type: 'Update',
            action_description: 'Updated profile.',
            user_id: cookies['userId']
          });

          navigate(`/profile`);
        }).catch(err => {
          setOutcome({
            message: err.response.data.message,
            type: 'Danger'
          });

          document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';

          
        });
      } else {
        const emptyFields = result.emptyFields;

        emptyFields.forEach((emptyField) => {
          if (document.getElementById(emptyField)) {
            document.getElementById(emptyField).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
          } else {
            if (document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0] !== undefined) {
              document.getElementsByClassName(`bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${emptyField}`)[0].className = `bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${emptyField}`;
            }
          }
        });

        setOutcome({
          message: 'Compulsory fields have not been filled.',
          type: 'Danger'
        });

         
      }
    } else {
      navigate('/profile');
    }
  };

  const handleChange = (column, event) => {
    const tableColumnsToUpdate = [...tableColumns];
    const selectedDropdownToUpdate = [...selectedDropdown];

    if (event.target !== undefined && event.target.type !== 'checkbox') {
      event.target.className = 'shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300';
    }

    // if checkbox, handle differently
    if (column['input_type'] === 'checkbox') {
      tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.checked;
    } else if (column['column_name'] === 'tableAndColumn_id') {
      const filteredSelectedDropdownToUpdate = selectedDropdownToUpdate.map((selectedDropdownToUpdateRow) => {
        return selectedDropdownToUpdateRow.column_name;
      });

      if (filteredSelectedDropdownToUpdate.indexOf(event.column_name) === -1) {
        tableColumnsToUpdate[column['sequence'] - 1]['value'].push(event);
        selectedDropdownToUpdate.push(event);
      }

      document.getElementsByClassName('w-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm')[0].value = '';
    } else {
      tableColumnsToUpdate[column['sequence'] - 1]['value'] = event.target.value;
    }

    setTableColumns(tableColumnsToUpdate);
    setSelectedDropdown(selectedDropdownToUpdate);
  };

  const handleDropdownChange = (rightStubObj, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const tableColumnsToUpdate = [...tableColumns];

    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn['sequence'] - 1].value = '';
    } else {
      tableColumnsToUpdate[tableColumn['sequence'] - 1].value = event.dropdown_item;
    }
    setTableColumns(tableColumnsToUpdate);
  };

  return (
    <>
      <Header />
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <SubHeader actions={actions} />
        <AlertNotification outcome={outcome} setOutcome={setOutcome} />
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5 shadow">
            <div className="bg-gray-100 px-2 py-1">
              <h3 className="text-lg leading-6 font-bold text-gray-900">EDIT MY PROFILE</h3>
            </div>
            <div className="border-t border-gray-200 px-4 pb-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="items-top sm:py-5 sm:grid sm:py-4 sm:grid-cols-3 sm:gap-4 sm:px-6">
                  {tableColumns.map((tableColumn, index) => {
                    if (tableColumn['dropdowns'].length > 0) {
                      return (
                        <Fragment key={index}>
                          <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                            <SelectOption tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={tableColumn['value']}/>
                            {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            {/* { tableColumn['remarks'] !== null ? <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p> : "" } */}
                          </dd>
                        </Fragment>
                      )
                    } else {
                      if (tableColumn['privilege'] === 'Yes') {
                        if (tableColumn['input_type'] === 'checkbox') {
                          if (tableColumn['value']) {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <input
                                    type="checkbox"
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    checked
                                    onChange={(event) => handleChange(tableColumn, event)}
                                    className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                  />
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          } else {
                            return (
                              <Fragment key={index}>
                                <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                  <input
                                    type="checkbox"
                                    name={tableColumn['column_name']}
                                    id={tableColumn['column_name']}
                                    onChange={(event) => handleChange(tableColumn, event)}
                                    className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                  />
                                  {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                                </dd>
                              </Fragment>
                            )
                          }
                        } else {
                          return (
                            <Fragment key={index}>
                              <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']} {tableColumn['is_nullable'] ? <></> : <>*</>}</dt>
                              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                <input
                                  type={tableColumn['input_type']}
                                  name={tableColumn['column_name']}
                                  id={tableColumn['column_name']}
                                  value={tableColumn['value']}
                                  onChange={(event) => handleChange(tableColumn, event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />
                                {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                              </dd>
                            </Fragment>
                          )
                        }
                      } else if (tableColumn['privilege'] === 'View') {
                        return (
                          <Fragment key={index}>
                            <dt className="mt-2 sm:mt-0 text-sm font-medium text-gray-500">{tableColumn['label_name']}</dt>
                            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                              <input
                                type={tableColumn['input_type']}
                                name={tableColumn['column_name']}
                                id={tableColumn['column_name']}
                                className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                placeholder={tableColumn['value']}
                                disabled
                              />
                              {tableColumn['remarks'] === 'NULL' || tableColumn['remarks'] === null || tableColumn['remarks'] === '' ? "" : <p className="mt-2 text-sm text-gray-500">{tableColumn['remarks']}</p>}
                            </dd>
                          </Fragment>
                        )
                      }
                    }
                  })}
                </div>
              </dl>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}