import { useState, useEffect, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { format } from 'date-fns'
import { getAuditLog, listenToNotifications } from '../../scripts/helpers'
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import _ from 'lodash'

import Header from '../../components/header'
import Loading from '../../components/loading'
import { AuthContext } from '../../AuthProvider'

const link = (workerId, jobId, problemId, recordId, tableName) => {
  if ([
    'ordinary-attachments',
    'sensitive-attachments'
  ].indexOf(tableName) !== -1) {
    if (workerId && jobId && problemId) {
      return `/workers/${workerId}/jobs/${jobId}/problems/${problemId}/${tableName}/${recordId}`;
    } else if (workerId && jobId) {
      return `/workers/${workerId}/jobs/${jobId}/${tableName}/${recordId}`;
    } else {
      return `/workers/${workerId}/${tableName}/${recordId}`;
    }
  } else if ([
      'aggravating-issues',
      'lead-case-workers',
      'auxiliary-case-workers',
      'interpreters',
      'ordinary-case-discussions',
      'sensitive-discussions',
      'warrants-to-act',
      'lawyers',
      'legal-or-medical-funding',
      'manpower-officers',
      'salary-histories',
      'salary-claim-statuses',
      'hearings-progress',
      'injury-histories',
      'illness-histories',
      'hospitals',
      'wica-claims-lodged',
      'wica-statuses',
      'insurer-details',
      'mc-statuses',
      'other-problems',
      'police-reports',
      'other-complaints-lodged',
      'criminal-case-milestones'
    ].indexOf(tableName) !== -1) {
    return `/workers/${workerId}/jobs/${jobId}/problems/${problemId}/${tableName}/${recordId}`;
  } else if ([
      'problems',
      'current-passes',
      'benefits',
      'ipa-details',
      'verbal-assurances-pre-job',
      'employment-contracts',
      'intermediary-agents',
      'employer-details',
      'workplaces',
      'work-histories',
      'accommodation-during-work',
      'transfer-and-repatriation',
    ].indexOf(tableName) !== -1) {
    return `/workers/${workerId}/jobs/${jobId}/${tableName}/${recordId}`;
  } else if ([
      'pdpa-attachments',
    ].indexOf(tableName) !== -1) {
    if (workerId) {
      return `/workers/${workerId}/${tableName}/${recordId}`;
    } else {
      return `/${tableName}/${recordId}`;
    }
  } else if ([
      'jobs',
      'twid-cards',
      'nicknames',
      'passports',
      'facepic-attachments',
      'singapore-phone-numbers',
      'digital-contacts',
      'home-country-phone-numbers ',
      'ticket-numbers',
      'addresses-in-singapore',
      'home-country-addresses',
      'next-of-kins',
      'family-members-in-singapore',
      'friends-in-singapore',
      'languages',
      'bank-accounts',
      'client-appointments',
    ].indexOf(tableName) !== -1) {
    return `/workers/${workerId}/${tableName}/${recordId}`;
  } else {
    return `/${tableName}/${recordId}`;
  }
};

export default function AuditLogIndex() {
  const [objects, setObjects] = useState([]);
  const [backButton, setBackButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [forwardButton, setForwardButton] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [sort, setSort] = useState({});
  const navigate = useNavigate();

  const toggleSort = (column) => {
    let sortToUpdate = {...sort};

    if (Object.keys(sortToUpdate).length === 1) {
      if (column !== Object.keys(sortToUpdate)[0]) {
        sortToUpdate = {};
      }
    }

    let objectsToUpdate = [...objects];

    if (objectsToUpdate[column] === undefined) {
      objectsToUpdate[column] = false;
    }

    if (sortToUpdate[column] === undefined) {
      sortToUpdate[column] = false;
    }

    if (sortToUpdate[column] === false) {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['asc']);
    } else {
      objectsToUpdate = _.orderBy(objectsToUpdate, [column], ['desc']);
    }

    sortToUpdate[column] = !sortToUpdate[column];
    setSort(sortToUpdate);
    setObjects(objectsToUpdate);
    setLoading(false);
  };

  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const page = searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page'), 10);

  useEffect(() => {
    getAuditLog(cookies['token'], page).then(result => {
      const totalPages = Math.ceil(result.data.count / 50);
      setTotalPages(totalPages);

      if (page === 1) {
        setBackButton(true);
        setForwardButton(false);
      } else if (page === totalPages) {
        setBackButton(false);
        setForwardButton(true);
      } else {
        setBackButton(false);
        setForwardButton(false);
      }

      setObjects(result.data.rows);
      setLoading(false);
    })
  }, [page]);

  if (loading) {
    return <><Header /><div style={{height: `calc(100vh - 64px)`}}><Loading /></div></>;
  } else {
    return (
      <>
        <Header />
        <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
          <div className="mt-5">
            <div className="pb-5 border-b border-gray-300 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-bold text-gray-900">
                AUDIT LOGS
              </h3>
              {(totalPages > 1) ?
                (
                  <span className="relative z-0 inline-flex shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={() => navigate(`/audit-log?page=${page - 1}`)}
                      disabled={backButton}
                    >
                      <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 focus:z-10 focus:outline-none"
                      disabled
                    >
                      {page} of {totalPages}
                    </button>
                    <button
                      type="button"
                      className="-ml-px relative inline-flex items-center px-2.5 border border-gray-300 bg-white text-sm text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none"
                      onClick={() => navigate(`/audit-log?page=${page + 1}`)}
                      disabled={forwardButton}
                    >
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </button>
                  </span>
                ) 
              : <></>}
            </div>
            <div className="mt-5 flex flex-col">
              <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-top inline-block min-w-full">
                  <div className="shadow overflow-hidden border-gray-300">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('date_record_created')}>
                              Record created
                              {sort['date_record_created'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['date_record_created'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('user_id')}>
                              User ID
                              {sort['user_id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['user_id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('action_type')}>
                              Action type
                              {sort['action_type'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['action_type'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('worker_id')}>
                              Worker ID
                              {sort['worker_id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['worker_id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('job_id')}>
                              Job ID
                              {sort['job_id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['job_id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('problem_id')}>
                              Problem ID
                              {sort['problem_id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['problem_id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('table_name')}>
                              Table name
                              {sort['table_name'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['table_name'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('record_id')}>
                              Record ID
                              {sort['record_id'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['record_id'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                          <th
                            scope="col"
                            className="border border-gray-300 border border-gray-300 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <span className="group inline-flex cursor-pointer" onClick={() => toggleSort('action_description')}>
                              Action description
                              {sort['action_description'] === true ? (<><ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                              {sort['action_description'] === false ? (<><ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" /></>) : (<></>)}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {objects.map((object, index) => (
                          <tr key={index}>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{format(new Date(object['date_record_created']), 'yyyy-MMM-dd HH:mm')}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['user_id']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['action_type']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">
                              <Link to={link(null, null, null, object['worker_id'], 'workers')} className="text-sky-500 font-medium hover:text-sky-700">
                                {object['worker_id']}
                              </Link>
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">
                              <Link to={link(object['worker_id'], null, null, object['job_id'], 'jobs')} className="text-sky-500 font-medium hover:text-sky-700">
                                {object['job_id']}
                              </Link>
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">
                              <Link to={link(object['worker_id'], object['job_id'], null, object['problem_id'], 'problems')} className="text-sky-500 font-medium hover:text-sky-700">
                                {object['problem_id']}
                              </Link>
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['table_name']}</td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">
                              {
                                object['problem_id'] ? (
                                  object['table_name'] === 'problems' ? (
                                    <Link to={link(object['worker_id'], object['job_id'], null, object['record_id'], 'problems')} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  ) : (
                                    <Link to={link(object['worker_id'], object['job_id'], object['problem_id'], object['record_id'], object['table_name'])} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  )
                                ) : object['job_id'] ? (
                                  object['table_name'] === 'jobs' ? (
                                    <Link to={link(object['worker_id'], null, null, object['record_id'], 'jobs')} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  ) : (
                                    <Link to={link(object['worker_id'], object['job_id'], null, object['record_id'], object['table_name'])} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  )
                                ) : (
                                  object['table_name'] === 'workers' ? (
                                    <Link to={link(null, null, null, object['record_id'], 'workers')} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  ) : (
                                    <Link to={link(object['worker_id'], null, null, object['record_id'], object['table_name'])} className="text-sky-500 font-medium hover:text-sky-700">
                                      {object['record_id']}
                                    </Link>
                                  )
                                )
                              }
                            </td>
                            <td className="border border-gray-300 px-2 py-1 text-sm text-gray-900">{object['action_description']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}