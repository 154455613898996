import { Fragment, useState, useEffect, Component, useContext } from 'react'
import { useCookies } from 'react-cookie'
import DatePicker from 'react-datepicker'
import { getMonth, getYear, format } from 'date-fns'
import { CheckIcon, SelectorIcon, ChevronUpIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Link, Navigate, useParams, useNavigate } from "react-router-dom"
import { editDefaultValue, editObject, getTableSlugFromDefaultTableSlug, getPrivilegeForCurrentPage, prepareDefaultTableColumnsWithPrivilegeAndInputType, listenToNotifications, addAuditLog, checkDeactivated, checkSystemDown, checkCookieToken } from '../../scripts/helpers'
import axios from 'axios'

import Header from '../../components/header';
import SelectOption from '../../components/selectOption'
import SubHeader2 from '../../components/subheader2'
import AlertNotification from '../../components/alertNotification';
import EditPrivilegeTable from '../../components/privileges/editPrivilegeTable';
import { AuthContext } from '../../AuthProvider'

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => startAt - i);
}

const years = range(100, getYear(new Date()) + 20);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function DefaultValuesEdit() {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie']);
  const [tableAndColumnDefaultValues, setTableAndColumnDefaultValues] = useState({});
  const [subHeaderObj, setSubHeaderObj] = useState([]);
  const [modifiedColumnDefaultValues, setModifiedColumnDefaultValues] = useState({});
  const [outcome, setOutcome] = useState({});
  const [privileges, setPrivileges] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const {socket, show, setShow, notifications, setNotifications, update} = context;
  useEffect(() => socket && listenToNotifications(socket, notifications, setNotifications, show, update), [socket]);

  const {defaultTableSlug} = params;

  const transformPrivileges = (privilegesResult, action) => {
    const resultPrivileges = privilegesResult;
    const privilegesToUpdate = {};
    resultPrivileges.forEach((privilege) => {
      if (privilegesToUpdate[privilege['mode']] === undefined) {
        privilegesToUpdate[privilege['mode']] = {};
      }

      privilegesToUpdate[privilege['mode']][privilege['tableAndColumn']['column_name']] = privilege['privilege'];
    });

    // if user is not authorised to view this page, redirect back to home page
    if (privilegesToUpdate[action]['id'] === 'No') {
      navigate('/');
    }

    return privilegesToUpdate;
  };

  const handleCalendarChange = (tableAndColumnDefaultValues, column, event) => {
    const tableColumnsToUpdate = [...tableAndColumnDefaultValues];
    tableColumnsToUpdate[column['sequence'] - 1].value = event;

    setTableAndColumnDefaultValues(tableColumnsToUpdate);
  };

  const handleDropdownChange = (tableAndColumnDefaultValues, tableColumn, column, event) => {
    if (document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0] !== undefined) {
      document.getElementsByClassName(`bg-red-50 relative w-full border border-red-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500 sm:text-sm ${column}`)[0].className = `bg-white relative w-full border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${column}`;
    }
    const tableColumnsToUpdate = [...tableAndColumnDefaultValues];
    if (event.dropdown_item === '-') {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = '';
    } else {
      tableColumnsToUpdate[tableColumn.sequence - 1].value = event.dropdown_item;
    }

    setTableAndColumnDefaultValues(tableAndColumnDefaultValues);
  };

  useEffect(() => {
    setSubHeaderObj({
      columnVisibility: true,
      table: defaultTableSlug, 
      page: 'Edit'
    });

    getTableSlugFromDefaultTableSlug(defaultTableSlug).then(result => {
      const tableSlug = result['data']['table_slug'];

      const privilegeObject = {
        tableSlug: tableSlug, 
        token: cookies['token']
      };

      getPrivilegeForCurrentPage(privilegeObject).then(privilegesResult => {
        const privilegesToUpdate = transformPrivileges(privilegesResult, 'Add');
        setPrivileges(privilegesToUpdate);
        
        prepareDefaultTableColumnsWithPrivilegeAndInputType(tableSlug, defaultTableSlug, privilegesToUpdate).then(result => {
          setTableAndColumnDefaultValues(result);
        });
      });
    })
  }, []);

  const handleChange = (index, column, event, input) => {
    const tableAndColumnDefaultValuesToUpdate = [...tableAndColumnDefaultValues];

    if (input === 'checkbox' && column.input_type === 'date') {
      if (event.target.checked === true) {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = new Date('0001-01-01');
      } else {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = '';
      }
    } else if (input === 'checkbox' && column.input_type === 'datetime-local') {
      if (event.target.checked === true) {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = new Date('1001-01-01 00:00');
      } else {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = '';
      }
    } else if (input === 'text') {
      if (event.target.checked === true) {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = "Current user's username";
      } else {
        tableAndColumnDefaultValuesToUpdate[index]['value'] = '';
      }
    } else {
      tableAndColumnDefaultValuesToUpdate[index][column] = event.target.value;
    }

    setTableAndColumnDefaultValues(tableAndColumnDefaultValuesToUpdate);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (checkCookieToken(document.cookie)) {
      checkDeactivated(cookies['token'])
      .then((result) => {
        const buttonClicked = event.nativeEvent.submitter.innerHTML;
    
        if (buttonClicked === 'Save') {
          let counter = 0;

          editObject(defaultTableSlug, tableAndColumnDefaultValues, 0).then(result => {
            addAuditLog({
              table_name: 'Default Value',
              action_type: 'Update',
              action_description: `Updated default value: ${tableAndColumnDefaultValues[0]['table_name']}.`,
              user_id: cookies['userId']
            });

            navigate(`/default-values`);
          }).catch(err => {
            setOutcome({
              message: err.response.data.message,
              type: 'Danger'
            });

            if (document.getElementById(err.response.data.input) !== null) {
              document.getElementById(err.response.data.input).className = 'shadow-sm focus:ring-red-500 bg-red-50 focus:border-red-500 block w-full sm:text-sm border-red-300';
            }

            
          });
        } else if (buttonClicked === 'Abandon') {
          navigate('/default-values');
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message === 'User forbidden.') {
              addAuditLog({
                table_name: 'Auth',
                action_type: 'Kicked out',
                action_description: `Check deactivated - defaultValues/edit.jsx - ${error}`,
                user_id: cookies['userId']
              }).then(result => {
                removeCookie('token');
                window.location.replace("/")
              })
            }
          }
        }
      });
      checkSystemDown()
      .then((result) => {
      }).catch((error) => {
        if (cookies['role'] !== 'Admin') {
          addAuditLog({
            table_name: 'Auth',
            action_type: 'Kicked out',
            action_description: `Check system down - defaultValues/edit.jsx - ${error}`,
            user_id: cookies['userId']
          }).then(result => {
          removeCookie('token');
          window.location.replace("/")
          });
        }
      });
    }
  };

  if (tableAndColumnDefaultValues.length > 0) {
    return (
      <>
        <Header />
        <form onSubmit={handleFormSubmit}>
          <SubHeader2 subHeaderObj={subHeaderObj} />
          <AlertNotification outcome={outcome} setOutcome={setOutcome} />
          <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <div className="mt-5">
              <div className="sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnDefaultValues[0]['table_name']}</h3>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-top inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-300">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Field name
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Default Value
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {tableAndColumnDefaultValues.map((tableColumn, index) => {
                            if (tableColumn['privilege'] === 'Yes') {
                              if (tableColumn['input_type'] === 'checkbox') {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <input
                                        type="checkbox"
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        onChange={(event) => handleChange(index, tableColumn, event, 'checkbox')}
                                        className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      />
                                    </td>
                                  </tr>
                                )
                              } else if (tableColumn['input_type'] === 'datetime-local') {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <DatePicker 
                                        renderCustomHeader={({
                                          date,
                                          changeYear,
                                          changeMonth,
                                          decreaseMonth,
                                          increaseMonth,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                        }) => (
                                          <div
                                            style={{
                                              margin: 10,
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </button>
                                            <select
                                              className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                              value={getYear(date)}
                                              onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                              {years.map((option) => (
                                                <option key={option} value={option}>
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <select
                                              className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                              value={months[getMonth(date)]}
                                              onChange={({ target: { value } }) =>
                                                changeMonth(months.indexOf(value))
                                              }
                                            >
                                              {months.map((option) => (
                                                <option key={option} value={option}>
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </button>
                                          </div>
                                        )}
                                        showTimeSelect
                                        selected={tableColumn['value']} 
                                        onChange={(event) => handleCalendarChange(tableAndColumnDefaultValues, tableColumn, event)} 
                                        dateFormat='yyyy-MMM-dd HH:mm' 
                                        id={tableColumn['column_name']}
                                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                        <br />
                                        <input
                                          type="checkbox"
                                          name={tableColumn['column_name']}
                                          id={tableColumn['column_name']}
                                          onChange={(event) => handleChange(index, tableColumn, event, 'checkbox')}
                                          className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                        /> <span className="mt-4 ml-2 text-sm font-medium text-gray-900 inline-block">Current date on add</span>
                                    </td>
                                  </tr>
                                )
                              } else if (tableColumn['input_type'] === 'date') {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <DatePicker 
                                        renderCustomHeader={({
                                          date,
                                          changeYear,
                                          changeMonth,
                                          decreaseMonth,
                                          increaseMonth,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                        }) => (
                                          <div
                                            style={{
                                              margin: 10,
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                              <ChevronLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </button>
                                            <select
                                              className="shadow-sm focus:ring-sky-500 ml-2 mr-1 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                              value={getYear(date)}
                                              onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                              {years.map((option) => (
                                                <option key={option} value={option}>
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <select
                                              className="shadow-sm focus:ring-sky-500 ml-1 mr-2 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                              value={months[getMonth(date)]}
                                              onChange={({ target: { value } }) =>
                                                changeMonth(months.indexOf(value))
                                              }
                                            >
                                              {months.map((option) => (
                                                <option key={option} value={option}>
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </button>
                                          </div>
                                        )}
                                        selected={tableColumn['value']} 
                                        onChange={(event) => handleCalendarChange(tableAndColumnDefaultValues, tableColumn, event)} 
                                        dateFormat='yyyy-MMM-dd' 
                                        id={tableColumn['column_name']}
                                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300" />
                                      <br />
                                      <input
                                        type="checkbox"
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        onChange={(event) => handleChange(index, tableColumn, event, 'checkbox')}
                                        className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      /> <span className="mt-4 ml-2 text-sm font-medium text-gray-900 inline-block">Current date on add</span>
                                    </td>
                                  </tr>
                                )
                              } else if (tableColumn['dropdowns'] !== undefined && tableColumn['dropdowns'].length > 0) {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <SelectOption rightStubObj={tableAndColumnDefaultValues} tableColumn={tableColumn} column={tableColumn['column_name']} values={tableColumn['dropdowns']} handleDropdownChange={handleDropdownChange} selectedValue={tableColumn['value']}/>
                                    </td>
                                  </tr>
                                )
                              } else {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <input
                                        type='text'
                                        name={tableColumn['id']}
                                        id={tableColumn['id']}
                                        value={tableColumn['value']}
                                        onChange={(event) => handleChange(index, 'value', event)}
                                        className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                      />  
                                      <input
                                        type="checkbox"
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        onChange={(event) => handleChange(index, tableColumn, event, 'text')}
                                        className="focus:ring-sky-500 h-4 w-4 text-sky-600 border-gray-300"
                                      /> <span className="mt-4 ml-2 text-sm font-medium text-gray-900 inline-block">Current user's username</span>
                                    </td>
                                  </tr>
                                )
                              }
                            } else if (tableColumn['privilege'] === 'View') {
                              if (tableColumn['label_name'] === 'Created by') {
                                
                              } else {
                                return (
                                  <tr key={index}>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableColumn['column_name']}</td>
                                    <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <input
                                        type={tableColumn['input_type']}
                                        name={tableColumn['column_name']}
                                        id={tableColumn['column_name']}
                                        className="block w-full border-gray-300 bg-gray-300 text-gray-400 sm:text-sm"
                                        placeholder={tableColumn['value']}
                                        disabled
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* <form onSubmit={handleFormSubmit}>
          <SubHeader2 subHeaderObj={subHeaderObj} />
          
          <div className="max-w-7xl mx-auto px-2 md:px-6 lg:px-8 lg:block">
            <div className="mt-5">
              <div className="sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{tableAndColumnDefaultValues[0]['table_name']}</h3>
              </div>
              <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto">
                  <div className="py-2 align-top inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-300">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="border border-gray-300 border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Field name
                            </th>
                            <th
                              scope="col"
                              className="border border-gray-300 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Default Value
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {tableAndColumnDefaultValues.map((tableAndColumnVisibility, index) => 
                            <tr key={index}>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{tableAndColumnVisibility['column_name']}</td>
                              <td className="border border-gray-300 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <input
                                  type='text'
                                  name={tableAndColumnVisibility['id']}
                                  id={tableAndColumnVisibility['id']}
                                  value={tableAndColumnVisibility['default_value']}
                                  onChange={(event) => handleChange(index, 'default_value', event)}
                                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300"
                                />  
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form> */}
      </>
    )
  } else {
    return (
      <></>
    )
  }
}